import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../context";
export default function User() {
  const http = global.services.http;
  const userDetails = useAuthState(); //read user details from context
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const http = global.services.http;
    setLoading(true);
    const getUser = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/users/${id}`);
      if (req) {
        setUser(req.data.data);
        setLoading(false);
      }
    };
    getUser();
  }, [id]);

  const getUser = async () => {
    var req = await http.get(`${process.env.REACT_APP_API}/users/${id}`);
    if (req) {
      setUser(req.data.data);
      setLoading(false);
    }
  };

  console.log("user", user);

  return (
    <Layout title={`User: ${user && user.title ? user.title : "loading"}`}>
      {user && (
        <div className={"bg-white w-full p-4 mb-8 shadow rounded"}>
          <h1 className="font-bold text-2xl mb-8">
            {user && user.first_name} {user && user.last_name}
          </h1>
          <p className="mb-4">Email: {user && user.email}</p>
          <h2 className="font-bold text-xl mb-4">Stations</h2>
          {user.stations && user.stations.length > 0 ? (
            <div className="mb-4 block">
              {user.stations.map((station) => (
                <Link
                  key={`new=${station.id}`}
                  to={`/station/${station.id}`}
                  className="block p-2 hover:bg-gray-200"
                >
                  {station.name}
                </Link>
              ))}
            </div>
          ) : (
            <div className="mb-4">No stations</div>
          )}{" "}
          <h2 className="font-bold text-xl mb-4">Owned Stations</h2>
          {user.owned_stations && user.owned_stations.length > 0 ? (
            <div className="mb-4 block">
              {user.owned_stations.map((station) => (
                <Link
                  key={`hello=${station.id}`}
                  to={`/station/${station.id}`}
                  className="block py-2 hover:bg-gray-200"
                >
                  {station.name}
                </Link>
              ))}
            </div>
          ) : (
            <div>
              <div className="">No Owned stations</div>
            </div>
          )}
        </div>
      )}

      {user && (
        <div className={"text-center py-6"}>
          <Link to={`/users`}>Back to users.</Link>
        </div>
      )}
    </Layout>
  );
}
