import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { useAuthState } from "../context";
import Spinner from "../components/Spinner";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  first_name: yup.string(),
  last_name: yup.string(),
  role: yup.string(),
});

export default function AddUser() {
  // let history = useHistory();
  const http = global.services.http;
  const userDetails = useAuthState(); //read user details from context
  let history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (userDetails.role !== "admin") {
      history.push("/");
      return;
    }
  }, []);

  const userSubmit = async (payload) => {
    if (loading === true) return;

    // handle submitting the form
    console.log(payload);

    let fields = { ...payload };

    setLoading(true);
    try {
      var req = await http.post(`${process.env.REACT_APP_API}/users`, {
        ...fields,
      });
      if (!req) return;
      setLoading(false);
      //   history.push(`/users`);
    } catch (error) {
      console.log(error);
      setErrorMessage(error);
    }
  };

  return (
    <Layout title="Add New User">
      <div className={"max-w-2xl p-4 mx-auto"}>
        {errorMessage ? <p>{errorMessage}</p> : null}

        <form onSubmit={handleSubmit(userSubmit)}>
          <div className="mb-8">
            <label
              htmlFor="name"
              className={`block font-bold text-sm mb-2 ${
                errors.email ? "text-red-400" : "text-gray-800"
              }`}
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="email"
              className={`border-solid border-gray-300 border py-2 px-4 w-full
              rounded text-gray-700  ${
                errors.email ? "text-red-300 border-red-400" : " "
              }`}
              {...register("email")}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-2">
                A valid email is required.
              </p>
            )}
          </div>
          <div className="mb-8">
            <label
              htmlFor="first_name"
              className={`block font-bold text-sm mb-2 ${
                errors.bio ? "text-red-400" : "text-gray-900"
              }`}
            >
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              placeholder="First Name"
              className={`border-solid border-gray-300 border py-2 px-4 w-full
              rounded text-gray-700  ${
                errors.first_name ? "border-red-400" : " "
              }`}
              {...register("first_name")}
            />
            {errors.first_name && (
              <p className="text-red-500 text-sm mt-2">
                A description is required
              </p>
            )}
          </div>
          <div className="mb-8">
            <label
              htmlFor="last_name"
              className={`block font-bold text-sm mb-2 ${
                errors.bio ? "text-red-400" : "text-gray-900"
              }`}
            >
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Last Name"
              className={`border-solid border-gray-300 border py-2 px-4 w-full
              rounded text-gray-700 ${
                errors.last_name ? "border-red-400" : " "
              }`}
              {...register("last_name")}
            />
            {errors.last_name && (
              <p className="text-red-500 text-sm mt-2">
                A description is required
              </p>
            )}
          </div>
          <div className="mb-8">
            <label
              htmlFor="role"
              className={`block font-bold text-sm mb-2 ${
                errors.bio ? "text-red-400" : "text-gray-900"
              }`}
            >
              User Role
            </label>
            <select {...register("role")} id="role">
              <option value="presenter">Presenter</option>
              <option value="librarian">Librarian</option>
              <option value="admin">Admin</option>
            </select>

            {errors.last_name && (
              <p className="text-red-500 text-sm mt-2">
                A description is required
              </p>
            )}
          </div>

          <div className="mb-8">
            <label
              htmlFor="password"
              className={`block font-bold text-sm mb-2 ${
                errors.password ? "text-red-400" : "text-gray-900"
              }`}
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              className={`border-solid border-gray-300 border py-2 px-4 w-full
              rounded text-gray-700 ${
                errors.password ? "border-red-400" : " "
              }`}
              {...register("password")}
            />
            {errors.password && (
              <p className="text-red-500 text-sm mt-2">
                A description is required
              </p>
            )}
          </div>
          <button className="inline-block bg-blue-500 text-white rounded shadow py-2 px-5 text-sm">
            Add User
            {loading && <Spinner className="w-4 h-4 ml-2" />}
          </button>
          <Link
            className="ml-4 inline-block bg-red-500 text-white rounded shadow py-1 px-3 text-xs"
            to={`/users`}
          >
            Cancel
          </Link>
        </form>
      </div>
    </Layout>
  );
}
