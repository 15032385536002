import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { StationContext } from "../context/station-context";
import SectionHeader from "../components/SectionHeader";
import Modal from "react-modal";
import { Pad, returnWeekday } from "../services/pad";
import AddSlot from "../components/Grid/AddSlot";
import EpisodeItem from "../components/EpisodeItem";
import WidgetEmbed from "../components/Embeds/WidgetEmbed";
import { useAuthState } from "../context";
import {
  PlusIcon,
  PencilAltIcon,
  ViewListIcon,
  TrashIcon,
} from "@heroicons/react/outline";

export default function Widget() {
  const { id, station_id } = useParams();
  const http = global.services.http;
  const userDetails = useAuthState();
  const [widget, setWidget] = useState(null);
  const { station, setStation, stationOwner } =
    React.useContext(StationContext);
  const [editSlot, setEditSlot] = useState(null);
  const [theme, setTheme] = useState(null);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [station]);

  useEffect(() => {
    const http = global.services.http;
    const getWidget = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/widgets/${id}`);
      if (req) {
        setWidget(req.data.data);
      }
    };
    getWidget();
  }, [id]);

  useEffect(() => {
    if (widget) setTheme(JSON.parse(widget.theme));
  }, [widget]);

  const getWidget = async () => {
    var req = await http.get(`${process.env.REACT_APP_API}/widgets/${id}`);
    if (req) {
      setWidget(req.data.data);
    }
  };

  const deleteSlot = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?") === true) {
      var req = await http.delete(`${process.env.REACT_APP_API}/slots/${id}`);
      if (req) {
        console.log("req");
        await getWidget();
      }
    }
  };

  const finishUpdate = async () => {
    setEditSlot(null);
    getWidget();
    console.log("Slot updated");
  };

  // const EditModal = () => (
  //   <Modal
  //     isOpen={editSlot !== null}
  //     // onAfterOpen={afterOpenModal}
  //     onRequestClose={finishUpdate}
  //     // style={customStyles}
  //     contentLabel="Edit Widget Time"
  //   >
  //     <AddSlot slot={editSlot} onComplete={finishUpdate} />
  //   </Modal>
  // );

  return (
    <Layout route="widgets" title={widget && widget.name}>
      {/* <EditModal /> */}
      <div className={"mb-4  bg-white rounded-xl shadow-lg mb-8"}>
        <SectionHeader
          content={
            widget && (
              <div className={"font-bold text-left text-2xl"}>
                {widget.name}
              </div>
            )
          }
        />
        <div className="w-full pb-4 pl-4 pr-4 flex gap-4">
          {(stationOwner == true || userDetails.role === "admin") && (
            <Link
              className={
                "border-lightBlue-600 border text-lightBlue-600 rounded shadow py-2 px-4 text-uppercase text-sm flex gap-2"
              }
              to={`/station/${station_id}/widget/${id}/edit`}
            >
              <PencilAltIcon className={"w-5 h-5"} />
              Edit Widget
            </Link>
          )}
          <Link
            to={`/station/${station_id}/widgets`}
            className="border-lightBlue-600 border text-lightBlue-600 rounded shadow py-2 px-4 text-uppercase text-sm flex gap-2"
          >
            <ViewListIcon className="w-4 h-4 mr-2" />
            <span>All Widgets</span>
          </Link>
          {(stationOwner == true || userDetails.role === "admin") && (
            <Link
              to={`/station/${station_id}/widget/new`}
              className="border-lightBlue-600 border text-lightBlue-600 rounded shadow py-2 px-4 text-uppercase text-sm flex gap-2"
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              <span>New Widget</span>
            </Link>
          )}
        </div>
        <div className="w-full pb-4 pl-4 pr-4 lg:pb-4 lg:pl-4 lg:pr-4 md:flex gap-8">
          <div className="flex-grow">
            {widget && widget.type && (
              <p className={"mb-2"}>
                <span className={"font-bold"}>WIDGET TYPE:</span> {widget.type}
              </p>
            )}
            {theme && (
              <div>
                {widget &&
                  widget.type === "ondemand" &&
                  theme &&
                  theme.paginate !== null && (
                    <p className={"mb-2"}>
                      <span className={"font-bold"}>PAGINATION:</span>{" "}
                      {theme.paginate ? "Yes" : "No"}
                    </p>
                  )}
                {theme && theme.limit && (
                  <p className={"mb-2"}>
                    <span className={"font-bold"}>POST PER PAGE:</span>{" "}
                    {theme.limit}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {station && widget && <WidgetEmbed station={station} url={widget.url} />}
    </Layout>
  );
}
