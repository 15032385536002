import React, { useState, useEffect } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";

import { useAuthState } from "../../context";
import { useHistory } from "react-router-dom";
import WidgetOndemandForm from "./WidgetOndemandForm";
import WidgetSectionForm from "./WidgetSectionForm";
import WidgetGridForm from "./WidgetGridForm";
import WidgetStreamForm from "./WidgetStreamForm";

export default function WidgetForm({ id = null, stationId = null }) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const userDetails = useAuthState();
  const { station, setStation } = React.useContext(StationContext);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [widget, setWidget] = useState(null);
  const [type, setType] = useState("ondemand");
  const [theme, setTheme] = useState(null);
  const [disabled, setDisabled] = useState(
    widget && widget.explicit === 1 ? 1 : 0
  );

  useEffect(() => {
    if (widget) setTheme(JSON.parse(widget.theme));
  }, [widget]);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${stationId}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
        if (userDetails.role !== "admin") {
          if (req.data.data.owner_id !== userDetails.userDetails.id) {
            history.push("/");
          }
        }
      }
    };

    if (!station && userDetails) {
      getStation();
    } else {
      // setThisStation()
    }
  }, [stationId, userDetails]);

  useEffect(() => {
    const http = global.services.http;
    const getWidget = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/widgets/${id}`);
      if (req) {
        setWidget(req.data.data);
        setType(req.data.data.type);
        setDisabled(req.data.data && req.data.data.disabled === 1 ? 1 : 0);
      }
    };
    if (id) {
      getWidget();
    }
  }, [id]);

  const widgetSubmit = async (payload) => {
    if (loading === true) return;
    let fields = { ...payload, type: type, station_id: stationId };
    fields.disabled = disabled;
    console.log(userDetails);

    if (!id) {
      fields.is_counted = 1;
      fields.active = 1;
    }

    // handle submitting the form
    setLoading(true);
    try {
      if (id) {
        var req = await http.put(
          `${process.env.REACT_APP_API}/widgets/${id}`,
          fields
        );
      } else {
        var req = await http.post(
          `${process.env.REACT_APP_API}/widgets`,
          fields
        );
      }

      setLoading(false);
      if (widget) {
        history.push(`/station/${station.id}/widget/${widget.id}`);
      } else {
        history.push(`/station/${station.id}/widget/${req.data.data.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(watch("email"));
  const Header = () => (
    <p className={"text-lg font-bold"}>{` ${
      id ? `Edit Widget: ${widget && widget.name}` : "Add New Widget"
    }`}</p>
  );

  return (
    <div className={"max-w-full  mr-auto  bg-white shadow-lg rounded-xl"}>
      <SectionHeader content={<Header />} />
      <div className="p-4 block  bg-white gap-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        {station && ((id && widget) || !id) && (theme || !id) ? (
          <>
            <label htmlFor={"type"} className={`block font-bold text-sm mb-2 `}>
              Choose a widget type
            </label>
            <select
              className={`mb-8 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  `}
              value={type}
              id="type"
              onChange={(e) => setType(e.target.value)}
            >
              <option value={"stream"}>Live Stream</option>
              <option value={"ondemand"}>On-Demand Widget</option>
              <option value={"sections"}>Station Sections</option>
              <option value={"grid"}>Grid</option>
            </select>
            {type === "ondemand" && (
              <WidgetOndemandForm
                loading={loading}
                setLoading={() => setLoading()}
                station={station}
                widget={widget}
                theme={theme}
                onFormComplete={(payload) => widgetSubmit(payload)}
              />
            )}
            {type === "sections" && (
              <WidgetSectionForm
                loading={loading}
                setLoading={() => setLoading()}
                station={station}
                widget={widget}
                theme={theme}
                onFormComplete={(payload) => widgetSubmit(payload)}
              />
            )}
            {type === "grid" && (
              <WidgetGridForm
                loading={loading}
                setLoading={() => setLoading()}
                station={station}
                widget={widget}
                theme={theme}
                onFormComplete={(payload) => widgetSubmit(payload)}
              />
            )}
            {type === "stream" && (
              <WidgetStreamForm
                loading={loading}
                setLoading={() => setLoading()}
                station={station}
                widget={widget}
                theme={theme}
                onFormComplete={(payload) => widgetSubmit(payload)}
              />
            )}
          </>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
