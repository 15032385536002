import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import { StationContext } from "../context/station-context";
import ReplaceAudioForm from "../components/forms/ReplaceAudioForm";
import UploadRoll from "../components/forms/UploadRoll";
import RollList from "../components/rolls/RollList";
import { Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { userCheck } from "../config/functions";
import { useAuthState } from "../context";

export default function Rolls() {
  const { id } = useParams();
  const { station, setStation } = React.useContext(StationContext);
  const userDetails = useAuthState();
  const [update, setUpdate] = React.useState(false);
  const [newRoll, setNewRoll] = React.useState(false);
  const [edit, setEdit] = React.useState(null);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/stations/${id}`);
      if (req) {
        console.log("user details", userDetails.userDetails);
        setStation(req.data.data);
        userCheck({ station: req.data.data, user: userDetails });
      }
    };
    if (!station) getStation();
  }, [id]);

  const refresh = () => {
    setUpdate(!update);
    setEdit(null);
    setNewRoll(false);
  };

  const ContentHeader = () => {
    return (
      <div className="flex items-center justify-between">
        <h2 className="font-bold ">
          Pre-roll your ondemand episodes with custom audio.
        </h2>
        <div>
          <button
            className="px-4 py-2 rounded-md bg-blue-500 text-white"
            onClick={() => setNewRoll(true)}
          >
            New Pre-Roll
          </button>
        </div>
      </div>
    );
  };

  const NewModal = ({ episode, closeModal, setEpisode }) => (
    <>
      <Transition show={newRoll !== null}>
        {/* Background overlay */}
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={() => closeModal()}
            className=" max-w-screen fixed z-40 left-0 top-0 right-0 bg-gray-900 bg-opacity-70 bottom-0 "
          ></div>
        </Transition.Child>
        {/* Sliding sidebar */}
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className=" max-w-full fixed z-50  w-[400px] top-0 right-0 bg-white p-4 bottom-0  overflow-y-auto">
            <div className="w-full flex justify-between mb-4 items-center">
              <h2 className="font-bold">NEW PRE-ROLL</h2>
              <button onClick={() => closeModal()}>
                <XCircleIcon className="w-8 h-8 hover:text-gray-800" />
              </button>
            </div>
            <UploadRoll station={station} onAdded={refresh} />
          </div>
          {/* ... */}
        </Transition.Child>
      </Transition>
    </>
  );

  const EditModal = ({ episode, closeModal, setEpisode }) => (
    <>
      <Transition show={edit !== null}>
        {/* Background overlay */}
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={() => closeModal()}
            className=" max-w-screen fixed z-40 left-0 top-0 right-0 bg-gray-900 bg-opacity-70 bottom-0 "
          ></div>
        </Transition.Child>
        {/* Sliding sidebar */}
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className=" max-w-full fixed z-50  w-[400px] top-0 right-0 bg-white p-4 bottom-0  overflow-y-auto">
            <div className="w-full flex justify-between mb-4 items-center">
              <h2 className="font-bold">EDIT PRE-ROLL</h2>
              <button onClick={() => closeModal()}>
                <XCircleIcon className="w-8 h-8 hover:text-gray-800" />
              </button>
            </div>
            <UploadRoll roll={edit} station={station} onAdded={refresh} />
          </div>
          {/* ... */}
        </Transition.Child>
      </Transition>
    </>
  );

  return (
    <Layout route="rolls" title={station && `Replace Audio: ${station.name}`}>
      <SectionHeader content={<ContentHeader />} />
      {newRoll && <NewModal closeModal={() => setNewRoll(false)} />}
      {edit !== null && <EditModal closeModal={() => setEdit(null)} />}
      {station && (
        <div>
          <RollList station={station} update={update} setEdit={setEdit} />
        </div>
      )}
    </Layout>
  );
}
