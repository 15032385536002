import React, { useState, useCallback } from "react";
import Spinner from "./Spinner";
import { formatBytes } from "../config/functions";
import { useDropzone } from "react-dropzone";
import MultiTrackFile from "./MultiTrackFile";
import { XCircleIcon } from "@heroicons/react/outline";

const MultiTrackUpload = ({
  accept = "audio/*",
  onComplete = () => alert("Done"),
  maxNoFiles = 10,
}) => {
  const max = 104857600;
  const maximumFileSize = formatBytes(104857600);
  const [dragOn, setDragOn] = useState(false);
  const [files, setFiles] = useState([]);
  const [completed, setCompleted] = useState([]);
  const http = global.services.http;
  const [fileUploadProgress, setFileUploadProgress] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (maxNoFiles <= files.length) {
        alert(
          `You have reached the maximum of 10 tracks uploaded, you can upload ${
            maxNoFiles - files.length
          } more files`
        );
        return;
      }
      setDragOn(false);
      const newL = acceptedFiles.map((item) => {
        return {
          file: item,
          name: item.name,
        };
      });
      setFiles([...newL, ...files]);
    },
    [files]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
    maxFiles: 2,
    maxSize: max,
    multiple: true,
    onDragEnter: () => setDragOn(true),
    onDragLeave: () => setDragOn(false),
  });

  return (
    <div className={"lg:flex gap-4"}>
      <div className="w-full lg:w-96">
        <h3 className="font-bold mb-3">Step 1: Uploading some tracks</h3>
        <div {...getRootProps()} className={"mb-5"}>
          <input accept={accept} {...getInputProps()} />
          <div className="flex justify-center">
            <div className="w-full">
              <label
                className={`flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none ${
                  dragOn === true ? "bg-gray-200" : ""
                }`}
              >
                <span className="flex items-center space-x-2">
                  {fileUploadProgress ? (
                    <Spinner className={"text-red-500 w-8 h-8"} />
                  ) : dragOn === true ? (
                    <p>Drop file to upload</p>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                      <span className="font-medium text-gray-600 text-xs">
                        Drop up audio files here, or
                        <br />
                        <span className="text-blue-600 underline text-xs">
                          Browse
                        </span>{" "}
                        <br />
                        <span className="text-xs text-gray-400">
                          (max {maximumFileSize} per file}
                        </span>
                        <br />
                        <span className="text-xs text-gray-400">
                          Accepts ({accept})
                        </span>{" "}
                        <br />
                        <span className="text-xs font-bold text-gray-400">
                          Maximum 2 at a time
                        </span>
                      </span>
                    </>
                  )}
                </span>
              </label>
            </div>
          </div>
        </div>
        {/* <p>By uploading audio you agree you</p> */}
      </div>
      <div className="w-full">
        <h3 className="font-bold mb-3">Step 2: Fill In Track Meta Data</h3>
        {files.length > 0 && (
          <div>
            {files.map((file, i) => (
              <MultiTrackFile key={`file-${file.file.path}`} file={file.file} />
            ))}
          </div>
        )}
        <div className="mt-4 w-full flex justify-end ">
          <button
            onClick={() => onComplete()}
            className="ml-auto hover:bg-blue-500 rounded-xl hover:text-white text-brandPrimary flex gap-2 items-center text-xs py-1 px-3"
          >
            CLOSE <XCircleIcon className="w-8 h-8" />
          </button>
        </div>
      </div>
      {/* 
      {completed.length > 0 && (
        <div>
          {completed.map((file, i) => (
            <div key={`c${file.id}`}>
              {file.filename} Has finished uploading,{" "}
              <button onClick={() => onComplete(file.id)}>
                click here to edit
              </button>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
};
export default MultiTrackUpload;
