import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuthState } from "../context";
import { StationContext } from "../context/station-context";
import EpisodeSearch from "../components/forms/EpisodeSearch";
import ReactPaginate from "react-paginate";
import moment from "moment";
import EpisodeItem from "../components/EpisodeItem";
import "moment-timezone";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import NewPageHeader from "../components/Global/NewPageHeader";
// import { Link } from 'react-router-dom';

function convertUTCToTimezone(utcDt, utcDtFormat, timezone) {
  return moment.utc(utcDt, utcDtFormat).tz(timezone).format();
}

export default function Episodes() {
  const { no, station_id } = useParams();
  const queryP = useLocation().search;
  const searchTest = new URLSearchParams(queryP).get("search");
  const { station, setStation } = React.useContext(StationContext);
  let history = useHistory();
  const userDetails = useAuthState(); //read user details from context
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(null);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState(searchTest);
  const [onlyNew, setOnlyNew] = useState(true);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
  }, []);

  useEffect(() => {
    const http = global.services.http;
    const getEpisode = async () => {
      setLoading(true);
      var filter = JSON.stringify({ station_id: station_id });
      var searchString =
        search !== "" ? `&search=${encodeURIComponent(search)}` : "";
      var onlyNewString = onlyNew === true ? `&only_new=true` : "";
      var req = await http.get(
        `${process.env.REACT_APP_API}/episodes?page=${pageNo}&filter=${filter}${onlyNewString}${searchString}`
      );
      if (req.data) {
        setEpisodes(req.data.data);
        console.log(req.data.meta.last_page);
        setPageCount(req.data.meta.last_page + 1);
        setLoading(false);
      }
    };
    getEpisode();
  }, [pageNo, search, onlyNew]);

  const getEpisode = async () => {
    const http = global.services.http;
    setLoading(true);
    var filter = JSON.stringify({ station_id: station_id });
    var req = await http.get(
      `${process.env.REACT_APP_API}/episodes?page=${pageNo}&filter=${filter}&only_new=true`
    );
    if (req.data) {
      setEpisodes(req.data.data);
      console.log(req.data.meta.last_page);
      setPageCount(req.data.meta.last_page + 1);
      setLoading(false);
    }
  };

  const handlePageClick = (event) => {
    console.log(event);
    setPageNo(event.selected + 1);
    var searchString =
      search !== "" ? `&search=${encodeURIComponent(search)}` : "";
    history.push(
      `/station/${station_id}/episodes/${event.selected + 1}?${searchString}`
    );
  };

  // const EditCell = ({ value, column, row }) => {
  //   const start = convertUTCToTimezone(
  //     row.original.show_start,
  //     null,
  //     row.original.station.timezone
  //   );
  //   return (
  //     <>
  //       <Link className={"mr-2"} to={`/album/${row.original.id}`}>
  //         View
  //       </Link>

  //       {(userDetails.role === "admin" || userDetails.role === "librarian") && (
  //         <Link to={`/episodes/${row.original.id}/edit`}>Edit</Link>
  //       )}

  //       {moment().isAfter(start) && row.original.station.hls_stream ? (
  //         <button
  //           className={"ml-2"}
  //           onClick={() =>
  //             setCurrent(
  //               `${process.env.REACT_APP_API}/public/ondemand/${row.original.station.hls_stream}/${row.original.timestamp}/${row.original.duration}/master.m3u8`
  //             )
  //           }
  //         >
  //           Play
  //         </button>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };
  // const StationCell = ({ value, column, row }) => {
  //   return (
  //     <>
  //       <Link className={"mr-2"} to={`/station/${row.original.station.id}`}>
  //         {row.original.station.name}
  //       </Link>
  //     </>
  //   );
  // };
  // const ProgramCell = ({ value, column, row }) => {
  //   return (
  //     <>
  //       <Link className={"mr-2"} to={`/program/${row.original.program.id}`}>
  //         {row.original.program.name}
  //       </Link>
  //     </>
  //   );
  // };
  // const DurationCell = ({ value, column, row }) => {
  //   return moment.utc(row.original.duration * 1000).format("HH:mm:ss");
  // };

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: "ID",
  //       accessor: "id",
  //     },
  //     {
  //       Header: "Program",
  //       accessor: "program_id",
  //       Cell: ProgramCell,
  //     },
  //     {
  //       Header: "Start",
  //       accessor: "show_start",
  //     },
  //     {
  //       Header: "End",
  //       accessor: "show_end",
  //     },
  //     {
  //       Header: "Duration",
  //       accessor: "duration",
  //       Cell: DurationCell,
  //     },

  //     {
  //       Header: "Station",
  //       accessor: "station_id",
  //       Cell: StationCell,
  //     },
  //     // {
  //     //   Header: "Artist",
  //     //   accessor: "artist_id",
  //     //   Cell: ArtistCell,
  //     // },
  //     {
  //       Header: "Action",
  //       Cell: EditCell,
  //       accessor: "updated_at",
  //     },
  //   ],
  //   []
  // );

  return (
    <Layout route="episodes" title={"Episodes"}>
      <NewPageHeader title="Episodes" />
      <div className="block w-full overflow-x-auto  b-4">
        <div className="p-4 block w-full shadow-xl gap-4 items-center bg-white mb-4 rounded-xl">
          <div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4"
                onChange={(e) => {
                  setOnlyNew(e.target.checked);
                }}
                checked={onlyNew}
              />
              <span className="ml-2 text-sm">
                Show Recent? (disable for all available upcoming episodes)
              </span>
            </label>
          </div>
          <EpisodeSearch setPageNo={setPageNo} setSearch={setSearch} />
        </div>
        {search && search !== "" && search !== "null" && (
          <div className="p-4 w-full shadow-xl bg-white mb-4 rounded-xl">
            <p className="mb-4">
              Current Searching for{" "}
              <strong>
                <em>"{search}"</em>
              </strong>
            </p>
            <button
              onClick={() => {
                setSearch("");
                setPageNo(1);
              }}
              className="p-2 bg-gray-200 text-sm rounded-lg"
            >
              Clear search
            </button>
          </div>
        )}
        <>
          {loading === true && (
            <div className="mx-auto py-12 flex justify-center w-full px-12">
              <LoadingIndicator width={"12"} />
            </div>
          )}
          {episodes && station && (
            <>
              {episodes.map((episode, i) => (
                <EpisodeItem
                  key={`a${i}`}
                  station={station}
                  index={i}
                  episode={episode}
                  showPlayer={false}
                  onComplete={getEpisode}
                />
              ))}
              {/* <Table
                columns={loading ? loadingColumns : columns}
                data={loading ? loadingData : episodes}
              /> */}
            </>
          )}
          <div className=" px-0 py-3 flex items-center justify-around border-t border-gray-200  ">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount - 1}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName={"paginator"}
              forcePage={pageNo - 1}
            />
          </div>
        </>
      </div>
    </Layout>
  );
}
