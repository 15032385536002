import React, { useEffect } from "react";
import Log from "./Log";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "react-modal";
import { Transition } from "@headlessui/react";
import EditLog from "../EditLog";
import {
  CheckCircleIcon,
  ClipboardCopyIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { CSVLink } from "react-csv";
import { DownloadIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";
import CopyToClipboard from "react-copy-to-clipboard";

Modal.setAppElement("#root");

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function LogList({ logs, setLogs, episode, updateLogs = null }) {
  const http = global.services.http;
  const [edit, setEdit] = React.useState(null);
  const [copied, setCopied] = React.useState(false);
  const [copyText, setCopyText] = React.useState("");
  const [csvData, setCsvData] = React.useState("");
  const [copiedComma, setCopiedComma] = React.useState(false);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newList = reorder(
      logs,
      result.source.index,
      result.destination.index
    );

    const req = newList.map((item) => item.id);
    updateList({ logs: req });
    setLogs(newList);
  };

  const closeModal = (item = null) => {
    setEdit(null);
  };

  const sendGaEvent = (action, data = {}) => {
    window.gtag("event", action, {
      ...data,
      episode: episode.id,
      program_id: episode.program.id,
      program: episode.program.name,
      station_id: episode.station.id,
      station_id: episode.station.name,
    });
  };

  const EditModal = ({ log, episode, closeModal, logs, setLogs }) => (
    <>
      <Transition show={edit !== null}>
        {/* Background overlay */}
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={() => closeModal()}
            className=" max-w-screen fixed z-40 left-0 top-0 right-0 bg-gray-900 bg-opacity-70 bottom-0 "
          ></div>
        </Transition.Child>
        {/* Sliding sidebar */}
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className=" max-w-full fixed z-50  w-[400px] top-0 right-0 bg-white p-4 bottom-0  overflow-y-auto">
            <div className="w-full flex justify-between mb-4 items-center">
              <h2 className="font-bold">EDIT LOG</h2>
              <button onClick={() => closeModal()}>
                <XCircleIcon className="w-8 h-8 hover:text-gray-800" />
              </button>
            </div>
            <EditLog
              log={log}
              activeEpisode={episode}
              logs={logs}
              setLogs={setLogs}
              onComplete={closeModal}
            />
          </div>
          {/* ... */}
        </Transition.Child>
      </Transition>
    </>
  );

  useEffect(() => {
    const copyTextArray = logs.map((item) => {
      return `${
        episode.hide_times === 1
          ? ""
          : `${moment(moment.utc(item.air_time)).local().format("hh:mma")} - `
      }${item.title} by ${item.artist}${
        item.album ? ` from ${item.album}` : ""
      }${item.label ? ` on ${item.label}` : ""}${
        item.release_date ? ` (${item.release_date})` : ""
      }${item.location ? ` (${item.location})` : ""}`;
    });
    var str = copyTextArray.join("\n");
    setCopyText(str);

    let csv = logs?.map((item) => [
      moment(moment.utc(item.air_time)).local().format("hh:mma"),
      item.title,
      item.artist,
      item.album,
      item.label,
      item.release_date,
      item.location,
    ]);
    csv.unshift([
      "Air Time",
      "Track Title",
      "Artist",
      "Album Title",
      "Record Label",
      "Release Date",
      "Location",
    ]);

    setCsvData(csv);
  }, [logs]);

  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const updateList = async (payload) => {
    try {
      var req = await http.post(`${process.env.REACT_APP_API}/logs/reorder`, {
        ...payload,
      });
    } catch (error) {
      console.log("Went bad here", error);
    }
  };

  const deleteLog = async (log) => {
    if (window.confirm("Are you sure? this cannot be un-done.") === true) {
      try {
        var req = await http.delete(
          `${process.env.REACT_APP_API}/logs/${log.id}`
        );
        const updated = logs.filter((item) => item.id !== log.id);
        setLogs(updated);
      } catch (error) {
        console.log("Went bad here", error);
      }
    } else {
      return;
    }
  };
  return logs && logs.length > 0 ? (
    <div className="flex flex-col">
      {edit !== null && (
        <EditModal
          episode={episode}
          closeModal={closeModal}
          log={edit}
          logs={logs}
          setLogs={setLogs}
        />
      )}
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow-md sm:rounded-lg">
            <table className="min-w-full">
              <thead className="bg-gray-50 ">
                <tr>
                  <th></th>
                  {episode.hide_times !== 1 && (
                    <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                      Time
                    </th>
                  )}
                  <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Track Name
                  </th>
                  <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Artist
                  </th>
                  <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Album
                  </th>
                  <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Label
                  </th>
                  <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Release Date
                  </th>
                  <th className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Location
                  </th>
                  <th scope="col" className="relative py-3 px-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {logs.map((item, i) => (
                        <Draggable
                          key={`l${item.id}`}
                          draggableId={`l${item.id}`}
                          index={i}
                        >
                          {(provided) => (
                            <tr
                              className="border-b odd:bg-white even:bg-gray-50  "
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Log
                                episode={episode}
                                log={item}
                                setEdit={setEdit}
                                deleteLog={deleteLog}
                              />
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
        </div>
      </div>
      {logs && logs.length === 0 && (
        <div>No logs added to this episode yet</div>
      )}
      {logs && logs.length > 0 && copyText && (
        <div className="py-2 w-full flex justify-end gap-2">
          {csvData && (
            <div>
              <CSVLink
                data={csvData}
                filename={`${episode.station.name}_${
                  episode.program.name
                }_Playlist_${moment(moment.utc(episode.show_start))
                  .local()
                  .format()}.csv`}
                onClick={() => sendGaEvent("download_playlist")}
              >
                <p className=" bg-orange-500 text-white hover:bg-orange-600 transition-colors px-4 py-3 rounded-md cursor-pointer text-xs flex gap-2 items-center">
                  <DownloadIcon className={" h-4 w-4"} />
                  Download CSV/Excel File
                </p>
              </CSVLink>
            </div>
          )}
          <div>
            <CopyToClipboard
              text={copyText}
              onCopy={() => setCopied(true)}
              onClick={() => sendGaEvent("copy_playlist")}
            >
              <div className="  bg-pink-500 text-white hover:bg-pink-600 transition-colors px-4 py-3 rounded-md cursor-pointer text-xs flex gap-2 items-center">
                {copied ? (
                  <>
                    <CheckCircleIcon className="w-4 h-4" /> Copied
                  </>
                ) : (
                  <>
                    <ClipboardCopyIcon className="w-4 h-4" />
                    Copy Playlist to Clipboard
                  </>
                )}
              </div>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </div>
  ) : (
    ""
  );
}
