import React, { useState, useEffect } from "react";
import { useAuthState } from "../context";
import moment from "moment";
import { usePDF } from "react-to-pdf";
import LoadingIndicator from "./Global/LoadingIndicator";
import StationChartNew from "./Charts/StationChartNew";
import StationChartBreakDown from "./Charts/StationChartBreakDown";

export default function StationReportDetails({ station = null }) {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  const [loading, setLoading] = useState(false);
  const userDetails = useAuthState(); //read user details from context

  const { toPDF, targetRef } = usePDF({
    filename: station
      ? `${station.name}-listener-report`
      : "listener report.pdf",
  });
  const [today, setToday] = useState([]);
  const [date, setDate] = useState(moment().unix());
  const [all, setAll] = useState(null);
  // const [date, setDate] = useState(1707179674);

  useEffect(() => {
    const getResults = async () => {
      setLoading(true);
      const http = global.services.http;
      setLoading(true);
      const res = await http.get(
        `${process.env.REACT_APP_API}/report/station/${station.id}?date=${date}`
      );
      if (res) {
        setLoading(false);
        console.log(res.data.data);
        setToday(res.data.data.current);
        setAll(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        alert("There was an error receiving this information");
      }
    };
    getResults();
  }, [date]);

  return (
    station && (
      <div className="w-full">
        <div className={"mb-4 p-4 bg-white rounded shadow-lg "}>
          <div className="w-full" ref={targetRef}>
            <h1 className="text-2xl lg:text-4xl font-bold mb-4">
              Live Stream Stats
            </h1>
            {loading ? (
              <div className="w-full flex justify-center py-5 items-center min-h-screen">
                <LoadingIndicator />
              </div>
            ) : (
              <div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <h2 className="text-xl lg:text-2xl font-bold">
                      {station.name} - {moment.unix(date).format("DD/MM/YYYY")}
                    </h2>
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => setDate(date - 86400)}
                      >
                        Previous Day
                      </button>
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => setDate(moment().unix())}
                      >
                        Today
                      </button>
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => setDate(date + 86400)}
                      >
                        Next Day
                      </button>
                    </div>
                  </div>
                </div>
                {all && (
                  <div>
                    <div className="mb-12 block w-full page-break">
                      <p className="font-light mb-4">
                        Within each half hour bracket, we have a number showing
                        the total amount of listeners within the given time
                        period. This includes new and existing connections.
                      </p>
                      <StationChartNew report={all} label="Listeners Hsitory" />
                    </div>
                    <div className="mb-12 block w-full page-break">
                      <StationChartBreakDown
                        report={all.current}
                        label="Today's Listeners"
                      />
                    </div>
                    <div className="mb-12 block w-full page-break">
                      <StationChartBreakDown
                        report={all.yesterday}
                        label="1 Day Prior"
                      />
                    </div>
                    <div className="mb-12 block w-full page-break">
                      <StationChartBreakDown
                        report={all.last_week}
                        label="1 Week Prior"
                      />
                    </div>
                    <div className="mb-12 block w-full page-break">
                      <StationChartBreakDown
                        report={all.last_month}
                        label="4 Weeks Prior"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <button onClick={() => toPDF()}>Download PDF</button>
      </div>
    )
  );
}
