import React from "react";
import {
  ChevronDoubleDownIcon,
  PencilAltIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import "moment-timezone";
import { StationContext } from "../../../context/station-context";
// import { dateFormat } from "../../../config/constants";

export default function Log({ episode, log, setEdit, deleteLog }) {
  const { station } = React.useContext(StationContext);
  const local =
    log.air_time &&
    moment.utc(log.air_time).tz(station.timezone).format("ddd, D MMM, h:mm a");
  return log ? (
    <>
      <td>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
          />
        </svg>
      </td>
      {episode.hide_times !== 1 && (
        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap ">
          {local}
        </td>
      )}
      <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap font-bold">
        {log.title && log.title}
      </td>
      <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
        {log.artist && log.artist}
      </td>
      <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
        {log.album && log.album}
      </td>
      <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
        {log.label && log.label}
      </td>
      <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
        {log.release_date && log.release_date}
      </td>
      <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
        {log.location && log.location === "int"
          ? "International"
          : log.location === "nat"
          ? "National"
          : log.location === "loc"
          ? "Local"
          : ""}
      </td>
      <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap flex justify-end gap-2">
        <button
          onClick={() => setEdit(log)}
          className="text-gray-700 border-gray-200 border bg-gray-50 hover:bg-blue-700 hover:border-blue-700 hover:text-white transition-all rounded-lg px-2 py-1 flex items-center gap-1"
        >
          <span>EDIT</span>
          <PencilAltIcon className="  w-5 h-5" />
        </button>
        <button
          onClick={() => deleteLog(log)}
          href="#"
          className="text-blue-600 hover:underline"
        >
          <TrashIcon className="text-red-500 w-5 h-5" />
        </button>
      </td>
    </>
  ) : (
    ""
  );
}
