import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import NewMediaUpload from "../NewMediaUpload";
import { useAuthState } from "../../context";
import { useHistory, Link } from "react-router-dom";
import { StationContext } from "../../context/station-context";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TimezoneSelect from "react-timezone-select";
import "react-tabs/style/react-tabs.css";
import * as yup from "yup";
import { PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import Spinner from "../Spinner";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  // timezone: yup.string().required(),
});

export default function NewStationForm() {
  const [tabIndex, setTabIndex] = useState(0);
  const userDetails = useAuthState(); //read user details from context
  let history = useHistory();
  const http = global.services.http;
  const [media, setMedia] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(null);
  const resolver = useYupValidationResolver(validationSchema);
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  const stationSubmit = async (payload) => {
    if (loading === true) return;

    let fields = await { ...payload, timezone: selectedTimezone };

    if (media) {
      fields = await {
        ...fields,
        media_id: media.id,
        timezone: selectedTimezone,
      };
    }
    setLoading(true);
    try {
      let req;

      req = await http.post(`${process.env.REACT_APP_API}/stations`, {
        ...fields,
      });
      setLoading(false);

      if (req.status === 422) {
        if (req.data.data) {
          if (req.data.data.name) {
            setError("name", {
              type: "manual",
              message: req.data.data.name[0],
            });
          }
          if (req.data.data.timezone) {
            console.log("Got here", req.data.data.timezone[0]);
            setError("timezone", {
              type: "manual",
              message: req.data.data.timezone[0],
            });
          }
          if (req.data.data.icecast_stream) {
            setError("icecast_stream", {
              type: "manual",
              message: "req.data.errors.icecast_stream",
            });
          }
          if (req.data.data.hls_stream) {
            setError("icecast_stream", {
              type: "manual",
              message: "req.data.errors.icecast_stream",
            });
          }
          if (req.data.data.call_sign) {
            setError("call_sign", {
              type: "manual",
              message: "req.data.errors.call_sign",
            });
          }
        }
      } else {
        history.push(`/station/${req.data.data.id}`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage("An error has occured.");
    }
  };
  const deleteMedia = async (id) => {
    var req = await http.delete(`${process.env.REACT_APP_API}/medias/${id}`);
  };

  return (
    <div className={"max-w-full p-4 me-auto bg-white shadow-xl rounded-xl"}>
      <h1 className="mb-4 text-2xl lg:text-4xl font-bold">
        Create Your Radio Station
      </h1>{" "}
      <p className="mb-4 text-red-500">
        <span className="font-bold">STATION MANAGERS ONLY.</span> Before you
        begin, this form is only needed to be used once for any given station,
        by a station managers. If you are a presenter, you do not need to fill
        this form out.
      </p>
      <p className="mb-4">
        Lets start by creating a new station. This is your first step to
        creating your station's online presence!
      </p>
      <p className="mb-4">
        Once we are done here, we can begin adding programs, timetables,
        presenters and building your new website widgets, to name a few things!
      </p>
      <p className="mb-12">Lets get into it shall we?</p>
      {errorMessage ? <p>{errorMessage}</p> : null}
      <div className="block lg:flex gap-4  w-full">
        <div className="mb-8  w-full lg:w-96">
          <h4 className="font-bold mb-4">Step 1. Upload a logo</h4>
          <label
            htmlFor="name"
            className={`sr-only block font-bold text-sm mb-2  `}
          >
            Station Image
          </label>
          {media && media.url ? (
            <div className={"text-center w-full relative"}>
              <div className="absolute top-0 right-0">
                <button
                  className="p-2 text-red-500 hover:text-white hover:bg-red-500"
                  onClick={() => {
                    deleteMedia(media.id);
                    setMedia(null);
                  }}
                >
                  <TrashIcon className=" w-6 h-6" />
                </button>
              </div>
              <img
                className={"max-w-full ml-auto mr-auto mt-4 mb-4"}
                src={media.url}
                alt={"for this station"}
              />
            </div>
          ) : (
            <NewMediaUpload
              title={null}
              onComplete={setMedia}
              accept={"image/*"}
              type="s3"
            />
          )}
        </div>
        <form
          onSubmit={handleSubmit(stationSubmit)}
          className={"flex-grow w-full"}
        >
          <h4 className="font-bold mb-4">
            Step 2. Add Basic Station Information
          </h4>
          <div className="mb-8">
            <label
              htmlFor="name"
              className={`block font-bold text-sm mb-2 ${
                errors.name ? "text-red-400" : "text-gray-800"
              }`}
            >
              Station Name
            </label>
            <input
              autoFocus
              type="text"
              name="name"
              id="name"
              placeholder="Enter your Station's Full Name"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                errors.name ? "border-red-400" : " "
              }`}
              {...register("name")}
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-2">
                A valid station name is required.
              </p>
            )}
          </div>
          <div className="mb-8">
            <label
              htmlFor="call_sign"
              className={`block font-bold text-sm mb-2 ${
                errors.call_sign ? "text-red-400" : "text-gray-800"
              }`}
            >
              Station Call Sign
            </label>
            <input
              type="text"
              name="call_sign"
              id="call_sign"
              placeholder="Call Sign"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                errors.call_sign ? " border-red-400" : " "
              }`}
              {...register("call_sign")}
            />
            {errors.call_sign && (
              <p className="text-red-500 text-sm mt-2">
                A valid call sign is required.
              </p>
            )}
          </div>
          <div className="mb-8">
            <label
              htmlFor="timezone"
              className={`block font-bold text-sm mb-2 ${
                errors.timezone ? "text-red-400" : "text-gray-800"
              }`}
            >
              Station Timezone
            </label>
            <TimezoneSelect
              value={selectedTimezone}
              onChange={setSelectedTimezone}
            />
            {/* <input
              type="text"
              name="timezone"
              id="timezone"
              placeholder="Timezone"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                errors.timezone ? " border-red-400" : " "
              }`}
              {...register("timezone")}
            /> */}
            {errors.timezone && (
              <p className="text-red-500 text-sm mt-2">
                A valid timezone is required.
              </p>
            )}
          </div>
          <div className="mb-8">
            <label
              htmlFor="icecast_stream"
              className={`block font-bold text-sm mb-2 ${
                errors.bio ? "text-red-400" : "text-gray-900"
              }`}
            >
              Icecast Stream
            </label>
            <input
              type="text"
              name="icecast_stream"
              id="icecast_stream"
              placeholder="Your Icecast URL"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                errors.password ? "border-red-400" : "border-blue-400"
              }`}
              {...register("icecast_stream")}
            />
            {errors.icecast_stream && (
              <p className="text-red-500 text-sm mt-2">
                A icecast_stream is required
              </p>
            )}
          </div>
          <div className="w-full flex justify-end gap-2">
            <div className="text-right">
              <button className="flex items-center gap-2 bg-lightBlue-600 hover:bg-blue-500 text-white rounded shadow py-2 px-3 text-uppercase text-sm rounded mb-4">
                <PlusSmIcon className={"w-4 h-4"} />
                Create New Station
                {loading && <Spinner className={"w-4 h-4"} />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
