import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { StationContext } from "../context/station-context";
import EpisodePageDetails from "../components/EpisodePageDetails";
import AddLog from "../components/Playout/AddLog";
import LogList from "../components/Playout/Components/LogList";
import EpisodeSections from "../components/EpisodeSections";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import NewPageHeader from "../components/Global/NewPageHeader";
import EpisodeReportComponent from "../components/Reports/EpisodeReportComponent";
import AiHeader from "../components/AiHeader";

export default function Episode() {
  const { id, station_id } = useParams();
  const [episode, setEpisode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const { station, setStation } = React.useContext(StationContext);
  const [logs, setLogs] = React.useState([]);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const http = global.services.http;

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [station]);

  useEffect(() => {
    const http = global.services.http;
    const getEpisode = async () => {
      setLoading(true);
      var req = await http.get(`${process.env.REACT_APP_API}/episodes/${id}`);
      if (req) {
        setEpisode(req.data.data);
        setLogs(req.data.data.logs);
        setLoading(false);
      }
    };
    getEpisode();
  }, [id]);

  const getEpisode = async () => {
    var req = await http.get(`${process.env.REACT_APP_API}/episodes/${id}`);
    if (req) {
      setEpisode(req.data.data);
      setLogs(req.data.data.logs);
      setLoading(false);
    }
  };

  return (
    <Layout
      title={"Episode"}
      route="episodes"
      classString="max-w-[1600px] w-full"
    >
      <AiHeader location="episode-page" />
      <NewPageHeader title="Episode" links={[]} />
      <div className="rounded-xl shadow-xl mb-4">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList className={"flex gap-1"}>
            <Tab className={"px-3 py-2 bg-white tab-button"}>
              Episode Details
            </Tab>
            <Tab className={"px-3 py-2 bg-white tab-button"}>Edit Playlist</Tab>
            <Tab className={"px-3 py-2 bg-white tab-button"}>Edit Segments</Tab>
          </TabList>
          <TabPanel>
            <div className="bg-white p-4">
              {loading === true && (
                <div className="flex flex-col justify-center items-center">
                  <LoadingIndicator className="mx-auto" width={12} />
                  <br />
                  <p>Loading Episode Details.</p>
                </div>
              )}
              {episode && station !== null && (
                <EpisodePageDetails
                  episode={episode}
                  station={station}
                  index={1}
                  showPlayer={false}
                  onUpdate={setEpisode}
                  setEpisode={setEpisode}
                  setTabIndex={setTabIndex}
                />
              )}
            </div>

            {episode && episode.report && (
              <div className={"mb-4 p-4 bg-white rounded shadow-lg flex"}>
                <EpisodeReportComponent
                  report={episode.report}
                  episode={episode}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <div className=" ">
              {episode && station !== null && (
                <div
                  className={"bg-white p-4 block relative"}
                  style={{ alignSelf: "auto" }}
                >
                  <div
                    style={{ alignSelf: "stretch" }}
                    className={"w-full lg:sticky sticky-bit bg-white py-4"}
                  >
                    {episode && (
                      <AddLog
                        logs={logs}
                        setLogs={setLogs}
                        activeEpisode={episode}
                        station={station}
                      />
                    )}
                  </div>
                  <div className={"w-full"}>
                    {episode && (
                      <LogList
                        logs={logs}
                        episode={episode}
                        setLogs={setLogs}
                        station={station}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bg-white p-4">
              {station && episode && (
                <EpisodeSections
                  episode={episode}
                  station_id={station_id}
                  afterSave={getEpisode}
                />
              )}
            </div>
          </TabPanel>
        </Tabs>
      </div>

      {/* {episode && report !== null && (
        <div className={"mb-4 p-4 bg-white rounded shadow-lg flex"}>
          <div className={"w-1/4"}>
            <p>Reports:</p>
            <p>
              {report && report.livestreams && report.livestreams.length}{" "}
              listens via the website live stream
            </p>
            <p>
              {report && report.ondemand && report.ondemand.length} listens via
              the ondemand service
            </p>
          </div>
        </div>
      )} */}
    </Layout>
  );
}
