import React from "react";

import Spinner from "../Spinner";

export default function LoadingIndicator({
  showWrapper = false,
  width = "16",
  className = "bg-white p-16 text-center shadown-lg rounded-xl",
}) {
  return showWrapper === true ? (
    <div className={className}>
      <Spinner className={`animate-spin h-${width} w-${width} text-4xl`} />
    </div>
  ) : (
    <div>
      <Spinner className={`animate-spin h-${width} w-${width} text-4xl`} />
    </div>
  );
}
