import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useContext } from "react";
import { AuthProvider } from "./context";
import { StationProvider } from "./context/station-context.js";
import { RadioProvider } from "./RadioContext";
import AppRoutes from "./components/AppRoutes";
import ReactPlayer from "react-player";
import { RadioContext } from "./RadioContext";
import routes from "./config/routes";
import "./App.css";
import VerifyCheck from "./components/VerifyCheck";
import ScrollToTop from "./hooks/ScrollToTop.js";

function App() {
  const {
    ref,
    updateDuration,
    updateProgress,
    setBuffering,
    playing,
    track,
    pickNewTrack,
  } = useContext(RadioContext);
  return (
    <AuthProvider>
      <StationProvider>
        <ReactPlayer
          ref={ref}
          progressInterval={50}
          onDuration={(progress) => updateDuration(progress)}
          // onPlay={(item) => console.log("here", item)}
          playing={playing}
          onProgress={(progress) => updateProgress(progress)}
          controls={false}
          onBuffer={() => setBuffering(true)}
          onBufferEnd={() => setBuffering(false)}
          onEnded={() => pickNewTrack()}
          height={0}
          width={0}
          url={track && track.url}
          config={{
            file: {
              forceAudio: true,
            },
          }}
        />
        <Router>
          {/* <ScrollToTop /> */}
          <Switch>
            {routes.map((route) => (
              <AppRoutes
                key={route.path}
                path={route.path}
                component={route.component}
                isPrivate={route.isPrivate}
              />
            ))}
          </Switch>
          <VerifyCheck />
        </Router>
      </StationProvider>
    </AuthProvider>
  );
}

export default App;
