import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { StationContext } from "../context/station-context";
import SectionHeader from "../components/SectionHeader";
import Modal from "react-modal";
import AddSlot from "../components/Grid/AddSlot";
import {
  PlusIcon,
  PencilAltIcon,
  ViewListIcon,
} from "@heroicons/react/outline";

export default function Post() {
  const { id, station_id } = useParams();
  const http = global.services.http;
  const [post, setPost] = useState(null);
  const { station } = React.useContext(StationContext);
  const [editSlot, setEditSlot] = useState(null);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [station]);

  useEffect(() => {
    const http = global.services.http;
    const getPost = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/posts/${id}`);
      if (req) {
        setPost(req.data.data);
      }
    };
    getPost();
  }, [id]);

  const getPost = async () => {
    var req = await http.get(`${process.env.REACT_APP_API}/posts/${id}`);
    if (req) {
      setPost(req.data.data);
    }
  };

  const deleteSlot = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?") === true) {
      var req = await http.delete(`${process.env.REACT_APP_API}/slots/${id}`);
      if (req) {
        console.log("req");
        await getPost();
      }
    }
  };

  const finishUpdate = async () => {
    setEditSlot(null);
    getPost();
    console.log("Slot updated");
  };

  // const EditModal = () => (
  //   <Modal
  //     isOpen={editSlot !== null}
  //     // onAfterOpen={afterOpenModal}
  //     onRequestClose={finishUpdate}
  //     // style={customStyles}
  //     contentLabel="Edit Post Time"
  //   >
  //     <AddSlot slot={editSlot} onComplete={finishUpdate} />
  //   </Modal>
  // );

  return (
    <Layout title={post && post.title}>
      {/* <EditModal /> */}
      <div className={"mb-4  bg-white rounded-xl shadow-lg mb-8"}>
        <SectionHeader
          content={
            post && (
              <div className={"font-bold text-left text-2xl"}>{post.title}</div>
            )
          }
        />
        <div className="w-full pb-4 pl-4 pr-4 flex gap-4">
          <Link
            className={
              "bg-pink-500 text-white rounded shadow py-2 px-4 text-uppercase text-sm flex gap-2"
            }
            to={`/station/${station_id}/post/${id}/edit`}
          >
            <PencilAltIcon className={"w-5 h-5"} />
            Edit {post && post.title}
          </Link>
          <Link
            to={`/station/${station_id}/posts`}
            className=" bg-green-500 text-white rounded shadow py-2 px-4 text-uppercase text-sm flex gap-2"
          >
            <ViewListIcon className="w-4 h-4 mr-2" />
            <span>All Posts</span>
          </Link>
          <Link
            to={`/station/${station_id}/post/new`}
            className=" bg-brandDark text-white rounded shadow py-2 px-4 text-uppercase text-sm flex gap-2"
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            <span>New Post</span>
          </Link>
        </div>
        <div className="w-full pb-4 pl-4 pr-4 lg:pb-4 lg:pl-4 lg:pr-4 md:flex gap-8">
          <div className="md:w-1/4">
            {post && post.image ? (
              <div className={"  w-full mb-4 lg:mb-0"}>
                <img
                  alt={"for our post"}
                  className={"max-w-full ml-auto mr-auto "}
                  src={post.image.url}
                />
              </div>
            ) : (
              <p className={"text-center"}>No Image Provided for this post</p>
            )}
          </div>
          <div className="flex-grow">
            {post && post.genre_string && (
              <p className={"mb-4"}>
                <span className={"font-bold text-sm"}>Excerpt:</span>
                <br />
                {post.precontent}
              </p>
            )}
            <p className={"mb-4"}>
              <span className={"font-bold text-sm"}>SLUG:</span>{" "}
              {post && post.slug}
              <span className={"font-bold text-sm"}>
                <br /> ID:
              </span>{" "}
              {post && post.id}
            </p>
            {post && post.content && (
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
