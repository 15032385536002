import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import LayoutLogin from "../components/LayoutLogin";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as MyLogo } from "../images/logo.svg";
import * as yup from "yup";

import { loginUser, useAuthState, useAuthDispatch } from "../context";
import LoadingIndicator from "../components/Global/LoadingIndicator";

// import { Link } from 'react-router-dom';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(3),
  recaptcha: "",
});

export default function Signup() {
  // const [formError, setFormError] = React.useState(null);
  let history = useHistory();
  const dispatch = useAuthDispatch();
  const http = global.services.http;
  // const [error, setError] = React.useState(null);
  const { errorMessage } = useAuthState();
  const [recaptcha, setRecaptcha] = useState(null);
  const [loading, setLoading] = useState(false);

  function onChange(value) {
    // console.log("Captcha value:", value);
    setRecaptcha(value);
    clearErrors("recaptcha");
  }
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    error,
    // watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const registerSubmit = async (payload) => {
    // if (!recaptcha) {
    //   alert("Please ensure you have checked 'I'm not a robot'");
    //   return;
    // }
    setLoading(true);
    try {
      var req = await http.post(`${process.env.REACT_APP_API}/auth/register`, {
        ...payload,
        recaptcha,
      });

      if (req.status === 422) {
        if (req.data.errors) {
          if (req.data.errors.password) {
            setError("password", {
              type: "manual",
              message: req.data.errors.password[0],
            });
          }
          if (req.data.errors.description) {
            setError("description", {
              type: "manual",
              message: req.data.errors.description[0],
            });
          }
          if (req.data.errors.recaptcha) {
            setError("recaptcha", {
              type: "manual",
              message: req.data.errors.recaptcha[0],
            });
          }

          if (req.data.errors.title) {
            setError("title", {
              type: "manual",
              message: "req.data.errors.title",
            });
          }

          if (req.data.errors.email) {
            setError("email", {
              type: "manual",
              message: req.data.errors.email[0],
            });
          }
          setLoading(false);
        }
      } else if (req.status === 201) {
        alert(
          "Sign up successful. Please check your email to confirm your account."
        );
        setLoading(false);
        history.push(`/login`);
      } else {
        setLoading(false);
        alert("An unknown error has occured. Please try again later.");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  //   console.log(watch("email"));

  console.log("errros", errors);

  return (
    <LayoutLogin>
      {loading === true && (
        <div className="bg-gray-900 bg-opacity-20 flex justify-center items-center z-10 fixed left-0 right-0 top-0 bottom-0">
          <LoadingIndicator />
        </div>
      )}
      <div className="flex flex-wrap mt-6 relative">
        <div className="w-full text-gray-200 ml-2 text-center font-bold text-lg  lg:text-4xl mb-4 lg:mb-3 flex items-center justify-center gap-4">
          <MyLogo className="w-12  h-12" />
          MyRadio
        </div>
      </div>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className={"flex-auto px-4 lg:px-10 py-10 "}>
          <h1 className={"text-center font-bold mb-6"}>Sign Up</h1>
          <form onSubmit={handleSubmit(registerSubmit)}>
            {" "}
            <div className="mb-3">
              <label
                htmlFor="email"
                className={`block font-bold text-sm mb-2 ${
                  errors.email ? "text-red-400" : "text-gray-900"
                }`}
              >
                Email
              </label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Your Email"
                className={`border-b-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                {...register("email")}
              />
              {errors.email && (
                <p className="text-red-500  mt-2 text-xs text-xs">
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="fName"
                className={`block font-bold text-sm mb-2 ${
                  errors.fName ? "text-red-400" : "text-gray-900"
                }`}
              >
                First Name
              </label>
              <input
                type="text"
                name="fName"
                id="fName"
                placeholder="First Name"
                className={`border-b-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                {...register("fName")}
              />
              {errors.fName && (
                <p className="text-red-500  mt-2 text-xs">
                  A valid FirstName is required.
                </p>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="lName"
                className={`block font-bold text-sm mb-2 ${
                  errors.lName ? "text-red-400" : "text-gray-900"
                }`}
              >
                Last Name
              </label>
              <input
                type="text"
                name="lName"
                id="lName"
                placeholder="Last Name"
                className={`border-b-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                {...register("lName")}
              />
              {errors.lName && (
                <p className="text-red-500  mt-2 text-xs">
                  A valid lastName is required.
                </p>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="password"
                className={`block font-bold text-sm mb-2 ${
                  errors.password ? "text-red-400" : "text-gray-900"
                }`}
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Your Password"
                className={`border-b-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                {...register("password")}
              />
              {errors.password && (
                <p className="text-red-500  mt-2 text-xs">
                  Your password is required.
                </p>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="password_check"
                className={`block font-bold text-sm mb-2 ${
                  errors.password_check ? "text-red-400" : "text-gray-900"
                }`}
              >
                Password
              </label>
              <input
                type="password"
                name="password_check"
                id="password_check"
                placeholder="Confirm Your Password"
                className={`border-b-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                {...register("password_check")}
              />
              {errors.password_check && (
                <p className="text-red-500  mt-2 text-xs">
                  Your password is required.
                </p>
              )}
            </div>
            <div className="mb-3">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onChange}
              />
              {errors.recaptcha && (
                <p className="text-red-500  mt-2 text-xs">
                  {errors.recaptcha.message}
                </p>
              )}
            </div>
            {errorMessage ? (
              <p className={"text-center text-red-500 mb-3"}>{errorMessage}</p>
            ) : null}
            <button className="w-full inline-block bg-blue-900 text-white rounded shadow py-4 px-6 text-uppercase text-sm rounded-full">
              SIGN UP
            </button>
          </form>
          {/* <p className="text-center mt-8 text-xs">
            If you are a presenter looking to you join your station, please ask
            your station manager to invite from our admin panel.
          </p>
          <p className="text-center mt-8 text-xs">
            We are sorry but signing up is currently disabled. Please try again
            after April the 20th, once we have completed some maintenance. If
            you would like an account sooner, please contact us at{" "}
            <a href="https://myradio.click">myradio.click</a> and we can
            organise one for you.
          </p> */}

          <p className="text-center mt-8 text-xs">
            <a href="/login">Already have an account? Login Here</a>
          </p>
        </div>
      </div>
    </LayoutLogin>
  );
}
