import React, { useState, useEffect } from "react";
import SectionForm from "./forms/SectionForm";
import { Link } from "react-router-dom";
import { loadingColumns, loadingData } from "../config/constants";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import Table from "./Table";

export default function EpisodeSections({
  station_id,
  episode,
  afterSave = null,
}) {
  const http = global.services.http;
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {

  //   if (afterSave) {
  //     afterSave();
  //   }
  //   };

  //   getSection();
  // }, [station_id, episode]);

  // const getSection = async () => {
  //   setLoading(true);
  //   var filter = JSON.stringify({
  //     station_id: station_id,
  //     episode_id: episode.id,
  //   });
  //   var req = await http.get(
  //     `${process.env.REACT_APP_API}/sections?filter=${filter}&page=1&limit=5`
  //   );
  //   if (req.data) {
  //     setSections(req.data.data);
  //     setLoading(false);
  //   }
  // };

  const deleteSection = async (id) => {
    if (window.confirm("Are you sure you wish to delete this item?") === true) {
      var req = await http.delete(
        `${process.env.REACT_APP_API}/sections/${id}`
      );
      await afterSave();
    }
    // console.log(event);
    // setPageNo(event.selected + 1);
    // history.push(`/station/${station_id}/sections/${event.selected + 1}`);
  };

  const EditCell = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-end"}>
        <div className="flex justify-end gap-2">
          <Link
            className={
              "border-lightBlue-600 border text-lightBlue-600 rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
            }
            to={`/station/${station_id}/section/${row.original.id}/edit`}
          >
            <PencilAltIcon className="w-3 h-3" />
            Edit
          </Link>
          <button
            className={
              "border-red-600 border text-red-600 rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
            }
            onClick={() => deleteSection(row.original.id)}
          >
            <TrashIcon className="w-3 h-3" />
            Delete
          </button>
        </div>
      </div>
    );
  };

  const ShowName = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-start"}>
        {row.original.image && row.original.image.url && (
          <img src={row.original.image.url} className={"w-4 mr-2"} />
        )}
        <span>
          {row.original.title} ({row.original.status})
        </span>
      </div>
    );
  };

  const ShowDuration = ({ row }) => {
    const time = row.original.duration / 60;
    return `${time.toFixed(2)} (minutes)`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Section Name",
        accessor: "title",
        Cell: ShowName,
      },
      {
        Header: "Time",
        accessor: "readable",
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ShowDuration,
      },
      {
        Header: "Action",
        accessor: "created_at",
        className: "text-right text-end",
        Cell: EditCell,
      },
    ],
    []
  );

  const TableHeader = () => {
    return (
      <div
        className={
          "text-blueGray-700 text-sm font-bold flex justify-between items-center ml-auto"
        }
      ></div>
    );
  };

  return (
    <div className="">
      <SectionForm
        stationId={station_id}
        episode={episode}
        onComplete={() => afterSave()}
      />
      <div>{loading && "Loading sections..."}</div>
      <div className="p-4 bg-white rounded-xl shadow-xl mt-4">
        <h2>Episode Sections</h2>
        {sections && (
          <Table
            columns={loading ? loadingColumns : columns}
            data={loading ? loadingData : episode.sections}
            content={<TableHeader />}
          />
        )}
      </div>
    </div>
  );
}
