import axios from 'axios'
// import { API_ENDPOINT } from 'configs/api'
import LocalStorageService from './localstorage'

const API_ENDPOINT = process.env.REACT_APP_API

class HttpService {

	async config (config, endpoint) {
		const rawUserData = LocalStorageService.get('currentUser')
		let token = null
		let userData = null

		if (rawUserData.value) {
			userData = JSON.parse(rawUserData.value)
			token = userData.token
		}

		if (!endpoint.includes('http://') && !endpoint.includes('https://'))
			config.baseURL = API_ENDPOINT

		if (!config.headers)
			config.headers = {}
		
		if (token && !config.headers.Authorization)
			config.headers.Authorization = `Bearer ${token}`

		return config;
	}

	handleErrorResponse (errorData) {
		let throwbackData = errorData
		if (errorData.response) {
			throwbackData = errorData.response
			if (throwbackData.status === 401) {
				// let authErrorMessage = throwbackData.data?.message || 'Your session has expired. Please re-login to access your dashboard again.'
				// LocalStorageService.remove('user_data')
				// LocalStorageService.set('authentication_error', authErrorMessage)
				// window.location.href = `${ window.location.origin }/login`
			}
		}

		return throwbackData
	}

	get (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config.params = params;
			config = await this.config(config, endpoint)
			axios.get(endpoint, config)
				.then(response => resolve(response))
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

	post (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.post(endpoint, params, config)
				.then(response => resolve(response))
				.catch(err => resolve(this.handleErrorResponse(err)))
		})
	}

	put (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.put(endpoint, params, config)
				.then(response => resolve(response))
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

	patch (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.patch(endpoint, params, config)
				.then(response => resolve(response))
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

	delete (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config.params = params;
			config = await this.config(config, endpoint)
			axios.delete(endpoint, config)
				.then(response => resolve(response))
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

}

export default new HttpService()