import React from "react";

export default function PageTitle({ title }) {
  return (
    <></>
    // <header className=" relative site-header z-10 lg:hidden">
    //   <div className="mx-auto py-6 px-4   sm:px-6  relative z-10 bg-white">
    //     <h1 className=" text-sm font-semibold uppercase ">{title}</h1>
    //   </div>
    // </header>
  );
}
