import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm } from "react-hook-form";

import { useAuthState } from "../../context";
import { useHistory, Link } from "react-router-dom";
import * as yup from "yup";
import Spinner from "../Spinner";

const fieldsList = [
  {
    input: "input",
    type: "text",
    name: "name",
    title: "Widget Name",
    placeholder: "Give this widget a name",
    required: true,
  },
  {
    input: "input",
    type: "number",
    name: "limit",
    title: "Episodes Per Page",
    placeholder: "Episodes Per Page",
    required: true,
  },
  {
    input: "select",
    type: "text",
    name: "type",
    title: "Show Pagination?",
    required: true,
    options: [
      {
        name: "Yes",
        value: "true",
      },
      {
        name: "No",
        value: "true",
      },
    ],
  },
];

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  bio: yup.string(),
});

export default function WidgetOndemandForm({
  widget = null,
  station = null,
  onFormComplete,
  theme = null,
  loading,
  setLoading,
}) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const userDetails = useAuthState();

  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [disabled, setDisabled] = useState(
    widget && widget.explicit === 1 ? 1 : 0
  );
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  const widgetSubmit = async (payload) => {
    if (loading === true) return;
    let fields = { ...payload, station_id: station.id };
    fields.disabled = disabled;
    console.log(userDetails);

    // handle submitting the form
    setLoading(true);
    onFormComplete(fields);
  };

  return (
    <div className={"w-full"}>
      <div className=" block lg:flex bg-white gap-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        <>
          <form className="w-full " onSubmit={handleSubmit(widgetSubmit)}>
            <div className="mb-8">
              <label
                htmlFor={"name"}
                className={`block font-bold text-sm mb-2 ${
                  errors["name"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Name
              </label>
              <input
                type={"text"}
                name={"name"}
                id={"name"}
                defaultValue={widget && widget.name}
                placeholder={"Widget Name"}
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.name ? "text-red-300  " : " "
                }`}
                {...register("name", { required: true })}
              />
              {errors["name"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>
            <div className="mb-8">
              <label
                htmlFor={"limit"}
                className={`block font-bold text-sm mb-2 ${
                  errors["limit"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Episodes Per Page
              </label>
              <input
                type={"number"}
                name={"limit"}
                id={"limit"}
                max={"24"}
                min={"0"}
                defaultValue={widget && theme && theme.limit}
                placeholder={"Widget limit"}
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.limit ? "text-red-300  " : " "
                }`}
                {...register("limit", { required: true })}
              />
              {errors["limit"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>
            <div className="mb-8">
              <label
                htmlFor={"paginate"}
                className={`block font-bold text-sm mb-2 ${
                  errors["paginate"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Enable Pagination?
              </label>
              <select
                name={"paginate"}
                id={"paginate"}
                defaultValue={
                  widget && theme && theme.paginate === true ? "true" : "false"
                }
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.paginate ? "text-red-300  " : " "
                }`}
                {...register("paginate")}
              >
                <option value={"true"}>Yes</option>
                <option value={"false"}>No</option>
              </select>
              {errors["paginate"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>

            <button className="w-full default-button  ">
              {!widget ? "Create New Widget" : "Edit Widget"}
              {loading && <Spinner className="w-4 h-4 ml-2" />}
            </button>
            <p className=" text-center mt-4">
              <Link
                className="ml-auto inline-block bg-gray-200 text-gray-800 rounded shadow py-1 px-3 text-xs"
                to={`/station/${station.id}/widgets`}
              >
                Cancel
              </Link>
            </p>
          </form>
        </>
      </div>
    </div>
  );
}
