import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import randomColor from "randomcolor";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DeviceChart = ({
  report,
  label = "Listeners per Country",
  type = "region",
}) => {
  console.log("Here", report);
  const [data, setData] = useState(null);

  useEffect(() => {
    const updateReport = async () => {
      const gl = Object.entries(report.device).map(([k, v], i) =>
        k === "" ? "Unknown" : k
      );

      const gs = Object.entries(report.device).map(([k, v], i) => v);

      setData({
        labels: gl,
        datasets: [
          {
            label: label,
            data: gs,
            backgroundColor: gl.map(() => randomColor()),
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    };
    if (report !== null && type !== null) updateReport();
  }, [report, setData]);

  const options = {
    indexAxis: "y",
    responsive: true,
    tension: 0.4,
    fill: true,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  return <div className="w-full">{data && <Bar data={data} />}</div>;
};

export default DeviceChart;
