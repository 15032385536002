import { MailIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import Layout from "../components/Layout";
import LoadingIndicator from "../components/LoadingIndicator";
import { useAuthState } from "../context";

const ResendEmail = ({ userData, setUserData }) => {
  const userDetails = useAuthState();
  const history = useHistory();
  const [state, setState] = useState({
    loading: false,
    message: "",
  });
  const [emailSent, setEmailSent] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (userDetails) {
      if (userDetails.userDetails.email_verified_at !== null) {
        history.push("/dashboard");
      }
    }
  }, [userDetails]);

  const runSendEmail = async () => {
    const http = global.services.http;
    setLoading(true);
    var req = await http.post(
      `${process.env.REACT_APP_API}/users/send-email-verification`
    );
    if (req.data) {
      setEmailSent(true);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className=" bg-white rounded-xl shadow-xl text-2xl block items-center justify-center   space-y-3 p-4">
        {loading === true ? <LoadingIndicator className="  w-6 h-6" /> : null}
        <div className=" ">
          <p className="mb-4 font-bold">
            Your email address needs verification.
          </p>
          <p className="mb-4 text-base">
            New users will find an email in their inbox, if not, click the link
            below to send a notification allowing you to confirm your email
            address. (you only have to do this once).
          </p>
          <p className="text-base">
            {emailSent === false ? (
              <button
                className="font-bold flex items-center gap-4 hover:text-blue-900"
                onClick={() => runSendEmail()}
              >
                Send Verification Email <MailIcon className="w-8 h-8 " />
              </button>
            ) : (
              "Check your inbox for a link to confirm your account"
            )}
          </p>
          <p className="mt-4 text-base">
            Having problems? Contact us{" "}
            <a href="https://myradio.click/contact" className="font-bold">
              here.
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ResendEmail;
