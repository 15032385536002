import React, { useEffect } from "react";
import logo from "../images/logo-ai.png";

export default function AiLink({ location = "footer" }) {
  const sendEvent = () => {
    window.gtag("event", "ai_link_clicked", {
      location: location,
    });
  };
  return (
    <a
      className="flex justify-center gap-4 items-center  group "
      target="_new"
      href="https://radiohostai.com"
      onClick={() => sendEvent()}
    >
      <div className="w-16">
        <img src={logo} alt="RadioHostAI" className="w-full h-auto mx-auto" />
      </div>
      <div>
        <h3 className="text-2xl font-bold group-hover:text-blue-800 transition-all">
          RadioHostAI.com
        </h3>
        <p>Developed by myradio.click</p>
      </div>
    </a>
  );
}
