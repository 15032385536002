
const WeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


export const Pad = (d) => {
  return d < 10 ? "0" + d.toString() : d.toString();
};



export const returnWeekday = (i) => {
  return WeekDays[i] ? WeekDays[i] : null;
};

// export default Pad;
