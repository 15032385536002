import React from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import SectionForm from "../components/forms/SectionForm";

export default function EditSection() {
  const { id, station_id } = useParams();
  return (
    <Layout route="sections" title="Add New Segment">
      <SectionForm id={id} stationId={station_id} />
    </Layout>
  );
}
