// import React from 'react'
import Login from "../pages/Login";
import AddUser from "../pages/AddUser";
import Users from "../pages/Users";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import StationUsers from "../pages/StationUsers";
import EpisodeReport from "../pages/EpisodeReport";
import Segments from "../pages/Segments";
import Profile from "../pages/Profile";
import StationGrid from "../pages/StationGrid";
import AcceptInvite from "../pages/AcceptInvite";
import Programs from "../pages/Programs";
import EditWidget from "../pages/EditWidget";
import Widgets from "../pages/Widgets";
import Sections from "../pages/Sections";
import EditProgram from "../pages/EditProgram";
import AddWidget from "../pages/AddWidget";
import EditSection from "../pages/EditSection";
import Program from "../pages/Program";
import AddProgram from "../pages/AddProgram";
import AddSection from "../pages/AddSection";
import Episodes from "../pages/Episodes";
import Replacer from "../pages/Replacer";
import Dontate from "../pages/Donate";
import AddStation from "../pages/AddStation";
import EmailVerification from "../pages/EmailConfirmation";
import Signup from "../pages/SignUp";
import EditStation from "../pages/EditStation";
import Station from "../pages/Station";
import Episode from "../pages/Episode";
import AddPost from "../pages/AddPost";
import Posts from "../pages/Posts";
import Post from "../pages/Post";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import StationInvites from "../pages/StationInvites";
import StationReports from "../pages/StationReports";
import AddStream from "../pages/AddStream";
import Widget from "../pages/Widget";
import ResendEmail from "../pages/ResendEmail";
import PurchaseCredits from "../pages/PurchaseCredits";
import Stash from "../pages/Stash";
import User from "../pages/User";
import Rolls from "../pages/Rolls";
import Genres from "../pages/Genres";
import Categories from "../pages/Categories";

const routes = [
  {
    path: "/email-verification",
    authRequired: false,
    component: () => <EmailVerification />,
  },
  {
    path: "/verify",
    authRequired: true,
    component: () => <ResendEmail />,
  },
  {
    path: "/login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    isPrivate: false,
  },
  {
    path: "/password-reset",
    component: ResetPassword,
    isPrivate: false,
  },
  {
    path: "/accept-invite",
    component: AcceptInvite,
    isPrivate: false,
  },
  {
    path: "/signup",
    component: Signup,
    isPrivate: false,
  },
  {
    path: "/donate",
    component: Dontate,
    isPrivate: true,
  },
  {
    path: "/stash",
    component: Stash,
    isPrivate: true,
  },
  {
    path: "/purchase-credits",
    component: PurchaseCredits,
    isPrivate: true,
  },
  {
    path: "/home",
    component: Home,
    isPrivate: true,
  },
  {
    path: "/station/:id/reports",
    component: StationReports,
    isPrivate: true,
  },
  {
    path: "/profile",
    component: Profile,
    isPrivate: true,
  },
  {
    path: "/station/:id/edit",
    component: EditStation,
    isPrivate: true,
  },
  {
    path: "/station/:id/segments",
    component: Segments,
    isPrivate: true,
  },
  {
    path: "/station/:id/replace",
    component: Replacer,
    isPrivate: true,
  },
  {
    path: "/station/:id/rolls",
    component: Rolls,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/invites/:no",
    component: StationInvites,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/invites",
    component: StationInvites,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/users",
    component: StationUsers,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/episode/:id/reports",
    component: EpisodeReport,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/episode/:id",
    component: Episode,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/episodes/:no",
    component: Episodes,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/episodes",
    component: Episodes,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/post/new",
    component: AddPost,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/post/:id/edit",
    component: AddPost,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/post/:id",
    component: Post,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/posts/:no",
    component: Posts,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/posts",
    component: Posts,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/stream/new",
    component: AddStream,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/widgets",
    component: Widgets,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/widget/new",
    component: AddWidget,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/widget/:id/edit",
    component: EditWidget,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/program/new",
    component: AddProgram,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/program/:id/edit",
    component: EditProgram,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/program/:id",
    component: Program,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/widget/:id",
    component: Widget,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/programs/:no",
    component: Programs,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/programs",
    component: Programs,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/genres/:no",
    component: Genres,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/genres",
    component: Genres,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/categories/:no",
    component: Genres,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/categories",
    component: Categories,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/section/new",
    component: AddSection,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/section/:id/edit",
    component: EditSection,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/sections/:no",
    component: Sections,
    isPrivate: true,
  },
  {
    path: "/station/:station_id/sections",
    component: Sections,
    isPrivate: true,
  },
  {
    path: "/station/new",
    component: AddStation,
    isPrivate: true,
  },
  {
    path: "/station/:id/grid",
    component: StationGrid,
    isPrivate: true,
  },
  {
    path: "/station/:id",
    component: Station,
    isPrivate: true,
  },
  {
    path: "/stations/:no",
    component: Home,
    isPrivate: true,
  },
  {
    path: "/stations",
    component: Home,
    isPrivate: true,
  },
  {
    path: "/users/new",
    component: AddUser,
    isPrivate: true,
  },
  {
    path: "/users/:id",
    component: User,
    isPrivate: true,
  },
  {
    path: "/users",
    component: Users,
    isPrivate: true,
  },

  {
    path: "/",
    component: Home,
    isPrivate: true,
  },
  {
    path: "/*",
    component: NotFound,
    isPrivate: true,
  },
];

export default routes;
