import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm, Controller } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import { useHistory, Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import NewMediaUpload from "../NewMediaUpload";
import * as yup from "yup";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import Spinner from "../Spinner";

const Inline = Quill.import("blots/inline");
const BlockEmbed = Quill.import("blots/block/embed");

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  precontent: yup.string(),
});

export default function PostForm({ id = null, stationId = null }) {
  console.log("Gpt hjere", stationId);
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const { station } = React.useContext(StationContext);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const quilRef = React.useRef(null);
  const http = global.services.http;
  const [content, setContent] = useState("");
  const [embed, setEmbed] = useState("");
  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [post, setPost] = useState(null);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${stationId}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        console.log("Updates station");
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [stationId]);

  useEffect(() => {
    const http = global.services.http;
    const getPost = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/posts/${id}`);
      if (req) {
        setPost(req.data.data);
        if (req.data.data.image) {
          setMedia(req.data.data.image);
        }
        setContent(req.data.data.content);
      }
    };
    if (id) {
      getPost();
    }
  }, [id]);

  const postSubmit = async (payload) => {
    if (loading === true) return;
    let fields = { ...payload, type: "s3", station_id: stationId };

    if (media) {
      fields.media_id = media.id;
    }

    if (content) {
      fields.content = content;
    }
    // handle submitting the form
    setLoading(true);
    try {
      if (id) {
        var req = await http.put(
          `${process.env.REACT_APP_API}/posts/${id}`,
          fields
        );
      } else {
        var req = await http.post(`${process.env.REACT_APP_API}/posts`, fields);
      }
      if (!req) return;
      setLoading(false);
      history.push(`/station/${stationId}/post/${req.data.data.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(watch("email"));
  const Header = () => (
    <p className={"text-lg font-bold"}>{`Edit Post: ${
      id ? post && post.name : "Add New Post"
    }`}</p>
  );

  return (
    <div className={"max-w-full  mr-auto  bg-white shadow-lg rounded-xl"}>
      <SectionHeader content={<Header />} />
      <div className="p-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        {!id || post ? (
          <>
            <div className="mb-8">
              <label
                htmlFor="name"
                className={`block font-bold text-sm mb-2  `}
              >
                Post Image
              </label>
              <NewMediaUpload
                onComplete={setMedia}
                accept={"image/*"}
                type="s3"
              />
            </div>
            <form onSubmit={handleSubmit(postSubmit)}>
              {" "}
              {media && media.url ? (
                <div className={"text-center w-full"}>
                  <img
                    className={"max-w-full ml-auto mr-auto mt-4 mb-4"}
                    src={media.url}
                    alt={"for this post"}
                  />
                </div>
              ) : (
                <p className={"font-bold mb-2"}>Upload an image</p>
              )}
              <div className="mb-8">
                <label
                  htmlFor="title"
                  className={`block font-bold text-sm mb-2 ${
                    errors.title ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Post Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  defaultValue={post && post.title}
                  placeholder="Post Title"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.title ? "text-red-300  " : " "
                  }`}
                  {...register("title")}
                />
                {errors.title && (
                  <p className="text-red-500 text-sm mt-2">
                    A valid Post Title is required.
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label>Publish Date:</label>
                {post && post.published_date}
                {(!id || post !== null) && (
                  <Controller
                    control={control}
                    name="published_date"
                    className="input"
                    // defaultValue={
                    //   post && post.published_date
                    //     ? moment(post.published_date, 'YYYY-MM-MM hh:mm:ss')
                    //     : moment()
                    // }
                    placeholderText="Select date"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="Pp"
                      />
                    )}
                  />
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="precontent"
                  className={`block font-bold text-sm mb-2 ${
                    errors.precontent ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Excerpt
                </label>
                <input
                  type="text"
                  name="precontent"
                  id="precontent"
                  defaultValue={post && post.precontent}
                  placeholder=""
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.precontent ? "text-red-300  " : " "
                  }`}
                  {...register("precontent")}
                />
              </div>
              <div className="mb-8">
                <label
                  htmlFor="content"
                  className={`block font-bold text-sm mb-2 ${
                    errors.content ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Post Content
                </label>
                <ReactQuill
                  theme="snow"
                  value={content}
                  onChange={setContent}
                  ref={quilRef}
                  modules={PostForm.modules}
                  formats={PostForm.formats}
                />
                {/* <div>
                  <label
                    htmlFor="embed"
                    className={`block font-bold text-sm mb-2`}
                  >
                    Embed URL
                  </label>
                  <input
                    type="text"
                    value={embed}
                    onChange={(e) => setEmbed(e.target.value)}
                    placeholder="Paste your embed text"
                    className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                  />
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    buttonClick();
                  }}
                >
                  Insert Embed
                </button> */}
              </div>
              <button className="w-full default-button  ">
                {!id ? "Create New Post" : "Edit Post"}
                {loading && <Spinner className="w-4 h-4 ml-2" />}
              </button>
            </form>
            <p className=" text-center mt-4">
              <Link
                className="ml-auto inline-block bg-gray-200 text-gray-800 rounded shadow py-1 px-3 text-xs"
                to={`/station/${stationId}`}
              >
                Cancel
              </Link>
            </p>
          </>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
PostForm.modules = {};
PostForm.modules.toolbar = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"], // blocks
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }], // lists
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
  [{ color: [] }, { background: [] }], // dropdown with defaults
  [{ font: [] }], // font family
  [{ align: [] }], // text align
  ["clean"], // remove formatting
];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
PostForm.formats = [
  "header",
  "font",
  "background",
  "color",
  "code",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "script",
  "align",
  "direction",
  "link",
  "image",
  "code-block",
  "formula",
  "audio",
];
