import React, { useEffect, useState } from "react";
import { useAuthState } from "./context";
import { useAuthDispatch } from "./context";
import { refreshUser } from "./actions";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast as ToastMessage } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MySwal = withReactContent(Swal);
// import { logout } from ".";

export const StationContext = React.createContext();

const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("color-theme");
    if (typeof storedPrefs === "string") {
      return storedPrefs;
    }

    const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (userMedia.matches) {
      return "dark";
    }
  }

  return "light"; // light theme as the default;
};

export const StationProvider = ({ initialTheme, children }) => {
  const [station, setStation] = React.useState(null);
  let history = useHistory();
  const dispatch = useAuthDispatch();
  const http = global.services.http;
  const userDetails = useAuthState(); //read user details from context
  const [openPlayout, setOpenPlayout] = useState(false);
  const [active, setActive] = useState([]);
  const [sidebar, setSidebar] = React.useState(true);
  const [playoutLayout, setPlayoutLayout] = React.useState("2");
  const [stashes, setStashes] = useState([]);
  const [stashLoading, setStashLoading] = useState([]);
  const [user, setUser] = React.useState(null);
  const [theme, setTheme] = React.useState(getInitialTheme);
  const [stationOwner, setStationOwner] = React.useState(false);
  const [swal, setSwal] = useState(false);
  const [toast, setToast] = useState(null);
  const rawSetTheme = (rawTheme) => {
    const root = window.document.documentElement;
    const isDark = rawTheme === "dark";

    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(rawTheme);

    localStorage.setItem("color-theme", rawTheme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  useEffect(() => {
    if (swal) {
      MySwal.fire({
        title: <p>Hello World</p>,
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          // MySwal.showLoading();
        },
      }).then(() => {
        // return MySwal.fire(<p>Shorthand works too</p>);
      });
    }
  }, [swal]);

  useEffect(() => {
    const notify = (text) => ToastMessage(text);
    if (toast) {
      notify(toast);
    }
  }, [toast]);

  // useEffect(() => {
  //   const http = global.services.http;
  //   const getStash = async () => {
  //     setStashLoading(true);
  //     var req = await http.get(`${process.env.REACT_APP_API}/stash?limit=1000`);
  //     if (req.data) {
  //       setStashes(req.data.data);
  //       setStashLoading(false);
  //     }
  //   };
  //   if (stashes.length === 0) getStash();
  // }, [userDetails]);
  // const getStash = async () => {
  //   setStashLoading(true);
  //   var req = await http.get(`${process.env.REACT_APP_API}/stash?limit=1000`);
  //   if (req.data) {
  //     setStashes(req.data.data);
  //     setStashLoading(false);
  //   }
  // };
  React.useEffect(() => {
    if (station !== null && station.owner_id === userDetails.userDetails.id) {
      setStationOwner(true);
    } else {
      setStationOwner(false);
    }
  }, [station, setStationOwner]);

  React.useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  React.useEffect(() => {
    const getUser = async () => {
      refreshUser(dispatch);
    };
    if (userDetails && userDetails.userDetails !== "") {
      // console.log("Got here", userDetails);
      getUser();
    }
  }, [theme]);

  const getUser = async () => {
    refreshUser(dispatch);
  };

  React.useEffect(() => {
    if (userDetails) {
      setUser(userDetails.user);
    }
  }, [userDetails]);
  // React.useEffect(
  //   (_) => {
  //     if (activePlaylistTrack !== null) {
  //       setPlaylistOpen(true);
  //     }
  //   },
  //   [activePlaylistTrack]
  // );

  return (
    <StationContext.Provider
      value={{
        theme,
        setTheme,
        setStation,
        station,
        sidebar,
        setSidebar,
        stationOwner,
        getUser,
        user,
        stashes,
        setStashes,
        // getStash,
        stashLoading,
        setStashLoading,
        active,
        setActive,
        openPlayout,
        setOpenPlayout,
        playoutLayout,
        setPlayoutLayout,
        swal,
        setSwal,
        toast,
        setToast,
      }}
    >
      {children}
    </StationContext.Provider>
  );
};
