const ROOT_URL = `${process.env.REACT_APP_API}`;

export async function refreshUser(dispatch, userPayload) {
  try {
    const http = global.services.http;
    var req = await http.get(`${process.env.REACT_APP_API}/auth/refresh`);
    if (req.data.user) {
      dispatch({ type: "LOGIN_SUCCESS", payload: req.data });
      localStorage.setItem("currentUser", JSON.stringify(req.data));
    }
    // let oldUserProcessed = JSON.parse(oldUser);
    // if (oldUser) {
    //   let newUser = {
    //     // ...userPayload,
    //     ...oldUserProcessed,
    //     user: userPayload.user,
    //   };
    //   dispatch({ type: "USER_UPDATED", payload: newUser });
    //   localStorage.setItem("currentUser", JSON.stringify(newUser));
    //   return newUser;
    // }
    // dispatch({ type: "LOGIN_ERROR", error: data.message });
    return;
  } catch (error) {
    // dispatch({ type: "LOGIN_ERROR", error: error });
  }
}

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await fetch(`${ROOT_URL}/auth/login`, requestOptions);
    let data = await response.json();

    if (data.user) {
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      localStorage.setItem("currentUser", JSON.stringify(data));
      return data;
    }
    dispatch({ type: "LOGIN_ERROR", error: data.message });
    return;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}

export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
}
