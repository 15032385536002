import React from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import ProgramForm from "../components/forms/ProgramForm";
import NewPageHeader from "../components/Global/NewPageHeader";

export default function AddProgram() {
  const { id, station_id } = useParams();
  return (
    <Layout route="programs" title="Add New Program">
      <NewPageHeader title={"Create a program"} links={[]} />
      {station_id && <ProgramForm stationId={station_id} />}
    </Layout>
  );
}
