import React from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import SectionForm from "../components/forms/SectionForm";

export default function AddSection() {
  const { id, station_id } = useParams();
  return (
    <Layout route="sections" title="Add New Section">
      {station_id && <SectionForm stationId={station_id} />}
    </Layout>
  );
}
