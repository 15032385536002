import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });
        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );
const validationSchema = yup.object().shape({
  search: yup.string().required(),
});

export default function EpisodeSearch({
  search = "",
  setSearch = null,
  setPageNo = null,
}) {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    useErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  const doSearch = async (payload) => {
    console.log(payload);
    if (payload.search !== "") {
      setPageNo(1);
      setSearch(payload.search);
    }
  };

  return (
    <div className="p-4 block lg:flex gap-4 flex-grow">
      <form
        className="w-full flex gap-4 items-end"
        onSubmit={handleSubmit(doSearch)}
      >
        <div className="flex-grow">
          <label
            htmlFor="title"
            className={`block font-bold text-sm mb-2 ${
              errors.search ? "text-red-400" : "text-gray-800"
            }`}
          >
            Search Station Episodes
          </label>
          <input
            type="text"
            name="search"
            id="search"
            defaultValue={search}
            placeholder="Search Episodes by Program Name"
            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
              errors.search ? "text-red-300  " : " "
            }`}
            {...register("search")}
          />
          {errors.search && (
            <p className="text-red-500 text-sm mt-2">
              Oops! Please enter something in to perform a search
            </p>
          )}
        </div>
        <div>
          <button className="w-full default-button  ">Search</button>
        </div>
      </form>
    </div>
  );
}
