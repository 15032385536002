import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import NewMediaUpload from "../NewMediaUpload";
import { useAuthState } from "../../context";
import { useHistory, Link } from "react-router-dom";
import { StationContext } from "../../context/station-context";

import * as yup from "yup";
import TimezoneSelect from "react-timezone-select";
import Spinner from "../Spinner";
import { userCheck } from "../../config/functions";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  // timezone: yup.string().required(),
});

export default function StationForm({ id = null }) {
  const { station, setStation } = React.useContext(StationContext);
  const userDetails = useAuthState(); //read user details from context
  let history = useHistory();
  const http = global.services.http;
  const [media, setMedia] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(null);
  const resolver = useYupValidationResolver(validationSchema);

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });
  React.useEffect(() => {
    if (id === "new") {
      setStation(null);
    }
  }, [id]);

  const stationSubmit = async (payload) => {
    if (loading === true) return;
    // handle submitting the form
    alert();
    console.log(payload, selectedTimezone);

    let fields = await { ...payload, timezone: selectedTimezone };

    if (media) {
      fields = await {
        ...fields,
        media_id: media.id,
        timezone: selectedTimezone,
      };
    }
    setLoading(true);
    try {
      let req;
      if (id && id !== "new") {
        req = await http.put(`${process.env.REACT_APP_API}/stations/${id}`, {
          ...fields,
        });
      } else {
        req = await http.post(`${process.env.REACT_APP_API}/stations`, {
          ...fields,
        });
      }
      setLoading(false);

      console.log("here", req.data.data, req);
      if (req.status === 422) {
        if (req.data.data) {
          if (req.data.data.name) {
            setError("name", {
              type: "manual",
              message: req.data.data.name[0],
            });
          }
          if (req.data.data.timezone) {
            console.log("Got here", req.data.data.timezone[0]);
            setError("timezone", {
              type: "manual",
              message: req.data.data.timezone[0],
            });
          }
          if (req.data.data.icecast_stream) {
            setError("icecast_stream", {
              type: "manual",
              message: req.data.errors.icecast_stream[0],
            });
          }
          if (req.data.data.hls_stream) {
            setError("hls_stream", {
              type: "manual",
              message: req.data.errors.hls_stream[0],
            });
          }
          if (req.data.data.call_sign) {
            setError("call_sign", {
              type: "manual",
              message: req.data.errors.call_sign[0],
            });
          }
        }
      } else {
        history.push(`/station/${req.data.data.id}`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage("An error has occured.");
      //   setErrorMessage(error);
    }
  };

  React.useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/stations/${id}`);
      if (req) {
        setStation(req.data.data);
        setSelectedTimezone(req.data.data.timezone);
        if (req.data.data.image) {
          setMedia(req.data.data.image);
        }
        // userCheck({ station: req.data.data, user: userDetails });
      }
    };
    if (id) {
      getStation();
    }
  }, [id]);

  React.useEffect(() => {
    console.log("Updated Media", media);
  }, [media]);

  //   console.log(watch("email"));

  console.log("errros", errors);

  return !id || (id && station) ? (
    <div className={"max-w-full p-4 me-auto bg-white shadow-xl rounded-xl"}>
      {id !== "new" && (
        <h1 className="font-bold mb-8">{station && station.name}</h1>
      )}
      {errorMessage ? <p>{errorMessage}</p> : null}
      <>
        <div className="mb-8">
          <label htmlFor="name" className={`block font-bold text-sm mb-2  `}>
            Station Logo
          </label>
          <NewMediaUpload onComplete={setMedia} accept={"image/*"} type="s3" />
        </div>
      </>
      <form onSubmit={handleSubmit(stationSubmit)}>
        {media && media.url ? (
          <div className={"text-center w-full"}>
            <img
              className={"max-w-full ml-auto mr-auto mt-4 mb-4"}
              src={media.url}
              alt={"for this program"}
            />
          </div>
        ) : (
          <p className={"font-bold mb-8"}>
            No Logo Uploaded. Drag and drop an image file above to add a logo
          </p>
        )}
        <div className="mb-8">
          <label
            htmlFor="name"
            className={`block font-bold text-sm mb-2 ${
              errors.name ? "text-red-400" : "text-gray-800"
            }`}
          >
            Name
          </label>
          <input
            autoFocus
            type="text"
            name="name"
            id="name"
            defaultValue={station && id !== "new" ? station.name : ""}
            placeholder="name"
            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
              errors.name ? "border-red-400" : " "
            }`}
            {...register("name")}
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-2">
              A valid program name is required.
            </p>
          )}
        </div>
        <div className="mb-8">
          <label
            htmlFor="call_sign"
            className={`block font-bold text-sm mb-2 ${
              errors.call_sign ? "text-red-400" : "text-gray-800"
            }`}
          >
            Call Sign
          </label>
          <input
            type="text"
            name="call_sign"
            id="call_sign"
            defaultValue={station && id !== "new" ? station.call_sign : ""}
            placeholder="Call Sign"
            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
              errors.call_sign ? " border-red-400" : " "
            }`}
            {...register("call_sign")}
          />
          {errors.call_sign && (
            <p className="text-red-500 text-sm mt-2">
              A valid call sign is required.
            </p>
          )}
        </div>
        <div className="mb-8">
          <label
            htmlFor="timezone"
            className={`block font-bold text-sm mb-2 ${
              errors.timezone ? "text-red-400" : "text-gray-800"
            }`}
          >
            Timezone
          </label>
          <TimezoneSelect
            value={selectedTimezone}
            onChange={setSelectedTimezone}
          />
          {/* <input
            type="text"
            name="timezone"
            id="timezone"
            defaultValue={
              station && id !== "new" ? station.timezone : "Australia/Sydney"
            }
            placeholder="Timezone"
            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
              errors.timezone ? " border-red-400" : " "
            }`}
            {...register("timezone")}
          /> */}
          {errors.timezone && (
            <p className="text-red-500 text-sm mt-2">
              A valid timezone is required.
            </p>
          )}
        </div>
        {userDetails && userDetails.role === "admin" && (
          <div className="mb-8">
            <label
              htmlFor="netlify_publish"
              className={`block font-bold text-sm mb-2 ${
                errors.netlify_publish ? "text-red-400" : "text-gray-800"
              }`}
            >
              Netlify Publish URL (for gatsby users)
            </label>
            <input
              type="text"
              name="netlify_publish"
              id="netlify_publish"
              defaultValue={
                station && id !== "new" ? station.netlify_publish : ""
              }
              placeholder="Netlify Publish URL (for gatsby users)"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                errors.netlify_publish ? " border-red-400" : " "
              }`}
              {...register("netlify_publish")}
            />
            {errors.netlify_publish && (
              <p className="text-red-500 text-sm mt-2">
                A valid netlify_publish is required.
              </p>
            )}
          </div>
        )}
        <div className="mb-8">
          <label
            htmlFor="wordpress_url"
            className={`block font-bold text-sm mb-2 ${
              errors.wordpress_url ? "text-red-400" : "text-gray-800"
            }`}
          >
            Wordpress Url
          </label>
          <input
            type="text"
            name="wordpress_url"
            id="wordpress_url"
            defaultValue={station && id !== "new" ? station.wordpress_url : ""}
            placeholder="Wordpress Url"
            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
              errors.wordpress_url ? " border-red-400" : " "
            }`}
            {...register("wordpress_url")}
          />
          {errors.wordpress_url && (
            <p className="text-red-500 text-sm mt-2">
              A valid wordpress_url is required.
            </p>
          )}
        </div>
        <div className="mb-8">
          <label
            htmlFor="icecast_stream"
            className={`block font-bold text-sm mb-2 ${
              errors.bio ? "text-red-400" : "text-gray-900"
            }`}
          >
            Icecast Stream
          </label>
          <input
            type="text"
            name="icecast_stream"
            id="icecast_stream"
            defaultValue={station && id !== "new" ? station.icecast_stream : ""}
            placeholder="Your Icecast URL"
            className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
              errors.password ? "border-red-400" : "border-blue-400"
            }`}
            {...register("icecast_stream")}
          />
          {errors.icecast_stream && (
            <p className="text-red-500 text-sm mt-2">
              A icecast_stream is required
            </p>
          )}
        </div>
        {userDetails && userDetails.role === "admin" && (
          <div className="mb-8">
            <label
              htmlFor="hls_stream"
              className={`block font-bold text-sm mb-2 ${
                errors.bio ? "text-red-400" : " "
              }`}
            >
              Hls Stream
            </label>
            <input
              type="text"
              name="hls_stream"
              id="hls_stream"
              defaultValue={station && id !== "new" ? station.hls_stream : ""}
              placeholder="The Master URL For your HLS Stream"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                errors.hls_stream ? "border-red-400" : " "
              }`}
              {...register("hls_stream")}
            />
            {errors.hls_stream && (
              <p className="text-red-500 text-sm mt-2">
                A hls stream is required
              </p>
            )}
          </div>
        )}
        {userDetails && userDetails.role === "admin" && (
          <div className="mb-8">
            <label
              htmlFor="bitrates"
              className={`block font-bold text-sm mb-2 ${
                errors.bitrates ? "text-red-400" : " "
              }`}
            >
              BITRates
            </label>
            <input
              type="text"
              name="bitrates"
              id="bitrates"
              defaultValue={station && id !== "new" ? station.bitrates : ""}
              placeholder="The Master URL For your HLS Stream"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                errors.bitrates ? "border-red-400" : " "
              }`}
              {...register("bitrates")}
            />
            {errors.bitrates && (
              <p className="text-red-500 text-sm mt-2">A bitrates</p>
            )}
          </div>
        )}
        <div className="flex gap-12 item-center">
          <button className="  default-button  ">
            {id ? "Update Station" : "Create New Station"}
            {loading && <Spinner className="w-4 h-4 ml-2" />}
          </button>
          <p className={"text-center "}>
            <Link
              className="hover:underline ml-auto inline-block text-black py-1 px-3 text-sm border-2 border-gray-200 rounded-md"
              to={`/`}
            >
              Cancel
            </Link>
          </p>
        </div>
      </form>
    </div>
  ) : (
    "Loading"
  );
}
