import React from "react";
import NewStationForm from "../components/forms/NewStationForm";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";

export default function AddStation() {
  const { id } = useParams();
  return (
    <Layout title={`${id ? "Edit Station" : "Add New Station"}`}>
      <NewStationForm id={id} />
    </Layout>
  );
}
