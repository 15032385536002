import React, { useEffect } from "react";
import AiLink from "./AiLink";

const AiContent = ({ location }) => {
  const sendEvent = () => {
    window.gtag("event", "ai_link_clicked", {
      location: location,
      sign_up: true,
    });
  };
  return (
    <div>
      <p className="font-extrabold text-lg  text-center md:text-left mb-4 md:mb-0">
        Generate Program Images, Playlists, Program Summaries and more in
        seconds using the power of AI.
      </p>
      <p className="text-sm text-gray-500 text-center md:text-left">
        Use Coupon Code:{" "}
        <span className="font-bold text-black">MyradioClickVIP</span> for{" "}
        <span className="font-bold text-black">50% off</span> your first month.
        <a
          target="_new"
          onClick={() => sendEvent()}
          href="https://radiohostai.com"
          className="text-blue-800 hover:text-green-800 transition-all font-bold "
        >
          Sign up now >
        </a>
      </p>
    </div>
  );
};

export default function AiHeader({ location = "footer", content = null }) {
  const sendEvent = () => {
    window.gtag("event", "ai_link_clicked", {
      location: location,
    });
  };
  return (
    <div className="bg-white p-3 rounded-lg flex gap-6 items-center justify-start mb-6 flex-col md:flex-row">
      <div>
        <AiLink location={location} />
      </div>
      <div>{content ? content : <AiContent location={location} />}</div>
    </div>
  );
}
