import Layout from "../components/Layout";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import CreditCardForm from "../components/CreditCardForm";
import { loadStripe } from "@stripe/stripe-js";
import {
  ArrowCircleDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import LoadingIndicator from "../components/Global/LoadingIndicator";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const thClass = "text-sm font-medium text-gray-900 px-6 py-4 text-left";
const tdClass = "text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap";
const trClass =
  "bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100";

export default function Dontate() {
  const [intent, setIntent] = useState(null);
  const [options, setOptions] = useState(null);
  const [cards, setCards] = useState([]);
  const [amount, setAmount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [showCardList, setShowCardList] = useState(false);
  const [defaultCard, setDefaultCard] = useState(null);
  const [message, setMessage] = useState(null);
  const http = global.services.http;
  const appearance = {
    theme: "stripe",
  };

  const getCards = async () => {
    setLoading(true);
    var req = await http.get(
      `${process.env.REACT_APP_API}/stripe/return-cards`
    );
    if (req.data) {
      console.log("cards", req.data.data);
      setCards(req.data.data.cards);
      setDefaultCard(req.data.data.default);
      if (req.data.data.cards.length === 0) {
        setShowCardForm(true);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const getIntent = async () => {
      setLoading(true);
      var req = await http.post(
        `${process.env.REACT_APP_API}/stripe/create-intent`
      );
      if (req.data) {
        setIntent(req.data.data);
        console.log(req.data.data.client_secret);
        setOptions({
          clientSecret: req.data.data.client_secret,
          appearance,
        });
        getCards();
      }
    };

    if (!intent) {
      getIntent();
    }
  }, []);

  const changeDefault = async (paymentMethod) => {
    setLoading(true);
    var req = await http.post(
      `${process.env.REACT_APP_API}/stripe/change-card`,
      { paymentMethod }
    );
    if (req.data) {
      setLoading(false);
      setMessage(null);
      setDefaultCard(req.data.data);
    }
  };

  const makeDonation = async () => {
    if (loadingPayment === true) return;
    setLoadingPayment(true);
    var req = await http.post(
      `${process.env.REACT_APP_API}/stripe/make-donation`,
      { paymentAmount: amount, paymentMethodId: defaultCard.id }
    );
    if (req.data) {
      if (req.data.success === true) {
        setMessage("Donation received, Thanks for your support!");
      } else {
        if (req.data.data?.payment?.last_payment_error?.message) {
          setMessage(
            `OOPS! ${req.data.data?.payment?.last_payment_error?.message}`
          );
        }
      }
      console.log(req.data.data);
    }
    setLoadingPayment(false);
  };

  return (
    <Layout route="donate" path="donate">
      <h1 className="text-4xl font-bold mb-4">
        Donate and Support Myradio.Click
      </h1>
      <div className="bg-white p-4 shadow-xl rounded-xl relative max-w-2xl w-full mb-4">
        <h2 className=" text-xl mb-4 text-center">
          <span className="text-base">Step 1.</span>
          <br />
          <span className="font-bold">Choose Amount</span>
        </h2>

        <div className="mb-8">
          <button
            onClick={() => setAmount(2)}
            className={`p-2 mx-3 text-white ${
              amount === 2 ? " bg-blue-700" : "bg-blue-900"
            }`}
          >
            Donate $2
          </button>
          <button
            onClick={() => setAmount(5)}
            className={`p-2 mx-3 text-white ${
              amount === 5 ? " bg-blue-700" : "bg-blue-900"
            }`}
          >
            Donate $5
          </button>
          <button
            onClick={() => setAmount(10)}
            className={`p-2 mx-3 text-white ${
              amount === 10 ? " bg-blue-700" : "bg-blue-900"
            }`}
          >
            Donate $10
          </button>
          <button
            onClick={() => setAmount(20)}
            className={`p-2 mx-3 text-white ${
              amount === 20 ? " bg-blue-700" : "bg-blue-900"
            }`}
          >
            Donate $20
          </button>
          <button
            onClick={() => setAmount(50)}
            className={`p-2 mx-3 text-white ${
              amount === 50 ? " bg-blue-700" : "bg-blue-900"
            }`}
          >
            Donate $50
          </button>
        </div>
        <div className="flex gap-2 justify-center mb-8">
          <div>
            <span className="text-xl">$</span>
            <input
              className="text-xl p-2 border border-separate border-gray-700 ml-2 w-32 text-right"
              value={amount}
              type="number"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="bg-white p-4 shadow-xl rounded-xl relative max-w-2xl w-full mb-4">
        {loading && (
          <div
            className={
              "absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-20"
            }
          >
            <LoadingIndicator />
          </div>
        )}
        <h2 className="text-xl mb-4 text-center">
          <span className="text-base">Step 2.</span>
          <br />
          <span className="font-bold">Enter Billing Information</span>
        </h2>

        {defaultCard && (
          <div className="mb-4">
            <div className="mb-4 text-center">
              <p className="font-bold">Pay using:</p>
              <table className="min-w-full">
                <tbody>
                  <tr className={trClass}>
                    <td className={tdClass}>
                      <span className="font-bold">Type:</span>{" "}
                      <span className="uppercase">
                        {defaultCard.card.brand}
                      </span>
                    </td>
                    <td className={tdClass}>
                      <span className="font-bold">Last four:</span>{" "}
                      {defaultCard.card.last4}
                    </td>
                    <td className={tdClass}>
                      <span className="font-bold">Expiry:</span>{" "}
                      {defaultCard.card.exp_month}/{defaultCard.card.exp_year}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="text-center">
              <button
                className="font-bold text-center mx-auto"
                onClick={() => setShowCardList(!showCardList)}
              >
                Or Use Another Card{" "}
                {showCardList ? (
                  <ArrowUpIcon className="w-5 h-5 inline" />
                ) : (
                  <ArrowDownIcon className="w-5 h-5 inline" />
                )}
              </button>
            </div>
          </div>
        )}
        {cards.length === 0 && (
          <button onClick={() => setShowCardForm(true)}>
            Add {cards.length > 0 ? "another" : ""} credit card
          </button>
        )}

        {cards && showCardList && cards.length > 0 ? (
          <table className="min-w-full">
            <thead className="bg-white border-b">
              <tr className="py-2   px-4 w-full text-sm font-medium rounded-t-lg border-b border-gray-200   ">
                <th className={thClass}>Current</th>
                <th className={thClass}>Brand</th>
                <th className={thClass}>Last Four Digits</th>
                <th className={thClass}>Expiry</th>
                <th className={thClass}></th>
              </tr>
            </thead>
            <tbody>
              {cards.map((item, i) => (
                <tr
                  onClick={() => changeDefault(item.id)}
                  className={trClass}
                  key={i}
                >
                  <td className="px-3 py-2">
                    {defaultCard && defaultCard.id === item.id ? (
                      <CheckCircleIcon className="w-4 h-4" />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className={tdClass}>{item.card.brand}</td>
                  <td className={tdClass}>{item.card.last4}</td>
                  <td className={tdClass}>
                    {defaultCard.card.exp_month}/{defaultCard.card.exp_year}
                  </td>
                  <td className={tdClass}>
                    {defaultCard && defaultCard.id === item.id ? (
                      ""
                    ) : (
                      <span className="px-3 py-2 bg-blue-700 text-white hover:bg-blue-900">
                        SELECT
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ""
        )}

        {showCardList && (
          <div className="my-4 flex justify-center w-full">
            <button
              className="py-2 px-4 mb- bg-blue-900 text-white"
              onClick={() => setShowCardForm(true)}
            >
              <PlusIcon className="inline h-4 w-4" /> Add{" "}
              {cards.length > 0 ? "another" : ""} Credit Card
            </button>
          </div>
        )}
        {options && intent && showCardForm && (
          <Elements stripe={stripePromise} options={options} id="cardForm">
            <CreditCardForm
              intent={intent}
              setLoading={setLoading}
              getCards={getCards}
              setShowCardForm={setShowCardForm}
            />
          </Elements>
        )}
      </div>
      <div className="bg-white p-4 shadow-xl rounded-xl relative max-w-2xl w-full">
        <h2 className=" text-center text-2xl mb-4">
          <span className="text-base">Step 3.</span>
          <br />
          <span className="font-bold">Finalise Donation</span>
        </h2>

        <div className="font-bold text-lg text-center mb-4">
          {message ? (
            message
          ) : (
            <>
              {defaultCard && (
                <div className="flex mb-4    gap-2 justify-center items-center mt-8 w-full">
                  <button
                    className="px-4 py-3 mx-auto flex gap-2 items-center text-white bg-green-500"
                    onClick={() => makeDonation()}
                  >
                    {loadingPayment ? "Processing" : "Make"} ${amount} Donation{" "}
                    {loadingPayment && (
                      <LoadingIndicator
                        className={"text-white bg-white w-4 inline"}
                        width={4}
                      />
                    )}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}
