import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";
import { theme } from "tailwindcss/stubs/defaultConfig.stub";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

const StationChart = ({
  length,
  report,
  label = "Unique Ondemand Listeners",
}) => {
  const [data, setData] = useState(null);
  console.log("theme", theme);

  useEffect(() => {
    const updateReport = async () => {
      // const labelsOndmeand = report[length].map((item) => {
      //   if (length === "day") {
      //     return moment.unix(item.start).format("DD MMMM");
      //   } else if (length === "minute") {
      //     return moment.unix(item.start).format("h:mmA");
      //   } else {
      //     return moment.unix(item.start).format("DD MMMM hA");
      //   }
      // });
      // const dataOndemand = report[length].map((item) => item.unqiueCount);
      // console.log("dataOndemand", dataOndemand, "lablels", labelsOndmeand);

      const labels = report.map((item) => item.start);
      const thisData = report.map((item) => item.count);
      const mobiles = report.map((item) => item.mobiles);
      const uniques = report.map((item) => item.unique);

      setData({
        labels: labels,
        datasets: [
          {
            data: thisData,
            label: "New Live Stream Users",
            borderWidth: 4,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            data: mobiles,
            label: "Mobile Listeners",
            borderWidth: 4,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            data: uniques,
            label: "Unique Listeners",
            borderWidth: 4,
            borderColor: "rgb(74 222 128)",
            // backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    };
    if (report !== null && length !== undefined) updateReport();
  }, [report, setData, length]);

  const options = {
    responsive: true,
    responsive: true,
    plugins: {
      // legend: {
      //   position: 'top' as const,
      // },
      title: {
        display: true,
        text: "Listens",
      },
    },
  };

  // const data = useMemo(() => {
  //   return {
  //     listenersLabels,
  //     datasets: listenerCounts,
  //   };
  // }, [listenersLabels, listenerCounts]);

  return (
    <div className="w-full">
      {data && <Line options={options} data={data} />}
    </div>
  );
};

export default StationChart;
