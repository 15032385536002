import moment from "moment";
import "moment-timezone";

export const convertUTCToTimezone = (
  utcDt,
  utcDtFormat,
  timezone,
  targetFormat = "dddd, MMMM Do YYYY, h:mm a"
) => {
  return moment.utc(utcDt, utcDtFormat).tz(timezone).format(targetFormat);
};

export const userCheck = ({ station, user }) => {
  if (station.user_id === user.id || user.role === "admin") {
    return true;
  } else {
    window.location.href = "/dashboard";
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const buildEpisodeTrack = ({ episode }) => {
  return {
    isLive: false,
    readable: episode.readable,
    title: `${
      episode.title
        ? episode.title
        : `${episode.program.name} - ${episode.readable}`
    }`,
    url: `${process.env.REACT_APP_API}/public/ondemand/${episode.station.hls_stream}/96/${episode.timestamp}/${episode.duration}/listen.m3u8?unique=console`,
    link: `/station/${episode.station.id}/episode/${episode.id}`,
    time: `${
      episode.duration >= 60 * 60
        ? `${moment.utc(episode.duration * 1000).format("H")}hr ${moment
            .utc(episode.duration * 1000)
            .format("m")}min`
        : `${moment.utc(episode.duration * 1000).format("m")}min`
    }`,
    type: "track",
    timestamp: episode.timestamp,
    image: episode.image
      ? episode.image.url
      : episode.program && episode.program.image
      ? episode.program.image.url
      : null,
  };
};
