import React from "react";

export default function EpisodeReportTiny({ report }) {
  return (
    <div className="mb-2">
      {/* <p className="text-xs text-gray-700 mb-2">Episode Stats:</p> */}
      {report && (
        <div className="md:flex flex-wrap gap-2 text-xs">
          <div>
            <span className="font-bold">Live Stream Sessions:</span>{" "}
            {report.readable?.total_listeners}
          </div>

          <div>
            <span className="font-bold">Total Minutes Streamed (live):</span>{" "}
            {report.readable?.total_minutes.toFixed(2)}
          </div>

          <div>
            <span className="font-bold">New Sessions (live):</span>{" "}
            {report.readable?.new_connections}
          </div>

          <div>
            <span className="font-bold">Average Time Per Stream (live):</span>{" "}
            {report.readable?.average_minutes.toFixed(2)} minutes
          </div>
          <div>
            <span className="font-bold">On Demand Streams:</span>{" "}
            {report.readable?.ondemand || report.readable?.ondemand === 0
              ? report.readable?.ondemand
              : "N/A"}
          </div>
        </div>
      )}
    </div>
  );
}
