import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import StationReportDetails from "../components/StationReportDetails";
import { StationContext } from "../context/station-context";

export default function StationReports() {
  const { id } = useParams();

  const { station, setStation } = React.useContext(StationContext);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/stations/${id}`);
      if (req) {
        setStation(req.data.data);
      }
    };
    getStation();
  }, [id]);

  return (
    <Layout
      // classString="max-w-full"
      route="stationreports"
      title={station && `Station: ${station.name}`}
    >
      {station && <StationReportDetails station={station} />}
    </Layout>
  );
}
