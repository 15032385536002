import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LayoutLogin from "../components/LayoutLogin";
import { loginUser, useAuthState, useAuthDispatch } from "../context";
import { ReactComponent as MyLogo } from "../images/logo.svg";

// import { Link } from 'react-router-dom';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(3),
});

export default function Login() {
  // const [formError, setFormError] = React.useState(null);
  let history = useHistory();
  const dispatch = useAuthDispatch();
  const { errorMessage } = useAuthState();
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const loginSubmit = async (payload) => {
    try {
      let response = await loginUser(dispatch, payload);
      // if (!response.user) return;
      if (response.status === "success") {
        history.push("/home");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LayoutLogin>
      <div className="flex flex-wrap mt-6 relative">
        <div className="w-full text-gray-200 ml-2 text-center font-bold text-lg  lg:text-4xl mb-4 lg:mb-8 flex items-center justify-center gap-4">
          <MyLogo className="w-12  h-12" />
          MyRadio
        </div>
      </div>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className={"flex-auto px-4 lg:px-10 py-10 "}>
          <div className={"login"}>
            <h1 className={"text-blueGray-500 text-center mb-3 font-bold"}>
              Login
            </h1>
            <form onSubmit={handleSubmit(loginSubmit)}>
              <div className="mb-8">
                <label
                  htmlFor="email"
                  className={`block uppercase   text-xs font-bold mb-2 ml-1 ${
                    errors.email ? "text-red-400" : "text-blueGray-500"
                  }`}
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  className={`border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 `}
                  {...register("email")}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-2">
                    A valid email is required.
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="password"
                  className={`block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1 ${
                    errors.email ? "text-red-400" : "text-blueGray-500"
                  }`}
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Your Password"
                  className={`border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 `}
                  {...register("password")}
                />
                {errors.password && (
                  <p className="text-red-500 text-sm mt-2">
                    Your password is required.
                  </p>
                )}
              </div>
              {errorMessage ? (
                <p className={"text-center text-red-500 mb-8"}>
                  {errorMessage}
                </p>
              ) : null}
              <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md w-full text-center">
                LOG IN
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mt-6 relative">
        <div className="w-1/2">
          <a href="/forgot-password" className="text-blueGray-500 ml-2">
            <small>Forgot password?</small>
          </a>
        </div>
        <div className="w-1/2 text-right">
          <a href="/signup" className="text-blueGray-500 mr-2">
            <small>Create new account</small>
          </a>
        </div>
      </div>
    </LayoutLogin>
  );
}
