import React, { useEffect, useState } from "react";
import {
  CardElement,
  PaymentElement,
  useStripe,
  useElements,
  CardInputWrapper,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import LoadingIndicator from "./Global/LoadingIndicator";

export default function CreditCardForm({
  intent,
  getCards = null,
  setShowCardForm = null,
  setLoading,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const http = global.services.http;

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    console.log("here", intent);
    const clientSecret = intent.client_secret;

    if (!clientSecret) {
      console.log("This failed");
      return;
    }

    // stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
    //   switch (setupIntent.status) {
    //     case "succeeded":
    //       setMessage("Payment succeeded!");
    //       break;
    //     case "processing":
    //       setMessage("Your payment is processing.");
    //       break;
    //     case "requires_payment_method":
    //       setMessage("Your payment was not successful, please try again.");
    //       break;
    //     default:
    //       setMessage("Something went wrong.");
    //       break;
    //   }
    // });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    console.log("elements", elements);
    console.log("stripe", stripe);
    setIsLoading(true);

    const saveCard = async (paymentMethod) => {
      setLoading(true);
      var req = await http.post(
        `${process.env.REACT_APP_API}/stripe/save-card`,
        {
          paymentMethod,
        }
      );
      if (req.data) {
        console.log(req);
        if (getCards) {
          getCards();
        }
        if (setShowCardForm) {
          setShowCardForm(false);
        }
      }
    };

    const cardAdd = await stripe.confirmCardSetup(intent.client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });
    // console.log(cardAdd);
    if (cardAdd.error) {
      setLoading(false);
      console.log("There was an error", cardAdd.error);
      setMessage(cardAdd.error.message);
    } else if (cardAdd.setupIntent) {
      saveCard(cardAdd.setupIntent.payment_method);
      setMessage("Success! Your card is ready to use - loading....");
    }
    setIsLoading(false);
  };

  return (
    <form id="card-form" onSubmit={handleSubmit}>
      <div className="my-4">
        <div className="grid grid-cols-2 gap-2">
          <CardNumberElement
            className="p-2 border border-separate col-span-2"
            options={{
              style: {},
            }}
          />
          <CardExpiryElement
            className="p-2 border border-separate col-span-1"
            options={{
              style: {},
            }}
          />
          <CardCvcElement
            className="p-2 border border-separate col-span-1"
            options={{
              style: {},
            }}
          />
        </div>
      </div>
      {message && <div id="payment-message">{message}</div>}
      <div className="w-full flex justify-center">
        <button
          className="bg-green-700 px-4 py-2 text-white"
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
            {isLoading ? (
              <LoadingIndicator
                className={"text-white bg-white w-4"}
                width={4}
              />
            ) : (
              "Add Card"
            )}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
    </form>
  );
}
