import { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import AudioPlayer from "../AudioPlayer";
import MediaUpload from "../NewMediaUpload";
import { useAuthState } from "../../context";
import { useHistory } from "react-router-dom";
import { dateFormat } from "../../config/constants";
import Slider from "rc-slider";
import moment from "moment-timezone";

import "rc-slider/assets/index.css";

export default function ReplaceAudioForm({ station = null }) {
  const userDetails = useAuthState(); //read user details from context
  let history = useHistory();
  const http = global.services.http;
  const [loading, setLoading] = useState(null);
  const [date, setDate] = useState(new Date());
  const [previous, setPrevious] = useState([]);
  const [url, setUrl] = useState(null);
  const [duration, setDuration] = useState(300);
  const [file, setFile] = useState(null);
  const [dec, setDec] = useState(5);
  const SIX_MONTHS_AGO = new Date();
  const [min, setMin] = useState(
    SIX_MONTHS_AGO.setMonth(SIX_MONTHS_AGO.getMonth() - 5)
  );

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.role && userDetails.role === "admin") {
        return;
      }
      if (userDetails.userDetails.id !== station.owner_id) {
        history.push("/");
      }
    }
  }, [userDetails]);

  const getReplacers = async () => {
    var filter = JSON.stringify({ station_id: station.id });
    var req = await http.get(
      `${process.env.REACT_APP_API}/replacers?filter=${filter}&page=1&limit=30`
    );
    if (req) {
      setPrevious(req.data.data);
    }
  };

  const deleteReplacer = async (id) => {
    if (window.confirm("Are you sure?") === true) {
      var req = await http.delete(
        `${process.env.REACT_APP_API}/replacers/${id}`
      );
      getReplacers();
    }
  };

  useEffect(() => {
    const getReplacers = async () => {
      var filter = JSON.stringify({ station_id: station.id });
      var req = await http.get(
        `${process.env.REACT_APP_API}/replacers?filter=${filter}&page=1&limit=30`
      );
      if (req) {
        setPrevious(req.data.data);
      }
    };
    getReplacers();
  }, []);

  useEffect(() => {
    const minutes = duration / 60;
    setDec(minutes.toFixed(2));
  }, [duration, setDec]);

  const onChangeSlider = (value) => {
    const minutes = value / 60;
    setDuration(value);
    // setDec(minutes.toFixed(2));
    let hlsDate = moment(date).unix();
    // setUrl(
    //   `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${hlsDate}/${value}/playlist.m3u8?unique=chopper`
    // );
    setUrl(
      `${process.env.REACT_APP_API}/public/ondemand/${station.hls_stream}/96/${hlsDate}/${duration}/listen.m3u8?unique=console`
    );
  };

  const onChangeDate = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      if (isNaN(value)) {
        // d.getTime() or d.valueOf() will also work
        // date object is not valid
      } else {
        const test = moment(value);
        if (test.isValid()) {
          setDate(value);
          // let hlsDate = convertUTCToTimezone(value, null, station.timezone, "");
          let hlsDate = moment(value).unix();
          // setUrl(
          //   `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${hlsDate}/${duration}/playlist.m3u8?unique=chopper`
          // );
          setUrl(
            `${process.env.REACT_APP_API}/public/ondemand/${station.hls_stream}/96/${hlsDate}/${duration}/listen.m3u8?unique=console`
          );
        }
      }
    }
  };

  const finishedUpload = (value) => {
    console.log(value);
    setFile(value);
  };

  const replaceAudio = async () => {
    console.log(date);
    if (!file) {
      alert("Oops, upload a file first");
      return;
    }
    if (
      window.confirm("Are you sure? This action cannot be cancelled") === true
    ) {
      let timestamp = moment(date).format("X");

      let fields = {
        station_id: station.id,
        start_time: timestamp,
        duration: duration,
        media_id: file.id,
        status: "queued",
      };

      let req = await http.post(`${process.env.REACT_APP_API}/replacers`, {
        ...fields,
      });

      if (req) {
        setPrevious([req.data.data, ...previous]);
        setFile(null);
        setUrl(null);
      }
    } else {
      return;
    }
  };

  const handleChangeTime = (date, time) => {
    const [hh, mm, ss] = time.split(":");
    const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
    // setDate(targetDate);
    console.log("here");
    setDate(targetDate);
    let hlsDate = moment(targetDate).unix();
    setUrl(
      `${process.env.REACT_APP_API}/public/ondemand/${station.hls_stream}/96/${hlsDate}/${duration}/listen.m3u8?unique=console`
    );
    // setUrl(
    //   `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${hlsDate}/${duration}/playlist.m3u8?unique=chopper`
    // );
  };

  const CustomTimeInput = ({ date, onChangeCustom }) => {
    const value =
      date instanceof Date && !isNaN(date)
        ? // Getting time from Date beacuse `value` comes here without seconds
          date.toLocaleTimeString("it-IT")
        : "";

    return (
      <input
        className="bp3-input bp3-fill"
        type="time"
        step="5"
        value={value}
        onChange={(event) => onChangeCustom(date, event.target.value)}
      />
    );
  };

  return (
    <div>
      <div className="w-full bg-white pb-6 px-6 reounded-xl shadow-xl">
        <h2 className="font-bold text-2xl mb-4">Step 1 - Upload</h2>
        {file ? (
          <p className="text-sm mb-8">
            Your file has uploaded, proceed to step 2
          </p>
        ) : (
          <MediaUpload
            type="hls"
            accept={"audio/*"}
            max={314572800}
            onComplete={finishedUpload}
            title={"Upload a MP3, AAC or WAV File"}
          />
        )}
        {file && (
          <>
            <h2 className="font-bold text-2xl mb-4">
              Step 2 - Choose a Time And Duration
            </h2>
            <div className="mb-4">
              <p className="pb-4">
                Duration: {duration} Seconds, {dec && dec} Minutes
              </p>

              <Slider
                value={duration}
                min={30}
                max={10800}
                onChange={onChangeSlider}
              />
            </div>

            <ReactDatePicker
              className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700 mb-4"
              onChange={(date) => onChangeDate(date)}
              onBlur={onChangeDate}
              selected={date}
              timeIntervals={5}
              dateFormat="d, MMMM, yyyy h:mm:ss aa"
              minDate={min}
              showTimeInput
              customTimeInput={
                <CustomTimeInput onChangeCustom={handleChangeTime} />
              }
            />

            {/* <p>{url && url}</p> */}

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div>
                <h4>Currently On Server at specified time</h4>
                {url && <AudioPlayer url={url} index={0} />}
              </div>
              <div>
                <h4>Audio to replace with</h4>
                <AudioPlayer url={file.url} index={1} />
              </div>
            </div>
            {url && file && (
              <p className={"mt-4 mb-4"}>
                You have selected <span className="font-bold">{dec}</span>{" "}
                minutes starting at{" "}
                <span className="font-bold">
                  {moment(date).format("h:mm a, dddd, MMMM Do YYYY")}
                </span>
                .
              </p>
            )}
            <div>
              {url && file && (
                <button
                  className="bg-blue-500 text-white p-4 hover:bg-blue-700"
                  onClick={() => replaceAudio()}
                >
                  Replace Audio
                </button>
              )}
            </div>
          </>
        )}
      </div>

      <div className="w-full bg-white pb-6 px-6 reounded-xl shadow-xl">
        {previous && previous.length > 0 && station && (
          <>
            <div>
              <h4 className="text-sm font-bold mb-5  border-t pt-5 mt-5">
                Replace History
              </h4>
            </div>
            <div className="flex flex-col mt-6">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full">
                      <thead className="border-b">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Start Date
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Created At
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Duration
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {previous.map((item, i) => (
                          <tr className="border-b" key={i}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {moment
                                .utc(item.start_time)
                                .tz(station.timezone)
                                .format(dateFormat)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {moment
                                .utc(item.created_at)
                                .tz(station.timezone)
                                .format(dateFormat)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {Math.round(item.duration / 60)} Minutes
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item.status ? item.status : "queued"}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right flex gap-2 g-2 justify-end">
                              {item.status !== "purged" && item.file && (
                                <a href={item.file.url}>Download</a>
                              )}
                              <button
                                className="ml-autp"
                                onClick={() => {
                                  if (
                                    (item.status &&
                                      item.status === "complete") ||
                                    userDetails.role === "admin"
                                  ) {
                                    deleteReplacer(item.id);
                                  } else {
                                    alert(
                                      "Please allow the task to complete before deleting"
                                    );
                                  }
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
