import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Slider from "rc-slider";

export default function WidgetEmbed({ url }) {
  const iframe = `<script src="${url}"></script>`;

  const [copied, setCopied] = useState(false);

  return (
    <div className="mt-4 bg-white p-4 rounded-xl shadow-xl">
      <h4 className="font-bold text-2xl mb-4">Embed Widget</h4>
      <p className="text-xs">
        Copy and paste the following into any web pager builder to show the
        selected widget.
      </p>
      <div className="flex gap-2 mb-4">
        <textarea className="p-2 bg-gray-200 w-full" value={iframe} readOnly />
        <CopyToClipboard text={iframe} onCopy={() => setCopied(true)}>
          <span className="p-4 cursor-pointer">
            {copied ? "copied" : "copy"}
          </span>
        </CopyToClipboard>
      </div>
    </div>
  );
}
