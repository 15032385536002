import React, { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  XCircleIcon,
  ChatAlt2Icon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import StashPlayoutWrapper from "./StashPlayoutWrapper";
import { StationContext } from "../context/station-context";
const notSelected =
  "py-2 px-4 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white";
const selected =
  "py-2 px-4 text-sm font-medium text-gray-200 bg-lightBlue  border border-lightBlue dark:bg-gray-900 dark:border-gray-900 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white";

export default function Studio() {
  const [sure, setSure] = useState(false);
  // const [isShowingButton, setIsShowingButton] = useState(true)
  const [analyticsSent, setAnalyticsSent] = useState(false);
  const { openPlayout, setOpenPlayout, setPlayoutLayout, playoutLayout } =
    useContext(StationContext);

  useEffect(() => {
    if (analyticsSent === false) {
      setAnalyticsSent(true);
      if (typeof window !== "undefined") {
        if (window.gtag) {
          window.gtag("event", "open_popout");
        }
      }
    }
  }, [openPlayout, analyticsSent, setAnalyticsSent]);

  return (
    <>
      <div
        className={`bonusplayer fixed z-50 bg-gray-200 overflow-y-scroll w-screen h-screen transition-all  top-0 bottom-0 ${
          openPlayout === true ? "left-0" : "left-full"
        }`}
        //   show={}
        //   enter="transition ease-in-out duration-300 transform"
        //   leave="transition ease-in-out duration-300 transform"
        //   enterFrom="translate-x-full"
        //   enterTo="translate-x-0"
        //   leaveFrom="translate-x-0"
        //   leaveTo="translate-x-full"
      >
        <div className="relative w-full overflow-y-scroll p-4">
          <div className="flex items-center justify-between mb-2">
            <div className="md:flex gap-4 items-center">
              <h2 className="font-bold">MyRadio.Click Studio (beta)</h2>
              {/* <div
                className="inline-flex rounded-md shadow-sm mx-auto"
                role="group"
              >
                <button
                  type="button"
                  className={playoutLayout === "1" ? selected : notSelected}
                  onClick={() => setPlayoutLayout("1")}
                >
                  1 Per Row
                </button>
                <button
                  type="button"
                  className={playoutLayout === "2" ? selected : notSelected}
                  onClick={() => setPlayoutLayout("2")}
                >
                  2 Per Row
                </button>
              </div> */}
            </div>

            {sure === false ? (
              <button className="ml-auto p-4" onClick={() => setSure(true)}>
                <ArrowRightIcon className="w-6 h-6" />
              </button>
            ) : (
              <div className={"flex gap-2 items-center  p-4"}>
                {" "}
                Are you Sure you want exit this page?
                <button
                  className="ml-auto"
                  onClick={() => {
                    setOpenPlayout(false);
                    setSure(false);
                  }}
                >
                  Yes
                </button>
                /
                <button className="ml-auto" onClick={() => setSure(false)}>
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div>
            <p className="mb-4 text-xs">
              Welcome to our new studio page. This is an early version we are
              offering in order to gain feedback. Future builds of this system
              will have more functions (add to episode playlists at the click of
              a button, set cue points before going to air). Please use the form
              at{" "}
              <a
                href="https://myradio.click/contact"
                title="contact us"
                className="font-bold"
              >
                myradio.click/contact
              </a>{" "}
              to provide feedback.
            </p>
          </div>
          <div>
            <StashPlayoutWrapper />
          </div>
        </div>
      </div>
    </>
  );
}
