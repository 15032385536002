import React from "react";
import Layout from "../components/Layout"; 
import {  useParams  } from "react-router-dom"; 
import PostForm from "../components/forms/PostForm";
 
export default function AddPost() {
  const { id, station_id } = useParams(); 
  return (
    <Layout title="Add New Post">
      {station_id && 
       <PostForm stationId={station_id} id={id} />}
    </Layout>
  );
}
