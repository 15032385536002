import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import { StationContext } from "../context/station-context";
import Chopper from "../components/Segments/Chopper";
import NewPageHeader from "../components/Global/NewPageHeader";

export default function Segments() {
  const { id } = useParams();
  const { station, setStation } = React.useContext(StationContext);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/stations/${id}`);
      if (req) {
        setStation(req.data.data);
      }
    };
    getStation();
  }, [id]);

  return (
    <Layout route="segments" title={station && `Download: ${station.name}`}>
      <NewPageHeader title="Download Audio" links={[]} />
      {/* <SectionHeader content={<ContentHeader />} /> */}
      {station && (
        <div className="w-full bg-white p-4">
          <Chopper station={station} />
        </div>
      )}
    </Layout>
  );
}
