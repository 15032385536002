import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LoadingIndicator from "../components/LoadingIndicator";

const EmailVerification = () => {
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("requestToken");
  const [state, setState] = useState({
    loading: false,
    message: "",
  });
  const [showLinks, setShowLinks] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    const initialization = async () => {
      await setSent(true);
      await setState((prev) => ({ ...prev, loading: true }));
      try {
        if (!token) return history.replace("/dashboard");
        if (sent === true) return;
        const response = await fetch(
          `${process.env.REACT_APP_API}/auth/verify-email-verification`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            body: JSON.stringify({ token }), // body data type must match "Content-Type" header
          }
        );
        let result = await response.json();
        console.log(result);
        if (result.success === false) {
          // setState((prev) => ({
          //   ...prev,
          //   loading: false,
          //   message: result.message,
          // }));
          // setShowLinks(true);
        } else {
          if (result.status === "success") {
            setState((prev) => ({
              ...prev,
              loading: false,
              message: "Email verified, redirecting you now....",
            }));

            setTimeout(() => {
              return (window.location.href = "https://console.myradio.click");
            }, 3000);
          }
        }
      } catch (error) {
        // console.log("something went wrong", error);
        // let errorMsg =
        //   error?.data.message ||
        //   "An unknown error occured. Please refresh the page and try again.";
        // setState((prev) => ({
        //   ...prev,
        //   loading: false,
        //   message: errorMsg,
        // }));
        // setShowLinks(true);
      } finally {
        // setState((prev) => ({
        //   ...prev,
        //   loading: false,
        //   message: "Redirecting to dashboard",
        // }));
        // setTimeout(() => {
        //   return history.replace("/dashboard");
        // }, 3000);
      }
    };
    if (sent === false) {
      setSent(true);
      initialization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sent]);

  return (
    <div className="h-screen bg-blue-900 text-white text-2xl flex items-center justify-center flex-col space-y-3">
      {state.loading === true ? (
        <LoadingIndicator className="text-white w-6 h-6" />
      ) : null}
      {token && state.loading ? (
        <p>Verifying your email</p>
      ) : (
        <div className="text-center">
          <p>{state.message}</p>
          {showLinks && (
            <p className="mt-4">
              <Link to={"/login"} className="font-bold">
                Login
              </Link>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
