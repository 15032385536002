import React, { useEffect } from "react";
import EpisodeChart from "./EpisodeChart";
import SectionHeader from "../SectionHeader";
import EpisodeReportTiny from "../EpisodeReportTiny";
import { CSVLink } from "react-csv";
import { DownloadIcon } from "@heroicons/react/outline";
import moment from "moment";

export default function EpisodeReportComponent({ report, episode }) {
  const [csvData, setCsvData] = React.useState(null);

  useEffect(() => {
    if (!report.readable?.report) return;
    let csv = report.readable?.report?.map((item) => [
      item.time,
      item.start,
      item.end,
      item.total,
    ]);
    csv.unshift([
      "Time",
      "New Connections",
      "Lost Connections",
      "Total Sessions",
    ]);

    csv[csv.length] = [
      `Total Listeners: ${report.readable?.total_listeners}`,
      `Total Minutes Streamed (live): ${report.readable?.total_minutes.toFixed(
        2
      )}`,
      `New Sessions (live): ${report.readable?.new_connections}`,
      `Average Time Per Stream (live): ${report.readable?.average_minutes.toFixed(
        2
      )}`,
      `On Demand Streams: ${
        report.readable?.ondemand || report.readable?.ondemand === 0
          ? report.readable?.ondemand
          : "N/A"
      }`,
      `Report Taken at ${moment().format("dddd, MMMM Do YYYY, h:mm:ss a")} `,
    ];
    setCsvData(csv);
  }, []);

  return (
    <div className="w-full">
      <div className="mb-4">
        <h2 className="font-bold mb-4 text-xl">Episode Reports</h2>
        <EpisodeReportTiny report={report} />
      </div>
      {report.readable.report ? (
        <div>
          <h2 className="font-bold mb-4 ">Listener Activity Graph</h2>
          <EpisodeChart report={report.readable.report} />
        </div>
      ) : (
        ""
      )}
      {csvData && (
        <CSVLink
          data={csvData}
          filename={`ListenerReport ${episode.program.name} - ${episode.show_start}.csv`}
        >
          <p className="text-center py-4 mt-8 font-bold">
            Download report <DownloadIcon className={"inline h-4 w-4"} />
          </p>
        </CSVLink>
      )}
    </div>
  );
}
