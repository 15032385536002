import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LayoutLogin from "../components/LayoutLogin";
import { ReactComponent as MyLogo } from "../images/logo.svg";

// import { Link } from 'react-router-dom';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  password_confirmation: yup.string().required(),
});

export default function ResetPassword() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [token, setToken] = useState(null);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const http = global.services.http;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const initialization = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);

        const foundToken = urlSearchParams.get("requestToken");

        if (!foundToken) {
          setErrorMessage("Invalid reset link used.");
          return;
        }
        setToken(urlSearchParams.get("requestToken"));

        const req = await http.post(
          `${process.env.REACT_APP_API}/auth/verify-password-token`,
          {
            requestToken: urlSearchParams.get("requestToken"),
          }
        );
        if (req) {
          if (req.data.verified === true) {
            setVerified(true);
          } else {
            setErrorMessage("Invalid Token.");
          }
        }

        // setState(prevState => ({ ...prevState, verifying: false }))
      } catch (error) {
        let errorMsg =
          error?.data.error ||
          "An unknown error occured. Please refresh the page and try again.";
        // setState((prevState) => ({
        //   ...prevState,
        //   error: errorMsg,
        //   verifying: false,
        // }));
      }
    };

    initialization();
  }, []);

  const passwordSubmit = async (payload) => {
    if (payload.password !== payload.password_confirmation) {
      setErrorMessage("Oops! Your password fields do not match");
      return;
    }

    const params = { ...payload, token: token };
    try {
      var req = await http.post(
        `${process.env.REACT_APP_API}/auth/reset-password`,
        params
      );
      console.log("req", req);
      if (req) {
        if (req.data.success === true) {
          setErrorMessage(req.data.message);
          reset();
          setVerified(false);
        }
        if (req.data.success === false) {
          if (req.data.errors && req.data.errors.email) {
            setErrorMessage(req.data.email[0]);
          } else if (req.data.errors && req.data.errors.password) {
            setErrorMessage(req.data.errors.password[0]);
          } else if (req.data.errors && req.data.errors.password_confirmation) {
            setErrorMessage(req.data.errors.password_confirmation[0]);
          } else {
            setErrorMessage(req.data.message);
          }
        }
      }
    } catch (error) {
      setErrorMessage(
        "An error occured, please check your details and try again"
      );
      console.log(error);
    }
  };

  return (
    <LayoutLogin>
      <div className="flex flex-wrap mt-6 relative">
        <div className="w-full text-gray-200 ml-2 text-center font-bold text-lg  lg:text-4xl mb-4 lg:mb-8 flex items-center justify-center gap-4">
          <MyLogo className="w-12  h-12" />
          MyRadio
        </div>
      </div>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className={"flex-auto px-4 lg:px-10 py-10 "}>
          <div className={"login"}>
            {verified === true && (
              <form onSubmit={handleSubmit(passwordSubmit)}>
                {" "}
                <div className="mb-8">
                  <label
                    htmlFor="email"
                    className={`block uppercase  text-xs font-bold mb-2 ml-1 ${
                      errors.email ? "text-red-400" : "text-blueGray-500"
                    }`}
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    className={`border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 `}
                    {...register("email")}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-2">
                      A valid email is required.
                    </p>
                  )}
                </div>
                <div className="mb-8">
                  <label
                    htmlFor="password"
                    className={`block uppercase  text-xs font-bold mb-2 ml-1 ${
                      errors.password ? "text-red-400" : "text-blueGray-500"
                    }`}
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Your Password"
                    className={`border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 `}
                    {...register("password")}
                  />
                  {errors.password && (
                    <p className="text-red-500 text-sm mt-2">
                      Your password is required.
                    </p>
                  )}
                </div>
                <div className="mb-8">
                  <label
                    htmlFor="password_confirmation"
                    className={`block uppercase  text-xs font-bold mb-2 ml-1 ${
                      errors.password_confirmation
                        ? "text-red-400"
                        : "text-blueGray-500"
                    }`}
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    placeholder="Confirm Your Password"
                    className={`border-blueGray-300 px-3 py-2 text-sm  w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200 `}
                    {...register("password_confirmation")}
                  />
                  {errors.password_confirmation && (
                    <p className="text-red-500 text-sm mt-2">
                      Your password is required.
                    </p>
                  )}
                </div>
                {errorMessage ? (
                  <p className={"text-center text-red-500 mb-8"}>
                    {errorMessage}
                  </p>
                ) : null}
                <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md w-full text-center">
                  RESET PASSWORD
                </button>
              </form>
            )}
            {errorMessage && verified === false ? (
              <p className={"text-center text-red-500 mb-8"}>{errorMessage}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mt-6 relative">
        <div className="w-full text-center">
          <a href="/login" className="text-blueGray-500 ml-2">
            <small>Already have an account? Login Here</small>
          </a>
        </div>
      </div>
    </LayoutLogin>
  );
}
