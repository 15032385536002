import { useAuthState } from "../../context";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ProgramAutoComplete from "../ProgramAutoComplete";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { XCircleIcon } from "@heroicons/react/outline";
import { set } from "date-fns";

const validFileExtensions = {
  image: ["aac", "mp3", "mp4", "mpeg", "mpga", "m4a", "wav", "webm"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

const schema = yup
  .object()
  .shape({
    file: yup
      .mixed()
      .required("Required")
      .test("is-valid-type", "Not a valid audio type", (value) =>
        isValidFileType(value && value.name.toLowerCase(), "image")
      )
      .test(
        "is-valid-size",
        "Max allowed size is 25mb",
        (value) => value && value.size <= 50000000
      ),
    title: yup.string().required("Required"),
    start_date: yup
      .date()
      .typeError("Expected a value of type ${type} but got: ${value}")
      .required("Required"),
    end_date: yup
      .date()
      .typeError("Expected a value of type ${type} but got: ${value}")
      .required("Required"),
  })
  .required();

export default function UploadRoll({
  station = null,
  onAdded = console.log,
  roll = null,
}) {
  const schema = roll
    ? yup
        .object()
        .shape({
          file: yup.mixed().nullable(),
          // .test(
          //   "is-valid-size",
          //   "Max allowed size is 25mb",
          //   (value) => value && value.size <= 50000000
          // ),
          title: yup.string().required("Required"),
          start_date: yup
            .date()
            .typeError("Expected a value of type ${type} but got: ${value}")
            .required("Required"),
          end_date: yup
            .date()
            .typeError("Expected a value of type ${type} but got: ${value}")
            .required("Required"),
        })
        .required()
    : yup
        .object()
        .shape({
          file: yup
            .mixed()
            .required("Required")
            .test("is-valid-type", "Not a valid audio type", (value) =>
              isValidFileType(value && value.name.toLowerCase(), "image")
            )
            .test(
              "is-valid-size",
              "Max allowed size is 25mb",
              (value) => value && value.size <= 50000000
            ),
          title: yup.string().required("Required"),
          start_date: yup
            .date()
            .typeError("Expected a value of type ${type} but got: ${value}")
            .required("Required"),
          end_date: yup
            .date()
            .typeError("Expected a value of type ${type} but got: ${value}")
            .required("Required"),
        })
        .required();

  // const userDetails = useAuthState(); //read user details from context
  // let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState(
    roll && roll.programs ? roll.programs : []
  );
  const [active, setActive] = useState(
    roll && roll.active ? roll.active : true
  );
  const http = global.services.http;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: roll && roll.title ? roll.title : "",
      start_date: roll && roll.start_date ? new Date(roll.start_date) : null,
      end_date: roll && roll.end_date ? new Date(roll.end_date) : null,
      programs: roll && roll.programs ? roll.programs : [],
      // file: null,
      active: roll && roll.active ? roll.active : true,
    },
  });

  const local = new Date();
  const months = new Date(moment(local).add(1, "months"));

  const file = watch("file");

  const submitForm = async (data) => {
    let req;
    if (loading === true) return;
    setLoading(true);

    data.programs = programs;

    // for (let key in data) {
    //   formData.append(key, data[key]);
    // }

    console.log("Data", data);
    const formData = new FormData();
    formData.append(`file`, data.file);
    formData.append(`station_id`, station.id);
    formData.append(`title`, data.title);
    formData.append(`active`, active ? 1 : 0);
    if (data.start_date)
      formData.append(`start_date`, moment(data.start_date).format());
    if (data.end_date)
      formData.append(`end_date`, moment(data.end_date).format());

    const programIds = programs.map((p) => p.id);

    formData.append(`programs`, JSON.stringify(programIds));
    // formData.append("title", title);

    try {
      if (roll !== null) {
        data.start_date = moment(data.start_date).format();
        data.end_date = moment(data.end_date).format();
        data.active = active ? 1 : 0;
        req = await http.post(
          `${process.env.REACT_APP_API}/rolls/${roll.id}?_method=PUT`,
          formData
        );
      } else {
        req = await http.post(`${process.env.REACT_APP_API}/rolls`, formData);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      // setError("An error occurred. Please try again.");
      return;
    }

    console.log("data", req.data);

    setLoading(false);

    if (onAdded) {
      console.log("onAdded", req.data);
      onAdded(req.data);
    }
    // if (req.status === 422) {
    //   if (req.data.errors) {
    //     if (req.data.errors.title) {
    //       // console.log(req.data.errors.title[0]);
    //       setError("title", {
    //         type: "manual",
    //         message: req.data.errors.title[0],
    //       });
    //       setErrorMessage(req.data.errors.title[0]);
    //     }
    //     if (req.data.errors.artist) {
    //       setError("artist", {
    //         type: "manual",
    //         message: req.data.errors.artist[0],
    //       });
    //       setErrorMessage(req.data.errors.artist[0]);
    //     }
    //     if (req.data.errors.location) {
    //       setError("location", {
    //         type: "manual",
    //         message: "Please select a location",
    //       });
    //       setErrorMessage(req.data.errors.location[0]);
    //     }
    //   }
    //   setLoading(false);
    // } else {
    //   setErrorMessage(null);
    //   // alert("we good");
    //   // history.push(`/cart/${req.data.data.id}`);
    //   if (req.data.data) {
    //     if (log !== null) {
    //       const updatedLogs = await logs.map((item) =>
    //         item.id === log.id ? req.data.data : item
    //       );
    //       await setLogs(updatedLogs);
    //     } else {
    //       const newLogs = [...logs, req.data.data];
    //       setLogs(newLogs);
    //       reset({
    //         artist: "",
    //         title: "",
    //         album: "",
    //         label: "",
    //         release_date: null,
    //         air_time: new Date(),
    //       });
    //       sendGaEvent("log_added", req.data.data);
    //     }
    //   }
    //   setLoading(false);
    //   if (onComplete !== null) {
    //     onComplete(req.data.data);
    //   }
    // }
  };

  const addProgram = (program) => {
    const checkProgramDoesNotExist = programs.filter(
      (p) => p.id === program.id
    );
    if (checkProgramDoesNotExist.length === 0)
      setPrograms([program, ...programs]);
  };

  const removeProgram = (e, program_id) => {
    e.preventDefault();

    const checkProgramDoesNotExist = programs.filter(
      (p) => p.id !== program_id
    );
    setPrograms(checkProgramDoesNotExist);
  };

  return (
    <div>
      <div className="w-full  ">
        <form
          onSubmit={handleSubmit(submitForm)}
          className="flex flex-col gap-4 "
        >
          <div>
            <h2 className="font-extrabold text-lg">Upload Audio</h2>
            {roll && <p className="text-xs text-gray-700">(Optional)</p>}
            <div className="mb-8 mt-2">
              <label
                htmlFor="about"
                className={`block font-bold text-sm mb-4 ${
                  errors && errors.file
                    ? "text-red-400"
                    : "dark:text-gray-300 text-gray-900"
                }`}
              >
                Select a file <br />
                <span className="text-xs text-gray-500 dark:text-gray-100">
                  ({validFileExtensions.image.join(", ")})<br />
                  Max 25mb
                </span>
              </label>
              <Controller
                name="file"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, name, ref } }) => (
                  <input
                    name={name}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.files[0])} // Update the form state with the selected file
                    ref={ref}
                    type="file"
                  />
                )}
              />
              {file && <p>File name: {file.name}</p>}
              {errors.file?.message && (
                <p className="text-red-500 text-sm mt-2">
                  {errors.file?.message?.message || errors.file?.message}
                </p>
              )}
            </div>
          </div>
          <div className="mb-8">
            <label
              htmlFor="title"
              className={`block font-bold text-sm mb-2 ${
                errors.title ? "text-red-400" : "text-gray-800"
              }`}
            >
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              defaultValue={roll && roll.title}
              placeholder="Title your audio"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                errors.title ? "text-red-300  " : " "
              }`}
              {...register("title")}
            />
            {errors.title && (
              <p className="text-red-500 text-sm mt-2">
                {errors.title.message}
              </p>
            )}
          </div>
          <div>
            <label className="flex gap-4 items-center">
              <input
                name="active"
                type="checkbox"
                // ref={register}
                onChange={(e) => {
                  setActive(e.target.checked);
                }}
                checked={active}
              />
              <span className="">Pre-Roll is Active</span>
            </label>
          </div>
          <div>
            <ProgramAutoComplete onSet={addProgram} station={station} />
            {programs && programs.length > 0 ? (
              <div className="flex gap-4 flex-wrap pt-4">
                {programs.map((program) => (
                  <button
                    className="bg-pink-500 text-white rounded-full px-4 py-2 text-sm flex items-center gap-2 hover:bg-pink-600 transition-all"
                    key={program.id}
                    onClick={(e) => removeProgram(e, program.id)}
                  >
                    {program.name}
                    <XCircleIcon className="h-4 w-4" />
                  </button>
                ))}
              </div>
            ) : (
              <p>No programs added</p>
            )}
          </div>
          <div className="flex gap-2">
            <div className="w-1/2">
              <div className="w-full flex flex-col gap-2">
                <div className="flex gap-2 items-center justify-start">
                  <label className=" text-sm font-bold">Start Date:</label>
                </div>
                <Controller
                  control={control}
                  name="start_date"
                  className="input"
                  defaultValue={local}
                  placeholderText="Select Start date"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      // dateFormat="Pp"
                    />
                  )}
                />
                {errors.start_date && (
                  <p className="text-red-500 text-sm mt-2">
                    {errors.start_date.message}
                  </p>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className=" w-full flex flex-col gap-2">
                <div className="flex gap-2 items-center justify-start">
                  <label className=" text-sm font-bold">End Date:</label>
                </div>
                <Controller
                  control={control}
                  name="end_date"
                  className="input"
                  defaultValue={months}
                  placeholderText="Select Start date"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                {errors.end_date && (
                  <p className="text-red-500 text-sm mt-2">
                    {errors.end_date.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          <button
            className={`flex items-center justify-center w-full bg-blue-800 transition-colors text-white rounded shadow py-2 px-5 text-sm ${
              loading === true ? `opacity-70` : `hover:bg-blue-600`
            }`}
          >
            {loading === true ? "Loading...." : "Upload Audio"}
          </button>
        </form>
      </div>
    </div>
  );
}
