import React, { useState } from "react";
import ReactPlayer from "react-player";
import moment from "moment";
import ProgressBar from "./PlayerContainer/ProgressBar";
import {
  PlayIcon,
  PauseIcon,
  FastForwardIcon,
  RewindIcon,
} from "@heroicons/react/outline";

// import "./AudioPlayer.scss";

function AudioPlayer({
  url,
  index,
  episode = null,
  bgcolor = "rgba(0,0,0,0.2)",
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [streamProgress, setStreamProgress] = useState(0);
  const [streamPercent, setStreamPercent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [length, setLength] = useState(0);
  const [player, setPlayer] = useState(null);
  const [time, setTime] = useState(0);

  const playAudio = (e) => {
    setIsPlaying(!isPlaying);
  };

  const updateProgress = (progress) => {
    // console.log("Progress", progress);
    var per = (progress.playedSeconds / length) * 100;
    // console.log("per", per);
    setStreamPercent(Math.floor(per));
    // setDuration(Math.floor(progress.loadedSeconds));
    setStreamProgress(Math.floor(Math.floor(progress.playedSeconds)));
  };

  const updateTime = (event) => {
    var bcr = event.target.getBoundingClientRect();
    // if (this.state.track === null) {
    // return;
    // }
    // if (this.state.track !== null && this.state.track.isLive !== true) {
    let seekValue = event.clientX - event.target.offsetLeft;
    console.log("S", seekValue);
    // let percent = seekValue / event.target.offsetWidth;
    let percent = (event.clientX - bcr.left) / bcr.width;
    console.log("S", percent);
    console.log(percent);
    // this.state.player.seekTo(percent);
    // }
    player.seekTo(percent);
  };

  const updateDuration = (newDuration) => {
    setLength(Math.floor(newDuration));
    setDuration(
      moment("2015-01-01")
        .startOf("day")
        .seconds(Math.floor(newDuration))
        .format("H:mm:ss")
    );
  };

  const updateSeek = (time) => {
    var diff = streamProgress + time;
    player.seekTo(diff > 0 ? diff : 0, "seconds");
  };

  let ref = (player) => {
    setPlayer(player);
  };

  // const seek = (e) => {
  //   var test =
  //     ((e.clientX - e.currentTarget.getBoundingClientRect().left) /
  //       e.currentTarget.offsetWidth) *
  //     100;
  //   player.seekTo(test / 100, "fraction");
  // };

  // const updateProgress = (progress) => {
  //   // console.log(progress);
  //   var per = (progress.playedSeconds / this.state.duration) * 100;
  //   const tmpTime =
  //     progress.playedSeconds && this.state.time !== 0
  //       ? moment("2015-01-01")
  //           .startOf("day")
  //           .seconds(Math.floor(progress.playedSeconds))
  //           .format("H:mm:ss")
  //       : 0;
  //   return setStreamProgress(Math.floor(per));
  //   // setStreamPercent(Math.floor(per));
  //   // setStreamProgress(Math.floor(Math.floor(progress.playedSeconds)));
  // };

  const newSeek = (percent) => {
    console.log("seeking to", percent, percent / 100);
    // const newSpot = (1 / percent) * duration;
    // console.log(newSpot);
    player.seekTo(percent / 100, "fraction");
  };
  return (
    <>
      <div className={"w-full flex flex-wrap items-center p-4"}>
        <div
          className={
            "flex-0 flex w-full lg:w-1/6 justify-center lg:justify-start mb-4 md:md-0"
          }
        >
          <button
            aria-label="Rewind Audio 15 seconds"
            icon={"rewind"}
            onClick={(e) => updateSeek(-15)}
          >
            <RewindIcon className={"w-8 h-8"} />
          </button>
          <button
            aria-label="Play and Pause Audio"
            mx={3}
            icon={isPlaying === true ? "pause" : "play"}
            onClick={(e) => playAudio(e)}
          >
            {isPlaying ? (
              <PauseIcon className={"w-8 h-8"} />
            ) : (
              <PlayIcon className={"w-8 h-8"} />
            )}
          </button>
          <button
            icon="fastforward"
            aria-label="Fast Forward Audio 30 Seconds"
            onClick={(e) => updateSeek(30)}
          >
            <FastForwardIcon className={"w-8 h-8"} />
          </button>
        </div>
        <div className={"lg:w-5/6 flex flex-1 lg:pl-4"}>
          <ReactPlayer
            ref={ref}
            progressInterval={50}
            onDuration={(progress) => updateDuration(progress)}
            playing={isPlaying}
            controls={false}
            height={0}
            width={0}
            onProgress={(progress) => updateProgress(progress)}
            url={url}
            config={{
              file: {
                forceAudio: true,
              },
            }}
          />
          <div className={"w-full"}>
            <div w={"100%"} mb={2}>
              <div
                className={"progress-wrapper"}
                onClick={(e) => updateTime(e)}
              >
                <ProgressBar
                  bgcolor={bgcolor}
                  seek={newSeek}
                  index={index}
                  completed={streamPercent}
                  episode={episode}
                />
              </div>
            </div>
            <div className={"flex flex-wrap lg:flex-nowrap justify-between "}>
              <div>
                {moment("2015-01-01")
                  .startOf("day")
                  .seconds(Math.floor(streamProgress))
                  .format("H:mm:ss")}
              </div>
              <div className={"text-right"}>{duration}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AudioPlayer;
