import React, { useState, useEffect } from "react";
import axios from "axios";
import { DownloadIcon } from "@heroicons/react/outline";
import LoadingIndicator from "./Global/LoadingIndicator";

function DownloadAudio({ url = null, stationCallsign = "" }) {
  const [processing, setProcessing] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  // const [type, setType] = useState("aac");
  const type = "aac";

  useEffect(() => {
    // if (station === null) {
    setDownloadUrl(null);
    // }
  }, [url]);

  const generateDownloadFile = () => {
    if (processing === true) {
      return true;
    }
    setProcessing(true);
    var newUrl = url.replace("+", "plusplus");
    axios
      .get(
        `https://hls-sticher-2.nicklarosa.net/?url=${newUrl}&station=${stationCallsign}&type=${type}`
      )
      .then((res) => {
        const result = res.data;
        setProcessing(false);
        setDownloadUrl(result.url);
      });
  };

  // const setTypeAndDownload = async (newType) => {
  //   await generateDownloadFile(newType);
  // };

  return (
    url &&
    stationCallsign && (
      <div className="flex gap-4 items-center py-4">
        {processing === true ? (
          <>
            <LoadingIndicator showWrapper={false} />
          </>
        ) : null}
        {/* {processing === false && downloadUrl === null ? (
          <select
            className="form-select appearance-none
          block 
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding bg-no-repeat
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value={"aac"}>aac</option>
            <option value={"mp3"}>mp3</option>
            <option value={"wav"}>wav</option>
          </select>
        ) : null} */}
        <button
          className={`hover:underline text-lightBlue-600 p-2 text-uppercase text-sm flex gap-2 items-center ${
            processing === true && "opacity-60"
          }`}
          onClick={() => generateDownloadFile()}
        >
          <DownloadIcon className={"w-4 h-4"} />
          Queue Download
        </button>
        {downloadUrl && (
          <div color="text-green-500">
            <a
              href={downloadUrl}
              className="border-solid border-green-500 p-2 rounded border-2 text-sm flex gap-4 text-green-500 items-center"
            >
              <DownloadIcon className={"w-4 h-4"} />{" "}
              <span>Click here to download your audio</span>
            </a>
          </div>
        )}
      </div>
    )
  );
}

export default DownloadAudio;
