import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthState } from "../context";
import {
  EyeIcon,
  PencilAltIcon,
  CheckIcon,
  XCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { loadingColumns, loadingData } from "../config/constants";
import { StationContext } from "../context/station-context";
import Table from "../components/Table";
import ReactPaginate from "react-paginate";

// import { Link } from 'react-router-dom';

export default function Widgets() {
  const { no, station_id } = useParams();
  const { station, setStation } = React.useContext(StationContext);
  let history = useHistory();
  const userDetails = useAuthState(); //read user details from context
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const [widgets, setWidgets] = useState([]);
  const [postPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
        if (userDetails.role !== "admin") {
          if (req.data.data.owner_id !== userDetails.userDetails.id) {
            history.push("/");
          }
        }
      }
    };

    if (!station && userDetails) {
      getStation();
    } else {
      // setThisStation()
    }
  }, [station, userDetails]);

  useEffect(() => {
    const http = global.services.http;
    const getWidget = async () => {
      setLoading(true);
      var filter = JSON.stringify({ station_id: station_id });
      var req = await http.get(
        `${process.env.REACT_APP_API}/widgets?filter=${filter}&page=${pageNo}&limit=${postPerPage}`
      );
      if (req.data) {
        setWidgets(req.data.data);
        setPageCount(req.data.meta.last_page + 1);
        setLoading(false);
        console.log("Test", userDetails);
      }
    };
    if (thisStation) {
      getWidget();
    }
  }, [pageNo, thisStation]);

  const handlePageClick = (event) => {
    console.log(event);
    setPageNo(event.selected + 1);
    history.push(`/station/${station_id}/widgets/${event.selected + 1}`);
  };

  const EditCell = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-end"}>
        <Link
          className={
            "border-gray-600 border text-gray-600 rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
          }
          to={`/station/${station_id}/widget/${row.original.id}`}
        >
          <EyeIcon className="w-3 h-3" />
          View
        </Link>
        {(userDetails.role === "admin" ||
          (station && userDetails.userDetails.id === station.owner_id)) && (
          <Link
            className={
              "border-lightBlue-600 border text-lightBlue-600 rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
            }
            to={`/station/${station_id}/widget/${row.original.id}/edit`}
          >
            <PencilAltIcon className="w-3 h-3" />
            Edit
          </Link>
        )}
      </div>
    );
  };

  const ShowName = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-start"}>
        {row.original.image && row.original.image.url && (
          <img src={row.original.image.url} className={"w-4 mr-2"} />
        )}
        <Link
          className={"font-bold text-brandPrimary "}
          to={`/station/${station_id}/widget/${row.original.id}`}
        >
          {row.original.name}
        </Link>
      </div>
    );
  };

  const ShowDisabled = ({ row }) => {
    return row.original.disabled && row.original.disabled === 1 ? (
      <XCircleIcon className="w-5 h-5" />
    ) : (
      <CheckIcon className="w-5 h-5" />
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Widget Name",
        accessor: "name",
        Cell: ShowName,
      },
      {
        Header: "Widget Type?",
        accessor: "type",
      },
      {
        Header: "Enabled?",
        accessor: "disabled",
        Cell: ShowDisabled,
      },
      {
        Header: "Action",
        accessor: "is_active",
        className: "text-right",
        Cell: EditCell,
      },
    ],
    []
  );

  const TableHeader = () => {
    return (
      <div
        className={
          "text-blueGray-700 text-sm font-bold flex justify-between items-center ml-auto"
        }
      >
        <span>{`Widgets ${thisStation ? `for ${thisStation.name}` : ""}`}</span>
        {station &&
          userDetails &&
          (userDetails.role === "admin" ||
            userDetails.userDetails.id === station.owner_id) && (
            <Link
              to={`/station/${station_id}/widget/new`}
              className=" bg-lightBlue-600 text-white rounded shadow py-2 px-4 text-uppercase text-sm rounded"
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              <span>Add New Widget</span>
            </Link>
          )}
      </div>
    );
  };

  return (
    <Layout
      route="widgets"
      title={`Widget ${thisStation ? `for ${thisStation.name}` : ""}`}
    >
      <div className="block w-full overflow-x-auto">
        <>
          {widgets && station && userDetails && userDetails.role && (
            <Table
              columns={loading ? loadingColumns : columns}
              data={loading ? loadingData : widgets}
              content={<TableHeader />}
            />
          )}
          <div className="mx-auto py-4 flex items-center justify-around">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount - 1}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName={"paginator"}
              forcePage={pageNo - 1}
            />
          </div>
        </>
      </div>
    </Layout>
  );
}
