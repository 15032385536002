import React from "react";
// import axios from "axios";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../../context";
import { loadingColumns, loadingData } from "../../config/constants";
import Table from "../Table";
import ReactPaginate from "react-paginate";
import { XCircleIcon } from "@heroicons/react/outline";

// import { Link } from 'react-router-dom';

export default function UserList({
  users,
  pageNo,
  setPageNo,
  pageCount,
  setPageCount,
  station,
  no,
  handlePageClick,
  loading,
  setLoading,
  deleteUser,
  reload,
}) {
  let history = useHistory();
  const userDetails = useAuthState(); //read user details from context

  const EditCell = ({ value, column, row }) => {
    return (
      <>
        {(userDetails.role === "admin" || userDetails.role === "librarian") && (
          <button
            className={
              "border-red-600 border text-red-600 hover:bg-red-600 hover:text-white rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
            }
            onClick={() => deleteUser(row.original.id)}
            to={`/user/${row.original.id}/edit`}
          >
            <XCircleIcon className="h-4 w-4" /> Remove User
          </button>
        )}
      </>
    );
  };

  const ProfileCell = ({ value, column, row }) => {
    return <>{row.original.profile.public_name}</>;
  };

  const RoleCell = ({ value, column, row }) => {
    return row.original.role ? row.original.role.role : false;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Public Name",
        accessor: "created_at",
        Cell: ProfileCell,
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: RoleCell,
      },
      {
        Header: "Action",
        Cell: EditCell,
        accessor: "updated_at",
      },
    ],
    []
  );

  return (
    <div>
      <div className="block w-full overflow-x-auto bg-white ">
        <>
          {users && (
            <Table
              columns={loading ? loadingColumns : columns}
              data={loading ? loadingData : users}
            />
          )}
          <div className="bg-white px-0 py-3 flex items-center justify-between border-t border-gray-200 ">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount - 1}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName={"paginator"}
              forcePage={pageNo - 1}
            />
          </div>
        </>
      </div>
    </div>
  );
}
