import React, { useState, useContext } from "react";
import { Transition } from "@headlessui/react";
import { menus } from "../config/constants";
import { useHistory, Link } from "react-router-dom";
import { useAuthDispatch, logout, useAuthState } from "../context";
import { ReactComponent as MyLogo } from "../images/logo-dark.svg";
import { StationContext } from "../context/station-context";
import {
  PlayIcon,
  LogoutIcon,
  LoginIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";

import {
  ViewListIcon,
  EyeIcon,
  ClockIcon,
  PencilAltIcon,
  ArchiveIcon,
  UserAddIcon,
  SaveAsIcon,
  SaveIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/react/outline";
import AiModal from "./AiModal";

function NewHeader({ route }) {
  const [isOpen, setIsOpen] = useState(false);
  let history = useHistory();
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const userDetails = useAuthState();
  const { station, setStation, setSideBar, user, setOpenPlayout } =
    useContext(StationContext);

  const handleLogout = () => {
    logout(dispatch); //call the logout action
    history.push("/login"); //navigate to logout page on logout
  };
  return (
    <div className={"  z-30"}>
      <nav className="bg-white">
        <div className="w-fullmx-auto px-4 sm:px-4  ">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center w-full">
              <Link to={"/"} className="flex-shrink-0 flex gap-3 items-center">
                <MyLogo className="w-6 h-6 text-brandPrimary" />
                MyRadio.Click
              </Link>
              <AiModal />
              <div className="hidden lg:flex space-between w-full">
                <div className="ml-auto flex items-center space-x-4">
                  <Link className="flex items-center " to={"/profile"}>
                    {userDetails?.userDetails?.profile?.image ? (
                      <div className="profile-button">
                        <img
                          src={userDetails?.userDetails?.profile?.image?.url}
                          alt="two-column-layout-component-brand-logo"
                          className="hidden md:block"
                        />
                      </div>
                    ) : (
                      <div className="profile-button">
                        <QuestionMarkCircleIcon className="w-12 h-12 text-brandPrimary" />
                      </div>
                    )}
                    <div className="pl-4 hidden lg:flex">
                      {userDetails && (
                        <div className="profile-name">
                          <span className="profile-name-text text-brandPrimary text-right">
                            {userDetails?.userDetails?.profile?.public_name
                              ? userDetails?.userDetails?.profile?.public_name
                              : userDetails?.userDetails?.first_name &&
                                userDetails?.userDetails?.first_name}
                          </span>
                        </div>
                      )}
                    </div>
                  </Link>
                  {userDetails ? (
                    <button
                      className="  text-brandPrimary px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleLogout()}
                    >
                      <LogoutIcon className="h-4 w-4" />
                    </button>
                  ) : (
                    <Link
                      className=" hover:bg-blue-900 text-brandPrimary px-3 py-2 rounded-md text-sm font-medium"
                      to={"/login"}
                    >
                      <LoginIcon className="h-4 w-5" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex gap-4 lg:hidden">
              <Link className="flex items-center " to={"/profile"}>
                {userDetails?.userDetails?.profile?.image ? (
                  <div className="profile-button">
                    <img
                      src={userDetails?.userDetails?.profile?.image?.url}
                      alt="two-column-layout-component-brand-logo"
                    />
                  </div>
                ) : (
                  <div className="profile-button">
                    <QuestionMarkCircleIcon className="w-12 h-12 text-brandPrimary" />
                  </div>
                )}
              </Link>
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className=" inline-flex items-center justify-center p-2 rounded-md text-brandPrimary hover:bg-gray-100   "
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="lg:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {menus.map((item, i) => {
                  return (
                    item.roles.includes(userDetails.role) && (
                      <Link
                        className="whitespace-nowrap hover:bg-blue-900 text-brandPrimary block px-3 py-2 rounded-md text-base font-medium"
                        key={`${i}menu`}
                        to={item.link}
                      >
                        {item.name}
                      </Link>
                    )
                  );
                })}
                {/* <Link
                  className="whitespace-nowrap hover:bg-blue-900 text-brandPrimary block px-3 py-2 rounded-md text-base font-medium"
                  to={"/stash"}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <span
                    className={"ml-4 flex items-center gap-4 whitespace-nowrap"}
                  >
                    <ViewListIcon className="w-5 h-5 text-brandPrimary" />
                    My Tracks
                  </span>
                </Link>{" "}
                <button
                  className="whitespace-nowrap hover:bg-blue-900 text-brandPrimary block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => {
                    setIsOpen(false);
                    setOpenPlayout(true);
                  }}
                >
                  <span
                    className={"ml-4 flex items-center gap-4 whitespace-nowrap"}
                  >
                    <PlayIcon className="w-5 h-5 text-brandPrimary" />
                    Studio
                  </span>
                </button> */}
                <button
                  className="whitespace-nowrap flex items-start text-left gap-2 hover:bg-blue-900 text-brandPrimary  px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => handleLogout()}
                >
                  <span>LOG OUT</span>
                  <LogoutIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default NewHeader;
