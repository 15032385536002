import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { StationContext } from "../context/station-context";
import SectionHeader from "../components/SectionHeader";
import Modal from "react-modal";
import { Pad, returnWeekday } from "../services/pad";
import AddSlot from "../components/Grid/AddSlot";
import EpisodeItem from "../components/EpisodeItem";
import ProgramEmbed from "../components/Embeds/ProgramEmbed";
import { useAuthState } from "../context";
import {
  PlusIcon,
  PencilAltIcon,
  ViewListIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import NewPageHeader from "../components/Global/NewPageHeader";

export default function Program() {
  const { id, station_id } = useParams();
  const http = global.services.http;
  const userDetails = useAuthState();
  const [program, setProgram] = useState(null);
  const { station, setStation, stationOwner } =
    React.useContext(StationContext);
  const [editSlot, setEditSlot] = useState(null);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [station]);

  useEffect(() => {
    const http = global.services.http;
    const getProgram = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/programs/${id}`);
      if (req) {
        setProgram(req.data.data);
      }
    };
    getProgram();
  }, [id]);

  const getProgram = async () => {
    var req = await http.get(`${process.env.REACT_APP_API}/programs/${id}`);
    if (req) {
      setProgram(req.data.data);
    }
  };

  const deleteSlot = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?") === true) {
      var req = await http.delete(`${process.env.REACT_APP_API}/slots/${id}`);
      if (req) {
        console.log("req");
        await getProgram();
      }
    }
  };

  const finishUpdate = async () => {
    setEditSlot(null);
    getProgram();
    console.log("Slot updated");
  };

  const EditModal = () => (
    <Modal
      isOpen={editSlot !== null}
      // onAfterOpen={afterOpenModal}
      onRequestClose={finishUpdate}
      // style={customStyles}
      contentLabel="Edit Program Time"
    >
      <AddSlot slot={editSlot} onComplete={finishUpdate} />
    </Modal>
  );

  const LinkBlock = ({}) => (
    <div className="   flex gap-1 justify-end items-center">
      {(stationOwner === true || userDetails.role === "admin") && (
        <Link
          className={" default-button "}
          to={`/station/${station_id}/program/${id}/edit`}
        >
          {/* <PencilAltIcon className={"w-5 h-5"} /> */}
          Edit program
        </Link>
      )}
      <Link to={`/station/${station_id}/programs`} className=" default-button ">
        {/* <ViewListIcon className="w-4 h-4 mr-2" /> */}
        <span>All programs</span>
      </Link>
      {(stationOwner === true || userDetails.role === "admin") && (
        <Link
          to={`/station/${station_id}/program/new`}
          className=" default-button "
        >
          <span>New program</span>
        </Link>
      )}
    </div>
  );

  return (
    <Layout route="programs">
      {/* <EditModal /> */}
      <NewPageHeader
        title={!program ? <LoadingIndicator /> : `Program: ${program.name}`}
        links={[]}
        linkBlock={<LinkBlock />}
      />
      {program ? (
        <>
          <div className={"  bg-white rounded-xl shadow-lg mb-8"}>
            <div className="w-full p-4 lg:pb-4  md:flex gap-8">
              <div className="md:w-1/4">
                {program && program.image ? (
                  <div className={"  w-full mb-4 lg:mb-0"}>
                    <img
                      alt={"for our program"}
                      className={"max-w-full ml-auto mr-auto "}
                      src={program.image.url}
                    />
                  </div>
                ) : (
                  <p className={"text-center"}>
                    No Image Provided for this program
                  </p>
                )}
              </div>
              <div className="flex-grow">
                {program && program.introduction && (
                  <p className={"mb-2"}>
                    <span className={"font-bold text-sm"}>INTRODUCTION:</span>{" "}
                    {program.introduction}
                  </p>
                )}
                {program && program.grid_one_liner && (
                  <p className={"mb-2"}>
                    <span className={"font-bold text-sm"}>
                      TIMETABLE DESCRIPTION:
                    </span>{" "}
                    {program.grid_one_liner}
                  </p>
                )}
                {program && program.genre_string && (
                  <p className={"mb-2"}>
                    <span className={"font-bold text-sm"}>GENRE:</span>{" "}
                    {program.genre_string}
                  </p>
                )}
                {program && program.producer && (
                  <p className={"mb-2"}>
                    <span className={"font-bold text-sm"}>PRODUCER:</span>{" "}
                    {program.producer}
                  </p>
                )}
                {program && program.language && (
                  <p className={"mb-2"}>
                    <span className={"font-bold text-sm"}>LANGUAGE:</span>{" "}
                    {program.language}
                  </p>
                )}
                {program && program.description && (
                  <div className={"mb-4 "}>
                    <span className={"font-bold text-sm"}>DESCRIPTION:</span>{" "}
                    <br />
                    <p
                      itemProp="description"
                      className="mb-2 p-4 border-dashed border-2 border-gray-300 rounded-xl"
                      dangerouslySetInnerHTML={{
                        __html: program.description,
                      }}
                    ></p>
                  </div>
                )}
                {program && program.presenter_string && (
                  <p className={"mb-4"}>
                    <span className={"font-bold text-sm"}>PRESENTERS:</span>
                    <br />
                    {program.presenter_string}
                  </p>
                )}
                {program && program.genre_string && (
                  <p className={"mb-4"}>
                    <span className={"font-bold text-sm"}>GENRE:</span>
                    <br />
                    {program.genre_string}
                  </p>
                )}
                <p className={"mb-4"}>
                  <span className={"font-bold text-sm"}>SLUG:</span>{" "}
                  {program && program.slug}
                  <span className={"font-bold text-sm"}>
                    <br /> ID:
                  </span>{" "}
                  {program && program.id}
                </p>{" "}
              </div>
            </div>
          </div>
          {((station &&
            userDetails &&
            userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") && (
            <div className={"mb-4  bg-white rounded-xl shadow-lg"}>
              <SectionHeader
                content={
                  program && (
                    <div className={"font-bold text-left text-xl"}>
                      {program.name} Time Slots
                    </div>
                  )
                }
              />
              {/* {stationOwner === true && program && (
                <AddSlot
                  onComplete={finishUpdate}
                  station_id={station_id}
                  program={program}
                />
              )} */}
              <div className={"p-4"}>
                <table className={"min-w-full divide-y divide-gray-200"}>
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                      >
                        Start
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                      >
                        End
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                      >
                        Duration
                      </th>
                      {stationOwner === true && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {program &&
                      program.slots.map((slot, j) => (
                        <tr key={`-${j}`}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {slot.hour_start > 12
                              ? Pad(slot.hour_start - 12)
                              : Pad(slot.hour_start)}
                            :{Pad(slot.minute_start)}
                            {slot.hour_start < 12 ? "am" : "pm"}{" "}
                            {returnWeekday(slot.weekday_start)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {slot.hour_end > 12
                              ? Pad(slot.hour_end) - 12
                              : Pad(slot.hour_end)}
                            :{Pad(slot.minute_end)}
                            {slot.hour_end < 12 ? "am" : "pm"}{" "}
                            {returnWeekday(slot.weekday_end)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {slot.duration / 60 / 60}h
                          </td>
                          {stationOwner === true && (
                            <td className="px-6 py-4 whitespace-nowrap flex gap-2 ">
                              <button
                                className={
                                  "rounded-full w-10 h-10 flex align-center items-center justify-center bg-lightBlue-600 hover:bg-lightBlue-600 text-white"
                                }
                                onClick={() => setEditSlot(slot)}
                              >
                                <PencilAltIcon className="h-5 w-5  " />
                              </button>
                              <button
                                className={
                                  "rounded-full w-10 h-10 flex align-center items-center justify-center bg-red-400 hover:bg-red-500 text-white"
                                }
                                onClick={() => deleteSlot(slot.id)}
                              >
                                <TrashIcon className="h-5 w-5  " />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className={"mb-4  bg-white rounded-xl shadow-lg"}>
                <SectionHeader
                  content={
                    program && (
                      <div className={"font-bold text-left text-xl"}>
                        Recent Episodes
                      </div>
                    )
                  }
                />
                <div className={"p-4"}>
                  {program && program.episodes && thisStation ? (
                    <>
                      {program.episodes.map((episode, i) => (
                        <EpisodeItem
                          key={`a${i}`}
                          station={thisStation}
                          index={i}
                          episode={{ ...episode, program: program }}
                          showPlayer={false}
                          onComplete={getProgram}
                        />
                      ))}
                    </>
                  ) : (
                    "Nothing found"
                  )}
                </div>
              </div>
            </div>
          )}
          {station && program && (
            <ProgramEmbed station={station} program={program} />
          )}
        </>
      ) : (
        <div className="flex justify-center px-4 py-8 bg-white w-full rounded-md">
          <div className="flex flex-col justify-center items-center">
            <LoadingIndicator className="mx-auto" width={12} />
            <br />
            <p>Loading Program Details..</p>
          </div>
        </div>
      )}
    </Layout>
  );
}
