import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import { StationContext } from "../context/station-context";
import ReplaceAudioForm from "../components/forms/ReplaceAudioForm";

export default function Replacer() {
  const { id } = useParams();
  const { station, setStation } = React.useContext(StationContext);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/stations/${id}`);
      if (req) {
        setStation(req.data.data);
      }
    };
    getStation();
  }, [id]);

  const ContentHeader = () => {
    return (
      <h2 className="font-bold ">
        Upload an audio file to replace your recording history
      </h2>
    );
  };

  return (
    <Layout
      route="replacer"
      title={station && `Replace Audio: ${station.name}`}
    >
      <SectionHeader content={<ContentHeader />} />
      {station && (
        <div>
          <ReplaceAudioForm station={station} />
        </div>
      )}
    </Layout>
  );
}
