import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { StationContext } from "../context/station-context";
import RegionChart from "../components/Charts/RegionChart";
import DeviceChart from "../components/Charts/DeviceChart";
import StationChart from "../components/Charts/StationChart";
import GeoChart from "../components/Charts/GeoChart";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import moment from "moment";
import { CSVLink } from "react-csv";
import { DownloadIcon } from "@heroicons/react/outline";
import PageTitle from "../components/PageTitle";
function compareNums(a, b) {
  return a.total - b.total;
}

export default function EpisodeReport() {
  const { id, station_id } = useParams();
  const [episode, setEpisode] = useState(null);
  const { station, setStation } = React.useContext(StationContext);

  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );

  const d = new Date();
  d.setHours(0, 0, 0, 0);
  const [loading, setLoading] = useState(false);
  // const [dates, setDates] = useState({
  //   start: moment(d).unix(),
  //   end: moment(d).add(1, "days").unix(),
  // });
  const [reports, setReports] = useState(null);
  const [liveReports, setLiveReports] = useState(null);
  const [reportsOndemand, setReportsOndemand] = useState(null);
  const [type, setType] = useState("today");
  const [length, setLength] = useState("minute");
  const [averageTotal, setAverageTotal] = useState(null);
  const [results, setResults] = useState([]);
  const [resultCities, setResultCities] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [unique, setUnique] = useState(null);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
  }, [station]);

  useEffect(() => {
    const http = global.services.http;
    setLoading(true);
    const getEpisode = async () => {
      var req1 = await http.get(`${process.env.REACT_APP_API}/episodes/${id}`);
      if (req1) {
        setEpisode(req1.data.data);
      }
      const dates = {
        start: moment.unix(req1.data.data.timestamp),
        end: moment.unix(req1.data.data.timestamp + req1.data.data.duration),
      };
      console.log("Dates", dates);
      var reqOndemand = await http.get(
        `${process.env.REACT_APP_API}/stats/station/${station_id}?episode_id=${id}`
      );
      if (reqOndemand) {
        console.log("setting reports", reqOndemand.data);
        setReportsOndemand(reqOndemand.data.data);
      }
      var req = await http.get(
        `${process.env.REACT_APP_API}/stats/station/${station_id}?start=${
          req1.data.data.timestamp
        }&end=${req1.data.data.timestamp + req1.data.data.duration}`
      );
      if (req) {
        var res = req.data.data;
        setResults(req.data.data);
        let format = "ddd, MMM Do h:mm:ss a";

        let un = res.map(
          (item) =>
            `${item.agent}:${item.ip}:${item.iserId ? item.userId : "na"}`
        );
        let unIds = await un.filter(
          (value, index, self) => self.indexOf(value) === index
        );
        setUnique(unIds);
        console.log("here");
        let hours = [];
        let s, e, obj;
        let beginning;
        let end;
        beginning = dates.start;
        end = dates.end;
        let ts = dates.start.unix();
        let te = dates.end.unix();
        let tmpEnd;
        let diff = 60 * 5;
        while (ts < te) {
          tmpEnd = ts + diff;
          let report = req.data.data.filter(
            (item) => item.timestamp >= ts && item.timestamp < tmpEnd
          );
          let reportMobile = req.data.data.filter(
            (item) =>
              item.timestamp >= ts &&
              item.timestamp < tmpEnd &&
              item.isMobile === true
          );

          un = report.map(
            (item) =>
              `${item.agent}:${item.ip}:${item.iserId ? item.userId : "na"}`
          );
          unIds = await un.filter(
            (value, index, self) => self.indexOf(value) === index
          );

          let thisDuration = await report.map((item) => item.duration);
          var sum = 0;
          for (var number of thisDuration) {
            sum += number;
          }
          let averageD = sum / thisDuration.length;

          obj = {
            start: moment.unix(ts).format(format),
            end: moment.unix(tmpEnd).format(format),
            ts: ts,
            te: tmpEnd,
            items: report,
            mobile: reportMobile,
            count: report.length,
            unique: unIds.length,
            mobiles: reportMobile.length,
            average_duration: averageD ? averageD : "00:00:00",
          };
          console.log(obj);
          hours.unshift(obj);
          ts = ts + diff;
        }
        hours = hours.reverse();

        setReports(hours);

        let csv = hours.map((item) => [
          item.start,
          item.end,
          item.unique,
          item.count,
          item.mobiles,
          item.average_duration && item.average_duration > 0
            ? moment.utc(item.average_duration * 1000).format("HH:mm:ss")
            : 0,
        ]);
        csv.unshift([
          "Start",
          "End",
          "Unique Listeners",
          "Listens",
          "Mobile Listens",
          "Average Duration",
        ]);
        setCsvData(csv);
        let cities = await req.data.data.filter(
          (item) => item.geo?.city && item.geo?.city
        );
        // console.log("her ccccce", cities);
        let uniqueCities = await cities.map((item) =>
          item.geo.city.toUpperCase()
        );
        let uniqueCitieArrays = await uniqueCities.filter(
          (value, index, self) => self.indexOf(value) === index
        );

        let finalCities = [];
        for (let index = 0; index < uniqueCitieArrays.length; index++) {
          let thisResults = await req.data.data.filter(
            (item) =>
              item.geo?.city?.toUpperCase() ===
              uniqueCitieArrays[index].toUpperCase()
          );
          await finalCities.push({
            city: uniqueCitieArrays[index],
            data: thisResults,
            total: thisResults.length,
          });
        }
        await finalCities.sort(compareNums);
        const reversed = finalCities.reverse();
        setResultCities(reversed);
        let duration = await req.data.data.map((item) => item.duration);
        var sum = 0;
        for (var number of duration) {
          sum += number;
        }
        let average = sum / duration.length;
        setAverageTotal(average);
      }
      // if (req) {
      //   console.log("setting reports", req.data);
      //   setLiveReports(req.data.data);
      // }
      setLoading(false);
    };
    getEpisode();
  }, [id]);

  return (
    <Layout title={"Episode"}>
      <div className="bg-white p-4 rounded-xl shadow-xl">
        {/* <div className="flex gap-9 justify-center mb-4"> 
          <div className="flex items-center justify-center gap-0 mb-4">
            <button
              className={`${
                length === "minute"
                  ? "bg-pink-500 text-white"
                  : "bg-gray-200 text-black hover:bg-red-300 hover:text-white"
              } px-4 py-2 uppercase`}
              onClick={() => setLength("minute")}
            >
              Minutes
            </button>
            
            <button
              className={`${
                length === "hour"
                  ? "bg-pink-500 text-white"
                  : "bg-gray-200 text-black hover:bg-red-300 hover:text-white"
              } px-4 py-2 uppercase`}
              onClick={() => setLength("hour")}
            >
              Hours
            </button>
            
          </div>
        </div> */}
        {loading === true ? (
          <div className="flex items-center justify-center p-8">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="mx-auto xl:max-w-7xl">
            <div className="w-full">
              <h1 className="text-2xl lg:text-4xl font-bold mb-4">
                Episode Statistics and Reports (beta)
              </h1>
              {episode && (
                <h2 className="text-lg font-bold mb-4">
                  {episode.readable} - {episode.program?.name}
                </h2>
              )}
            </div>
            <div className="col-span-12 w-full justify-center">
              {reports && (
                <div className="w-full ">
                  <div className="flex col-span-12 text-xl lg:col-span-12 justify-start mb-4 font-bold">
                    Live Stream
                  </div>
                  <p className="mb-2 text-xs">
                    This chart will display new listeners who connected during
                    this episode. It is important to note, those who connected
                    prior to the show starting are not reflected here (take a
                    look at the program that aired before you, the average
                    listen time and chart should give youa rough idea).
                  </p>
                  {/* <p className="mb-2 text-xs">
                    One further point, access to stats and report are available
                    free until the 15th of August 2022. From there, station
                    managers will need to speak to Nick about upgrading their
                    subscription for access moving forward.
                  </p>
                  <p className="mb-4 text-xs">
                    Thanks, <span className="font-bold">Nick L.</span>
                  </p> */}
                  <div className=" col-span-12 lg:col-span-12  px-4 text-center mb-2">
                    <p className="mb-2">
                      <span className="font-bold">Total Listens:</span>{" "}
                      {results.length}{" "}
                      <span className="font-bold ml-4">
                        Average Listen Time:
                      </span>{" "}
                      {averageTotal && averageTotal > 0
                        ? moment.utc(averageTotal * 1000).format("HH:mm:ss")
                        : "00:00:00"}
                      <span className="font-bold ml-4">
                        Unique Listeners Detected:
                      </span>{" "}
                      {unique && unique.length}
                    </p>
                    {csvData && (
                      <p>
                        <CSVLink data={csvData}>
                          Download report{" "}
                          <DownloadIcon className={"inline h-4 w-4"} />
                        </CSVLink>
                      </p>
                    )}
                  </div>
                  <div className={"w-full mb-4"}>
                    <StationChart length={length} report={reports} />
                  </div>
                </div>
              )}
            </div>
            <div className="block">
              <p className=" font-bold text-center">
                Radio On-Demand:{" "}
                {reports && (
                  <span className=" font-normal">
                    {reportsOndemand.length} on-demand plays
                  </span>
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
