import React, { useEffect, useState } from "react";
import { useAuthState } from "../context";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";

import PlaceholderImage from "../images/example-screnshot.png";
import { theme } from "tailwindcss/stubs/defaultConfig.stub";
import { Line } from "react-chartjs-2";
import LoadingIndicator from "./Global/LoadingIndicator";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

export default function StationCachedReport({ station = null }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const http = global.services.http;
  const userDetails = useAuthState(); //read user details from context

  const options = {
    responsive: true,
    responsive: true,
    plugins: {
      // legend: {
      //   position: 'top' as const,
      // },
      title: {
        display: false,
        text: "Station Listeners",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  useEffect(() => {
    const loadReport = async () => {
      setLoading(true);
      var req = await http.get(
        `${process.env.REACT_APP_API}/station/${station.id}/cachedReport`
      );
      if (req.data) {
        const labels = req.data.data.map((item) => item.readable);
        const data = req.data.data.map((item) => item.count);

        setData({
          labels: labels,
          datasets: [
            {
              data: data,
              label: "Online Listeners",
              borderWidth: 4,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
          ],
        });
        setLoading(false);
      }
    };
    if (station.has_live_count === 1) {
      loadReport();
    } else {
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="w-full flex justify-center">
          <div className="text-center">
            <p className="mb-4">Loading Listener Report</p>
            <LoadingIndicator />
          </div>
        </div>
      )}
      {data && <Line options={options} data={data} />}
      {station.has_live_count === 0 && (
        <div className="min-h-48 text-center relative">
          <img src={PlaceholderImage} className="w-full h-auto" />
          <div className="absolute left-0 right-0 bottom-0 top-0 flex items-center justify-center bg-white bg-opacity-70 z-2 text-sm md:text-base">
            <p className="px-4">
              Station listener reports are not available for this station.
              <br />
              Managers can speak with us to organise this.
            </p>
          </div>
        </div>
      )}
    </>
  );
}
