import React, { useState } from "react";
import { useAuthState } from "../context";
import { Link } from "react-router-dom";
import PlaceholderImage from "../images/placeholder.png";
import moment from "moment";
import LoadingIndicator from "./Global/LoadingIndicator";
import StationOndemandEmbed from "./Embeds/StationOndemandEmbed";
import NewPageHeader from "./Global/NewPageHeader";
import { PencilIcon } from "@heroicons/react/outline";

export default function StationDetails({ station = null, loading = false }) {
  const [loadingNetlify, setLoadingNetlify] = useState(false);
  const userDetails = useAuthState(); //read user details from context
  const isOwner =
    (userDetails &&
      station &&
      userDetails.userDetails.id === station.owner_id) ||
    userDetails.role === "admin";
  const isAdmin = userDetails.role === "admin";

  const deployNetlify = async () => {
    const http = global.services.http;
    setLoadingNetlify(true);
    var req = await http.post(
      `${process.env.REACT_APP_API}/station/netlify/${station.id}`
    );
    setLoadingNetlify(false);
    if (req) {
      alert(req.data.message);
    }
  };

  const Header = () => {
    return (
      <>
        <div
          className={
            "text-blueGray-700 text-xl font-bold flex justify-between items-center"
          }
        >
          <span>{station.name}</span>
          {((station &&
            userDetails &&
            userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              to={`/station/${station.id}/edit`}
              className=" bg-brandDark text-white rounded shadow py-2 px-4 text-uppercase text-sm"
            >
              <PencilIcon className="w-4 h-4 mr-2" />
              <span>Edit {station.name}</span>
            </Link>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      <NewPageHeader
        title={station ? `Station: ${station.name}` : <LoadingIndicator />}
        links={
          ((station &&
            userDetails &&
            userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") && [
            {
              name: "Edit Station",
              link: station && `/station/${station.id}/edit`,
            },
          ]
        }
      />

      <div className={"mb-4  bg-white rounded shadow-lg "}>
        {/* {station && <SectionHeader content={<Header />} />} */}

        {station && (
          <div className="w-full items-start flex flex-wrap gap-4 md:p-4 lg:p-4 ">
            {station && station.image ? (
              <img
                alt={"for our station"}
                src={station.image.url}
                style={{
                  width: "300px",
                  height: "auto",
                }}
                className="p-4 mb-4"
              />
            ) : (
              <div className="">
                <img
                  alt={"for our station"}
                  src={PlaceholderImage}
                  style={{
                    width: "300px",
                    height: "auto",
                  }}
                  className="p-4 mb-4"
                />{" "}
                <p className="text-center text-xs px-4 text-gray-600">
                  No logo yet.{" "}
                  <Link
                    to={`/station/${station.id}/edit`}
                    className={"font-bold text-black"}
                  >
                    Edit this station to upload a logo
                  </Link>
                  .
                </p>
              </div>
            )}

            <div className="px-4 station-details">
              <p className="mb-4 text-sm">
                Last updated: {moment(station.updated_at).format("DD/MM/YYYY")}
              </p>
              <p className="mb-4 text-sm">Timezone: {station.timezone}</p>
              {userDetails && userDetails.role === "admin" && (
                <p>PID: {station && station.pid}</p>
              )}
              {userDetails && userDetails.role === "admin" && station.owner && (
                <p>
                  Owner: {station.owner.email} {station.owner.first_name}{" "}
                  {station.owner.last_name}
                </p>
              )}

              {((station &&
                userDetails &&
                userDetails.userDetails.id === station.owner_id) ||
                userDetails.role === "admin") &&
                station.netlify_publish && (
                  <button
                    className="p-2 bg-blue-500 text-white mt-4 flex gap-2"
                    onClick={() => deployNetlify()}
                  >
                    Trigger Netlify Deploy{" "}
                    {loadingNetlify === true && (
                      <LoadingIndicator width={"5"} />
                    )}
                  </button>
                )}
            </div>
          </div>
        )}
        {station && (
          <div className="pb-4">
            <p className="mb-2 p-4 font-bold">Active Programs:</p>

            <div className="max-h-96 overflow-y-auto p-4 rounded-lg border-2 border-gray-200 mx-4">
              {station && station.programs && station.programs.length > 0 ? (
                station.programs.map((program) => (
                  <div key={program.id}>
                    <Link
                      to={`/station/${station.id}/program/${program.id}`}
                      className="hover:bg-gray-200 p-2 block w-full"
                    >
                      {program.name}
                    </Link>
                    {/* <td className="text-xs p-2">{program.presenter_string}</td> */}
                    {/* <td className="text-xs p-2">
                      {moment(program.updated_at).format("DD/MM/YYYY")}
                    </td> */}
                  </div>
                ))
              ) : (
                <p>
                  No programs added yet. <br />
                  <Link
                    className={"font-bold"}
                    to={`/station/${station && station.id}/program/new`}
                  >
                    Click here to start adding your first program
                  </Link>
                </p>
              )}
            </div>
          </div>
        )}

        {station && station.hls_stream && (
          <StationOndemandEmbed station={station} />
        )}
      </div>
      {station && isAdmin && (
        <div className="mt-4 p-4 bg-white rounded-lg shadow-md">
          <h2 className="font-bold mb-4">Owner</h2>
          <Link
            to={`/users/${station && station.owner_id}`}
            className="mb-4 block"
          >
            {station && station.owner.first_name}{" "}
            {station && station.owner.last_name}{" "}
            {station && station.owner.email}
          </Link>
          <h2 className="font-bold mb-4">Users</h2>
          {station.users && station.users.length === 0 && (
            <p>No Users added to station as yet.</p>
          )}
          {station.users &&
            station.users.map((user, i) => (
              <Link
                key={`user-${i}`}
                to={`/users/${user.id}`}
                className="block p-2 hover:bg-gray-200"
              >
                {user.email} {user.first_name} {user.last_name}
              </Link>
            ))}
        </div>
      )}
    </>
  );
}
