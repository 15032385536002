import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Link, useHistory, useParams } from "react-router-dom";
import { StationContext } from "../context/station-context";
import InviteList from "../components/Users/InviteList";
import InviteForm from "../components/forms/InviteForm";
import * as yup from "yup";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import { useAuthState } from "../context";

export default function StationInvites() {
  let history = useHistory();
  const userDetails = useAuthState();
  const { station_id, no } = useParams();
  const { station, setStation } = React.useContext(StationContext);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  // let history = useHistory();
  const http = global.services.http;
  const [loading, setLoading] = useState(null);
  const [invites, setInvites] = useState([]);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      setLoading(true);
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };
    const getInvites = async () => {
      var filter = {
        station_id: station_id,
      };
      var req = await http.get(
        `${process.env.REACT_APP_API}/invites?filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}&page=${pageNo}`
      );
      if (req.data) {
        setInvites(req.data.data);
        setLoading(false);
      }
    };

    if (!station) {
      getStation();
    } else {
      getInvites();
    }
    if (userDetails && station) {
      if (userDetails.role === "admin") {
      } else if (userDetails.userDetails.id !== station.owner_id) {
        history.push("/");
      }
    }
  }, [station_id, station, userDetails]);

  const getInvites = async () => {
    var filter = {
      station_id: station_id,
    };
    setLoading(true);
    var req = await http.get(
      `${process.env.REACT_APP_API}/invites?filter=${encodeURIComponent(
        JSON.stringify(filter)
      )}&page=${pageNo}`
    );
    if (req.data) {
      setInvites(req.data.data);
      setLoading(false);
    }
  };

  const handlePageClick = (event) => {
    console.log(event);
    setPageNo(event.selected + 1);
    history.push(`/station/${station.id}/invites/${event.selected + 1}`);
  };

  return (
    <Layout route="users" title="Station Invites">
      {loading ? (
        <div className="p-2 w-full flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        station && (
          <>
            <InviteForm
              stationId={station && station.id}
              onComplete={() => getInvites()}
            />
            <InviteList
              reload={getInvites}
              station={station}
              invites={invites}
              pageNo={pageNo}
              setPageNo={setPageNo}
              pageCount={pageCount}
              setPageCount={setPageCount}
              no={no}
              handlePageClick={handlePageClick}
              loading={loading}
              setLoading={setLoading}
            />
          </>
        )
      )}
      {station && (
        <div className="p-4 bg-white ">
          <Link to={`/station/${station.id}/users`} className={"font-bold"}>
            Return to Presenters
          </Link>
        </div>
      )}
    </Layout>
  );
}
