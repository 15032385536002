import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm, Controller } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import LoadingIndicator from "../Global/LoadingIndicator";

export default function InviteForm({
  id = null,
  stationId = null,
  onComplete = null,
}) {
  let history = useHistory();
  const { station } = React.useContext(StationContext);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const http = global.services.http;
  const [emails, setEmails] = React.useState([]);
  const [text, setText] = React.useState("");

  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const submitForm = async (e) => {
    e.preventDefault();

    let enteredEmails = text;
    let emailsArray = enteredEmails.split(",");
    let array3 = emailsArray.concat(emails);
    setEmails(array3);
    setText("");
  };

  const removeEmail = (email) => {
    let tmp = emails.filter((item) => item !== email);
    setEmails(tmp);
  };

  const submitEmails = async () => {
    if (loading === true) return;
    if (emails.length === 0) return;
    let fields = { emails: emails, station_id: station.id };
    // handle submitting the form
    setLoading(true);
    try {
      var req = await http.post(`${process.env.REACT_APP_API}/invites`, fields);
      if (!req) return;
      setLoading(false);
      setEmails([]);
      if (onComplete !== null) {
        onComplete();
      }
      console.log(req);
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(watch("email"));
  const Header = () => <p className={"text-lg font-bold"}>Create Invites</p>;

  return (
    <div className={"max-w-full  mr-auto  bg-white shadow-xl rounded-xl"}>
      <SectionHeader content={<Header />} />
      {loading && (
        <div className="p-2 bg-white py-4 w-full flex justify-center">
          <LoadingIndicator />
        </div>
      )}
      <div className="p-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        <form onSubmit={(e) => submitForm(e)}>
          <div className="mb-8">
            <label
              htmlFor="title"
              className={`block font-bold text-sm mb-2 ${
                errors.emails ? "text-red-400" : "text-gray-800"
              }`}
            >
              Email Addresses
            </label>
            <input
              type="text"
              name="emails"
              value={text}
              onChange={(e) => {
                console.log("event", e);
                let tmp = e.target.value.replace(" ", "");
                setText(tmp);
              }}
              id="emails"
              placeholder="Email Addresses - seperate with a comma"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                errors.emails ? "text-red-300  " : " "
              }`}
            />
            {errors.emails && (
              <p className="text-red-500 text-sm mt-2">
                oops! please use valid email addresses
              </p>
            )}
          </div>
        </form>
        <div className="flex items-center justify-start w-full">
          {emails.length > 0 ? (
            emails.map((email, i) => (
              <div key={i} className="m-3">
                <div className="bg-white text-gray-800 font-bold rounded border-b-2 border-green-500 hover:border-green-600 hover:bg-green-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                  <span className="mr-2">{email}</span>
                  <button onClick={() => removeEmail(email)}>X</button>
                </div>
              </div>
            ))
          ) : (
            <p>Add an email above to invite a presenter to your station</p>
          )}
        </div>
        <div className="w-full mb-8">
          {emails.length > 0 && (
            <button
              onClick={() => submitEmails()}
              className="inline-block bg-brandDark text-white rounded shadow py-2 px-4 text-uppercase text-sm rounded"
            >
              Create {emails.length} Invite{emails.length > 1 && "s"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
