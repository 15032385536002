import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Slider from "rc-slider";

export default function ProgramEmbed({ program, station = null }) {
  const [url, setUrl] = useState(
    `https://programpage.myradio.click?station=${station.id}&program=${program.id}`
  );
  const [showTitle, setShowTitle] = useState(true);
  const [height, setHeight] = useState(500);
  const [showHeight, setShowHeight] = useState(true);
  const [showIframe, setShowIframe] = useState(false);
  const [copied, setCopied] = useState(false);
  const [iframe, setIframe] = useState(
    `<iframe src="${url}" style="height:${height}px"></iframe>`
  );

  useEffect(() => {
    setCopied(false);

    setIframe(
      `<iframe src="${url}" ${
        showHeight === true ? `style="height:${height}px"` : ``
      }></iframe>`
    );
  }, [setIframe, url, height, showHeight]);

  useEffect(() => {
    setCopied(false);
    setUrl(
      `https://programpage.myradio.click?station=${station.id}&program=${
        program.id
      }${showTitle ? "" : "&name=false"}`
    );
  }, [showTitle, setCopied]);

  const onChangeSlider = (value) => {
    setHeight(value);
  };

  return (
    <div className="mt-4 bg-white p-4 rounded-xl shadow-xl">
      <h4 className="font-bold text-2xl mb-4">Embed Program</h4>
      <p className="text-xs">
        Copy and paste the following into any web pager builder to show the
        iframe you see below.
      </p>
      <div className="flex gap-2 mb-4">
        <textarea className="p-2 bg-gray-200 w-full" value={iframe} readOnly />
        <CopyToClipboard text={iframe} onCopy={() => setCopied(true)}>
          <span className="p-4 cursor-pointer">
            {copied ? "copied" : "copy"}
          </span>
        </CopyToClipboard>
      </div>

      <div className="form-check mb-4 ">
        <label
          className="form-check-label inline-block text-gray-800"
          htmlFor="flexCheckDefault"
        >
          <input
            className="mr-2 cursor-pointer"
            type="checkbox"
            defaultChecked={showTitle}
            id="flexCheckDefault"
            onChange={() => setShowTitle(!showTitle)}
          />
          Show Program Title
        </label>
      </div>

      <div className="form-check mb-4">
        <label
          className="form-check-label inline-block text-gray-800"
          htmlFor="flexCheckDefault2"
        >
          <input
            className="mr-2 cursor-pointer"
            type="checkbox"
            defaultChecked={showHeight}
            id="flexCheckDefault2"
            onChange={() => setShowHeight(!showHeight)}
          />
          Enable Height Toggle: (disable to control the height elsewhere)
        </label>
      </div>
      {showHeight && (
        <>
          <div className="mb-4 w-full">
            <p className="mb-4">Height: {height}px</p>
            <Slider
              value={height}
              min={100}
              max={10800}
              onChange={onChangeSlider}
            />
          </div>
        </>
      )}
      <div className="my-4">
        <button
          className="p-2 bg-gray-200 rounded"
          onClick={() => setShowIframe(!showIframe)}
        >
          {showIframe ? "Hide example iframe" : "Display example iframe"}
        </button>
      </div>
      {showIframe && (
        <>
          <h6 className="mb-4 text-lg mb-4 font-bold">Example Iframe: </h6>
          <p className="font-normal text-sm mb-4">
            Border is added to show the iframe container, this will not appear
            when iframe is used
          </p>
          <div className="border border-gray-600 p-4">
            <iframe
              style={{ height: `${height}px` }}
              src={url}
              className={"w-full"}
            />
          </div>
        </>
      )}
    </div>
  );
}
