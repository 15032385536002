import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "../context";
import StationCachedReport from "./StationCachedReport";
import EpisodeDropDown from "./EpisodeDropDown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StationRow({ station, changeStation }) {
  const now = moment().unix();
  const userDetails = useAuthState(); //read user details from context

  const isAdmin =
    (userDetails &&
      userDetails.userDetails !== "" &&
      userDetails.userDetails.id === station.owner_id) ||
    userDetails.role === "admin";

  const onAir =
    station.episodes &&
    station.episodes.filter(
      (episode) => episode.timestamp <= now && episode.timestampEnd >= now
    );

  return (
    <div className="w-full mb-8">
      <div className="station-row flex flex-col xl:flex-row lg:gap-8 gap-4 p-4 mb-2 border border-separate bg-white rounded-xl">
        <div className={`flex-grow`}>
          <div>
            <div className="block lg:flex gap-2 justify-between items-center">
              <div className="flex gap-2 justify-start item-center flex-1">
                {isAdmin ? (
                  <Link
                    to={`/station/${station.id}`}
                    className="text-4xl lg:text-4xl font-bold hover:text-blueGray-500 block w-full mb-2 lg:mb-0 text-center lg:text-left"
                    onClick={() => changeStation(station)}
                  >
                    {station.name}
                  </Link>
                ) : (
                  <spane className="text-4xl lg:text-4xl font-bold   block w-full mb-2 lg:mb-0 text-center lg:text-left">
                    {station.name}
                  </spane>
                )}
              </div>
              <div className="flex flex-wrap  gap-5 justify-center lg:justify-end">
                {isAdmin && (
                  <Menu as="div" className="relative w-full md:w-auto ">
                    <Menu.Button className="z-8 inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                      Station Manager Menu
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 ml-2 -mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-10 absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/station/${station.id}/users`}
                                onClick={() => changeStation(station)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Presenters
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/station/${station.id}/widgets`}
                                onClick={() => changeStation(station)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Website Widgets
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/station/${station.id}/edit`}
                                onClick={() => changeStation(station)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Edit {station.name}
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/station/${station.id}/grid`}
                                onClick={() => changeStation(station)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Update Schedule
                              </Link>
                            )}
                          </Menu.Item>
                          {station.hls_stream && (
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  onClick={() => changeStation(station)}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  to={`/station/${station.id}/replace`}
                                >
                                  Replace Audio
                                </Link>
                              )}
                            </Menu.Item>
                          )}
                          {station.hls_stream && (
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  onClick={() => changeStation(station)}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  to={`/station/${station.id}/rolls`}
                                >
                                  On-Demand Pre-Rolls
                                </Link>
                              )}
                            </Menu.Item>
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}

                <Menu as="div" className="relative w-full md:w-auto">
                  <Menu.Button className="z-8 inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    Station Menu
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 ml-2 -mr-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {station.hls_stream && (
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/station/${station.id}/segments`}
                                onClick={() => changeStation(station)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Download Audio
                              </Link>
                            )}
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/station/${station.id}/episodes`}
                              onClick={() => changeStation(station)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Latest Episodes
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/station/${station.id}/sections`}
                              onClick={() => changeStation(station)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Station Segments
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/station/${station.id}/programs`}
                              onClick={() => changeStation(station)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Our Programs
                            </Link>
                          )}
                        </Menu.Item>
                        {/* {station.stats_enabled === 1 ||
                          (userDetails && userDetails.role === "admin" && (
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  to={`/station/${station.id}/reports`}
                                  onClick={() => changeStation(station)}
                                >
                                  Reports (beta)
                                </Link>
                              )}
                            </Menu.Item>
                          ))} */}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {isAdmin && station.episodes ? (
                  <Menu as="div" className="relative w-full md:w-auto">
                    <div>
                      <Menu.Button className="z-8 inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                        Latest Episodes
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 ml-2 -mr-1"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-10 absolute right-0 lg:right-auto origin-top-right lg:right-0 w-56 mt-2 lg:origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {station.episodes.map((episode, i) => (
                            <EpisodeDropDown
                              changeStation={changeStation}
                              episode={episode}
                              index={i}
                              station={station}
                              key={`e${episode.id}`}
                            />
                          ))}
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/station/${station.id}/episodes`}
                                onClick={() => changeStation(station)}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                All Episodes
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <></>
                )}

                {station.image && (
                  <img
                    className="h-8 w-auto bg-black p-1 rounded-md hidden lg:block"
                    src={
                      station.image.webp
                        ? station.image.webp.original
                        : station.image.url
                    }
                    alt={station.name}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 bg-white mb-2 block">
        <h4 className="text-md font-bold mb-4">
          Recent and Upcoming Episodes{" "}
          <Link
            className="text-xs text-blue-700"
            to={`/station/${station.id}/episodes`}
            onClick={() => changeStation(station)}
          >
            (View All)
          </Link>
        </h4>
        <p className="mb-4"></p>
        <div className="grid grid-cols-6 gap-2">
          {station.episodes.map((episode, i) => (
            <div
              key={`${station.id}-${episode.id}`}
              className=" col-span-6 md:col-span-3 lg:col-span-2 "
            >
              <Link
                className="mb-4 block group"
                to={`/station/${station.id}/episode/${episode.id}`}
              >
                <span className="text-xs">
                  {moment.unix(episode.timestamp).fromNow()}
                </span>
                <br />
                <span className="font-bold text-lg group-hover:text-brandPrimary">
                  {episode.program.name}{" "}
                  {onAir && onAir[0] && onAir[0].id == episode.id && (
                    <span className="text-xs text-red-500"> (on air)</span>
                  )}
                </span>
                <br />
                {moment
                  .unix(episode.timestamp)
                  .format("MMMM Do YYYY, h:mm:ss a")}{" "}
              </Link>
            </div>
          ))}
        </div>
        <Link
          className="text-xs text-blue-700"
          to={`/station/${station.id}/episodes`}
        >
          View All Episodes
        </Link>
      </div>

      {isAdmin && (
        <div className="lg:flex gap-2">
          <div className="w-full lg:w-2/3 bg-white rounded-xl mb-4 p-4">
            <StationCachedReport station={station} />
          </div>
          <div
            className={`w-full xl:w-1/3 flex flesx-row gap-2 lg:flex-col justify-evenly`}
          >
            <div className="w-full text-center bg-blueGray-700 text-white rounded-xl mb-4  p-4 lg:h-1/2 flex justify-center  items-center">
              {onAir && onAir.length > 0 ? (
                <div>
                  <h3 className="mb-2 text-xs">Current Program</h3>
                  <Link
                    className=" text-2xl hover:text-gray-400 text-gray-200 font-bold"
                    to={`/station/${station.id}/episode/${onAir[0].id}`}
                  >
                    {onAir[0].program.name}
                  </Link>
                </div>
              ) : (
                <div>
                  <h3 className="mb-2 text-xs">Current Program</h3>
                  <p className="hover:text-gray-400 text-gray-200 font-bold">
                    We were unable to determine the current program.
                    <br />
                    The station manager should ensure the station grid is up to
                    date.
                  </p>
                </div>
              )}
            </div>
            <div className="w-full text-center bg-blueGray-700 text-white  rounded-xl mb-4  p-4 lg:h-1/2 flex justify-center  items-center">
              {station.has_live_count === 1 ? (
                <div>
                  <h3 className="mb-2 text-xs">Current Online Listeners</h3>
                  <p className="text-2xl lg:text-6xl text-gray-200 mb-2">
                    {station.current_count}{" "}
                  </p>
                  {station.last_seen && (
                    <p className="text-sm   text-gray-200">
                      Last Seen: {station.last_seen}
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <p className="mb-4 text-gray-200">
                    Live stats not available for this station.{" "}
                  </p>
                  <p>
                    {userDetails.userDetails.id === station.owner_id ||
                    userDetails.role === "admin" ? (
                      <a
                        className="font-bold hover:text-brandPrimary"
                        target="_new"
                        href="https://myradio.click/contact"
                      >
                        Contact us here to organise this
                      </a>
                    ) : (
                      "Station managers can speak with us to organise this."
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
