import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm } from "react-hook-form";
import ProfileAutoComplete from "../ProfileAutoComplate";

import ReactQuill, { Quill } from "react-quill";

import { useAuthState } from "../../context";
import { useHistory, Link } from "react-router-dom";
import {
  XCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import NewMediaUpload from "../NewMediaUpload";
import { languages } from "../../config/constants";
import * as yup from "yup";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import NewPageHeader from "../Global/NewPageHeader";
import LoadingIndicator from "../Global/LoadingIndicator";
import Spinner from "../Spinner";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  bio: yup.string(),
  producer: yup
    .string()
    .max(254, "You can use a maximum of 254 characters here"),
  language: yup
    .string()
    .max(254, "You can use a maximum of 254 characters here"),
  grid_one_liner: yup
    .string()
    .max(120, "You can use a maximum of 120 characters here"),
  introduction: yup
    .string()
    .max(254, "You can use a maximum of 254 characters here"),
});

export default function ProgramForm({ id = null, stationId = null }) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const userDetails = useAuthState();
  const { station, setStation } = React.useContext(StationContext);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const quilRef = React.useRef(null);
  const [content, setContent] = useState("");
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [program, setProgram] = useState(null);
  const [presenters, setPresenters] = useState([]);
  const [disabled, setDisabled] = useState(
    program && program.explicit === 1 ? 1 : 0
  );
  const resolver = useYupValidationResolver(validationSchema);
  const [links, setLinks] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${stationId}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
  }, [stationId]);

  useEffect(() => {
    const http = global.services.http;
    const getProgram = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/programs/${id}`);
      if (req) {
        setProgram(req.data.data);
        setLinks(req.data.data.links ? JSON.parse(req.data.data.links) : []);
        setPresenters(req.data.data.presenters ? req.data.data.presenters : []);
        setContent(req.data.data.description ? req.data.data.description : []);
        setDisabled(req.data.data && req.data.data.disabled === 1 ? 1 : 0);
        if (req.data.data.image) {
          setMedia(req.data.data.image);
        }
      }
    };
    if (id) {
      getProgram();
    }
  }, [id]);

  const programSubmit = async (payload) => {
    if (loading === true) return;
    let fields = { ...payload, type: "s3", station_id: stationId };

    fields.disabled = disabled;
    fields.links = JSON.stringify(links);
    if (media) {
      fields.media_id = media.id;
    } else {
      fields.media_id = null;
    }
    fields.description = content;

    if (presenters) {
      if (
        userDetails.role === "admin" ||
        station.owner_id === userDetails.userDetails.id
      ) {
        const newIds = presenters.map((item) => item.id);
        fields.profiles = String(newIds);
      }
    }
    console.log(userDetails);

    // handle submitting the form
    setLoading(true);
    try {
      if (id) {
        var req = await http.put(
          `${process.env.REACT_APP_API}/programs/${id}`,
          fields
        );
      } else {
        var req = await http.post(
          `${process.env.REACT_APP_API}/programs`,
          fields
        );
      }

      if (req.status === 422) {
        if (req.data.data) {
          if (req.data.data.name) {
            setError("name", {
              type: "manual",
              message: req.data.data.name[0],
            });
          }
          if (req.data.data.bio) {
            setError("bio", {
              type: "manual",
              message: req.data.data.bio[0],
            });
          }
          if (req.data.data.introduction) {
            setError("introduction", {
              type: "manual",
              message: req.data.data.introduction[0],
            });
          }
          if (req.data.data.producer) {
            setError("producer", {
              type: "manual",
              message: req.data.data.producer[0],
            });
          }
          if (req.data.data.grid_one_liner) {
            setError("grid_one_liner", {
              type: "manual",
              message: req.data.data.grid_one_liner[0],
            });
          }
        }
        setLoading(false);
        return;
      } else {
        setLoading(false);
        history.push(`/station/${stationId}/program/${req.data.data.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newList = reorder(
      links,
      result.source.index,
      result.destination.index
    );

    console.log(newList);

    setLinks(newList);
  };

  //   console.log(watch("email"));
  const Header = () => (
    <p className={"text-lg font-bold"}>{`Edit Program: ${
      id ? program && program.name : "Add New Program"
    }`}</p>
  );

  const addLink = (e) => {
    e.preventDefault();
    setLinks([...links, { name: "", url: "", description: "" }]);
  };

  const updateLink = (event, value) => {
    //   console.log("here", item, i, value)
    //   let current = links;
    //   current[i][item] = value;
    //   setLinks(current);
    //   console.log(links, current)
  };

  const changeHandler = (index) => (event) => {
    const { name, value } = event.target;
    setLinks((input) =>
      input.map((el, i) =>
        i === index
          ? {
              ...el,
              [name]: value,
            }
          : el
      )
    );
  };

  const removeLink = (index) => (event) => {
    event.preventDefault();
    setLinks((input) => input.filter((el, i) => i !== index));
  };

  const addProfile = async (profile) => {
    setPresenters([...presenters, profile]);
  };

  const removePresenter = async (profile) => {
    const leftOver = presenters.filter((item) => item.id !== profile.id);
    setPresenters(leftOver);
  };

  return (
    <div className={"max-w-full  mr-auto  bg-white shadow-lg rounded-xl"}>
      {/* <SectionHeader content={<Header />} /> */}

      <div className="p-4 block lg:flex gap-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        {!id || program ? (
          <>
            <div className="mb-8 w-full lg:w-1/4">
              <label
                htmlFor="name"
                className={`block font-bold text-sm mb-2 sr-only `}
              >
                Program Image
              </label>
              {media && media.url ? (
                <div className={"text-center w-full relative group"}>
                  <button
                    className="absolute top-0 right-0 hidden group-hover:flex bg-blue-500 text-white p-1"
                    onClick={() => setMedia(null)}
                  >
                    <XCircleIcon className={"w-5 h-5"} />
                  </button>
                  <img
                    className={"max-w-full ml-auto mr-auto mt-4 mb-4"}
                    src={media.url}
                    alt={"for this episode"}
                  />
                </div>
              ) : (
                <NewMediaUpload
                  onComplete={setMedia}
                  accept={"image/*"}
                  type="s3"
                  title="Give your program an image or logo"
                  max={1363148.8}
                />
              )}
            </div>
            <form
              className="w-full lg:w-3/4 mb-40"
              onSubmit={handleSubmit(programSubmit)}
            >
              <div className="mb-8">
                <label
                  htmlFor="name"
                  className={`block font-bold text-sm mb-2 ${
                    errors.name ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Program Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={program && program.name}
                  placeholder="Program Name"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.name ? "text-red-300  " : " "
                  }`}
                  {...register("name")}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm mt-2">
                    Oops, there was something wrong with this name, perhaps this
                    program already exists?
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="genre_string"
                  className={`block font-bold text-sm mb-2 ${
                    errors.genre_string ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Genres
                </label>
                <input
                  type="text"
                  name="genre_string"
                  id="genre_string"
                  defaultValue={program && program.genre_string}
                  placeholder="Genres (seperate with a command, eg: `Funk,Arts,Soul`)"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.genre_string ? "text-red-300  " : " "
                  }`}
                  {...register("genre_string")}
                />
              </div>

              {station &&
                userDetails &&
                (userDetails.role === "admin" ||
                  userDetails.userDetails.id === station.owner_id) && (
                  <div className="mb-8">
                    <label className="font-bold">Choose Presenters</label>

                    <ProfileAutoComplete
                      station_id={stationId}
                      onSet={addProfile}
                    />
                    <div className={"flex flex-wrap mt-4"}>
                      {presenters &&
                        presenters.map((item, i) => (
                          <div key={`p${i}`} className={"mr-2 mb-1"}>
                            <div className="flex justify-center bg-white items-center m-1 font-medium py-1 pl-4 pr-1   rounded-full text-gray-900  border border-gray-200 ">
                              <div className="font-normal leading-none max-w-full flex-initial">
                                {item.public_name}
                              </div>
                              <div className="flex flex-auto flex-row-reverse ml-2 text-gray-500 hover:text-red-500">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removePresenter(item);
                                  }}
                                >
                                  <XCircleIcon className="h-8 w-8" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      {presenters && presenters.length === 0 && (
                        <p>
                          No presenters selected yet. Search above to add a
                          presenter to this program.
                        </p>
                      )}
                    </div>
                  </div>
                )}

              <div className="mb-8">
                <label
                  htmlFor="presenter_string"
                  className={`block font-bold text-sm mb-2 ${
                    errors.presenter_string ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Presenters
                </label>
                <input
                  type="text"
                  name="presenter_string"
                  id="presenter_string"
                  defaultValue={program && program.presenter_string}
                  placeholder="Presenters"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.presenter_string ? "text-red-300  " : " "
                  }`}
                  {...register("presenter_string")}
                />
              </div>
              <div className="mb-8">
                <label
                  htmlFor="producer"
                  className={`block font-bold text-sm mb-2 ${
                    errors.producer ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Producer
                </label>
                <input
                  type="text"
                  name="producer"
                  id="producer"
                  defaultValue={program && program.producer}
                  placeholder="Producer"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.producer ? "text-red-300  " : " "
                  }`}
                  {...register("producer")}
                />
              </div>
              <div className="mb-8">
                <label
                  htmlFor="language"
                  className={`block font-bold text-sm mb-2 ${
                    errors.language ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Language
                </label>
                <input
                  type="text"
                  name="language"
                  id="language"
                  defaultValue={
                    program && program.language ? program.language : "English"
                  }
                  placeholder="Program Language"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.language ? "text-red-300  " : " "
                  }`}
                  {...register("language")}
                />
              </div>
              <div className="mb-8">
                <label
                  htmlFor="website_url"
                  className={`block font-bold text-sm mb-2 ${
                    errors.website_url ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Website URL (leave blank to use default slug of program)
                </label>
                <input
                  type="text"
                  name="website_url"
                  id="website_url"
                  defaultValue={program && program.website_url}
                  placeholder="Website URL"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.website_url ? "text-red-300  " : " "
                  }`}
                  {...register("website_url")}
                />
              </div>
              <div className="mb-8">
                <label
                  htmlFor="introduction"
                  className={`block font-bold text-sm mb-2 ${
                    errors.introduction ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Short Description
                </label>
                <input
                  type="text"
                  name="introduction"
                  id="introduction"
                  defaultValue={program && program.introduction}
                  placeholder="Small Instroduction"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.introduction ? "text-red-300  " : " "
                  }`}
                  {...register("introduction")}
                />
                <p className="text-gray-500 font-light mt-2 text-xs">
                  Add a sentence or two summarising your program
                </p>
                {errors.introduction && (
                  <p className="text-red-500 text-sm mt-2">
                    {errors.introduction.message}
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="grid_one_liner"
                  className={`block font-bold text-sm mb-2 ${
                    errors.grid_one_liner ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Timetable Description
                </label>
                <input
                  type="text"
                  name="grid_one_liner"
                  id="grid_one_liner"
                  defaultValue={program && program.grid_one_liner}
                  placeholder="Small Instroduction"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.grid_one_liner ? "text-red-300  " : " "
                  }`}
                  {...register("grid_one_liner")}
                />
                <p className="text-gray-500 font-light mt-2 text-xs">
                  eg. Weekdays at 5pm
                </p>
                {errors.grid_one_liner && (
                  <p className="text-red-500 text-sm mt-2">
                    {errors.grid_one_liner.message}
                  </p>
                )}
              </div>
              <div className="mb-8 ">
                <label
                  htmlFor="bio"
                  className={`block font-bold text-sm mb-2 ${
                    errors.bio ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Program Description
                </label>
                <div className="bg-white">
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    ref={quilRef}
                    modules={ProgramForm.modules}
                    formats={ProgramForm.formats}
                  />
                </div>
                {errors.bio && (
                  <p className="text-red-500 text-sm mt-2">
                    {errors.bio.message}
                  </p>
                )}
              </div>

              <div className={"mb-8"}>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4"
                    onChange={(e) => {
                      setDisabled(e.target.checked === true ? 1 : 0);
                    }}
                    defaultChecked={program && program.disabled === 1}
                  />
                  <span className="ml-2">Disabled</span>
                </label>
              </div>
              <div className="mb-8">
                <label
                  htmlFor="program_links"
                  className={`block font-bold text-sm mb-2 text-gray-900 flex gap-4 items-center`}
                >
                  Program Links:{" "}
                  <button
                    onClick={(e) => addLink(e)}
                    className={`p-2 flex gap-2 bg-blue-500 text-white`}
                  >
                    <PlusCircleIcon className="h-5 w-5" /> Add Link
                  </button>
                </label>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {links.map((link, i) => (
                          <Draggable
                            key={`link${i}`}
                            draggableId={`link${i}`}
                            index={i}
                            className={"mb-8 p-4  "}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                className={`mb-8 flex gap-4 items-center bg-white border-2 border-indigo-600 rounded-lg border-dashed p-4`}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                                    />
                                  </svg>
                                </div>
                                <div className="w-full">
                                  <div>
                                    <label
                                      htmlFor={`link-${i}-name`}
                                      className={`block font-bold text-sm mb-2 text-gray-900`}
                                    >
                                      Link Name
                                    </label>
                                    <input
                                      required
                                      onChange={(e) => changeHandler(i)(e)}
                                      type="text"
                                      name={`name`}
                                      id={`link-${i}-name`}
                                      value={link.name}
                                      placeholder="Link Name"
                                      className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                                    />
                                  </div>
                                  <div className={`mb-2`}>
                                    <label
                                      htmlFor={`link-${i}-url`}
                                      className={`block font-bold text-sm mb-2 text-gray-900`}
                                    >
                                      Link URL
                                    </label>
                                    <input
                                      required
                                      onChange={(e) => changeHandler(i)(e)}
                                      type="text"
                                      name={`url`}
                                      id={`link-${i}-url`}
                                      value={link.url}
                                      placeholder="Link Url"
                                      className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                                    />
                                  </div>
                                  <div className={`mb-4`}>
                                    <label
                                      htmlFor={`link-${i}-url`}
                                      className={`block font-bold text-sm mb-2 text-gray-900`}
                                    >
                                      Link Description (Optional)
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => changeHandler(i)(e)}
                                      name={`description`}
                                      id={`link-${i}-description`}
                                      value={link.description}
                                      placeholder="Link Url"
                                      className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                                    />
                                  </div>
                                  <button
                                    onClick={(e) => removeLink(i)(e)}
                                    className={`p-2 flex gap-1 bg-red-500 text-white text-xs items-center rounded-lg`}
                                  >
                                    <TrashIcon className="h-4 w-4" /> Remove
                                    Link
                                  </button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <button className="w-full default-button  ">
                {!id ? "Create New Program" : "Edit Program"}
                {loading && <Spinner className="w-4 h-4 ml-2" />}
              </button>
              <p className=" text-center mt-4">
                <Link
                  className="ml-auto inline-block bg-gray-200 text-gray-800 rounded shadow py-1 px-3 text-xs"
                  to={`/station/${stationId}`}
                >
                  Cancel
                </Link>
              </p>
            </form>
          </>
        ) : (
          <div className="flex justify-center w-full py-12">
            <LoadingIndicator />
          </div>
        )}
      </div>
    </div>
  );
}

ProgramForm.modules = {
  toolbar: [
    [{ header: [1, 2, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};
// ProgramForm.modules.toolbar = [
//   ["bold", "italic", "underline", "strike"], // toggled buttons
//   ["blockquote", "code-block"], // blocks
//   [{ header: 1 }, { header: 2 }], // custom button values
//   [{ list: "ordered" }, { list: "bullet" }], // lists
//   // [{ script: "sub" }, { script: "super" }], // superscript/subscript
//   // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
//   // [{ direction: "rtl" }], // text direction
//   // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
//   [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
//   // [{ color: [] }, { background: [] }], // dropdown with defaults
//   // [{ font: [] }], // font family
//   // [{ align: [] }], // text align
//   ["clean"], // remove formatting
// ];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ProgramForm.formats = [
  "header",
  "font",
  "background",
  "color",
  "code",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "script",
  "align",
  // "direction",
  "link",
  "image",
  "code-block",
  "formula",
  "audio",
];
