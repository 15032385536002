import { useEffect } from "react";
import { useAuthState } from "../context";
import { useHistory, useLocation } from "react-router-dom";

export default function VerifyCheck() {
  const history = useHistory();
  const location = useLocation();
  const userDetails = useAuthState();

  useEffect(() => {
    if (
      userDetails &&
      history !== undefined &&
      location.pathname !== "/verify" &&
      location.pathname !== "/login" &&
      location.pathname !== "/signup" &&
      location.pathname !== "/forgot-password" &&
      location.pathname !== "/email-verification"
    ) {
      if (userDetails.userDetails.email_verified_at === null) {
        history.push("/verify");
      }
    } else {
    }
  });
  return <></>;
}
