import React from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import WidgetForm from "../components/forms/WidgetForm";

export default function AddWidget() {
  const { id, station_id } = useParams();
  return (
    <Layout route="widgets" title="Add New Widget">
      {station_id && <WidgetForm stationId={station_id} widget={null} />}
    </Layout>
  );
}
