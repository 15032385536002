import React from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import StreamForm from "../components/forms/StreamForm";

export default function AddStream() {
  const { id, station_id } = useParams();
  return (
    <Layout route="streams" title="Add New Stream">
      {station_id && <StreamForm stationId={station_id} />}
    </Layout>
  );
}
