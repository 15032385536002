import React from "react";

const LayoutLogin = ({ children }) => {
  return (
    <div
      className={
        "h-{900} min-h-screen w-screen relative flex items-center justify-center bg-newDarkBlue"
      }
    >
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LayoutLogin;
