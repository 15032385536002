import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthState } from "../context";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { loadingColumns, loadingData } from "../config/constants";
import { StationContext } from "../context/station-context";
import Table from "../components/Table";
import ReactPaginate from "react-paginate";
import NewPageHeader from "../components/Global/NewPageHeader";

// import { Link } from 'react-router-dom';

export default function Sections() {
  const http = global.services.http;
  const { no, station_id } = useParams();
  const { station, setStation } = React.useContext(StationContext);
  let history = useHistory();
  const userDetails = useAuthState(); //read user details from context
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const [sections, setSections] = useState([]);
  const [postPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        console.log("station is set loaded", req.data.data);
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [station]);

  useEffect(() => {
    const http = global.services.http;
    const getSection = async () => {
      setLoading(true);
      var filter = JSON.stringify({ station_id: station_id });
      var req = await http.get(
        `${process.env.REACT_APP_API}/sections?filter=${filter}&page=${pageNo}&limit=${postPerPage}`
      );
      if (req.data) {
        setSections(req.data.data);
        setPageCount(req.data.meta.last_page + 1);
        setLoading(false);
      }
    };
    if (thisStation) {
      getSection();
    }
  }, [pageNo, thisStation]);

  const getSection = async () => {
    setLoading(true);
    var filter = JSON.stringify({ station_id: station_id });
    var req = await http.get(
      `${process.env.REACT_APP_API}/sections?filter=${filter}&page=${pageNo}&limit=${postPerPage}`
    );
    if (req.data) {
      setSections(req.data.data);
      setPageCount(req.data.meta.last_page + 1);
      setLoading(false);
    }
  };
  const handlePageClick = (event) => {
    console.log(event);
    setPageNo(event.selected + 1);
    history.push(`/station/${station_id}/sections/${event.selected + 1}`);
  };

  const deleteSection = async (id) => {
    if (window.confirm("Are you sure you wish to delete this item?") === true) {
      var req = await http.delete(
        `${process.env.REACT_APP_API}/sections/${id}`
      );
      await getSection();
    }
    // console.log(event);
    // setPageNo(event.selected + 1);
    // history.push(`/station/${station_id}/sections/${event.selected + 1}`);
  };

  const EditCell = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-end"}>
        {(userDetails.role === "admin" ||
          userDetails.userDetails.id === station.owner_id) && (
          <div className="flex justify-end gap-2">
            <Link
              className={
                "border-lightBlue-600 border text-lightBlue-600 rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
              }
              to={`/station/${station_id}/section/${row.original.id}/edit`}
            >
              <PencilAltIcon className="w-3 h-3" />
              Edit
            </Link>
            <button
              className={
                "border-red-600 border text-red-600 rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
              }
              onClick={() => deleteSection(row.original.id)}
            >
              <TrashIcon className="w-3 h-3" />
              Delete
            </button>
          </div>
        )}
      </div>
    );
  };

  const ShowName = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-start"}>
        {row.original.image && row.original.image.url && (
          <img src={row.original.image.url} className={"w-4 mr-2"} />
        )}

        <span>
          {row.original.title} ({row.original.status})
        </span>
      </div>
    );
  };

  const Episode = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-start"}>
        {row.original.episode_id ? (
          <Link
            className={"font-bold text-brandPrimary "}
            to={`/station/${station_id}/episode/${row.original.episode_id}`}
          >
            {row.original.episode.program.name} -{" "}
            {row.original.episode.readable}
          </Link>
        ) : (
          "NA"
        )}
      </div>
    );
  };

  const ShowDuration = ({ row }) => {
    const time = row.original.duration / 60;
    return `${time.toFixed(2)} (minutes)`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Section Name",
        accessor: "title",
        Cell: ShowName,
      },
      {
        Header: "Time",
        accessor: "readable",
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ShowDuration,
      },
      {
        Header: "Episode",
        accessor: "episode_id",
        Cell: Episode,
      },
      {
        Header: "Action",
        accessor: "created_at",
        className: "text-right text-end",
        Cell: EditCell,
      },
    ],
    []
  );

  const TableHeader = () => {
    return <div></div>;
  };

  return (
    <Layout
      route="sections"
      title={`Section ${thisStation ? `for ${thisStation.title}` : ""}`}
    >
      <NewPageHeader
        title="Segments"
        links={
          (userDetails.role === "admin" ||
            userDetails.userDetails.id === station.owner_id) && [
            {
              name: "Add new segment",
              link: `/station/${station_id}/section/new`,
            },
          ]
        }
      />
      <div className="block w-full overflow-x-auto">
        <>
          {sections && (
            <Table
              columns={loading ? loadingColumns : columns}
              data={loading ? loadingData : sections}
              // content={<TableHeader />}
            />
          )}
          <div className="mx-auto py-4 flex items-center justify-around">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount - 1}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName={"paginator"}
              forcePage={pageNo - 1}
            />
          </div>
        </>
      </div>
    </Layout>
  );
}
