import { useEffect, useState } from "react";
import Spinner from "../Spinner";
import moment from "moment";
import { useAuthState } from "../../context";

export default function RollList({
  station,
  predefined = null,
  update = null,
  setEdit = null,
}) {
  const [rolls, setRolls] = useState(predefined ? predefined : []);
  const [page, setPage] = useState(1);
  const userDetails = useAuthState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const http = global.services.http;
    const getRolls = async () => {
      console.log("user", userDetails);
      setLoading(true);
      const filter = JSON.stringify({ station_id: station.id });
      var req = await http.get(
        `${process.env.REACT_APP_API}/rolls?filter=${filter}&page=${page}&limit=500`
      );
      if (req) {
        setLoading(false);
        setRolls(req.data.data);
      }
    };
    getRolls();
  }, [station, page, update]);

  return (
    <div className="mt-8 p4 bg-white text-gray-800 rounded-md p-4">
      {rolls && rolls.length > 0 ? (
        <div>
          {rolls.map((roll) => {
            return (
              <div
                key={roll.id}
                className="flex flex-col lg:flex-row lg:justify-between lg:items-center border-b border-gray-100 py-2 gap-2"
              >
                <div className="flex items-center">
                  <div className="flex items-center space-x-2">
                    <div>
                      <p className="text-lg font-bold">{roll.title}</p>
                      <p className="text-sm ">
                        Status: {roll.status} Currenty Active:{" "}
                        {roll.active ? "True" : "False"} Total Plays:{" "}
                        {roll.plays}
                      </p>
                    </div>
                    <div className="text-sm text-gray-500"></div>
                    {/* <div className="text-sm text-gray-500">
                      {roll.programs.length}
                    </div> */}
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  {roll &&
                    roll.programs &&
                    roll.programs.map((item, i) => {
                      return i < 3 ? (
                        <div
                          key={`p${item.id}`}
                          className="px-2 py-1 bg-pink-500 text-white rounded-full  whitespace-nowrap text-xs"
                        >
                          {item.name}
                        </div>
                      ) : (
                        <></>
                      );
                    })}
                  {roll.programs.length > 2 && (
                    <p className="text-xs">
                      +{roll.programs.length - 3} more....
                    </p>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <div className="text-sm text-gray-500">
                    Start Date: {moment(roll.start_date).format("l")}
                  </div>
                  <div className="text-sm text-gray-500">
                    End Date: {moment(roll.end_date).format("l")}
                  </div>
                  <button
                    onClick={() => setEdit(roll)}
                    className="text-sm text-blue-500"
                  >
                    Edit
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : loading ? (
        <div className="flex justify-center">
          <Spinner className={"w-16 h-6"} />
        </div>
      ) : (
        <div>No Pre-Rolls Found. Click on "New Pre-Roll" to begin.</div>
      )}
    </div>
  );
}
