import React from "react";
// import styled from 'styled-components'
import { useTable } from "react-table";
import SectionHeader from "./SectionHeader";

// import makeData from './makeData'

export default function Table({ columns, data, content = null }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <div className=" flex flex-col  bg-white">
      {content && <SectionHeader content={content} />}
      <div className=" overflow-x-auto  8">
        <div className=" align-middle inline-block min-w-full ">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-50  ">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        className={`${
                          column.className && column.className
                        } group px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider  `}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white   divide-y divide-gray-200  "
                {...getTableBodyProps()}
              >
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-4 py-4 whitespace-nowra first-child:font-bold text-xs "
                            role="cell"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
