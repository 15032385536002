import React, { useState, useEffect, useCallback, useContext } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm } from "react-hook-form";
import { refreshUser } from "../../context";
import { useAuthDispatch } from "../../context";
import { useHistory } from "react-router-dom";
import {
  XCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import ProfileEmbed from "../Embeds/ProfileEmbed";
import NewMediaUpload from "../NewMediaUpload";
import * as yup from "yup";
import NewPageHeader from "../Global/NewPageHeader";
import Spinner from "../Spinner";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  public_name: yup.string().required(),
  bio: yup.string(),
  introduction: yup
    .string()
    .max(254, "You can use a maximum of 254 characters here"),
});

export default function ProfileForm({}) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const { getUser } = useContext(StationContext);
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [profile, setProfile] = useState(null);
  const resolver = useYupValidationResolver(validationSchema);
  const dispatch = useAuthDispatch();
  const [links, setLinks] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  useEffect(() => {
    const http = global.services.http;
    const getProfile = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/auth/user`);
      if (req) {
        setProfile(req.data.profile);
        setLinks(
          req.data.profile.links ? JSON.parse(req.data.profile.links) : []
        );

        if (req.data.profile.image) {
          setMedia(req.data.profile.image);
        }
      }
    };

    getProfile();
  }, []);

  const profileSubmit = async (payload) => {
    if (loading === true) return;
    let fields = { ...payload };
    console.log("here", links);
    fields.links = JSON.stringify(links);
    if (media) {
      fields.media_id = media.id;
    } else {
      fields.media_id = null;
    }
    // handle submitting the form
    setLoading(true);
    try {
      var req = await http.put(
        `${process.env.REACT_APP_API}/profiles/${profile.id}`,
        fields
      );

      if (req.status === 422) {
        if (req.data.data) {
          if (req.data.data.public_name) {
            setError("public_name", {
              type: "manual",
              message: req.data.data.public_name[0],
            });
          }
          if (req.data.data.bio) {
            setError("bio", {
              type: "manual",
              message: req.data.data.bio[0],
            });
          }
        }
        setLoading(false);
        return;
      } else {
        getUser();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(watch("email"));
  const Header = () => <p className={"text-lg font-bold"}>{`Edit Profile`}</p>;

  const addLink = (e) => {
    e.preventDefault();
    setLinks([...links, { name: "", url: "", description: "" }]);
  };

  const changeHandler = (index) => (event) => {
    const { name, value } = event.target;
    setLinks((input) =>
      input.map((el, i) =>
        i === index
          ? {
              ...el,
              [name]: value,
            }
          : el
      )
    );
  };

  const removeLink = (index) => (event) => {
    event.preventDefault();
    setLinks((input) => input.filter((el, i) => i !== index));
  };

  return (
    <div className={"max-w-full  mr-auto  bg-white shadow-lg rounded-xl"}>
      {/* <SectionHeader content={<Header />} /> */}

      <div className="p-4 block lg:flex gap-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        {profile ? (
          <>
            <div className="mb-8 w-full lg:w-1/4">
              <label
                htmlFor="name"
                className={`block font-bold text-sm mb-2 sr-only `}
              >
                Profile Image
              </label>
              {media && media.url ? (
                <div className={"text-center w-full relative group"}>
                  <button
                    className="absolute top-0 right-0 hidden group-hover:flex bg-blue-500 text-white p-1"
                    onClick={() => setMedia(null)}
                  >
                    <XCircleIcon className={"w-5 h-5"} />
                  </button>
                  <img
                    className={"max-w-full ml-auto mr-auto mt-4 mb-4"}
                    src={media.url}
                    alt={"for this episode"}
                  />
                </div>
              ) : (
                <NewMediaUpload
                  onComplete={setMedia}
                  accept={"image/*"}
                  type="s3"
                  title="Give your profile an image or logo"
                />
              )}
            </div>
            <form
              className="w-full lg:w-3/4"
              onSubmit={handleSubmit(profileSubmit)}
            >
              <div className="mb-8">
                <label
                  htmlFor="name"
                  className={`block font-bold text-sm mb-2 ${
                    errors.name ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Public Name
                </label>
                <input
                  type="text"
                  name="public_name"
                  id="public_name"
                  defaultValue={profile && profile.public_name}
                  placeholder="Public Name"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.public_name ? "text-red-300  " : " "
                  }`}
                  {...register("public_name")}
                />
                {errors.public_name && (
                  <p className="text-red-500 text-sm mt-2">
                    Oops, there was something wrong with this name.
                  </p>
                )}
              </div>

              <div className="mb-8">
                <label
                  htmlFor="bio"
                  className={`block font-bold text-sm mb-2 ${
                    errors.bio ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Presenter Bio
                </label>
                <textarea
                  type="text"
                  name="bio"
                  id="bio"
                  defaultValue={profile && profile.bio}
                  placeholder="Presenter Bio"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.bio ? "border-red-400 " : " "
                  }`}
                  {...register("bio")}
                />
                {errors.bio && (
                  <p className="text-red-500 text-sm mt-2">
                    {errors.bio.message}
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="profile_links"
                  className={`block font-bold text-sm mb-2 text-gray-900 flex gap-4 items-center`}
                >
                  Profile Links:{" "}
                  <button
                    onClick={(e) => addLink(e)}
                    className={`p-2 flex gap-2 bg-blue-500 text-white`}
                  >
                    <PlusCircleIcon className="h-5 w-5" /> Add Link
                  </button>
                </label>
                {links.map((link, i) => (
                  <div
                    key={`link${i}`}
                    className={
                      "mb-8 p-4 border-2 border-indigo-600 rounded-lg border-dashed"
                    }
                  >
                    <div className={`mb-2`}>
                      <label
                        htmlFor={`link-${i}-name`}
                        className={`block font-bold text-sm mb-2 text-gray-900`}
                      >
                        Link Name
                      </label>
                      <input
                        required
                        onChange={(e) => changeHandler(i)(e)}
                        type="text"
                        name={`name`}
                        id={`link-${i}-name`}
                        value={link.name}
                        placeholder="Link Name"
                        className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                      />
                    </div>
                    <div className={`mb-2`}>
                      <label
                        htmlFor={`link-${i}-url`}
                        className={`block font-bold text-sm mb-2 text-gray-900`}
                      >
                        Link URL
                      </label>
                      <input
                        required
                        onChange={(e) => changeHandler(i)(e)}
                        type="text"
                        name={`url`}
                        id={`link-${i}-url`}
                        value={link.url}
                        placeholder="Link Url"
                        className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                      />
                    </div>
                    <div className={`mb-4`}>
                      <label
                        htmlFor={`link-${i}-url`}
                        className={`block font-bold text-sm mb-2 text-gray-900`}
                      >
                        Link Description (Optional)
                      </label>
                      <input
                        type="text"
                        onChange={(e) => changeHandler(i)(e)}
                        name={`description`}
                        id={`link-${i}-description`}
                        value={link.description}
                        placeholder="Link Url"
                        className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                      />
                    </div>
                    <button
                      onClick={(e) => removeLink(i)(e)}
                      className={`p-2 flex gap-1 bg-red-500 text-white text-xs items-center rounded-lg`}
                    >
                      <TrashIcon className="h-4 w-4" /> Remove Link
                    </button>
                  </div>
                ))}
              </div>
              <button className="w-full default-button  ">
                Edit Profile
                {loading && <Spinner className="w-4 h-4 ml-2" />}
              </button>
            </form>
          </>
        ) : (
          "Loading"
        )}
      </div>
      {profile && <ProfileEmbed profile={profile} />}
    </div>
  );
}
