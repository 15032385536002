import React, { useState, useCallback } from "react";
import Spinner from "./Spinner";
import { formatBytes } from "../config/functions";
import { useDropzone } from "react-dropzone";

const NewMediaUpload = ({
  title = "Upload a file",
  type = "s3",
  accept = "image/*, video/mp4, audio/*",
  onComplete = () => alert("Done"),
  max = 524288,
  maxFiles = 1,
}) => {
  const [files, setFiles] = useState(null);
  const [dragOn, setDragOn] = useState(false);
  const http = global.services.http;
  //state for checking file size
  const [fileSize, setFileSize] = useState(true);
  // for file upload progress message
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  //for displaying response message
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  //base end point url
  const maximumFileSize = formatBytes(max);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setDragOn(false);
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
      };
      // reader.readAsArrayBuffer(file);
      setFileSize(true);
      setFileUploadProgress(true);
      setFileUploadResponse(null);
      const formData = new FormData();
      formData.append(`file`, file);
      formData.append("type", type);
      uploadAudio(formData);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
    maxFiles: maxFiles,
    maxSize: max,
    onDragEnter: () => setDragOn(true),
    onDragLeave: () => setDragOn(false),
  });

  const uploadAudio = async (formData) => {
    const payload = { ...formData, type };
    http
      .post("/medias", formData)
      .then((res) => {
        console.log("res", res.data);
        onComplete(res.data.data);
        setFileUploadProgress(false);
        // removeUpload(res.data.data.filename);
        // getAlbum();
        // document.getElementById("formFile").value = "";
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const uploadFileHandler = async (event) => {
  //   console.log("here");

  //   if (event.target.files[0].size > max) {
  //     document.getElementById("formFile").value = "";
  //     alert(
  //       `This file is too large, please ensure the file is smaller than ${formatBytes(
  //         max
  //       )}`
  //     );
  //     return;
  //   }

  //   console.log(event.target.files);
  //   await setFiles(event.target.files);
  //   // fileSubmitHandler()
  //   setFileSize(true);
  //   setFileUploadProgress(true);
  //   setFileUploadResponse(null);
  //   const formData = new FormData();
  //   formData.append(`file`, event.target.files[0]);
  //   formData.append("type", type);
  //   await uploadAudio(formData);
  // };

  // const fileSubmitHandler = () => {
  //   // event.preventDefault();
  //   if (!files[0]) {
  //     alert("No file uploaded");
  //     return;
  //   }
  //   console.log("files", files[0]);
  //   setFileSize(true);
  //   setFileUploadProgress(true);
  //   setFileUploadResponse(null);
  //   const formData = new FormData();
  //   formData.append(`file`, files[0]);
  //   formData.append("type", type);
  //   uploadAudio(formData);
  // };

  return (
    <>
      {title && <h5 className="text-center font-bold text-xs mb-3">{title}</h5>}
      <div {...getRootProps()}>
        <input accept={accept} {...getInputProps()} />

        <div className="flex justify-center">
          <div className="w-full">
            <label
              className={`flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none ${
                dragOn === true ? "bg-gray-200" : ""
              }`}
            >
              <span className="flex items-center space-x-2">
                {fileUploadProgress ? (
                  <Spinner className={"text-red-500 w-8 h-8"} />
                ) : dragOn === true ? (
                  <p>Drop file to upload</p>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600 text-xs">
                      Drop files to Attach, or
                      <br />
                      <span className="text-blue-600 underline text-xs">
                        Browse
                      </span>{" "}
                      <br />
                      <span className="text-xs text-gray-400">
                        (max {maximumFileSize}}
                      </span>
                    </span>
                  </>
                )}
              </span>
              {/* <input
                type="file"
                name="file_upload"
                className="hidden"
                id="formFile"
                accept={accept}
                onChange={uploadFileHandler}
              /> */}
            </label>
          </div>
        </div>
      </div>

      {!fileSize && <p style={{ color: "red" }}>File size exceeded!!</p>}

      {fileUploadResponse != null && (
        <p style={{ color: "green" }}>{fileUploadResponse}</p>
      )}
    </>
  );
};
export default NewMediaUpload;
