import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import AudioPlayer from "../AudioPlayer";
import Slider from "rc-slider";
import subDays from "date-fns/subDays";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { useHistory, Link } from "react-router-dom";
import {
  XCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import NewMediaUpload from "../NewMediaUpload";
import moment from "moment";
import * as yup from "yup";
import { addDays } from "date-fns";
import { start } from "@popperjs/core";
import Spinner from "../Spinner";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  content: yup.string(),
});

export default function SectionForm({
  id = null,
  stationId = null,
  onComplete = null,
  episode = null,
}) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const { station, setStation } = React.useContext(StationContext);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [section, setSection] = useState(null);
  const [url, setUrl] = useState(null);
  const [duration, setDuration] = useState(300);
  const [startDate, setStartDate] = useState(new Date());
  const [timestamp, setTimestamp] = useState(
    section ? section.timestamp : null
  );
  const [dec, setDec] = useState(5);
  const resolver = useYupValidationResolver(validationSchema);
  const [links, setLinks] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    control,
    reset,
    formState: { errors, isDirty },
    formState,
    getValues,
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  // const fieldsEdited = isDirty;
  const fieldsEdited = formState.dirtyFields;

  useEffect(() => {
    const minutes = duration / 60;
    setDec(minutes.toFixed(2));
  }, [duration, setDec]);

  useEffect(() => {
    console.log(startDate);
    setTimestamp(moment(startDate).format("X"));
    let ts = moment(startDate).format("X");
    if (timestamp && duration && station) {
      setUrl(
        `${process.env.REACT_APP_API}/public/ondemand/${station.hls_stream}/96/${ts}/${duration}/listen.m3u8?unique=console`
      );
    }
  }, [timestamp, duration, station, startDate]);

  const onChangeSlider = (value) => {
    const minutes = value / 60;
    setDuration(value);
  };

  const handleChangeTime = (date, time) => {
    const [hh, mm, ss] = time.split(":");
    const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
    // setDate(targetDate);
    console.log("here");
    setStartDate(targetDate);
    let hlsDate = moment(targetDate).unix();
    setUrl(
      `${process.env.REACT_APP_API}/public/ondemand/${station.hls_stream}/96/${hlsDate}/${duration}/listen.m3u8?unique=console`
    );
    // setUrl(
    //   `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${hlsDate}/${duration}/playlist.m3u8?unique=chopper`
    // );
  };

  const CustomTimeInput = ({ date, onChangeCustom }) => {
    const value =
      date instanceof Date && !isNaN(date)
        ? // Getting time from Date beacuse `value` comes here without seconds
          date.toLocaleTimeString("it-IT")
        : "";

    return (
      <input
        className="bp3-input bp3-fill"
        type="time"
        step="5"
        value={value}
        onChange={(event) => onChangeCustom(date, event.target.value)}
      />
    );
  };

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${stationId}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
        console.log("Updates station");
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [stationId]);

  useEffect(() => {
    const http = global.services.http;
    const getSection = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/sections/${id}`);
      if (req) {
        setSection(req.data.data);
        setTimestamp(req.data.data.timestamp);

        setDuration(req.data.data.duration);
        setStartDate(new Date(req.data.data.timestamp * 1000));
        setLinks(req.data.data.links ? JSON.parse(req.data.data.links) : []);
        if (req.data.data.image) {
          setMedia(req.data.data.image);
        }
      }
    };
    if (id) {
      getSection();
    }
  }, [id]);

  const sectionSubmit = async (payload) => {
    if (loading === true) return;
    let fields = {
      ...payload,
      type: "s3",
      station_id: stationId,
      duration: duration,
      start: timestamp,
    };
    fields.links = JSON.stringify(links);
    if (media) {
      fields.media_id = media.id;
    }
    if (episode) {
      fields.episode_id = episode.id;
    }
    if (section && section.episode_id) {
      fields.episode_id = section.episode_id;
    }

    // handle submitting the form
    setLoading(true);
    try {
      if (id) {
        var req = await http.put(
          `${process.env.REACT_APP_API}/sections/${id}`,
          fields
        );
      } else {
        var req = await http.post(
          `${process.env.REACT_APP_API}/sections`,
          fields
        );
      }

      if (req.status === 422) {
        if (req.data.data) {
          if (req.data.data.name) {
            console.log("Got here", req.data.data.name);
            setError("title", {
              type: "manual",
              message: req.data.data.name[0],
            });
          }
          if (req.data.data.bio) {
            setError("content", {
              type: "manual",
              message: req.data.data.bio[0],
            });
          }
        }
        setLoading(false);
        return;
      } else {
        setLoading(false);
        if (onComplete === null) {
          history.push(`/station/${stationId}/sections`);
        } else {
          reset();
          onComplete();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(watch("email"));
  const Header = () => (
    <p className={"text-lg font-bold"}>{`${
      id ? section && section.title : "Add New Segment"
    }`}</p>
  );

  const addLink = (e) => {
    e.preventDefault();
    setLinks([...links, { name: "", url: "", description: "" }]);
  };

  const updateLink = (event, value) => {
    //   console.log("here", item, i, value)
    //   let current = links;
    //   current[i][item] = value;
    //   setLinks(current);
    //   console.log(links, current)
  };

  const changeHandler = (index) => (event) => {
    const { name, value } = event.target;
    setLinks((input) =>
      input.map((el, i) =>
        i === index
          ? {
              ...el,
              [name]: value,
            }
          : el
      )
    );
  };

  const removeLink = (index) => (event) => {
    event.preventDefault();
    setLinks((input) => input.filter((el, i) => i !== index));
  };

  return (
    <div className={"max-w-full  mr-auto  bg-white shadow-lg rounded-xl"}>
      <SectionHeader content={<Header />} />
      <div className="p-4 block lg:flex gap-4 bg-white">
        {errorMessage ? <p>{errorMessage}</p> : null}
        {!id || section ? (
          <>
            <div className="mb-8 w-full lg:w-1/4">
              <label
                htmlFor="name"
                className={`block font-bold text-sm mb-2 sr-only `}
              >
                Section Image
              </label>
              {media && media.url ? (
                <div className={"text-center w-full relative group"}>
                  <button
                    className="absolute top-0 right-0 hidden group-hover:flex bg-blue-500 text-white p-1"
                    onClick={() => setMedia(null)}
                  >
                    <XCircleIcon className={"w-5 h-5"} />
                  </button>
                  <img
                    className={"max-w-full ml-auto mr-auto mt-4 mb-4"}
                    src={media.url}
                    alt={"for this episode"}
                  />
                </div>
              ) : (
                <NewMediaUpload
                  onComplete={setMedia}
                  accept={"image/*"}
                  type="s3"
                  max={1363148.8}
                  title="Give your section an image or logo"
                />
              )}
            </div>
            <div className="w-full lg:w-3/4 white-bg">
              {url && station && station.hls_stream && (
                <AudioPlayer url={url} index={0} />
              )}
              <form onSubmit={handleSubmit(sectionSubmit)}>
                <div className="mb-8">
                  <label>Section Start:</label>
                  <ReactDatePicker
                    className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700"
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    // onBlur={onBlur}
                    selected={startDate}
                    placeholderText="Select a time"
                    showTimeInput
                    customTimeInput={
                      <CustomTimeInput onChangeCustom={handleChangeTime} />
                    }
                    timeIntervals={1}
                    dateFormat="d, MMMM, yyyy h:mm:ss aa"
                    includeDateIntervals={[
                      {
                        start: subDays(new Date(), 56),
                        end: addDays(new Date(), 1),
                      },
                    ]}
                  />
                  {errors.start && (
                    <p className="text-red-500 text-sm mt-2">
                      Please enter a valid date -{" "}
                      {errors.start.message && errors.start.message}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <p className="pb-4">
                    Duration: {duration} Seconds, {dec && dec} Minutes
                  </p>
                  <Slider
                    value={duration}
                    min={30}
                    max={10800}
                    onChange={onChangeSlider}
                  />
                </div>

                <div className="mb-8">
                  <label
                    htmlFor="name"
                    className={`block font-bold text-sm mb-2 ${
                      errors.name ? "text-red-400" : "text-gray-800"
                    }`}
                  >
                    Section Title
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    defaultValue={section && section.title}
                    placeholder="Section Title"
                    className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                      errors.name ? "text-red-300  " : " "
                    }`}
                    {...register("title")}
                  />
                  {errors.name && (
                    <p className="text-red-500 text-sm mt-2">
                      Oops, there was something wrong with this title?
                    </p>
                  )}
                </div>

                <div className="mb-8">
                  <label
                    htmlFor="bio"
                    className={`block font-bold text-sm mb-2 ${
                      errors.bio ? "text-red-400" : "text-gray-900"
                    }`}
                  >
                    Section Content
                  </label>
                  <textarea
                    type="text"
                    name="bio"
                    id="bio"
                    defaultValue={section && section.content}
                    placeholder="Section Description"
                    className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                      errors.content ? "border-red-400 " : " "
                    }`}
                    {...register("content")}
                  />
                  {errors.content && (
                    <p className="text-red-500 text-sm mt-2">
                      {errors.content.message}
                    </p>
                  )}
                </div>

                <div className="mb-8">
                  <label
                    htmlFor="section_links"
                    className={`block font-bold text-sm mb-2 text-gray-900 flex gap-4 items-center`}
                  >
                    Section Status
                  </label>
                  <select
                    {...register("status")}
                    defaultValue={section ? section.status : "published"}
                  >
                    <option value="published">Published</option>
                    <option value="draft">Draft</option>
                  </select>
                </div>
                <div className="mb-8">
                  <label
                    htmlFor="section_links"
                    className={`block font-bold text-sm mb-2 text-gray-900 flex gap-4 items-center`}
                  >
                    Section Links:{" "}
                    <button
                      onClick={(e) => addLink(e)}
                      className={`p-2 flex gap-2 bg-blue-500 text-white`}
                    >
                      <PlusCircleIcon className="h-5 w-5" /> Add Link
                    </button>
                  </label>
                  {links.map((link, i) => (
                    <div
                      key={`link${i}`}
                      className={
                        "mb-8 p-4 border-2 border-indigo-600 rounded-lg border-dashed"
                      }
                    >
                      <div className={`mb-2`}>
                        <label
                          htmlFor={`link-${i}-name`}
                          className={`block font-bold text-sm mb-2 text-gray-900`}
                        >
                          Link Name
                        </label>
                        <input
                          required
                          onChange={(e) => changeHandler(i)(e)}
                          type="text"
                          name={`name`}
                          id={`link-${i}-name`}
                          value={link.name}
                          placeholder="Link Name"
                          className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        />
                      </div>
                      <div className={`mb-2`}>
                        <label
                          htmlFor={`link-${i}-url`}
                          className={`block font-bold text-sm mb-2 text-gray-900`}
                        >
                          Link URL
                        </label>
                        <input
                          required
                          onChange={(e) => changeHandler(i)(e)}
                          type="text"
                          name={`url`}
                          id={`link-${i}-url`}
                          value={link.url}
                          placeholder="Link Url"
                          className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        />
                      </div>
                      <div className={`mb-4`}>
                        <label
                          htmlFor={`link-${i}-url`}
                          className={`block font-bold text-sm mb-2 text-gray-900`}
                        >
                          Link Description (Optional)
                        </label>
                        <input
                          type="text"
                          onChange={(e) => changeHandler(i)(e)}
                          name={`description`}
                          id={`link-${i}-description`}
                          value={link.description}
                          placeholder="Link Url"
                          className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
                        />
                      </div>
                      <button
                        onClick={(e) => removeLink(i)(e)}
                        className={`p-2 flex gap-1 bg-red-500 text-white text-xs items-center rounded-lg`}
                      >
                        <TrashIcon className="h-4 w-4" /> Remove Link
                      </button>
                    </div>
                  ))}
                </div>
                <button className="w-full default-button  ">
                  {!id ? "Create New Segment" : "Edit Section"}
                  {loading && <Spinner className="w-4 h-4 ml-2" />}
                </button>
                <p className=" text-center mt-4">
                  <Link
                    className="ml-auto inline-block bg-gray-200 text-gray-800 rounded shadow py-1 px-3 text-xs"
                    to={`/station/${stationId}/sections`}
                  >
                    Cancel
                  </Link>
                </p>
              </form>
            </div>
          </>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
