import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  // Tooltip,
  // Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import randomColor from "randomcolor";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement, 
  Tooltip,
  Legend
);

const RegionChart = ({
  report, 
  label = "Listeners by City",
  type = "region",
  bgColor = 'rgba(255, 99, 132, 0.5)'
}) => { 
  const [data, setData] = useState(null);

  useEffect(() => {
    const updateReport = async () => {
      const gl = Object.entries(report.geo[type]).map(([k, v], i) => k === "" ? "Unknown" : k);

      const gs = Object.entries(report.geo[type]).map(([k, v], i) => v);
      const colors =  gl.map(() => randomColor());
      setData({
        labels: gl,
        datasets: [
          { 
            label: label,
            data: gs,
            // backgroundColor: colors,
            // borderColor: colors,
            borderWidth: 1,
            backgroundColor: bgColor,
          },
        ],
      });
    };
    if (report !== null && type !== null) updateReport();
  }, [report, setData]);

  const options = {
    // responsive: true,
    // tension: 0.4,
    // fill: true,
    // scales: {
    //   x: {
    //     grid: {
    //       display: false,
    //     },
    //   },
    // },
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  return <div className="w-full">{data && <Bar data={data} />}</div>;
};

export default RegionChart;
