import React, { useEffect, useState } from "react";
import StashFormEdit from "./forms/StashFormEdit";
import LoadingIndicator from "./Global/LoadingIndicator";

export default function MultiTrackFile({ file }) {
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);
  const [uploading, setUploading] = useState(true);
  const [saved, setSaved] = useState(false);
  const [stash, setStash] = useState(null);
  const http = global.services.http;

  const onCompleted = (stash) => {
    setSaved(true);
    setStash(stash);
  };
  useEffect(() => {
    const uploadAudio = async ({ file }) => {
      const formData = new FormData();
      formData.append(`file`, file);
      setUploading(true);
      http
        .post("/stash", formData)
        .then((res) => {
          setUploading(false);
          setComplete(true);
          setStash(res.data.data);
          setSaved(false);
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    if (started === false) {
      setStarted(true);
      uploadAudio({ file });
    }
  }, []);

  return (
    <div>
      {uploading ? (
        <div className="flex gap-4 rounded-xl bg-gray-50 items-center w-full justify-center my-4 py-8">
          <div className="w-12 flex justify-end">
            <LoadingIndicator width={8} />
          </div>
          <p className="w-60">Uploading {file.name}</p>
        </div>
      ) : complete && stash && saved === false ? (
        <StashFormEdit
          hideHeader={true}
          hideCancel={true}
          id={stash.id}
          onRequestClose={(stash) => onCompleted(stash)}
          preloaded={stash}
        />
      ) : (
        <div className="flex gap-2 p-4 bg-gray-50 rounded-xl my-2">
          {stash && uploading === false
            ? `Successfully Added ${stash.title} - ${stash.title} ${stash.artist}`
            : ``}
        </div>
      )}
    </div>
  );
}
