import {
  ArrowRightIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "../context";

export default function Alerts() {
  const [run, setRun] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const http = global.services.http;
  const userDetails = useAuthState(); //read user details from context
  useEffect(() => {
    const http = global.services.http;
    const getAlerts = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/alert_logs`);
      if (req.data?.data) {
        setAlerts(req.data.data);
      } else {
        console.log("There was an issue finding alerts");
      }
    };
    if (run === false) {
      setRun(true);
      getAlerts();
    }
  }, [userDetails]);

  const removeIcon = async (id) => {
    const newAlerts = alerts.filter((item) => item.id !== id);
    setAlerts(newAlerts);
    var req = await http.put(`${process.env.REACT_APP_API}/alert_logs/${id}`);
  };
  return (
    <div className="alerts">
      {alerts &&
        alerts.map((alert, i) => (
          <div
            className="m-2 mb-2 bg-purple-600 text-white pl-4 pr-24 py-2 relative"
            key={i}
          >
            <button
              onClick={() => removeIcon(alert.id)}
              className="absolute text-white top-0 right-0 p-3"
            >
              <XCircleIcon className="w-6 h-6" />
            </button>
            <h3 className="mb-2 text-lg font-bold">{alert.alert.title}</h3>
            <p>{alert.alert.content}</p>
            {alert.alert?.link && (
              <p className="pt-2 text-sm">
                <Link to={alert.alert?.link}>
                  {alert.alert?.link_title
                    ? alert.alert?.link_title
                    : "More info"}
                  <ArrowRightIcon className="inline ml-4 h-4 w-4" />
                </Link>
              </p>
            )}
          </div>
        ))}
    </div>
  );
}
