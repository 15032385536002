import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
// import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthState } from "../context";
import { loadingColumns, loadingData } from "../config/constants";
import Table from "../components/Table";
import ReactPaginate from "react-paginate";
import { PlusIcon } from "@heroicons/react/outline";

// import { Link } from 'react-router-dom';

export default function Users() {
  const { no } = useParams();
  let history = useHistory();
  // let history = useHistory();
  // const http = global.services.http;
  // const dispatch = useAuthDispatch(); // read dispatch method from context
  const userDetails = useAuthState(); //read user details from context
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userDetails.role !== "admin") {
      history.push("/");
      return;
    }

    const http = global.services.http;
    const getUser = async () => {
      setLoading(true);
      var req = await http.get(
        `${process.env.REACT_APP_API}/users?page=${pageNo}`
      );
      console.log(req);
      if (req.data) {
        setUsers(req.data.data);
        setPageCount(req.data.meta.last_page + 1);
        setLoading(false);
      }
    };
    getUser();
  }, [pageNo]);

  const EditCell = ({ value, column, row }) => {
    return (
      <>
        {(userDetails.role === "admin" || userDetails.role === "librarian") && (
          <div className="flex">
            <Link className={"mr-2"} to={`/users/${row.original.id}`}>
              View
            </Link>
            <Link className={"mr-2"} to={`/users/${row.original.id}/edit`}>
              Edit
            </Link>
          </div>
        )}
      </>
    );
  };

  const RoleCell = ({ value, column, row }) => {
    return row.original.role?.role;
  };

  const StationsCell = ({ value, column, row }) => {
    return (
      <div className="flex flex-col">
        {row.original.stations?.map((station) => (
          <span key={`station-${station.id}`}>{station.name}</span>
        ))}
        {row.original.owned_stations?.map((station) => (
          <span key={`statiosn-${station.id}`}>{station.name} (Owned)</span>
        ))}
      </div>
    );
  };

  const handlePageClick = (event) => {
    console.log(event);
    setPageNo(event.selected + 1);
    history.push(`/users/${event.selected + 1}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: RoleCell,
      },
      {
        Header: "Stations",
        accessor: "created_at",
        Cell: StationsCell,
      },
      {
        Header: "Action",
        Cell: EditCell,
        accessor: "updated_at",
      },
    ],
    []
  );

  return (
    <Layout route="users" title={"User Management"}>
      <div
        className={"flex items-center  justify-between  content-evenly w-full"}
      >
        {(userDetails.role === "admin" || userDetails.role === "librarian") && (
          <div>
            <Link
              to={`/users/new`}
              className="bg-blue-500 hover:bg-grey text-white text-xs font-bold py-1 px-2 rounded inline-flex items-center"
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              <span>Add User</span>
            </Link>
          </div>
        )}
      </div>
      <div className="block w-full overflow-x-auto bg-white lg:p-4">
        <>
          {users && (
            <Table
              columns={loading ? loadingColumns : columns}
              data={loading ? loadingData : users}
            />
          )}
          <div className="bg-white px-0 py-3 flex items-center justify-between border-t border-gray-200 ">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount - 1}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName={"paginator"}
              forcePage={pageNo - 1}
            />
          </div>
        </>
      </div>
    </Layout>
  );
}
