import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthState } from "../context";
import {
  EyeIcon,
  PencilAltIcon,
  CheckIcon,
  XCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { loadingColumns, loadingData } from "../config/constants";
import { StationContext } from "../context/station-context";
import Table from "../components/Table";
import ReactPaginate from "react-paginate";
import NewPageHeader from "../components/Global/NewPageHeader";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import { Transition } from "@headlessui/react";
import GenreForm from "../components/forms/GenreForm";

// import { Link } from 'react-router-dom';

export default function Genres() {
  const { no, station_id } = useParams();
  const { station, setStation } = React.useContext(StationContext);
  let history = useHistory();
  const userDetails = useAuthState(); //read user details from context
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const [genres, setGenres] = useState([]);
  const [postPerPage] = useState(200);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);
  const [newGenre, setNewGenre] = useState(null);
  const [edit, setEdit] = useState(null);
  const [update, setUpdate] = React.useState(false);
  const http = global.services.http;

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [station]);

  const refresh = () => {
    setUpdate(!update);
    setEdit(null);
    setNewGenre(false);
  };

  useEffect(() => {
    const http = global.services.http;
    const getGenre = async () => {
      setLoading(true);
      var filter = JSON.stringify({ station_id: station_id });
      var req = await http.get(
        `${process.env.REACT_APP_API}/genres?filter=${filter}&page=${pageNo}&limit=${postPerPage}`
      );
      if (req.data) {
        setGenres(req.data.data);
        setPageCount(req.data.meta.last_page + 1);
        setLoading(false);
      }
    };
    if (thisStation) {
      getGenre();
    }
  }, [pageNo, thisStation, update]);

  const getGenre = async () => {
    setLoading(true);
    var filter = JSON.stringify({ station_id: station_id });
    var req = await http.get(
      `${process.env.REACT_APP_API}/genres?filter=${filter}&page=${pageNo}&limit=${postPerPage}`
    );
    if (req.data) {
      setGenres(req.data.data);
      setPageCount(req.data.meta.last_page + 1);
      setLoading(false);
    }
  };

  const handlePageClick = (event) => {
    console.log(event);
    setPageNo(event.selected + 1);
    history.push(`/station/${station_id}/genres/${event.selected + 1}`);
  };

  const NewModal = ({ episode, closeModal, setEpisode }) => (
    <>
      <Transition show={newGenre !== null}>
        {/* Background overlay */}
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={() => closeModal()}
            className=" max-w-screen fixed z-40 left-0 top-0 right-0 bg-gray-900 bg-opacity-70 bottom-0 "
          ></div>
        </Transition.Child>
        {/* Sliding sidebar */}
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className=" max-w-full fixed z-50  w-[400px] top-0 right-0 bg-white p-4 bottom-0  overflow-y-auto">
            <div className="w-full flex justify-between mb-4 items-center">
              <h2 className="font-bold">NEW GENRE</h2>
              <button onClick={() => closeModal()}>
                <XCircleIcon className="w-8 h-8 hover:text-gray-800" />
              </button>
            </div>
            <GenreForm station={station} onAdded={refresh} />
          </div>
          {/* ... */}
        </Transition.Child>
      </Transition>
    </>
  );

  const EditModal = ({ episode, closeModal, setEpisode }) => (
    <>
      <Transition show={edit !== null}>
        {/* Background overlay */}
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={() => closeModal()}
            className=" max-w-screen fixed z-40 left-0 top-0 right-0 bg-gray-900 bg-opacity-70 bottom-0 "
          ></div>
        </Transition.Child>
        {/* Sliding sidebar */}
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className=" max-w-full fixed z-50  w-[400px] top-0 right-0 bg-white p-4 bottom-0  overflow-y-auto">
            <div className="w-full flex justify-between mb-4 items-center">
              <h2 className="font-bold">EDIT GENRE</h2>
              <button onClick={() => closeModal()}>
                <XCircleIcon className="w-8 h-8 hover:text-gray-800" />
              </button>
            </div>
            <GenreForm station={station} onAdded={refresh} genre={edit} />
          </div>
          {/* ... */}
        </Transition.Child>
      </Transition>
    </>
  );

  const EditCell = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-end"}>
        {/* <Link
          className={
            "border-gray-600 border text-gray-600 rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
          }
          to={`/station/${station_id}/genre/${row.original.id}`}
        >
          <EyeIcon className="w-3 h-3" />
          View
        </Link> */}

        {station &&
          userDetails &&
          (userDetails.role === "admin" ||
            userDetails.userDetails.id === station.owner_id) && (
            <button
              className={
                "border-lightBlue-600 border text-lightBlue-600 rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
              }
              onClick={() => setEdit(row.original)}
            >
              <PencilAltIcon className="w-3 h-3" />
              Edit
            </button>
          )}
      </div>
    );
  };

  const ShowName = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-start"}>
        {row.original.image && row.original.image.url && (
          <img src={row.original.image.url} className={"w-4 mr-2"} />
        )}
        <Link
          className={"font-bold text-brandPrimary "}
          to={`/station/${station_id}/genre/${row.original.id}`}
        >
          {row.original.name}
        </Link>
      </div>
    );
  };

  const ShowPrograms = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2 justify-start"}>
        {row.original.programs && row.original.programs.length > 0 ? (
          <div className="flex flex-wrap gap-2 ">
            {row.original.programs.map((program, index) =>
              index < 3 ? (
                <p key={program.id}>
                  {program.name}{" "}
                  {index < 3 && index < row.original.programs?.length - 1
                    ? ", "
                    : ""}
                </p>
              ) : (
                ""
              )
            )}
            {row.original.programs.length > 3 && (
              <p>+ {row.original.programs.length - 3}</p>
            )}
          </div>
        ) : (
          <p>No Programs Attached</p>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Genre Name",
        accessor: "name",
        Cell: ShowName,
      },

      {
        Header: "Programs",
        accessor: "programs",
        Cell: ShowPrograms,
      },
      {
        Header: "Slug",
        accessor: "slug",
      },
      {
        Header: "Custom Order",
        accessor: "order",
      },
      {
        Header: "Action",
        accessor: "is_active",
        className: "text-right",
        Cell: EditCell,
      },
    ],
    []
  );

  const TableHeader = () => {
    return (
      <div
        className={
          "text-blueGray-700 text-sm font-bold flex justify-between items-center ml-auto"
        }
      >
        <span>{`Genres ${thisStation ? `for ${thisStation.name}` : ""}`}</span>
        {station &&
          userDetails &&
          (userDetails.role === "admin" ||
            userDetails.userDetails.id === station.owner_id) && (
            <button
              onClick={() => setNewGenre(true)}
              className="  default-button "
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              <span>Create Genre</span>
            </button>
          )}
      </div>
    );
  };

  return (
    <Layout
      route="genres"
      // title={`Genre ${thisStation ? `for ${thisStation.name}` : ""}`}
    >
      {newGenre && <NewModal closeModal={() => setNewGenre(false)} />}
      {edit !== null && <EditModal closeModal={() => setEdit(null)} />}
      <NewPageHeader
        title={
          thisStation ? (
            `Genres ${thisStation ? `for ${thisStation.name}` : ""}`
          ) : (
            <LoadingIndicator />
          )
        }
      />
      <div className="block w-full overflow-x-auto ">
        <>
          <TableHeader />
          <div className="h-8 w-full" />
          {genres && (
            <Table
              columns={loading ? loadingColumns : columns}
              data={loading ? loadingData : genres}
              // content={<TableHeader />}
            />
          )}
          <div className="mx-auto py-4 flex items-center justify-around">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount - 1}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName={"paginator"}
              forcePage={pageNo - 1}
            />
          </div>
        </>
      </div>
    </Layout>
  );
}
