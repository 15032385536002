import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LayoutLogin from "../components/LayoutLogin";
import { loginUser, useAuthState, useAuthDispatch } from "../context";

// import { Link } from 'react-router-dom';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  fName: yup.string().required(),
  lName: yup.string().required(),
  password: yup.string().required(),
  password_check: yup.string().required(),
});

export default function AcceptInvite() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [token, setToken] = useState(null);
  const [verified, setVerified] = useState(false);
  const [station, setStation] = useState(false);

  const http = global.services.http;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const initialization = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);

        const foundToken = urlSearchParams.get("requestToken");

        if (!foundToken) {
          setErrorMessage("Invalid reset link used.");
          return;
        }
        setToken(urlSearchParams.get("requestToken"));

        const req = await http.post(
          `${process.env.REACT_APP_API}/auth/verify-invite-token`,
          {
            requestToken: urlSearchParams.get("requestToken"),
          }
        );
        if (req) {
          if (req.data.verified === true) {
            setVerified(true);
            setStation(req.data.data);
          } else {
            setErrorMessage("Invalid Token.");
          }
        }

        // setState(prevState => ({ ...prevState, verifying: false }))
      } catch (error) {
        let errorMsg =
          error?.data.error ||
          "An unknown error occured. Please refresh the page and try again.";
      }
    };

    initialization();
  }, []);

  const registerSubmit = async (payload) => {
    console.log("Got here");
    if (payload.password !== payload.password_check) {
      setErrorMessage("Oops! Your password fields do not match");
      return;
    }
    const params = { ...payload, token: token };
    try {
      var req = await http.post(
        `${process.env.REACT_APP_API}/auth/register-invite`,
        params
      );

      if (req) {
        if (req.data.success === true) {
          setErrorMessage(req.data.message);
          reset();
          setVerified(false);
        } else {
          if (req.data.success === false) {
            if (req.data.errors && req.data.errors.email) {
              setErrorMessage(req.data.email[0]);
            } else if (req.data.errors && req.data.errors.password) {
              setErrorMessage(req.data.errors.password[0]);
            } else if (
              req.data.errors &&
              req.data.errors.password_confirmation
            ) {
              setErrorMessage(req.data.errors.password_confirmation[0]);
            } else {
              setErrorMessage(req.data.message);
            }
          }
        }
      }
    } catch (error) {
      setErrorMessage(
        "An error occured, please check your details and try again"
      );
      console.log(error);
    }
  };

  return (
    <LayoutLogin>
      <div className={"rounded bg-white p-4"}>
        {verified === true && (
          <div className={" login"}>
            <h1 className={"text-center font-bold mb-6"}>
              You are invited to work with {station}
            </h1>
            <p className="mb-6 text-center">
              Sign up now to start contributing
            </p>
            <form onSubmit={handleSubmit(registerSubmit)}>
              {" "}
              <div className="mb-8">
                <label
                  htmlFor="email"
                  className={`block font-bold text-sm mb-2 ${
                    errors.email ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500 ${
                    errors.email
                      ? "text-red-300 border-red-400"
                      : "text-gray-900 border-gray-900"
                  }`}
                  {...register("email")}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-2">
                    A valid email is required.
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="fName"
                  className={`block font-bold text-sm mb-2 ${
                    errors.fName ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="First Name"
                  className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500 ${
                    errors.fName
                      ? "text-red-300 border-red-400"
                      : "text-gray-900 border-gray-900"
                  }`}
                  {...register("fName")}
                />
                {errors.fName && (
                  <p className="text-red-500 text-sm mt-2">
                    A valid FirstName is required.
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="lName"
                  className={`block font-bold text-sm mb-2 ${
                    errors.lName ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="lName"
                  id="lName"
                  placeholder="Last Name"
                  className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500 ${
                    errors.lName
                      ? "text-red-300 border-red-400"
                      : "text-gray-900 border-gray-900"
                  }`}
                  {...register("lName")}
                />
                {errors.lName && (
                  <p className="text-red-500 text-sm mt-2">
                    A valid lastName is required.
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="password"
                  className={`block font-bold text-sm mb-2 ${
                    errors.password ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Your Password"
                  className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4 text-gray-900  placeholder-gray-500 ${
                    errors.password ? "border-red-400" : "border-gray-900"
                  }`}
                  {...register("password")}
                />
                {errors.password && (
                  <p className="text-red-500 text-sm mt-2">
                    Your password is required.
                  </p>
                )}
              </div>
              <div className="mb-8">
                <label
                  htmlFor="password_check"
                  className={`block font-bold text-sm mb-2 ${
                    errors.password_check ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password_check"
                  id="password_check"
                  placeholder="Confirm Your Password"
                  className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4 text-gray-900  placeholder-gray-500 ${
                    errors.password_check ? "border-red-400" : "border-gray-900"
                  }`}
                  {...register("password_check")}
                />
                {errors.password_check && (
                  <p className="text-red-500 text-sm mt-2">
                    Your password is required.
                  </p>
                )}
              </div>
              {errorMessage ? (
                <p className={"text-center text-red-500 mb-8"}>
                  {errorMessage}
                </p>
              ) : null}
              <button className="w-full inline-block bg-blue-900 text-white rounded shadow py-4 px-6 text-uppercase text-sm rounded-full">
                Register
              </button>
            </form>
            <p className="text-center mt-4">
              <a href="/login" className="font-bold">Already have an account? Login Here</a>
            </p>
          </div>
        )}
        {errorMessage && verified === false ? (
          <div>
            <p className={"text-center text-red-500 mb-8"}>{errorMessage}</p>
            <p className="text-center mt-4">
              <a href="/login" className="font-bold">Already have an account? Login here</a>
            </p>
          </div>
        ) : null}
      </div>
    </LayoutLogin>
  );
}
