import React from "react";
import Layout from "../components/Layout";
import ProfileForm from "../components/forms/ProfileForm";
import NewPageHeader from "../components/Global/NewPageHeader";

export default function Profile() {
  return (
    <Layout route="programs" title="Add New Program">
      <NewPageHeader title="Edit Profile" links={[]} />
      <ProfileForm />
    </Layout>
  );
}
