import React, { useContext, useEffect, useState } from "react";
import LoadingIndicator from "./Global/LoadingIndicator";
import Modal from "react-modal";
import StashFormEdit from "./forms/StashFormEdit";
import StashPlayoutPlayer from "./StashPlayerContainer/StashPlayoutPlayer";
import MultiTrackUpload from "./MultiTrackUploader";
import {
  PencilAltIcon,
  PlayIcon,
  PlusCircleIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { StationContext } from "../context/station-context";
import { RadioContext } from "../RadioContext";
import PlayButton from "./PlayButton";
import { Link } from "react-router-dom";

const customStyles = {
  content: {
    // top: "50%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function StashPlayoutWrapper() {
  //   const [stashes, setStashes] = useState([]);
  const [edit, setEdit] = useState(null);
  const [newItem, setNewItem] = useState(false);
  const {
    stashes,
    stashLoading,
    active,
    setActive,
    setOpenPlayout,
    playoutLayout,
  } = useContext(StationContext);

  const removeTrack = async (id) => {
    var newItems = active.filter((item) => item.id !== id);
    setActive(newItems);
  };

  const addTrack = async (track) => {
    var newItems = active.filter((item) => item.id === track.id);
    if (newItems.length < 1) setActive([...active, track]);
  };

  return (
    <div className="w-full">
      <div className={"sr-only lg:w-1/2 w-1/3"}></div>
      <div className="flex ">
        {active.length > 0 ? (
          active.map((item, i) => (
            <div
              className={`${
                playoutLayout === "1"
                  ? "w-full"
                  : playoutLayout === "2"
                  ? "w-full lg:w-1/2"
                  : "w-full lg:w-1/3"
              } playout mt-4 p-2`}
              key={`players${item.id}`}
            >
              <StashPlayoutPlayer
                removeTrack={removeTrack}
                track={item}
                index={`${item.id}`}
              />
            </div>
          ))
        ) : (
          <p className="p-4 rounded-xl w-full mb-4 bg-white shadow-xl">
            No tracks loaded yet.
            <br />
            {stashes.length > 0 && "Pick a track below and start broadcasting"}
          </p>
        )}
      </div>
      <div className=" bg-white rounded-xl shadow-xl p-4 relative">
        {stashLoading === true && (
          <div className=" flex items-center justify-center py-12 bg-white   bg-gray-800 bg-opacity-10 absolute left-0 right-0 bottom-0 top-0">
            <LoadingIndicator />
          </div>
        )}
        {stashes.length > 0 && (
          <p className="text-sm mb-4">
            Pick from your uploaded audio below to queue up tracks. Click{" "}
            <Link
              onClick={() => setOpenPlayout(false)}
              to={`/stash`}
              className={"font-bold"}
            >
              here
            </Link>{" "}
            to manage your track list.
          </p>
        )}
        <div className="overflow-scroll w-full">
          <table className={"border-collapse table-auto w-full text-sm"}>
            <thead>
              <tr>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Song
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Artist
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Album
                </th>
                <th></th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-right"></th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
              {stashes.length > 0 ? (
                stashes.map((stash, i) => (
                  <tr
                    key={`${i}s`}
                    className={
                      "w-full hover:bg-gray-50 transition-all cursor-pointer"
                    }
                    onClick={() => addTrack(stash)}
                  >
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {stash.title && stash.title}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
                      {stash.artist && stash.artist}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {stash.album && stash.album}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {stash.notes && stash.notes}
                    </td>
                    <td className="flex justify-end gap-2 p-4 ">
                      <PlusCircleIcon className="h-8 w-8" />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className={"text-center p-4"}>
                    You have no items in your stash just yet. You can start
                    adding track right away by clicking{" "}
                    <Link
                      onClick={() => setOpenPlayout(false)}
                      to={`/stash`}
                      className={"font-bold"}
                    >
                      here
                    </Link>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
