import React, { useContext, useEffect, useState } from "react";
import LoadingIndicator from "./Global/LoadingIndicator";
import Modal from "react-modal";
import StashFormEdit from "./forms/StashFormEdit";
import StashPlayoutPlayer from "./StashPlayerContainer/StashPlayoutPlayer";
import MultiTrackUpload from "./MultiTrackUploader";
import {
  PencilAltIcon,
  PlayIcon,
  PlusCircleIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { StationContext } from "../context/station-context";
import { RadioContext } from "../RadioContext";
import PlayButton from "./PlayButton";

const customStyles = {
  content: {
    // top: "50%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function StashPageWrapper() {
  //   const [stashes, setStashes] = useState([]);
  const [edit, setEdit] = useState(null);
  const [newItem, setNewItem] = useState(false);
  const {
    stashes,
    getStash,
    stashLoading,
    setStashLoading,
    active,
    setActive,
  } = useContext(StationContext);
  const { setTrack, track, playing, buffering } = useContext(RadioContext);
  const http = global.services.http;

  //   useEffect(() => {
  //     const http = global.services.http;
  //     const getStash = async () => {
  //       setStashLoading(true);
  //       var req = await http.get(`${process.env.REACT_APP_API}/stash?limit=1000`);
  //       if (req.data) {
  //         setStashes(req.data.data);
  //         setStashLoading(false);
  //       }
  //     };

  //     getStash();
  //   }, []);

  const deleteTrack = async (id) => {
    setStashLoading(true);
    var req = await http.delete(`${process.env.REACT_APP_API}/stash/${id}`);
    if (req.data) {
      getStash();
    }
  };

  const removeTrack = async (id) => {
    var newItems = active.filter((item) => item.id !== id);
    setActive(newItems);
  };

  const addTrack = async (track) => {
    var newItems = active.filter((item) => item.id === track.id);
    if (newItems.length < 1) setActive([...active, track]);
  };

  return (
    <div>
      <Modal
        isOpen={edit !== null ? true : false}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setEdit(null);
          getStash();
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <StashFormEdit
          id={edit}
          onRequestClose={() => {
            setEdit(null);
            getStash();
          }}
        />
      </Modal>
      <Modal
        isOpen={newItem}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setNewItem(false);
          getStash();
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <MultiTrackUpload
          onComplete={() => {
            setNewItem(null);
            getStash();
          }}
          maxNoFiles={stashes ? 10 - stashes.length : 10}
        />
      </Modal>

      <div className=" bg-white rounded-xl shadow-xl p-4 relative">
        {stashLoading === true && (
          <div className=" flex items-center justify-center py-12 bg-white   bg-gray-800 bg-opacity-10 absolute left-0 right-0 bottom-0 top-0">
            <LoadingIndicator />
          </div>
        )}
        <p className="text-sm mb-4">
          Here you can manage your personal collection of tracks. Think of it
          like bringing a CD or Record into the station, but with digital audio
          instead!
        </p>
        <p className="text-sm mb-8">
          Once you have built up a playlist below, you can click on the Studio
          link in the menu and begin broadcasting.
        </p>
        <div className="overflow-scroll w-full">
          <table className={"border-collapse table-auto w-full text-sm"}>
            <thead>
              <tr>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Song
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Artist
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Album
                </th>
                <th></th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-right">
                  {stashes && stashes.length < 10 ? (
                    <button
                      onClick={() => setNewItem(true)}
                      className="py-1 px-3 rounded-lg bg-blue-500 text-white flex gap-2 items-center ml-auto hover:bg-blue-900 transition-all"
                    >
                      Add <PlusIcon className="w-6 h-6" />
                    </button>
                  ) : (
                    "Limit reached, remove to add new items"
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
              {stashes.length > 0 ? (
                stashes.map((stash, i) => (
                  <tr key={`${i}s`} className={"w-full"}>
                    {/* <audio controls src={stash.url}>
                    <a href={stash.url}>Download audio</a>
                  </audio> */}
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {stash.title && stash.title}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
                      {stash.artist && stash.artist}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {stash.album && stash.album}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {stash.notes && stash.notes}
                    </td>
                    <td className="flex justify-end gap-2 p-4 ">
                      <button onClick={() => setTrack(stash)}>
                        <PlayIcon className="h-8 w-8" />
                      </button>
                      <button onClick={() => setEdit(stash.id)}>
                        <PencilAltIcon className="h-8 w-8" />
                      </button>

                      <button onClick={() => deleteTrack(stash.id)}>
                        <TrashIcon className="w-6 h-6 inline" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className={"text-center p-8"}>
                    You have no items in your stash, start by uploading some
                    audio now.
                    <br />
                    <br />
                    <button
                      onClick={() => setNewItem(true)}
                      className="py-1 px-3 rounded-lg bg-blue-500 text-white flex gap-2 items-center mx-auto hover:bg-blue-900 transition-all"
                    >
                      Add Tracks <PlusIcon className="w-6 h-6" />
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
