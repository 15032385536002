import React from "react";
import { useHistory, Link } from "react-router-dom";

export default function NewPageHeader({
  title = null,
  links = [],
  linkBlock = null,
}) {
  return (
    <div
      className={
        " flex flex-col md:flex-row justify-between items-center mb-6 border-b border-gray-200 pb-6"
      }
    >
      <span className="text-2xl md:text-4xl font-light">{title}</span>
      {links && links.length > 0 ? (
        <div className="flex items-center justify-end gap-1">
          {links.map((link, i) => {
            return (
              <Link
                key={`${i}link`}
                to={link.link}
                className="  default-button "
              >
                {link.name}
              </Link>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {linkBlock && linkBlock}
    </div>
  );
}
