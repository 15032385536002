import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { PencilAltIcon, XCircleIcon } from "@heroicons/react/outline";
import NewMediaUpload from "../NewMediaUpload";
import * as yup from "yup";
import Spinner from "../Spinner";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  title: yup.string(),
  description: yup.string(),
});

export default function EpisodeForm({
  id = null,
  stationId = null,
  onUpdate = null,
}) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const { station, setStation } = React.useContext(StationContext);
  // const [thisStation, setThisStation] = React.useState(
  //   station ? station : null
  // );
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const [episode, setEpisode] = useState(null);
  const [hideTimes, setHideTimes] = useState(
    episode && episode.hide_times === 1 ? 1 : 0
  );
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${stationId}`
      );
      if (req.data) {
        setStation(req.data.data);
        console.log("Updates station");
      }
    };

    if (!station) {
      getStation();
    }
  }, [stationId]);

  useEffect(() => {
    const http = global.services.http;
    const getEpisode = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/episodes/${id}`);
      if (req) {
        setEpisode(req.data.data);
        setHideTimes(req.data.data.hide_times ? req.data.data.hide_times : 0);
        if (req.data.data.image) {
          setMedia(req.data.data.image);
        }
      }
    };
    if (id) {
      getEpisode();
    }
  }, [id]);

  const episodeSubmit = async (payload) => {
    console.log("loading", loading);
    if (loading === true) return;
    let fields = { ...payload };
    fields.hide_times = hideTimes;
    if (media) {
      fields.media_id = media.id;
    }
    setLoading(true);
    try {
      if (id) {
        var req = await http.put(
          `${process.env.REACT_APP_API}/episodes/${id}`,
          fields
        );
      } else {
        var req = await http.post(
          `${process.env.REACT_APP_API}/episodes`,
          fields
        );
      }

      if (req.status === 422) {
        if (req.data.data) {
          if (req.data.data.title) {
            console.log("Got here", req.data.data.title);
            setError("title", {
              type: "manual",
              message: req.data.data.title[0],
            });
          }
          if (req.data.data.description) {
            setError("description", {
              type: "manual",
              message: req.data.data.description[0],
            });
          }
        }

        setLoading(false);
        return;
      } else {
        if (onUpdate) {
          console.log("here", req.data.data);
          onUpdate(req.data.data);
        }
        setEpisode(req.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  //   console.log(watch("email"));
  // const Header = () => (
  //   <p className={"text-lg font-bold"}>{`Edit Episode: ${
  //     id
  //       ? episode && episode.title
  //         ? episode.title
  //         : "Episode"
  //       : "Add New Episode"
  //   }`}</p>
  // );

  return (
    <div className={" w-full "}>
      {/* <SectionHeader content={<Header />} /> */}
      <div className="flex flex-col gap-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        {!id || episode ? (
          <>
            <div className="w-full ">
              <label
                htmlFor="name"
                className={`block font-bold text-sm mb-2  `}
              >
                Upload An Image:
              </label>

              {media && media.url ? (
                <div className={"text-center w-full relative group"}>
                  <button
                    className="absolute top-0 right-0 hidden group-hover:flex bg-blue-500 text-white p-1"
                    onClick={() => setMedia(null)}
                  >
                    <XCircleIcon className={"w-5 h-5"} />
                  </button>
                  <img
                    className={"max-w-full ml-auto mr-auto mt-4 mb-4"}
                    src={media.url}
                    alt={"for this episode"}
                  />
                </div>
              ) : (
                <NewMediaUpload
                  onComplete={setMedia}
                  accept={"image/*"}
                  type="s3"
                  max={1363148.8}
                  title=""
                />
              )}
            </div>
            <form className="w-full " onSubmit={handleSubmit(episodeSubmit)}>
              <div className="mb-8">
                <label
                  htmlFor="title"
                  className={`block font-bold text-sm mb-2 ${
                    errors.title ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Label this episode with a unique title:
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  defaultValue={episode && episode.title}
                  placeholder="eg. Episode 1: The Beginning of Time"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.title ? "text-red-300  " : " "
                  }`}
                  {...register("title")}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm mt-2">
                    Oops, there was something wrong with this name, perhaps this
                    episode already exists?
                  </p>
                )}
              </div>

              <div className="mb-8">
                <label
                  htmlFor="description"
                  className={`block font-bold text-sm mb-2 ${
                    errors.description ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Episode Description
                </label>
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  rows={8}
                  defaultValue={episode && episode.description}
                  placeholder="On today's episode we interviewed the president of the world and he told us about his plans for the future. We also had a special guest who told us about the future of the world and how we can all be a part of it."
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.description ? "border-red-400 " : " "
                  }`}
                  {...register("description")}
                />
                {errors.description && (
                  <p className="text-red-500 text-sm mt-2">
                    {errors.description.message}
                  </p>
                )}
              </div>
              <div className={"mb-8"}>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4"
                    onChange={(e) => {
                      setHideTimes(e.target.checked === true ? 1 : 0);
                    }}
                    defaultChecked={episode && episode.hide_times === 1}
                  />
                  <span className="ml-2">Hide times on playlist?</span>
                </label>
              </div>
              <button className="w-full flex flax gap-4 py-3 bg-purple-500 text-white hover:bg-puurple-700 transition-all items-center justify-center rounded-md">
                {!id ? "Create New Episode" : "Update Episode Details"}
                {loading ? (
                  <Spinner className="w-8 h-8  " />
                ) : (
                  <PencilAltIcon className="w-8 h-8  " />
                )}
              </button>
            </form>
          </>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
