import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ProgramAutoComplete from "../ProgramAutoComplete";
import { XCircleIcon } from "@heroicons/react/outline";
import ReactQuill, { Quill } from "react-quill";

function CategoryForm({
  station = null,
  onAdded = console.log,
  category = null,
}) {
  console.log("category", category);
  const schema = category
    ? yup
        .object()
        .shape({
          name: yup.string().required("Required"),
          order: yup.number(),
        })
        .required()
    : yup
        .object()
        .shape({
          name: yup.string().required("Required"),
          order: yup.number(),
        })
        .required();

  // const userDetails = useAuthState(); //read user details from context
  // let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(
    category ? category.description : ""
  );
  const quilRef = useRef(null);
  const [programs, setPrograms] = useState(
    category && category.programs ? category.programs : []
  );
  const [active, setActive] = useState(
    category && category.active ? category.active : true
  );
  const http = global.services.http;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: category && category.name ? category.name : "",
      order: category && category.order ? category.order : 0,
    },
  });

  const submitForm = async (data) => {
    let req;
    if (loading === true) return;
    setLoading(true);

    data.programs = programs;

    const programIds = programs.map((p) => p.id);

    // formData.append(`programs`, JSON.stringify(programIds));
    // formData.append("name", name);
    data.programs = programIds;
    data.description = description;
    data.station_id = station.id;
    try {
      if (category !== null) {
        req = await http.put(
          `${process.env.REACT_APP_API}/categories/${category.id}`,
          data
        );
      } else {
        req = await http.post(`${process.env.REACT_APP_API}/categories`, data);
      }

      if (req.data.success === false) {
        console.log(req.data.error);
        setLoading(false);
        if (req.data.data.name)
          setError("name", { message: req.data.data.name[0] });
        if (req.data.data.description)
          setError("description", { message: req.data.data.description[0] });
        if (req.data.data.order) {
          alert(req.data.data.order[0]);
          setError("order", { message: req.data.data.order[0] });
        }
        return;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      // setError("An error occurred. Please try again.");
      return;
    }

    setLoading(false);

    if (onAdded) {
      console.log("onAdded", req.data);
      onAdded(req.data);
    }
  };

  const addProgram = (program) => {
    const checkProgramDoesNotExist = programs.filter(
      (p) => p.id === program.id
    );
    if (checkProgramDoesNotExist.length === 0)
      setPrograms([program, ...programs]);
  };

  const removeProgram = (e, program_id) => {
    e.preventDefault();

    const checkProgramDoesNotExist = programs.filter(
      (p) => p.id !== program_id
    );
    setPrograms(checkProgramDoesNotExist);
  };

  return (
    <div>
      <div className="w-full  ">
        <form
          onSubmit={handleSubmit(submitForm)}
          className="flex flex-col gap-4 "
        >
          <div className="mb-8">
            <label
              htmlFor="name"
              className={`block font-bold text-sm mb-2 ${
                errors.name ? "text-red-400" : "text-gray-800"
              }`}
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={category && category.name}
              placeholder="Category"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                errors.name ? "text-red-300  " : " "
              }`}
              {...register("name")}
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-2">{errors.name.message}</p>
            )}
          </div>
          <div className="mb-8">
            <label
              htmlFor="order"
              className={`block font-bold text-sm mb-2 ${
                errors.order ? "text-red-400" : "text-gray-800"
              }`}
            >
              Order
            </label>
            <input
              type="text"
              name="order"
              id="order"
              defaultValue={category && category.order}
              placeholder="Custom Order"
              className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                errors.order ? "text-red-300  " : " "
              }`}
              {...register("order")}
            />
            {errors.order && (
              <p className="text-red-500 text-sm mt-2">
                {errors.order.message}
              </p>
            )}
          </div>

          <div className="mb-8 ">
            <label
              htmlFor="description"
              className={`block font-bold text-sm mb-2 ${
                errors.description ? "text-red-400" : "text-gray-900"
              }`}
            >
              Category Description
            </label>
            <div className="bg-white">
              <ReactQuill
                theme="snow"
                defaultValue={category ? category.description : " "}
                onChange={setDescription}
                ref={quilRef}
                modules={CategoryForm.modules}
                formats={CategoryForm.formats}
              />
            </div>
            {errors.bio && (
              <p className="text-red-500 text-sm mt-2">{errors.bio.message}</p>
            )}
          </div>

          <div>
            <ProgramAutoComplete onSet={addProgram} station={station} />
            {programs && programs.length > 0 ? (
              <div className="flex gap-4 flex-wrap pt-4">
                {programs.map((program) => (
                  <button
                    className="bg-pink-500 text-white rounded-full px-4 py-2 text-sm flex items-center gap-2 hover:bg-pink-600 transition-all"
                    key={program.id}
                    onClick={(e) => removeProgram(e, program.id)}
                  >
                    {program.name}
                    <XCircleIcon className="h-4 w-4" />
                  </button>
                ))}
              </div>
            ) : (
              <p>No programs added</p>
            )}
          </div>

          <button
            className={`flex items-center justify-center w-full bg-blue-800 transition-colors text-white rounded shadow py-2 px-5 text-sm ${
              loading === true ? `opacity-70` : `hover:bg-blue-600`
            }`}
          >
            {loading === true
              ? "Loading...."
              : category
              ? "Edit Category"
              : "Create Category"}
          </button>
        </form>
      </div>
    </div>
  );
}

CategoryForm.modules = {
  toolbar: [
    [{ header: [1, 2, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
CategoryForm.formats = [
  "header",
  "font",
  "background",
  "color",
  "code",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "script",
  "align",
  // "direction",
  "link",
  "image",
  "code-block",
  "formula",
  "audio",
];

export default CategoryForm;
