import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { StationContext } from "../context/station-context";
import UserList from "../components/Users/UserList";
import * as yup from "yup";
import SectionHeader from "../components/SectionHeader";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import { useAuthState } from "../context";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  first_name: yup.string(),
  last_name: yup.string(),
  role: yup.string(),
});

export default function StationUsers() {
  let history = useHistory();
  const userDetails = useAuthState();
  const { station_id, no } = useParams();
  const { station, setStation } = React.useContext(StationContext);

  const [loading, setLoading] = useState(null);
  const [users, setUsers] = useState([]);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);

  const http = global.services.http;
  const {
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      setLoading(true);
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setStation(req.data.data);
      }
    };

    const getUsers = async () => {
      setLoading(true);
      var req = await http.get(
        `${process.env.REACT_APP_API}/station/users/${station_id}?limit=200`
      );
      if (req.data) {
        setUsers(req.data.data);
        setLoading(false);
      }
    };

    if (!station) {
      getStation();
    } else {
      getUsers();
      // setThisStation()
    }
    if (userDetails && station) {
      if (userDetails.role === "admin") {
      } else if (userDetails.userDetails.id !== station.owner_id) {
        history.push("/");
      }
    }
  }, [station_id, station, userDetails]);

  const getUsers = async () => {
    setLoading(true);
    var req = await http.get(
      `${process.env.REACT_APP_API}/station/users/${station_id}?page=${pageNo}&limit=300`
    );
    if (req.data) {
      setUsers(req.data.data);
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    if (window.confirm("Are you sure you want to remove this user?") !== true) {
      return;
    }
    setLoading(true);
    var req = await http.delete(
      `${process.env.REACT_APP_API}/station/users/${id}/${station_id}`
    );
    if (req.data) {
      getUsers();
    }
  };
  const handlePageClick = (event) => {
    setPageNo(event.selected + 1);
    history.push(`/station/${station.id}/users/${event.selected + 1}`);
  };

  // const userSubmit = async (payload) => {
  //   if (loading === true) return;

  //   // handle submitting the form
  //   console.log(payload);

  //   let fields = { ...payload, station_id: station.id };

  //   setLoading(true);
  //   try {
  //     var req = await http.post(`${process.env.REACT_APP_API}/station/user`, {
  //       ...fields,
  //     });
  //     if (!req) return;
  //     setLoading(false);
  //     //   history.push(`/users`);
  //   } catch (error) {
  //     console.log(error);
  //     setErrorMessage(error);
  //   }
  // };

  return (
    <Layout
      title={`Station ${station && station.name} Presenters`}
      route="users"
    >
      {loading && (
        <div className="p-2 w-full flex justify-center">
          <LoadingIndicator />
        </div>
      )}
      {station && users && loading !== true && (
        <>
          <SectionHeader
            content={
              station && (
                <div className={"font-bold text-left text-2xl"}>
                  Station Presenters - {station.name}
                </div>
              )
            }
          />
          <div className="w-full bg-white p-4">
            <Link
              to={`/station/${station.id}/invites`}
              className={"font-bold mb-4"}
            >
              Click here to invite presenters to use your station.
            </Link>
          </div>
          <UserList
            station={station}
            users={users}
            pageNo={pageNo}
            setPageNo={setPageNo}
            pageCount={pageCount}
            setPageCount={setPageCount}
            no={no}
            handlePageClick={handlePageClick}
            loading={loading}
            setLoading={setLoading}
            reload={getUsers}
            deleteUser={deleteUser}
          />

          {users.length === 0 && loading === false && (
            <div className="p-4 bg-white rounded-xl shadow-lg text-center">
              No Users have access yet.
            </div>
          )}
        </>
      )}
    </Layout>
  );
}
