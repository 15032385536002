import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { StationContext } from "../../context/station-context";
import * as yup from "yup";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../Spinner";
import { PlusCircleIcon } from "@heroicons/react/outline";
const defaultValues = {
  artist: "",
  title: "",
  artist: "",
  air_time: new Date(),
};

export default function AddLog({
  logs,
  setLogs,
  activeEpisode,
  log = null,
  onComplete = null,
  closeModal = null,
}) {
  const { station } = React.useContext(StationContext);
  const http = global.services.http;
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(null);
  const [now, setNow] = useState(false);
  const schema = yup.object().shape({
    title: yup.string(),
    artist: yup.string(),
  });
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const local =
    log && log.air_time
      ? new Date(moment.utc(log.air_time).tz(station.timezone))
      : new Date();

  const logSubmit = async (formFields) => {
    if (loading === true) return;
    setErrorMessage(null);
    let payload = { ...formFields };
    // handle submitting the form
    payload.episode_id = activeEpisode.id;
    payload.station_id = activeEpisode.station.id;
    if (!log) {
      payload.orderNo = logs.length;
    }
    if (!log) payload.orderBy = logs.length + 1;

    setLoading(true);
    try {
      var req;
      if (log !== null) {
        req = await http.put(`${process.env.REACT_APP_API}/logs/${log.id}`, {
          ...payload,
        });
      } else {
        req = await http.post(`${process.env.REACT_APP_API}/logs`, {
          ...payload,
        });
      }
      // if (!req) return;

      if (req.status === 422) {
        if (req.data.errors) {
          if (req.data.errors.title) {
            // console.log(req.data.errors.title[0]);
            setError("title", {
              type: "manual",
              message: req.data.errors.title[0],
            });
            setErrorMessage(req.data.errors.title[0]);
          }
          if (req.data.errors.artist) {
            setError("artist", {
              type: "manual",
              message: req.data.errors.artist[0],
            });
            setErrorMessage(req.data.errors.artist[0]);
          }
          if (req.data.errors.location) {
            setError("location", {
              type: "manual",
              message: "Please select a location",
            });
            setErrorMessage(req.data.errors.location[0]);
          }
        }
        setLoading(false);
      } else {
        setErrorMessage(null);
        // alert("we good");
        // history.push(`/cart/${req.data.data.id}`);
        if (req.data.data) {
          if (log !== null) {
            const updatedLogs = await logs.map((item) =>
              item.id === log.id ? req.data.data : item
            );
            await setLogs(updatedLogs);
          } else {
            const newLogs = [...logs, req.data.data];
            setLogs(newLogs);
            reset({
              artist: "",
              title: "",
              album: "",
              label: "",
              release_date: null,
              air_time: new Date(),
            });
            sendGaEvent("log_added", req.data.data);
          }
        }
        setLoading(false);
        if (onComplete !== null) {
          onComplete(req.data.data);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("Went bad here", error);
    }
  };

  const setToNow = (e) => {
    e.preventDefault();
    setValue("air_time", new Date());
  };

  const sendGaEvent = (action, data = {}) => {
    window.gtag("event", action, {
      ...data,
      episode: activeEpisode.id,
      program_id: activeEpisode.program.id,
      program: activeEpisode.program.name,
      station_id: activeEpisode.station.id,
      station_id: activeEpisode.station.name,
    });
  };

  return (
    <div className={"w-full "}>
      {/* {errorMessage ? <p>{errorMessage}</p> : null} */}
      <form
        onSubmit={handleSubmit(logSubmit)}
        className="block lg:flex gap-3 items-center justify-between"
      >
        <div className="flex gap-4 w-full justify-between">
          <div className="flex flex-col flex-grow gap-4">
            <div className="flex flex-col lg:flex-row gap-4 items-center justify-between lg:flex-grow w-full">
              {activeEpisode.hide_times !== 1 && (
                <div className=" w-full flex flex-col gap-2">
                  <div className="flex gap-2 items-center justify-start">
                    <label className=" text-sm font-bold">Air Time:</label>
                    <button
                      className="font-bold text-xs hover:text-gray-700 "
                      onClick={(e) => setToNow(e)}
                    >
                      (Now)
                    </button>
                  </div>
                  <Controller
                    control={control}
                    name="air_time"
                    className="input"
                    defaultValue={local}
                    placeholderText="Select date"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="Pp"
                      />
                    )}
                  />
                </div>
              )}
              <div className=" w-full">
                <label
                  htmlFor="title"
                  className={`block font-bold text-sm mb-2 ${
                    errors.title ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Title:
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  defaultValue={log ? log.title : ""}
                  placeholder="Title"
                  className={`border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700  ${
                  errors.title ? "text-red-300 border-red-400" : " "
                }`}
                  {...register("title")}
                />
                {errors.title && (
                  <p className="text-red-500 text-sm mt-2">
                    A valid title is required.
                  </p>
                )}
              </div>
              <div className=" w-full">
                <label
                  htmlFor="artist"
                  className={`block font-bold text-sm mb-2 ${
                    errors.artist ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Artist
                </label>
                <input
                  type="text"
                  name="artist"
                  id="artist"
                  defaultValue={log ? log.artist : ""}
                  placeholder="Artist"
                  className={`border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700 ${
                  errors.artist ? "border-red-400" : " "
                }`}
                  {...register("artist")}
                />
                {errors.artist && (
                  <p className="text-red-500 text-sm mt-2">
                    An Artist is required
                  </p>
                )}
              </div>
              <div className=" w-full">
                <label
                  htmlFor="album"
                  className={`block font-bold text-sm mb-2 ${
                    errors.album ? "text-red-400" : "text-gray-900"
                  }`}
                >
                  Album
                </label>
                <input
                  type="text"
                  name="album"
                  id="album"
                  defaultValue={log ? log.album : ""}
                  placeholder="Album"
                  className={`border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700 ${errors.album ? "border-red-400" : " "}`}
                  {...register("album")}
                />
                {errors.album && (
                  <p className="text-red-500 text-sm mt-2">
                    An Album is required
                  </p>
                )}
              </div>
            </div>
            <div>
              <div className="flex flex-col lg:flex-row  items-center justify-between gap-4 lg:gap-4 w-full  flex-grow">
                <div className="w-full lg:w-auto">
                  <label
                    htmlFor="label"
                    className={`block font-bold text-sm mb-2 ${
                      errors.album ? "text-red-400" : "text-gray-900"
                    }`}
                  >
                    Record Label
                  </label>
                  <input
                    type="text"
                    name="label"
                    id="label"
                    defaultValue={log ? log.label : ""}
                    placeholder="Record Label"
                    className={`border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700 ${errors.label ? "border-red-400" : " "}`}
                    {...register("label")}
                  />
                  {errors.label && (
                    <p className="text-red-500 text-sm mt-2">
                      A Record Label is required
                    </p>
                  )}
                </div>
                <div className="w-full lg:w-auto flex flex-col gap-2">
                  <label className="w-full text-sm font-bold">
                    Release Date:
                  </label>
                  <Controller
                    control={control}
                    name="release_date"
                    className="input"
                    placeholderText="Select date"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        showTimeSelect={false}
                        dateFormat={"yyyy-MM-dd"}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col gap-4 flex-grow w-full lg:w-auto">
                  <div>
                    <label className="block font-bold text-sm mb-2">
                      Location:
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <div className="form-check">
                      <input
                        className="form-check-input  rounded-full h-4 w-4 border border-gray-300 bg-white   focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        {...register("location")}
                        type="radio"
                        value="loc"
                        defaultChecked={log && log.location === "loc"}
                      />

                      <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="flexRadioDefault1"
                      >
                        Local
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input  rounded-full h-4 w-4 border border-gray-300 bg-white   focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        {...register("location")}
                        type="radio"
                        value="nat"
                        defaultChecked={log && log.location === "nat"}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="flexRadioDefault2"
                      >
                        National
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input  rounded-full h-4 w-4 border border-gray-300 bg-white   focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        {...register("location")}
                        type="radio"
                        value="int"
                        defaultChecked={log && log.location === "int"}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="flexRadioDefault2"
                      >
                        International
                      </label>
                    </div>

                    {errors && errors.location && (
                      <p className="text-red-400 mb-4">
                        Please select a location
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex-grow mt-4 lg:mt-0">
                  <button className="mx-auto lg:ml-auto lg:mr-0 uppercase whitespace-nowrap flex  items-center gap-4 bg-purple-500 transition-all hover:bg-purple-800 text-white rounded shadow py-2 px-5 text-lg">
                    {log ? "Edit Log" : "Add Log"}
                    {loading ? (
                      <Spinner className="w-6 h-6 " />
                    ) : (
                      <PlusCircleIcon className="w-6 h-6 " />
                    )}
                  </button>
                  {log && (
                    <button
                      className="ml-4 inline-block bg-red-500 text-white rounded shadow py-1 px-3 text-xs"
                      onClick={() => onComplete()}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
