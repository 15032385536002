import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
// import axios from "axios";
import { StationContext } from "../context/station-context";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthState } from "../context";
import { loadingColumns, loadingData } from "../config/constants";
import Table from "../components/Table";
import ReactPaginate from "react-paginate";
import {
  ViewListIcon,
  EyeIcon,
  ClockIcon,
  PencilAltIcon,
  ArchiveIcon,
  ChartBarIcon,
  UsersIcon,
  SaveAsIcon,
  SaveIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import StationRow from "../components/StationRow";
import StationCachedReport from "../components/StationCachedReport";

// import { Link } from 'react-router-dom';

export default function Home() {
  const { no } = useParams();
  let history = useHistory();
  const http = global.services.http;
  const { setStation, stationOwner } = React.useContext(StationContext);
  const userDetails = useAuthState(); //read user details from context
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);

  const getStation = async () => {
    setLoading(true);
    var req = await http.get(
      `${process.env.REACT_APP_API}/stations?page=${pageNo}&limit=50`
    );
    if (req.data) {
      // setStation(null);
      setStations(req.data.data);
      if (req.data.data.length === 1) {
        setStation(req.data.data[0]);
      }
      setPageCount(req.data.meta.last_page + 1);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getStation = async () => {
      setLoading(true);
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations?page=${pageNo}&limit=50`
      );
      if (req.data) {
        // setStation(null);
        setStations(req.data.data);
        if (req.data.data.length === 1) {
          setStation(req.data.data[0]);
        }
        setPageCount(req.data.meta.last_page + 1);
        setLoading(false);
      }
    };
    getStation();
  }, [pageNo]);

  const changeStation = async (station) => {
    if (!station) {
      alert("please select a station on the homepage first");
      history.push(`/`);
    }
    await setStation(station);
  };

  const deleteStation = async (e, id) => {
    e.preventDefault();

    if (window.confirm("Are you sure? this cannot be undone") == true) {
      setLoading(true);
      var req = await http.delete(
        `${process.env.REACT_APP_API}/stations/${id}`
      );
      getStation();
    } else {
    }
  };

  const EditCell = ({ value, column, row }) => {
    const { setStation } = React.useContext(StationContext);
    return (
      userDetails && (
        <div className={"flex items-center flex-wrap gap-2 justify-end"}>
          <Link
            className={
              "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
            }
            to={`/station/${row.original.id}`}
            onClick={() => changeStation(row.original)}
          >
            <EyeIcon className={"w-3 h-3"} />
            Station
          </Link>
          <Link
            className={
              "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
            }
            onClick={() => changeStation(row.original)}
            to={`/station/${row.original.id}/episodes`}
          >
            <ArchiveIcon className={"w-3 h-3"} />
            Episodes
          </Link>

          <button
            className={
              "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
            }
            onClick={() => changeStation(row.original)}
          >
            <ViewListIcon className={"w-3 h-3"} /> Programs
          </button>
          {/* 
        {((userDetails &&
          userDetails.userDetails.id === row.original.owner_id) ||
          userDetails.role === "admin") && (
          <Link
            to={`/station/${row.original.id}/posts`}
            className={
              "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
            }
          >
            <PencilIcon className={"w-3 h-3"} /> Blog
          </Link>
        )} */}
          {/* <Link className={"mr-2"} to={`/station/${row.original.id}/episodes`}>
          Episodes
        </Link> */}
          {((userDetails &&
            userDetails.userDetails !== "" &&
            userDetails.userDetails.id === row.original.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              className={
                "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
              }
              to={`/station/${row.original.id}/edit`}
            >
              <PencilAltIcon className={"w-3 h-3"} />
              Edit
            </Link>
          )}

          {((userDetails &&
            userDetails.userDetails !== "" &&
            userDetails.userDetails.id === row.original.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              className={
                "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
              }
              to={`/station/${row.original.id}/grid`}
            >
              <ClockIcon className={"w-3 h-3"} /> Grid
            </Link>
          )}
          {row.original.hls_stream && (
            <Link
              className={
                "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
              }
              to={`/station/${row.original.id}/segments`}
            >
              <SaveIcon className={"w-3 h-3"} /> Download Audio
            </Link>
          )}

          {((userDetails &&
            userDetails.userDetails !== "" &&
            userDetails.userDetails.id === row.original.owner_id) ||
            userDetails.role === "admin") &&
            row.original.hls_stream && (
              <Link
                className={
                  "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
                }
                to={`/station/${row.original.id}/replace`}
              >
                <SaveAsIcon className={"w-3 h-3"} /> Replace Audio
              </Link>
            )}
          {/* 
          {row.original.stats_enabled === 1 && (
            <Link
              className={
                "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
              }
              to={`/station/${row.original.id}/reports`}
            >
              <ChartBarIcon className={"w-3 h-3"} /> Reports (beta)
            </Link>
          )} */}

          {((userDetails &&
            userDetails.userDetails !== "" &&
            userDetails.userDetails.id === row.original.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              className={
                "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
              }
              to={`/station/${row.original.id}/invites`}
            >
              <UsersIcon className={"w-3 h-3"} /> Invites
            </Link>
          )}
          {((userDetails &&
            userDetails.userDetails !== "" &&
            userDetails.userDetails.id === row.original.owner_id) ||
            userDetails.role === "admin") && (
            <button
              className={
                "border-gray-600 border text-gray-600 hover:bg-gray-600 hover:text-white  rounded shadow py-1 px-2 text-uppercase text-xs items-center   flex gap-2"
              }
              onClick={(e) => deleteStation(e, row.original.id)}
            >
              <TrashIcon className={"w-3 h-3"} /> Delete Station
            </button>
          )}
        </div>
      )
    );
  };

  const handlePageClick = (event) => {
    console.log(event);
    setPageNo(event.selected + 1);
    history.push(`/stations/${event.selected + 1}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Station Name",
        accessor: "name",
      },
      {
        Header: "Slug",
        accessor: "slug",
      },
      {
        Header: "Action",
        Cell: EditCell,
        accessor: "updated_at",
        className: "text-right",
      },
    ],
    []
  );

  const TableHeader = () => {
    return (
      <>
        <div
          className={
            "  flex justify-between items-center mb-6 border-b border-gray-200 pb-6"
          }
        >
          <span className="text-2xl md:text-4xl font-light">My Stations</span>

          <Link
            to={`/station/new`}
            className=" bg-brandPrimary text-white rounded shadow py-2 px-6 flex items-center gap-2 text-sm font-bold"
          >
            {/* <FontAwesomeIcon className="w-4 h-4 " icon={faPlus} /> */}
            <span>Create a station</span>
          </Link>
        </div>
      </>
    );
  };

  React.useEffect(
    (_) => {
      const http = global.services.http;
      // updatePlaylists();
    },
    [userDetails]
  );

  return (
    <Layout title={"My Stations"} route="stations">
      <TableHeader />
      <div className="block w-full overflow-x-auto    ">
        {loading === true && <LoadingIndicator showWrapper={true} />}
        {loading === false && stations.length === 0 && (
          <div className="bg-white p-16 text-center shadow-lg rounded-xl">
            <h2 className="font-bold text-4xl">
              There are no stations on your account yet.
            </h2>
            <h2 className="text-2xl">
              If you have been invited here and do not see a station in this
              list, speak with your station manager{" "}
              <Link to={"/station/new"}>here.</Link>
            </h2>
            <h2 className="font-bold text-4xl">Station Managers.</h2>
            <h2 className="text-2xl">
              <Link to={"/station/new"}>
                Click here to add a station and start building your content.
              </Link>
            </h2>
          </div>
        )}
        <>
          {userDetails && userDetails.userDetails !== "" && stations && (
            <>
              {stations.map((station, i) => (
                <StationRow
                  key={i}
                  station={station}
                  changeStation={changeStation}
                />
              ))}

              <div className=" py-4 flex items-center justify-around  ">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<ChevronDoubleRightIcon className="w-5 h-5" />}
                  previousLabel={<ChevronDoubleLeftIcon className="w-5 h-5" />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount - 1}
                  renderOnZeroPageCount={null}
                  containerClassName={"paginator"}
                  forcePage={pageNo - 1}
                />
              </div>
            </>
          )}
        </>
      </div>
    </Layout>
  );
}
