import React from "react";

const ProgressBar = ({
  bgcolor = "blue",
  completed,
  index,
  seek,
  episode = null,
}) => {
  //   const { bgcolor, completed } = props;

  const containerStyles = {
    height: episode && episode.waveform ? 60 : 20,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 0,
    // margin: 50,
    position: "relative",
  };

  const fillerStyles = {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  React.useEffect(() => {
    // document
    //   .getElementById(`player-${index}`)
    //   .addEventListener("click", function (event) {
    //     // var test = (event.clientX-this.offsetLeft) / this.offsetWidth * 100;
    //     var test = event.offsetX;
    //     const text = getComputedStyle(this).width.replace("'", "");
    //     const width = text.replace("px", "");
    //     const percent = (test / parseInt(width)) * 100;
    //     console.log( percent);
    //     if(seek){
    //         seek(percent)
    //     }
    //   });
  });

  return (
    <div style={containerStyles} id={`player-${index}`}>
      {episode && episode.waveform && (
        <img
          src={episode.waveform.url}
          style={{ height: "60px" }}
          className="w-full"
        />
      )}
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
