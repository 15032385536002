import React, { useContext } from "react";
import { StationContext } from "../context/station-context";
import { useHistory, Link } from "react-router-dom";
import { menus } from "../config/constants";
import { RadioContext } from "../RadioContext";
import { useAuthDispatch, logout, useAuthState } from "../context";
import { ReactComponent as MyLogo } from "../images/logo.svg";

import {
  ViewListIcon,
  EyeIcon,
  ClockIcon,
  PencilAltIcon,
  ArchiveIcon,
  UserAddIcon,
  SaveAsIcon,
  SaveIcon,
  ServerIcon,
  ChartBarIcon,
  CodeIcon,
  SpeakerphoneIcon,
  PlayIcon,
  CurrencyDollarIcon,
  LightBulbIcon,
  MusicNoteIcon,
} from "@heroicons/react/outline";
const ONESECOND = `https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3`;

export default function NewSidebar({ route }) {
  const { track } = useContext(RadioContext);
  const { sidebar, station, setStation, setOpenPlayout, openPlayout } =
    React.useContext(StationContext);
  let history = useHistory();
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const userDetails = useAuthState();

  const handleLogout = () => {
    logout(dispatch); //call the logout action
    history.push("/login"); //navigate to logout page on logout
  };

  const donateClicked = () => {
    window.gtag("event", "donate_clicked", {
      location: "sidebar",
    });
  };

  const atClicked = () => {
    window.gtag("event", "ai_link_clicked", {
      location: "sidebar",
    });
  };

  return (
    <aside
      className={`sidebar hidden md:block
       bg-white text-brandPrimary   
      `}
    >
      <ul className={"lg:w-64 sidebar-menu "}>
        <a
          className="   text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary"
          href={"https://radiohostai.com"}
          target={"_blank"}
          onClick={() => atClicked()}
        >
          <span
            className={"flex items-center gap-3 whitespace-nowrap blink-faster"}
          >
            <LightBulbIcon className="h-6 w-6  " />
            AI TOOLS FOR RADIO
          </span>
        </a>
        {menus.map((item, i) => {
          return (
            item.roles.includes(userDetails.role) && (
              <Link
                className="   text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary"
                key={`${i}menu`}
                to={item.link}
              >
                <span className={"flex items-center gap-3 whitespace-nowrap"}>
                  {item.icon && item.icon}
                  {item.name}
                </span>
              </Link>
            )
          );
        })}

        <a
          className="   text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary"
          href={"https://www.paypal.com/donate/?hosted_button_id=MB8DB5Q5KLB2Q"}
          target={"_blank"}
          onClick={() => donateClicked()}
        >
          <span className={"flex items-center gap-3 whitespace-nowrap"}>
            <CurrencyDollarIcon className="h-6 w-6  " />
            Donate
          </span>
        </a>
        {/* <Link
          className=" rounded  w-full text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary"
          to={"/stash"}
        >
          <span className={" flex items-center gap-3 whitespace-nowrap"}>
            <ViewListIcon className="h-6 w-6 text-brandprimary" />
            My Tracks
          </span>
        </Link>{" "}
        <button
          className=" rounded  w-full text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary"
          onClick={() => setOpenPlayout((openPlayout) => !openPlayout)}
        >
          <span className={" flex items-center gap-3 whitespace-nowrap"}>
            <PlayIcon className="h-6 w-6 text-brandprimary" />
            Studio
          </span>
        </button> */}
      </ul>
      {station && (
        <div className={"block items-center gap-2"}>
          <div className="flex gap-3 px-4 py-3 items-center  bg-gray-100 font-bold">
            <div>
              <span className="text-xs font-normal">Currently using:</span>
              <p className="font-bold  text-xl  ">{station.name}</p>
            </div>
          </div>

          <Link
            className={
              route === "station"
                ? ` rounded bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
            }
            to={`/station/${station.id}`}
          >
            <EyeIcon className={"h-6 w-6"} />
            Station Info
          </Link>
          {((userDetails && userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              className={
                route === "edit"
                  ? `  bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                  : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
              }
              to={`/station/${station.id}/edit`}
            >
              <PencilAltIcon className={"h-6 w-6"} />
              Edit Station
            </Link>
          )}
          {((userDetails && userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              className={
                route === "widgets"
                  ? `  bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                  : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
              }
              to={`/station/${station.id}/widgets`}
            >
              <CodeIcon className={"h-6 w-6"} />
              Website Widgets
            </Link>
          )}
          <Link
            className={
              route === "episodes"
                ? `  bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
            }
            to={`/station/${station.id}/episodes`}
          >
            <ArchiveIcon className={"h-6 w-6"} />
            Episodes
          </Link>

          <Link
            className={
              route === "sections"
                ? `  bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
            }
            to={`/station/${station.id}/sections`}
          >
            <ServerIcon className={"h-6 w-6"} />
            Segments
          </Link>

          <Link
            className={
              route === "programs"
                ? `  bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
            }
            to={`/station/${station.id}/programs`}
          >
            <ViewListIcon className={"h-6 w-6"} /> Programs
          </Link>
          {/* 
          {((userDetails && userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              to={`/station/${station.id}/posts`}
              className={
                route === "posts"
                  ? `  bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                  : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
              }
            >
              <PencilIcon className={"h-6 w-6"} /> Blog
            </Link>
          )} */}
          {/* <Link className={"mr-2"} to={`/station/${station.id}/episodes`}>
          Episodes
        </Link> */}

          {((userDetails && userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              className={
                route === "grid"
                  ? ` bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                  : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
              }
              to={`/station/${station.id}/grid`}
            >
              <ClockIcon className={"h-6 w-6"} /> Grid
            </Link>
          )}

          {station && station.hls_stream && (
            <Link
              className={
                route === "segments"
                  ? ` rounded bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                  : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
              }
              to={`/station/${station.id}/segments`}
            >
              <SaveIcon className={"h-6 w-6"} /> Download Audio
            </Link>
          )}

          {((userDetails && userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") &&
            station.hls_stream && (
              <Link
                className={
                  route === "replacer"
                    ? ` rounded bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                    : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                }
                to={`/station/${station.id}/replace`}
              >
                <SaveAsIcon className={"h-6 w-6"} /> Replace Audio
              </Link>
            )}
          {((userDetails && userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") &&
            station.hls_stream && (
              <Link
                className={
                  route === "rolls"
                    ? ` rounded bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                    : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                }
                to={`/station/${station.id}/rolls`}
              >
                <MusicNoteIcon className={"h-6 w-6"} /> Station Pre-Rolls
              </Link>
            )}
          {((userDetails && userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") &&
            station.stats_enabled === 1 && (
              <Link
                className={
                  route === "stationreports"
                    ? ` rounded bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                    : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                }
                to={`/station/${station.id}/reports`}
              >
                <ChartBarIcon className={"h-6 w-6"} /> Reports (beta)
              </Link>
            )}
          {((userDetails && userDetails.userDetails.id === station.owner_id) ||
            userDetails.role === "admin") && (
            <Link
              className={
                route === "users"
                  ? ` rounded bg-gray-100 text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
                  : ` text-brandPrimary  py-3 px-4 text-base font-light flex gap-3 items-center hover:bg-gray-100 hover:text-brandPrimary `
              }
              to={`/station/${station.id}/users`}
            >
              <UserAddIcon className={"h-6 w-6"} /> Presenters
            </Link>
          )}
        </div>
      )}
      <div className="mt-auto h-16 flex items-center w-full">
        <button
          onClick={() => handleLogout()}
          className="h-16 w-10 mx-auto flex flex justify-center items-center
				w-full focus:text-blue-900 hover:bg-white-200 focus:outline-none text-blueGray-700"
        >
          <svg
            className="h-6 w-6 text-blueGray-700"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
            <polyline points="16 17 21 12 16 7"></polyline>
            <line x1="21" y1="12" x2="9" y2="12"></line>
          </svg>
        </button>
      </div>
    </aside>
  );
}
