import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import { useAuthState } from "../context";
import Modal from "react-modal";
import { StationContext } from "../context/station-context";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import AddSlot from "../components/Grid/AddSlot";
import NewPageHeader from "../components/Global/NewPageHeader";
import { userCheck } from "../config/functions";

Modal.setAppElement("#root");

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const pad = (d) => {
  return d < 10 ? "0" + d.toString() : d.toString();
};

export default function StationGrid() {
  const http = global.services.http;
  let history = useHistory();
  const [edit, setEdit] = useState(null);
  const userDetails = useAuthState(); //read user details from context
  const { id } = useParams();
  const { station, setStation } = React.useContext(StationContext);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const [weekDays, setWeekDays] = React.useState([]);
  // const [station, setStation] = useState(null);
  const [slots, setSlots] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const http = global.services.http;

    if (userDetails.role !== "admin" && userDetails.role !== "librarian") {
      history.push("/");
      return;
    }
    setLoading(true);
    // if station not loaded, load here
    const getStation = async () => {
      setLoading(true);
      var req = await http.get(`${process.env.REACT_APP_API}/stations/${id}`);
      if (req) {
        setStation(req.data.data);
        setThisStation(req.data.data);
        setLoading(false);
        userCheck({ station: req.data.data, user: userDetails });
      }
    };
    const getSlots = async () => {
      var filter = {
        station_id: id,
        is_active: 1,
      };
      var req = await http.get(
        `${process.env.REACT_APP_API}/slots?filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}&limit=600`
      );
      if (req) {
        setSlots(req.data.data);
        processWeekDays(req.data.data);
        setLoading(false);
      }
    };
    getSlots();
    getStation();
  }, [id]);

  const processWeekDays = async (results) => {
    console.log("Got here", results);
    // setWeekDays
    let days = [];
    for (let index = 0; index < 7; index++) {
      days[index] = results.filter((item) => item.weekday_start === index);
    }
    console.log("days", days);
    setWeekDays(days);
  };

  const getSlots = async () => {
    var filter = {
      station_id: id,
      is_active: 1,
    };
    var req = await http.get(
      `${process.env.REACT_APP_API}/slots?filter=${encodeURIComponent(
        JSON.stringify(filter)
      )}&limit=600`
    );
    if (req) {
      setSlots(req.data.data);
      processWeekDays(req.data.data);
      setLoading(false);
    }
  };

  const finishUpdate = async () => {
    setEdit(null);
    getSlots();
    console.log("Slot updated");
  };

  const EditModal = () => (
    <div
      // isOpen={edit !== null}
      // onAfterOpen={afterOpenModal}
      onRequestClose={finishUpdate}
      // style={customStyles}
      contentLabel="Edit Program Time"
    >
      {station && (
        <AddSlot
          slots={slots}
          slot={edit}
          onComplete={finishUpdate}
          station={station}
        />
      )}
    </div>
  );

  // c

  const deleteSlot = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?") === true) {
      var req = await http.delete(`${process.env.REACT_APP_API}/slots/${id}`);
      if (req) {
        console.log("req");
        await getSlots();
      }
    }
  };

  return (
    <Layout
      route="grid"
      title={!loading && station ? `${station.name} Program Grid` : "Loading.."}
    >
      <NewPageHeader title={"Program Grid"} links={[]} />
      <p className="mb-6 text-darkFaded text-xs">
        Add and modify program slots on this page.
      </p>
      <div className="nope-lg:sticky nope-lg:top-0 mb-4" id="add-modal">
        {station &&
          (edit ? (
            <EditModal />
          ) : (
            <AddSlot
              station_id={id}
              station={station}
              onComplete={() => getSlots()}
              slots={slots}
            />
          ))}
      </div>

      {weekDays.length === 7 && (
        <div className="w-full p-4 bg-white shadow-xl rounded-xl">
          {weekDays.map((day, i) => (
            <div key={i} className={" mb-5 "}>
              <h2 className="font-bold text-lg">{days[i]}</h2>
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className={"min-w-full divide-y divide-gray-200"}>
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              Start Time
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              End Time
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              Duration
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Program(s)
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {day.map((slot, j) => (
                            <tr key={`${i}-${j}`}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {slot.hour_start > 12
                                  ? pad(slot.hour_start - 12)
                                  : pad(slot.hour_start)}
                                :{pad(slot.minute_start)}
                                {slot.hour_start < 12 ? "am" : "pm"}
                                <p className="text-[10px]">
                                  {" "}
                                  {slot.start_date}
                                </p>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {slot.hour_end > 12
                                  ? pad(slot.hour_end) - 12
                                  : pad(slot.hour_end)}
                                :{pad(slot.minute_end)}
                                {slot.hour_end < 12 ? "am" : "pm"}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {slot.duration / 60 / 60}h
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {/* {slot.program.name} */}
                                {slot.programs && (
                                  <div>
                                    {slot.programs.map((program, k) => (
                                      <span key={`${i}-${j}-${k}`}>
                                        {program.name}
                                        {k < slot.programs.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    ))}
                                    {slot.title && (
                                      <p className="text-[10px] text-gray-400">
                                        Custom Title: {slot.title}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap flex gap-2 ">
                                <button
                                  className={
                                    "rounded-full w-10 h-10 flex align-center items-center justify-center bg-brandDark hover:bg-lightBlue-600 text-white"
                                  }
                                  onClick={() => {
                                    // scroll to id add-modal
                                    document
                                      .getElementById("add-modal")
                                      .scrollIntoView();
                                    setEdit(slot);
                                  }}
                                >
                                  <PencilAltIcon className="h-5 w-5  " />
                                </button>
                                <button
                                  className={
                                    "rounded-full w-10 h-10 flex align-center items-center justify-center bg-red-400 hover:bg-red-500 text-white"
                                  }
                                  onClick={() => deleteSlot(slot.id)}
                                >
                                  <TrashIcon className="h-5 w-5  " />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
}
