import Modal from "react-modal";
import React, { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { CheckCircleIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import AiLink from "./AiLink";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "60vw",
    maxHeight: "96vh",
  },
};

export default function AiModal() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      window.gtag("event", "ai_modal_opened", {});
    }
  }, [isOpen]);

  useEffect(() => {
    if (copied) {
      window.gtag("event", "ai_coupon_copied", {});

      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  return (
    <div className="hidden md:flex px-4">
      <button
        href="https://radiohostai.com"
        target="_new"
        className="py-2 px-5 bg-blue-900 text-white whitespace-nowrap rounded-full text-sm font-medium hover:bg-blue-700 "
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="blink">HARNESS THE POWER OF AI</span>
      </button>
      <Modal
        style={customStyles}
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        // style={customStyles}
        contentLabel="Harness the power of AI"
      >
        <AiLink />
        <h2 className="text-3xl text-center font-extrabold mb-6 mx-auto max-w-6xl mt-6">
          A new suite of AI powered tools to help you create better radio
        </h2>

        <div className="flex justify-center gap-12 items-start mt-4 ">
          {/* <div className="w-1/2">
            <img
              src={generators}
              alt="RadioHostAI"
              className="w-full h-auto mx-auto"
            />
          </div> */}
          <div className="w-full">
            {/* <h2 id="ai-powered-content-creation-suite">
                AI-Powered Content Creation Suite
              </h2> */}
            <p className="text-lg text-center mb-6 px-6">
              Step into the future of broadcasting with our revolutionary AI
              suite! Designed to turbocharge your creativity and streamline your
              workflow, this toolkit lets you effortlessly conjure up stunning
              images, captivating articles, personalised playlists, and concise
              content summaries. Elevate your radio game and create like never
              before.
            </p>
            <p className="text-lg text-center mb-6 px-6">
              <a
                className="font-bold hover:text-blue-700 tranistion-colors"
                href="https://radiohostai.com"
              >
                Dive in and discover the magic of making better radio,
                effortlessly!{" "}
              </a>
            </p>
            <div className="mx-auto block w-auto mb-6 max-w-sm">
              <div className="flex gap-4 border p-4 mx-auto  border-gray-300 rounded-md">
                <div>
                  <p className="mb-0 font-bold">50% Off the first Month</p>
                  <input
                    type="text"
                    className="border p-1"
                    value="MyRadioClickVIP"
                  />
                </div>

                <CopyToClipboard
                  text={"MyRadioClickVIP"}
                  onCopy={() => setCopied(true)}
                >
                  <span className="ml-auto bg-blue-900 text-white hover:bg-blue-600 transition-colors p-4 rounded-md cursor-pointer text-xs flex gap-2 items-center">
                    {copied ? (
                      <>
                        <CheckCircleIcon className="w-8 h-8" /> copied
                      </>
                    ) : (
                      <>
                        <ClipboardCopyIcon className="w-8 h-8" />
                        Copy
                      </>
                    )}
                  </span>
                </CopyToClipboard>
              </div>
            </div>
            <p className="text-lg text-center mb-6 px-6">
              <a
                className="font-bold hover:text-blue-700 tranistion-colors"
                href="https://radiohostai.com"
              >
                Sign up now and get 50% off your first month!
                <br />{" "}
                <span className="font-normal text-gray-700 text-xs">
                  No Credit Card Required to Try
                </span>
              </a>
            </p>
            <div className="article-content ">
              <p>
                Here are some of the forms you can use to suppliment your
                program:
              </p>
              <h3 id="playlist-summary">Playlist Summary</h3>
              <p>
                Generate a concise and engaging summary of your show for live
                reads, emails, social media, or blog posts. Perfect for
                capturing the essence of your content and engaging your
                audience.
              </p>
              <h3 id="article-generator">Article Generator</h3>
              <p>
                Initiate the creation of your next website article with a
                detailed draft. Our language models provide a comprehensive
                starting point, saving you time and sparking creativity.
              </p>
              <h3 id="summarise-text">Summarise Text</h3>
              <p>
                Short on time? Get quick summaries of press releases, bios,
                articles, and more. Ideal for digesting content swiftly without
                missing out on crucial details.
              </p>
              <h3 id="tweet-generator">Tweet Generator</h3>
              <p>
                Craft engaging and informative tweets in seconds. Our AI tool
                helps you maintain an active and appealing Twitter presence with
                minimal effort.
              </p>
              <h3 id="program-name-generator">Program Name Generator</h3>
              <p>
                Use the latest AI and language models to come up with creative
                and relevant names for your programs. Stand out with unique and
                catchy titles.
              </p>
              <h3 id="image-generator">Image Generator</h3>
              <p>
                Create high-resolution images tailored for your content,
                including website tiles and social media posts. Enhance your
                visual appeal with AI-generated imagery.
              </p>
              <h3 id="playlist-generator">Playlist Generator</h3>
              <p>
                Need a themed playlist quickly? Our AI tool curates music
                playlists based on your specified theme, making it perfect for
                setting the right ambiance.
              </p>
              <h3 id="artist-information">Artist Information</h3>
              <p>
                Obtain detailed information about artists in seconds. Our AI
                sifts through data to provide you with comprehensive artist bios
                and facts.
              </p>
              <h3 id="local-weather-report">Local Weather Report</h3>
              <p>
                Draft a script for your local weather conditions. This tool is
                ideal for creating timely and accurate weather reports for your
                audience.
              </p>
              <h3 id="speech-to-text">Speech To Text</h3>
              <p>
                Transcribe interviews and live content into text effortlessly.
                Our tool supports various languages and is perfect for creating
                show notes and blog posts.
              </p>
              <h3 id="text-to-speech">Text To Speech</h3>
              <p>
                Convert your text to high-quality MP3 files in seconds. Enhance
                your content with voiceovers perfect for show intros, promos,
                and more.
              </p>
              <h3 id="email-enhancer">Email Enhancer</h3>
              <p>
                Elevate your email communications. Our AI tool helps you refine
                your messaging for maximum impact.
              </p>
              <h3 id="live-read-generator">Live Read Generator</h3>
              <p>
                Create engaging live read briefs on any topic. Ideal for
                sponsors, announcements, upcoming programs, and more, ensuring
                your live reads captivate your audience.
              </p>
              <p>
                Our suite of AI-powered tools is designed to make your content
                creation process smoother and more efficient. Embrace the power
                of AI and elevate your digital content today.
              </p>
              <p>
                We are constantly working on improoving and adding new tools to
                our suite. If you have any suggestions or feedback, please feel
                free to{" "}
                <a className="font-bold" href="https://radiohostai.com/contact">
                  contact us
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
