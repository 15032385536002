import React, { useEffect, useState, createContext } from "react";
import moment from "moment";
import { uuidv4 } from "./config/functions";

const DEFAULT_TRACK = {
  program: null,
  original_id: "live-stream",
  url: `https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3`,
  start: null,
  isLive: true,
};

const getUid = async () => {
  const item = await window.localStorage.getItem("uuid");
  if (item) {
    return item;
  } else {
    const uuid = uuidv4();
    await window.localStorage.setItem("uuid", uuid);
    return uuid;
  }
};

const defaultContext = {
  currentTrack: DEFAULT_TRACK,
  sidebar: false,
  mobileSidebar: false,
};
export const RadioContext = createContext(defaultContext);

export const RadioProvider = ({ children }) => {
  const defaultStream = process.env.REACT_APP_STREAM_URL
    ? process.env.REACT_APP_STREAM_URL
    : null;
  const userId = getUid();
  const [streamProgress, setStreamProgress] = useState(0);
  const [streamPercent, setStreamPercent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [buffering, setBuffering] = useState(false);
  const [length, setLength] = useState(0);
  const [player, setPlayer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [track, setTrack] = useState(DEFAULT_TRACK);
  const [playlist, setPlaylist] = useState([]);
  // const [userId, setUserId] = useState(null);
  const [initPlaylist, setInitPlaylist] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  // here we pull all our station details

  let ref = (player) => {
    setPlayer(player);
  };

  const playAudio = (e) => {
    setPlaying(!playing);
  };

  const newSeek = (percent) => {
    player.seekTo(percent / 100, "fraction");
  };

  const updateProgress = (progress) => {
    var per = (progress.playedSeconds / length) * 100;
    setStreamPercent(Math.floor(per));
    setStreamProgress(Math.floor(Math.floor(progress.playedSeconds)));
  };

  const updateTime = (event, div) => {
    console.log(event.nativeEvent.offsetX);
    const thisDiv = document.getElementById(div);
    const width = window.getComputedStyle(thisDiv);
    const currentWidth = parseInt(width["width"]);
    var test = (event.nativeEvent.offsetX - thisDiv.offsetLeft) / currentWidth;
    player.seekTo(test);
  };

  const updateDuration = (newDuration) => {
    setLength(Math.floor(newDuration));
    setDuration(
      moment("2015-01-01")
        .startOf("day")
        .seconds(Math.floor(newDuration))
        .format("H:mm:ss")
    );
  };

  useEffect(() => {
    const toggle = async () => {
      if (
        track.url !==
        `https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3`
      ) {
        await setPlaying(true);
        console.log("Got here");
        setToastMessage(`Now playing ${track.title}`);
      }
    };
    toggle();
  }, [track]);

  useEffect(() => {
    const savePlaylist = async () => {
      localStorage.setItem("episodes", JSON.stringify(playlist));
    };
    if (initPlaylist === true) {
      savePlaylist();
    }
  }, [playlist]);

  useEffect(() => {
    const getPlaylist = async () => {
      const storedPrefs = await localStorage.getItem("episodes");
      if (typeof storedPrefs === "string") {
        try {
          const saved = JSON.parse(storedPrefs);
          if (saved) {
            setPlaylist(saved);
          } else {
            setPlaylist([]);
          }
        } catch (error) {
          console.log("There was an error with the saved playlist");
          setPlaylist([]);
        }
      } else {
        setPlaylist([]);
      }
      setInitPlaylist(true);
    };
    if (initPlaylist === false) {
      getPlaylist();
    }
  });

  const removeFromPlaylist = async (item) => {
    const after = playlist.filter((t) => t.url !== item.url);
    setPlaylist(after);
  };

  const pickNewTrack = async () => {
    const inPlaylist = await playlist.findIndex(
      (item) => item.url === track.url
    );
    console.log(inPlaylist, "track Dinished");
    if (inPlaylist !== null) {
      const next = inPlaylist + 1;
      if (playlist[next]) {
        console.log(playlist[next], "Here");
        setTrack(playlist[next]);
      } else {
        setPlaying(false);
      }
    } else {
      setPlaying(false);
    }
  };

  return (
    <RadioContext.Provider
      value={{
        ref,
        setTrack,
        track,
        buffering,
        setPlaying,
        updateDuration,
        updateProgress,
        setBuffering,
        playing,
        newSeek,
        updateTime,
        streamPercent,
        streamProgress,
        duration,
        playAudio,
        userId,
        playlist,
        setPlaylist,
        removeFromPlaylist,
        toastMessage,
        setToastMessage,
        pickNewTrack,
      }}
    >
      {children}
    </RadioContext.Provider>
  );
};
