import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, Doughnut } from "react-chartjs-2";
import randomColor from "randomcolor";
import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//   datasets: [
//     {
//       label: '# of Votes',
//       data: [12, 19, 3, 5, 2, 3],
//       backgroundColor: [
//         'rgba(255, 99, 132, 0.2)',
//         'rgba(54, 162, 235, 0.2)',
//         'rgba(255, 206, 86, 0.2)',
//         'rgba(75, 192, 192, 0.2)',
//         'rgba(153, 102, 255, 0.2)',
//         'rgba(255, 159, 64, 0.2)',
//       ],
//       borderColor: [
//         'rgba(255, 99, 132, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(255, 206, 86, 1)',
//         'rgba(75, 192, 192, 1)',
//         'rgba(153, 102, 255, 1)',
//         'rgba(255, 159, 64, 1)',
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

const GeoChart = ({
  report,
  label = "Listeners per Country",
  type = "country",
}) => {
  console.log("Here", report);
  const [data, setData] = useState(null);
  
  useEffect(() => {
    const updateReport = async () => {
      const gl = Object.entries(report.geo[type]).map(([k, v], i) => k);

      const gs = Object.entries(report.geo[type]).map(([k, v], i) => v);

      setData({
        labels: gl,
        datasets: [
          {
            label: label,
            data: gs,
            backgroundColor: gl.map(() => randomColor()),
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      }); 
    };
    if (report !== null && type !== null) updateReport();
  }, [report, setData]);
 
  const options = {
    responsive: true,
    tension: 0.4,
    fill: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
 
  return <div className="w-full">{data && <Doughnut data={data} />}</div>;
};

export default GeoChart;
