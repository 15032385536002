import React from "react";
import Layout from "../components/Layout";
import StashPageWrapper from "../components/StashPageWrapper";

export default function Stash() {
  return (
    <Layout route="stash" title={`My Stash`}>
      <StashPageWrapper />
    </Layout>
  );
}
