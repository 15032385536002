import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";
import { theme } from "tailwindcss/stubs/defaultConfig.stub";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

const StationChartNew = ({
  length,
  report,
  label = "Unique Ondemand Listeners",
}) => {
  const [data, setData] = useState(null);
  console.log("theme", theme);

  useEffect(() => {
    const updateReport = async () => {
      const labels = report.current.map((item) => item.readable.date);
      const thisData = report.current.map((item) => item.readable.listeners);
      const yesterday = report.yesterday.map((item) => item.readable.listeners);
      const week = report.last_week.map((item) => item.readable.listeners);
      const month = report.last_month.map((item) => item.readable.mobile);
      // const desktop = report.map((item) => item.readable.desktop);
      // const unknown = report.map((item) => item.readable.unknown);

      setData({
        labels: labels,
        datasets: [
          {
            data: thisData,
            label: "Today",
            borderWidth: 4,
            borderColor: "rgb(10,132,165)",
            backgroundColor: "rgba(10,132,165, 0.5)",
          },
          {
            data: yesterday,
            label: "Previous Day",
            borderWidth: 1,
            borderColor: "rgba(246,200,95,0.5)",
            backgroundColor: "rgba(246,200,95,0.5)",
          },
          {
            data: week,
            label: "7 Days Prior",
            borderWidth: 1,
            borderColor: "rgba(111,78,124,0.5)",
            backgroundColor: "rgba(111,78,124,0.5)",
          },
          {
            data: month,
            label: "4 Weeks Prior",
            borderWidth: 1,
            borderColor: "rgba(157,216,102, 0.5)",
            backgroundColor: "rgba(157,216,102, 0.5)",
          },
        ],
      });
    };
    if (report !== null) updateReport();
  }, [report, setData, length]);

  const options = {
    responsive: true,
    responsive: true,
    plugins: {
      // legend: {
      //   position: 'top' as const,
      // },
      title: {
        display: true,
        text: "Listens",
      },
    },
  };

  // const data = useMemo(() => {
  //   return {
  //     listenersLabels,
  //     datasets: listenerCounts,
  //   };
  // }, [listenersLabels, listenerCounts]);

  return (
    <div className="w-full">
      {data && <Line options={options} data={data} />}
    </div>
  );
};

export default StationChartNew;
