import React, { useState } from "react";
import PlaceholderImage from "../images/placeholder.png";
import AudioPlayer from "./AudioPlayer";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import DownloadAudio from "./DownloadAudio";
import { PencilAltIcon, XCircleIcon } from "@heroicons/react/outline";
import { useAuthState } from "../context";
import EpisodeForm from "./forms/EpisodeForm";
import { Transition } from "@headlessui/react";
import "moment-timezone";
import Modal from "react-modal";

const timeFormatLog = "h:mm:ss a";

function convertUTCToTimezone(
  utcDt,
  utcDtFormat,
  timezone,
  targetFormat = "dddd, MMMM Do YYYY, h:mm a"
) {
  return moment.utc(utcDt, utcDtFormat).tz(timezone).format(targetFormat);
}

export default function EpisodePageDetails({
  station,
  episode,
  index,
  showPlayer = true,
  onComplete = null,
  setEpisode = null,
  setTabIndex = null,
}) {
  let history = useHistory();
  const [edit, setEdit] = useState(false);
  const userDetails = useAuthState(); //read user details from context
  const http = global.services.http;
  const start = convertUTCToTimezone(
    episode.show_start,
    null,
    station.timezone
  );
  const startStream = convertUTCToTimezone(
    episode.show_start,
    null,
    station.timezone,
    ""
  );
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [displayPlayer, setDisplayPlayer] = React.useState(showPlayer);

  const streamUrl = `${process.env.REACT_APP_API}/public/ondemand/${episode.station.hls_stream}/96/${episode.timestamp}/${episode.duration}/listen.m3u8?unique=console`;

  const closeModal = (item = null) => {
    setEdit(null);
  };

  const EditModal = ({ episode, closeModal, setEpisode }) => (
    <>
      <Transition show={edit !== null}>
        {/* Background overlay */}
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={() => closeModal()}
            className=" max-w-screen fixed z-40 left-0 top-0 right-0 bg-gray-900 bg-opacity-70 bottom-0 "
          ></div>
        </Transition.Child>
        {/* Sliding sidebar */}
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className=" max-w-full fixed z-50  w-[400px] top-0 right-0 bg-white p-4 bottom-0  overflow-y-auto">
            <div className="w-full flex justify-between mb-4 items-center">
              <h2 className="font-bold">EDIT THIS EPISODE</h2>
              <button onClick={() => closeModal()}>
                <XCircleIcon className="w-8 h-8 hover:text-gray-800" />
              </button>
            </div>
            {episode && (
              <EpisodeForm
                onUpdate={(i) => {
                  closeModal();
                  setEpisode(i);
                }}
                id={episode.id}
              />
            )}
          </div>
          {/* ... */}
        </Transition.Child>
      </Transition>
    </>
  );

  const OldModal = ({ episode, closeModal, setEpisode }) => (
    <Modal
      isOpen={edit !== null}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      // style={customStyles}
      contentLabel="Edit Log"
    >
      {episode && (
        <EpisodeForm
          onUpdate={(i) => {
            closeModal();
            setEpisode(i);
          }}
          id={episode.id}
        />
      )}
    </Modal>
  );

  return (
    <div className="w-full md:flex text-gray-700 mb-4 gap-4 lg:gap-8 relative group">
      {edit !== false && (
        <EditModal
          episode={episode}
          closeModal={closeModal}
          setEpisode={setEpisode}
        />
      )}
      <div className="image lg:flex-0 mb-2 md:mb-0 text-center">
        {episode && episode.image ? (
          <img
            alt={"Episode Image"}
            src={episode.image.url}
            style={{
              width: "180px",
              height: "auto",
            }}
          />
        ) : (
          <div>
            <img
              alt={"Episode Image"}
              src={PlaceholderImage}
              style={{
                width: "180px",
                height: "auto",
              }}
            />
          </div>
        )}

        {moment().isAfter(startStream) &&
          episode &&
          episode.station.hls_stream &&
          showPlayer === false && (
            <div className="mt-4">
              <DownloadAudio
                url={streamUrl}
                stationCallsign={station && station.hls_stream}
              />
            </div>
          )}

        {displayPlayer &&
        moment().isAfter(startStream) &&
        episode.station.hls_stream ? (
          <div className="my-4">
            <AudioPlayer
              isPlaying={isPlaying}
              setIsPlaying={displayPlayer}
              episode={episode}
              url={streamUrl}
              index={index}
            />
          </div>
        ) : null}
      </div>
      <div className="image w-full flex-grow flex-1">
        {episode && station && (
          <div className="w-full">
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-4 xl:col-span-2">
                <p className="font-bold">Air Time:</p>
              </div>
              <div className="col-span-12 md:col-span-8 xl:col-span-10">
                <p>{start}</p>
              </div>
            </div>
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-4 xl:col-span-2">
                <p className="font-bold">
                  Episode Title:{" "}
                  <button onClick={() => setEdit(true)}>
                    <PencilAltIcon className="w-5 h-4" />
                  </button>
                </p>
              </div>
              <div className="col-span-12 md:col-span-8 xl:col-span-10">
                {episode && episode.title ? (
                  <p>{episode.title}</p>
                ) : (
                  <p>This episode has no custom title</p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-4 xl:col-span-2">
                <p className="font-bold">
                  Description:{" "}
                  <button onClick={() => setEdit(true)}>
                    <PencilAltIcon className="w-5 h-4" />
                  </button>
                </p>
              </div>
              <div className="col-span-12 md:col-span-8 xl:col-span-10 -mb-2">
                <p
                  itemProp="description"
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html: episode.description
                      ? episode.description.split("\n").join("<br>")
                      : "",
                  }}
                ></p>
              </div>
            </div>
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-4 xl:col-span-2">
                <p className="font-bold">Program:</p>
              </div>
              <div className="col-span-12 md:col-span-8 xl:col-span-10">
                <span>{episode.program.name}</span>{" "}
                <Link
                  to={`/station/${station.id}/program/${episode.program.id}`}
                  className="text-xs"
                >
                  (click here to view program page)
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-4 xl:col-span-2">
                <p className="font-bold">
                  Hide Times:{" "}
                  <button onClick={() => setEdit(true)}>
                    <PencilAltIcon className="w-5 h-4" />
                  </button>
                </p>
              </div>
              <div className="col-span-12 md:col-span-8 xl:col-span-10">
                {episode.hide_times === 1 ? "Yes" : "No"}
              </div>
            </div>
            <div className="grid grid-cols-12 mb-2 gap-4">
              <div className="col-span-12 ">
                {episode && episode.logs.length > 0 ? (
                  <section>
                    <h3 className="font-bold mb-2 flex gap-2 items-center">
                      Playlist:{" "}
                      <button
                        onClick={() => setTabIndex(1)}
                        className="flex gap-2 flex-nowrap items-center px-3 py-1 rounded-md bg-blue-500 text-white hover:bg-blue-400 transition-all"
                      >
                        <PencilAltIcon className="w-4 h-4" /> Edit Playlist
                      </button>
                    </h3>
                    <div className="flex flex-col w-full mt-4">
                      {episode &&
                        episode.logs.map((log, i) => (
                          <div
                            key={`log${i}`}
                            className={
                              "px-3 py-2 border-l border-r  border-gray-200 bg-white logs text-sm" +
                              (i == 0
                                ? " rounded-tl-md rounded-tr-md border-t "
                                : "") +
                              (i == episode.logs.length - 1
                                ? " rounded-bl-md rounded-br-md border-b"
                                : " border-b")
                            }
                          >
                            {episode.hide_times !== 1 && (
                              <p className="text-sm mb-1">
                                {moment(log.local).format(timeFormatLog)}{" "}
                              </p>
                            )}
                            <p className="text-sm">
                              <span className=" font-bold">{log.title}</span>{" "}
                              {log.artist && (
                                <span className="">by {log.artist}</span>
                              )}{" "}
                              <span className="mt-1 text-sm">
                                {log.location && log.location === "nat"
                                  ? "(National Release)"
                                  : log.location && log.location === "int"
                                  ? "(International Release)"
                                  : "(Local Release)"}
                              </span>
                            </p>
                            {log.album && (
                              <p className="mt-1">Release: {log.album}</p>
                            )}
                          </div>
                        ))}
                    </div>
                  </section>
                ) : (
                  <section className="mt-8 ">
                    <p className="mb-4">
                      No tracks have been entered for this episode.{" "}
                    </p>
                    <button
                      className="font-bold text-underline"
                      onClick={() => setTabIndex(1)}
                    >
                      Click here to start adding tracks now
                    </button>
                  </section>
                )}
              </div>

              <div className="col-span-12  ">
                {episode && episode.sections.length > 0 ? (
                  <section>
                    <h3 className="font-bold mb-2">
                      Sections:{" "}
                      <button onClick={() => setTabIndex(2)}>
                        <PencilAltIcon className="w-4 h-4" />
                      </button>
                    </h3>
                    {episode &&
                      episode.sections.map((segment, i) => (
                        <div
                          key={`segment${i}`}
                          className="p-4 border-solid border-2 border-gray-200  logs mb-4"
                        >
                          <p className="font-bold text-lg mb-2">
                            {segment.title}
                          </p>

                          <div className="mb-2">
                            {segment.content && (
                              <p
                                itemProp="description"
                                className="mb-2"
                                dangerouslySetInnerHTML={{
                                  __html: segment.content
                                    ? segment.content.split("\n").join("<br>")
                                    : "",
                                }}
                              ></p>
                            )}
                          </div>
                          <p className="text-sm mb-2">{segment.readable} </p>
                          <p className="text-sm mb-2">
                            {(segment.duration / 60).toFixed(2)} (minutes)
                          </p>
                        </div>
                      ))}
                  </section>
                ) : (
                  <section className="mt-8 ">
                    <p className="mb-4">
                      No segments have been entered for this episode.{" "}
                    </p>
                    <button
                      className="font-bold text-underline"
                      onClick={() => setTabIndex(2)}
                    >
                      Click here to start adding segments to this episode
                    </button>
                  </section>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
