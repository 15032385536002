import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";
import { theme } from "tailwindcss/stubs/defaultConfig.stub";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

const StationChartBreakDown = ({
  length,
  report,
  label = "Unique Ondemand Listeners",
}) => {
  const [data, setData] = useState(null);
  console.log("theme", theme);

  useEffect(() => {
    const updateReport = async () => {
      // const labelsOndmeand = report[length].map((item) => {
      //   if (length === "day") {
      //     return moment.unix(item.start).format("DD MMMM");
      //   } else if (length === "minute") {
      //     return moment.unix(item.start).format("h:mmA");
      //   } else {
      //     return moment.unix(item.start).format("DD MMMM hA");
      //   }
      // });
      // const dataOndemand = report[length].map((item) => item.unqiueCount);
      // console.log("dataOndemand", dataOndemand, "lablels", labelsOndmeand);

      const labels = report.map((item) => item.readable.date);
      const thisData = report.map((item) => item.readable.listeners);
      const mobiles = report.map((item) => item.readable.mobile);
      const desktop = report.map((item) => item.readable.desktop);
      const unknown = report.map((item) => item.readable.unknown);
      const newConnections = report.map((item) => item.readable.start);
      const lostConnections = report.map((item) => item.readable.end);
      const total = report.map((item) => item.readable.total);

      setData({
        labels: labels,
        datasets: [
          {
            data: thisData,
            label: "Total Sessions",
            borderWidth: 4,
            borderColor: "rgb(10,132,165)",
            backgroundColor: "rgba(10,132,165, 0.5)",
          },
          {
            data: newConnections,
            label: "New Connections",
            borderWidth: 2,
            borderColor: "rgba(246,200,95,0.5)",
            backgroundColor: "rgba(246,200,95,0.5)",
          },
          {
            data: lostConnections,
            label: "Lost Connections",
            borderWidth: 2,
            borderColor: "rgba(111,78,124,0.5)",
            backgroundColor: "rgba(111,78,124,0.5)",
          },
          {
            data: total,
            label: "Listeners (at this time)",
            borderWidth: 2,
            borderColor: "rgba(157,216,102, 0.5)",
            backgroundColor: "rgba(157,216,102, 0.5)",
          },
          // {
          //   data: mobiles,
          //   label: "Mobile Listeners (Approx)",
          //   borderWidth: 2,
          //   borderColor: "rgba(202,71,47,0.5)",
          //   backgroundColor: "rgba(202,71,47,0.5)",
          // },
          // {
          //   data: desktop,
          //   label: "Desktop Listeners (Approx)",
          //   borderWidth: 2,
          //   borderColor: "rgba(255,160,85,0.5)",
          //   backgroundColor: "rgba(255,160,85,0.5)",
          // },
          // {
          //   data: unknown,
          //   label: "Unknown Device",
          //   borderWidth: 2,
          //   borderColor: "rgba(141,221,208, 0.5)",
          //   backgroundColor: "rgba(141,221,208, 0.5)",
          // },
        ],
      });
    };
    if (report !== null) updateReport();
  }, [report, setData, length]);

  const options = {
    responsive: true,
    responsive: true,
    plugins: {
      // legend: {
      //   position: 'top' as const,
      // },
      title: {
        display: true,
        text: label,
      },
    },
  };

  // const data = useMemo(() => {
  //   return {
  //     listenersLabels,
  //     datasets: listenerCounts,
  //   };
  // }, [listenersLabels, listenerCounts]);

  return (
    <div className="w-full">
      {data && <Line options={options} data={data} />}
    </div>
  );
};

export default StationChartBreakDown;
