import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm } from "react-hook-form";
import ProfileAutoComplete from "../ProfileAutoComplate";

import ReactQuill from "react-quill";

import { useAuthState } from "../../context";
import { useHistory, Link } from "react-router-dom";
import { SaveAsIcon } from "@heroicons/react/outline";
import NewMediaUpload from "../NewMediaUpload";
import * as yup from "yup";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Spinner from "../Spinner";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  title: yup.string().max(120).required(),
  artist: yup.string().max(120).required(),
  producer: yup
    .string()
    .max(254, "You can use a maximum of 254 characters here"),
});

export default function StashFormEdit({
  id = null,
  onRequestClose = null,
  hideCancel = false,
  hideHeader = false,
  preloaded = null,
}) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const userDetails = useAuthState();
  const { station, setStation } = React.useContext(StationContext);
  const [stash, setStash] = React.useState(preloaded ? preloaded : null);
  const quilRef = React.useRef(null);
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const resolver = useYupValidationResolver(validationSchema);
  const [links, setLinks] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  useEffect(() => {
    const http = global.services.http;
    const getStash = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/stash/${id}`);
      if (req.data) {
        setStash(req.data.data);
      }
    };

    if (!stash) {
      getStash();
    } else {
      // setThisStation()
    }
  }, [id]);

  const stashSubmit = async (payload) => {
    if (loading === true) return;
    let fields = { ...payload };
    setLoading(true);
    try {
      var req = await http.put(
        `${process.env.REACT_APP_API}/stash/${id}`,
        fields
      );
      if (req.status === 422) {
        if (req.data.data) {
          if (req.data.data.name) {
            setError("title", {
              type: "manual",
              message: req.data.data.title[0],
            });
          }
          if (req.data.data.artist) {
            setError("artist", {
              type: "manual",
              message: req.data.data.artist[0],
            });
          }
          if (req.data.data.album) {
            setError("album", {
              type: "manual",
              message: req.data.data.album[0],
            });
          }
          if (req.data.data.note) {
            setError("note", {
              type: "manual",
              message: req.data.data.note[0],
            });
          }
          if (req.data.data.label) {
            setError("grid_olabelne_liner", {
              type: "manual",
              message: req.data.data.label[0],
            });
          }
        }
        setLoading(false);
        return;
      } else {
        setLoading(false);
        onRequestClose(req.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(watch("email"));
  const Header = () => (
    <p className={"text-lg font-bold"}>{`Edit Your Track: ${
      stash && stash.title
    } `}</p>
  );

  return (
    <div className={"max-w-full  mr-auto bg-white border-b-2"}>
      {stash && hideHeader === false && <SectionHeader content={<Header />} />}
      <div className="p-4 block gap-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        {stash ? (
          <div className="block lg:flex gap-4">
            <div>
              <p className="mb-2">{stash && <>FILENAME: {stash.filename}</>}</p>
              <audio controls src={stash.url} className={"w-full lg:w-96 mb-6"}>
                <a href={stash.url}>Download audio</a>
              </audio>
            </div>
            <form
              className="w-full md:flex md:flex-wrap  -mx-2"
              onSubmit={handleSubmit(stashSubmit)}
            >
              <div className="mb-8 w-1/2 px-2">
                <label
                  htmlFor="title"
                  className={`block font-bold text-sm mb-2 ${
                    errors.name ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Track Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  defaultValue={stash && stash.title}
                  placeholder="Track Title"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.title ? "text-red-300  " : " "
                  }`}
                  {...register("title")}
                />
                {errors.title && (
                  <p className="text-red-500 text-sm mt-2">
                    Oops, there was something wrong with this title. PLease
                    double check it and try again?
                  </p>
                )}
              </div>
              <div className="mb-8 w-1/2 px-2">
                <label
                  htmlFor="artist"
                  className={`block font-bold text-sm mb-2 ${
                    errors.name ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Track Artist
                </label>
                <input
                  type="text"
                  name="artist"
                  id="artist"
                  defaultValue={stash && stash.artist}
                  placeholder="Track Artist"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.artist ? "text-red-300  " : " "
                  }`}
                  {...register("artist")}
                />
                {errors.artist && (
                  <p className="text-red-500 text-sm mt-2">
                    Oops, there was something wrong with this artist. PLease
                    double check it and try again?
                  </p>
                )}
              </div>
              <div className="mb-8 w-1/2 px-2">
                <label
                  htmlFor="album"
                  className={`block font-bold text-sm mb-2 ${
                    errors.name ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Track Album
                </label>
                <input
                  type="text"
                  name="album"
                  id="album"
                  defaultValue={stash && stash.album}
                  placeholder="Track Album"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.album ? "text-red-300  " : " "
                  }`}
                  {...register("album")}
                />
                {errors.album && (
                  <p className="text-red-500 text-sm mt-2">
                    Oops, there was something wrong with this album.
                  </p>
                )}
              </div>
              <div className="mb-8 w-1/2 px-2">
                <label
                  htmlFor="notes"
                  className={`block font-bold text-sm mb-2 ${
                    errors.name ? "text-red-400" : "text-gray-800"
                  }`}
                >
                  Track Notes
                </label>
                <textarea
                  name="notes"
                  id="notes"
                  defaultValue={stash && stash.notes}
                  placeholder="Track Notes"
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                    errors.notes ? "text-red-300  " : " "
                  }`}
                  {...register("notes")}
                />
                {errors.notes && (
                  <p className="text-red-500 text-sm mt-2">
                    Oops, there was something wrong with these notes. Please
                    double check it and try again.
                  </p>
                )}
              </div>

              <button className=" flex gap-2 bg-brandDark text-white rounded shadow py-2 px-4 text-uppercase text-sm hover:bg-blue-500 ml-auto">
                Save Track Details
                {loading ? (
                  <Spinner className="w-4 h-4 " />
                ) : (
                  <SaveAsIcon className="w-4 h-4 " />
                )}
              </button>
              {hideCancel === false && (
                <p className=" text-center mt-4">
                  <button
                    className="ml-auto inline-block bg-gray-200 text-gray-800 rounded shadow py-1 px-3 text-xs"
                    onClick={() => {
                      onRequestClose();
                    }}
                  >
                    Cancel
                  </button>
                </p>
              )}
            </form>
          </div>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}

StashFormEdit.modules = {};
StashFormEdit.modules.toolbar = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"], // blocks
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }], // lists
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction
  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
  // [{ color: [] }, { background: [] }], // dropdown with defaults
  // [{ font: [] }], // font family
  // [{ align: [] }], // text align
  ["clean"], // remove formatting
];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
StashFormEdit.formats = [
  "header",
  "font",
  "background",
  "color",
  "code",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "script",
  "align",
  // "direction",
  "link",
  "image",
  "code-block",
  "formula",
  "audio",
];
