import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import StationDetails from "../components/StationDetails";
import { StationContext } from "../context/station-context";

export default function Station() {
  const { id } = useParams();

  const { station, setStation } = React.useContext(StationContext);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/stations/${id}`);
      if (req) {
        setStation(req.data.data);
        setLoading(false);
      }
    };
    getStation();
  }, [id]);

  return (
    <Layout route="station" title={station && `Station: ${station.name}`}>
      <StationDetails station={station} loading={loading} />
    </Layout>
  );
}
