import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import PlayButton from "./PlayButton";
import "moment-timezone";
import { buildEpisodeTrack } from "../config/functions";
import { Menu } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EpisodeDropDown({
  station,
  episode,
  disablePlay = false,
  textClass = "text-sm",
  changeStation,
}) {
  const thisTrack = buildEpisodeTrack({ episode });
  const [isOnAir, setIsOnAir] = useState(false);

  useEffect(() => {
    var CurrentDate = moment().unix();
    if (
      episode.timestamp <= CurrentDate &&
      episode.timestampEnd >= CurrentDate
    ) {
      setIsOnAir(true);
    }
  });

  return (
    <Menu.Item>
      {({ active }) => (
        <Link
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "block px-4 py-2 text-sm"
          )}
          to={`/station/${station.id}/episode/${episode.id}`}
          onClick={() => changeStation(station)}
        >
          {episode.program.name}
        </Link>
      )}
    </Menu.Item>
    // <div className="w-full items-center md:flex text-gray-700 mb-1 gap-4 relative group">
    //   {/* <div className="image lg:flex-0 mb-2 md:mb-0 text-center">
    //     <Link to={`/station/${station.id}/episode/${episode.id}`}>
    //       {episode.image || episode.program?.image ? (
    //         <img
    //           alt={"for our station"}
    //           src={
    //             episode.image && episode.image.webp
    //               ? episode.image.webp.tile
    //               : episode.program.image && episode.program.image.webp
    //               ? episode.program.image.webp.tile
    //               : null
    //           }
    //           className={` rounded-full w-8 h-8`}
    //         />
    //       ) : (
    //         <div>
    //           <img
    //             alt={"for our station"}
    //             src={PlaceholderImage}
    //             className={`rounded-full w-8 h-8`}
    //           />
    //         </div>
    //       )}
    //     </Link>
    //   </div> */}
    //   <div className="image w-full  ">
    //     <h3 className={`${textClass} flex items-center gap-3 justify-start`}>
    //       {thisTrack && thisTrack.url && disablePlay !== true && (
    //         <PlayButton
    //           iconClass="w-6 h-6"
    //           divClass="w-6 h-6"
    //           thisTrack={thisTrack}
    //         />
    //       )}
    //       <Link
    //         to={`/station/${station.id}/episode/${episode.id}`}
    //         className={`hover:text-brandPrimary ${isOnAir ? "font-bold" : ""}`}
    //       >
    //         {isOnAir && "ONAIR - "}
    //         {episode.program.name}
    //       </Link>{" "}
    //     </h3>
    //   </div>
    // </div>
  );
}
