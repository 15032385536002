import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthState } from "../context";
import { EyeIcon, PencilAltIcon, PlusIcon } from "@heroicons/react/outline";
import { loadingColumns, loadingData } from "../config/constants";
import { StationContext } from "../context/station-context";
import Table from "../components/Table";
import ReactPaginate from "react-paginate";

// import { Link } from 'react-router-dom';

export default function Posts() {
  const { no, station_id } = useParams();
  const { station, setStation } = React.useContext(StationContext);
  let history = useHistory();
  const userDetails = useAuthState(); //read user details from context
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const [posts, setPosts] = useState([]);
  const [postPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(!no ? 1 : no);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${station_id}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    console.log("station is set", station);
  }, [station]);

  useEffect(() => {
    const http = global.services.http;
    const getPost = async () => {
      setLoading(true);
      var filter = JSON.stringify({ station_id: station_id });
      var req = await http.get(
        `${process.env.REACT_APP_API}/posts?filter=${filter}&page=${pageNo}&limit=${postPerPage}`
      );
      if (req.data) {
        setPosts(req.data.data);
        setPageCount(req.data.meta.last_page + 1);
        setLoading(false);
      }
    };
    if (thisStation) {
      getPost();
    }
  }, [pageNo, thisStation]);

  const handlePageClick = (event) => {
    console.log(event);
    setPageNo(event.selected + 1);
    history.push(`/station/${station_id}/posts/${event.selected + 1}`);
  };

  const EditCell = ({ value, column, row }) => {
    return (
      <div className={"flex items-center gap-2"}>
        <Link
          className={
            "bg-pink-500 text-white rounded shadow py-2 px-4 text-uppercase text-sm flex gap-2"
          }
          to={`/station/${station_id}/post/${row.original.id}`}
        >
          <EyeIcon className="w-5 h-5" />
          View
        </Link>

        {(userDetails.role === "admin" || userDetails.role === "librarian") && (
          <Link
            className={
              "bg-brandDark text-white rounded shadow py-2 px-4 text-uppercase text-sm flex gap-2"
            }
            to={`/station/${station_id}/post/${row.original.id}/edit`}
          >
            <PencilAltIcon className="w-5 h-5" />
            Edit
          </Link>
        )}
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Post Title",
        accessor: "title",
      },
      {
        Header: "Action",
        accessor: "created_at",
        Cell: EditCell,
      },
    ],
    []
  );

  const TableHeader = () => {
    return (
      <>
        <div
          className={
            "text-blueGray-700 text-xl font-bold flex justify-between items-center"
          }
        >
          <span>{`Post ${thisStation ? `for ${thisStation.name}` : ""}`}</span>
          {(userDetails.role === "admin" ||
            userDetails.role === "librarian") && (
            <Link
              to={`/station/${station_id}/post/new`}
              className=" bg-brandDark text-white rounded shadow py-2 px-4 text-uppercase text-sm rounded"
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              <span>Add New Post</span>
            </Link>
          )}
        </div>
      </>
    );
  };

  return (
    <Layout
      route="posts"
      title={`Post ${thisStation ? `for ${thisStation.name}` : ""}`}
    >
      <div className="block w-full overflow-x-auto">
        <>
          {posts && (
            <Table
              columns={loading ? loadingColumns : columns}
              data={loading ? loadingData : posts}
              content={<TableHeader />}
            />
          )}
          <div className="shadow-lg rounded mx-auto px-0 py-3 flex items-center justify-around border-t border-gray-200 ">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount - 1}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName={"paginator"}
              forcePage={pageNo - 1}
            />
          </div>
        </>
      </div>
    </Layout>
  );
}
