import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import AudioPlayer from "../AudioPlayer";
import DownloadAudio from "../DownloadAudio";
import Slider from "rc-slider";
import moment from "moment";
import "rc-slider/assets/index.css";

export default function Chopper({ station = null }) {
  const [date, setDate] = useState(new Date());
  const [url, setUrl] = useState(null);
  const [duration, setDuration] = useState(300);
  const [dec, setDec] = useState(5);
  const SIX_MONTHS_AGO = new Date();
  const [min, setMin] = useState(
    SIX_MONTHS_AGO.setMonth(SIX_MONTHS_AGO.getMonth() - 5)
  );

  const onChangeSlider = (value) => {
    const minutes = value / 60;
    setDuration(value);
    setDec(minutes.toFixed(2));
    // let hlsDate = convertUTCToTimezone(date, null, station.timezone, "");

    let hlsDate = moment(date).unix();
    setUrl(
      `${process.env.REACT_APP_API}/public/ondemand/${station.hls_stream}/96/${hlsDate}/${duration}/listen.m3u8?unique=console`
    );
  };

  const onChangeDate = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      if (isNaN(value)) {
        // d.getTime() or d.valueOf() will also work
        // date object is not valid
      } else {
        setDate(value);

        let hlsDate = moment(value).unix();
        setUrl(
          `${process.env.REACT_APP_API}/public/ondemand/${station.hls_stream}/96/${hlsDate}/${duration}/listen.m3u8?unique=console`
        );
      }
    }

    // let hlsDate = convertUTCToTimezone(value, null, station.timezone, "");
    // setUrl(
    //   `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${hlsDate}/${duration}/playlist.m3u8?unique=chopper`
    // );
  };

  const CustomTimeInput = ({ date, onChangeCustom }) => {
    const value =
      date instanceof Date && !isNaN(date)
        ? // Getting time from Date beacuse `value` comes here without seconds
          date.toLocaleTimeString("it-IT")
        : "";

    return (
      <input
        className="bp3-input bp3-fill"
        type="time"
        step="5"
        value={value}
        onChange={(event) => onChangeCustom(date, event.target.value)}
      />
    );
  };

  const handleChangeTime = (date, time) => {
    const [hh, mm, ss] = time.split(":");
    const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
    // setDate(targetDate);
    console.log("here");
    setDate(targetDate);
    let hlsDate = moment(targetDate).unix();
    setUrl(
      `${process.env.REACT_APP_API}/public/ondemand/${station.hls_stream}/96/${hlsDate}/${duration}/listen.m3u8?unique=console`
    );
    // setUrl(
    //   `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${hlsDate}/${duration}/playlist.m3u8?unique=chopper`
    // );
  };

  return (
    <div className=" ">
      <p className="text-xs mb-2">
        On this page you can download sections of your stream by choosing a
        duration and start time.
      </p>
      <p className="text-xs mb-5">
        You can preview the audio you are downloading in the audio player before
        starting your download. Please be patient when queing a download, and do
        not refresh the page while it is loading as your download will be lost
        if you do.
      </p>
      <p className="pb-4 text-xs font-bold">
        All Times are using the {station.timezone} timezone.
      </p>
      <div className="mb-4">
        <p className="pb-4">
          Duration: {duration} Seconds, {dec && dec} Minutes
        </p>
        <label className="text-xs pb-6">
          Use the slider to set your duration.
        </label>

        <Slider min={30} max={10800} onChange={onChangeSlider} />
      </div>
      <div>
        <label className="text-xs mb-2">Select a date</label>
        <br />
        <ReactDatePicker
          id="date-picker"
          className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700 mb-4"
          onChange={onChangeDate}
          onBlur={onChangeDate}
          selected={date}
          showTimeInput
          timeIntervals={1}
          customTimeInput={
            <CustomTimeInput onChangeCustom={handleChangeTime} />
          }
          dateFormat="d, MMMM, yyyy h:mm:ss aa"
          minDate={min}
        />
      </div>
      {/* <p>{url && url}</p> */}
      {url && (
        <div>
          <AudioPlayer url={url} index={0} />
          {url && (
            <p className={"mt-4 mb-4"}>
              You have selected <span className="font-bold">{dec}</span> minutes
              starting at{" "}
              <span className="font-bold">
                {moment(date).format("h:mm:ss a, dddd, MMMM Do YYYY")}
              </span>
              .
            </p>
          )}
          <DownloadAudio stationCallsign={station.hls_stream} url={url} />
        </div>
      )}
    </div>
  );
}
