import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import constants from "../../constants";
import TimePicker from "react-time-picker";
import ReactDatePicker from "react-datepicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ProgramAutoComplete from "../ProgramAutoComplete";
import { XCircleIcon } from "@heroicons/react/outline";
import moment from "moment-timezone";

const pad = (d) => {
  return d < 10 ? "0" + d.toString() : d.toString();
};

const schema = yup.object().shape({
  // program_id: yup.number().required(),
  station_id: yup.number().required(),
  duration: yup.number().required(),
  start_weekday: yup.number().required(),
  start_date: yup.date().required(),
  title: yup.string().max(254),
});

const default_values = {
  duration: 0,
  name: "",
  bio: "",
  programs: [],
  start_date: new Date(),
  title: "",
};

export default function AddSlot({
  slot = null,
  station_id,
  onComplete = null,
  slots = [],
  program = null,
  programs = [],
  station = null,
}) {
  const [time, onChange] = useState(
    slot ? `${pad(slot.hour_start)}:${pad(slot.minute_start)}` : "00:00"
  );
  const [programsToPick, setProgramsToPick] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPrograms, setSelectedPrograms] = useState(
    slot && slot.programs ? slot.programs : []
  );
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const http = global.services.http;

  const onSubmit = (data) => {
    if (loading) {
      return;
    }
    console.log(time);
    let week_seconds = data.start_weekday * 24 * 60 * 60;

    let splitTime = time.split(":");
    let hoursToSeconds = parseInt(splitTime[0]) * 60 * 60;
    let minutesToSeconds = parseInt(splitTime[1]) * 60;
    let seconds_from_sunday = week_seconds + hoursToSeconds + minutesToSeconds;
    let final = { ...data, duration: data.duration * 60, seconds_from_sunday };
    addSlot(final);
  };

  React.useEffect(() => {
    const http = global.services.http;
    const getProgram = async () => {
      if (program !== null) {
        setProgramsToPick([program]);
        return;
      }
      var filter = JSON.stringify({
        station_id: slot ? slot.station_id : station_id,
      });
      var req = await http.get(
        `${process.env.REACT_APP_API}/programs?filter=${filter}&limit=1000`
      );
      if (req.data) {
        setProgramsToPick(req.data.data);
      }
    };
    getProgram();
  }, []);

  const addSlot = async (fields) => {
    setLoading(true);
    // check that time isnt taken up
    const checkExists = slots.filter(
      (item) => item.seconds_from_sunday === fields.seconds_from_sunday
    );

    if (selectedPrograms.length == 0) {
      alert("Please select a program");
      setLoading(false);
      return;
    }

    if (checkExists.length > 0 && slot === null) {
      alert("A program already exists in this slot");
      setLoading(false);
      return;
    }

    let payload = {
      ...fields,
      programs: selectedPrograms.map((item) => item.id),
    };

    // const checkOverLaps = slots.filter(
    //   (item) =>
    //     item.seconds_from_sunday + item.duration > fields.seconds_from_sunday &&
    //     item.seconds_from_sunday < fields.seconds_from_sunday
    // );

    // if (checkOverLaps.length > 0) {
    //   alert(`Oops! this one overlaps with ${checkOverLaps[0].program.name}`);
    //   setLoading(false);
    //   return;
    // }

    var req;
    if (slot) {
      req = await http.put(
        `${process.env.REACT_APP_API}/slots/${slot.id}`,
        payload
      );
    } else {
      req = await http.post(`${process.env.REACT_APP_API}/slots`, payload);
    }

    if (req) {
      console.log(req);
      // if(slot){
      setSelectedPrograms([]);
      onComplete();
      // }
      reset();
      setLoading(false);
      // reset(default_values);
    }
  };

  React.useEffect(() => {
    console.log("Errors", errors);
  }, [errors]);

  const addProgram = async (program) => {
    console.log("Program", program);
    setSelectedPrograms([...selectedPrograms, program]);
  };

  const removeProgramViaIndex = (index) => {
    let programs = selectedPrograms;
    programs.splice(index, 1);
    setSelectedPrograms([...programs]);
  };

  //   console.log(watch("example")); // watch input value by passing the name of it

  return (
    <div
      className={
        "add-slot w-full p-4 bg-white rounded-xl shadown-xl mb-4 md:mb-0 relative "
      }
    >
      {slot && (
        <div className="absolute top-0 right-0">
          <button
            className="text-xs p-2 text-gray-500 hover:text-gray-900 transition-all flex items-center flex-nowrap gap-2"
            onClick={() => onComplete()}
          >
            Cancel <XCircleIcon className="h-3 w-3" />
          </button>
        </div>
      )}
      {!slot ? (
        <h2 className={"text-xl  font-bold mb-4"}>Add Program Slot</h2>
      ) : (
        <h2 className={"text-xl mb-4  font-bold "}>Edit Program Slot</h2>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={
          "text-sm block lg:flex lg:items-start gap-2 justify-between flex-wrap"
        }
      >
        <input
          type="hidden"
          {...register("station_id", { required: true })}
          defaultValue={slot ? slot.station_id : station_id}
        />
        <div className={"mb-4 md:mb-0 w-full"}>
          <label
            htmlFor="title"
            className={`block font-bold text-sm mb-2 text-gray-800`}
          >
            Custom Grid Title
          </label>
          <input
            type="text"
            className={
              "block w-full bg-transparent outline-none  py-2 px-4  placeholder-gray-300 focus:bg-blue-50  border-gray-300 border rounded-md "
            }
            defaultValue={slot && slot.title ? slot.title : ""}
            {...register("title", { required: false })}
            placeholder={"Enter a custom title for your grid"}
          />
          {errors.title && (
            <span>
              This field is optional, and cannot exceed 254 characters.
            </span>
          )}
        </div>

        {/* <div className={"mb-4 md:mb-0 grow"}>
          <label
            htmlFor="program_id"
            className={`block font-bold text-sm mb-2 ${
              errors.program_id ? "text-red-400" : "text-gray-800"
            }`}
          >
            Select Program
          </label>
          {programsToPick && (
            <select
              className={
                "block w-full bg-transparent outline-none  py-2 px-4 placeholder-blue-500 focus:bg-blue-50 "
              }
              {...register("program_id")}
              defaultValue={slot ? slot.program_id : ""}
            >
              {programsToPick.map((program, i) => (
                <option key={`${i}p`} value={program.id}>
                  {program.name}
                </option>
              ))}
            </select>
          )}
        </div> */}
        <div className={"mb-4 md:mb-0 grow"}>
          <label
            htmlFor="start_date"
            className={`block font-bold text-sm mb-2 ${
              errors.start_date ? "text-red-400" : "text-gray-800"
            }`}
          >
            Start Date
          </label>
          <Controller
            control={control}
            name="start_date"
            className="input"
            defaultValue={
              slot
                ? new Date(slot.start_date)
                : moment()
                    .tz("UTC")
                    .startOf("week")
                    // .add(1, "days")
                    .toDate()
            }
            placeholderText="Select date"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <ReactDatePicker
                className="border-solid border-gray-300 border py-2 px-4 w-full
                rounded text-gray-700"
                onChange={onChange}
                onBlur={onBlur}
                includeDateIntervals={[
                  {
                    start: moment()
                      .startOf("week")
                      .subtract("28", "days")
                      .toDate(),
                    end: moment().toDate(),
                  },
                ]}
                selected={value}
                // timeIntervals={1}
                dateFormat="P"
              />
            )}
          />
          <p className="text-gray-400 text-xs mt-2">
            Please use a sunday before today.{" "}
          </p>
        </div>
        <div className={"mb-4 md:mb-0 grow"}>
          <label
            htmlFor="start_weekday"
            className={`block font-bold text-sm mb-2 ${
              errors.start_weekday ? "text-red-400" : "text-gray-800"
            }`}
          >
            Select Day
          </label>
          <select
            className={
              "block w-full outline-none  py-2 px-2 placeholder-blue-500 focus:bg-blue-50 border-gray-300 border rounded-md"
            }
            {...register("start_weekday")}
            defaultValue={slot ? slot.weekday_start : ""}
          >
            {constants.days.map((value, i) => (
              <option key={i} value={i}>
                {value}
              </option>
            ))}
          </select>
        </div>
        <div className={"mb-4 md:mb-0 grow"}>
          <label
            htmlFor="time"
            className={`block font-bold text-sm mb-2 text-gray-800`}
          >
            Start Time
          </label>
          <TimePicker
            className={
              "block w-full outline-none  placeholder-blue-500 focus:bg-blue-50 "
            }
            disableClock={true}
            onChange={onChange}
            value={time}
          />
        </div>
        <div className={"mb-4 md:mb-0 md:mb-0 grow"}>
          <label
            htmlFor="duration"
            className={`block font-bold text-sm mb-2 text-gray-800`}
          >
            Set Duration (mins)
          </label>
          <input
            type="number"
            step="5"
            className={
              "block w-full bg-transparent outline-none  py-2 px-4  placeholder-blue-500 focus:bg-blue-50  border-gray-300 border rounded-md "
            }
            defaultValue={slot ? slot.duration / 60 : 60}
            {...register("duration", { required: true })}
            placeholder={"Duration in minutes"}
          />
          {errors.duration && <span>This field is required</span>}
        </div>

        <div className="w-full flex items-start gap-2 -mb-2 mt-2">
          <label
            htmlFor="programs"
            className={`block font-bold text-sm mb-2 text-gray-800`}
          >
            Program(s)
          </label>
        </div>
        <div className="w-full flex items-start gap-2">
          <div className="w-full md:w-1/2">
            <ProgramAutoComplete
              station={station}
              onSet={(program) => {
                addProgram(program);
              }}
            />
            <p className="text-gray-400 text-xs mt-2">
              Search for programs to add to this slot. Multiple programs can be
              added to a single slot.
            </p>
          </div>
          <div className="w-full md:w-1/2 flex gap-4 items-center flex-wrap">
            {selectedPrograms && selectedPrograms.length > 0 ? (
              selectedPrograms.map((program, i) => (
                <button
                  key={i}
                  className="px-4 py-2 text-white bg-blue-500 transition-all hover:bg-blue-400 flex gap-2 items-center flex-nowrap rounded-full"
                  onClick={(e) => {
                    e.preventDefault();
                    removeProgramViaIndex(i);
                  }}
                >
                  {program.name}
                  <XCircleIcon className="w-4 h-4 " />
                </button>
              ))
            ) : (
              <p className="text-gray-400 text-xs mt-3">
                No programs added yet, search and add programs to this slot.
              </p>
            )}
          </div>
        </div>
        <div>
          <input
            type="submit"
            className={"bg-lightBlue-600 rounded px-4 py-2 text-white"}
            value={loading ? "Loading" : slot ? "Edit Slot" : "Add Slot"}
          />
        </div>
      </form>
    </div>
  );
}
