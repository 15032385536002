import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm } from "react-hook-form";

import { useAuthState } from "../../context";
import { useHistory, Link } from "react-router-dom";
import * as yup from "yup";
import Spinner from "../Spinner";

const fieldsList = [
  {
    input: "input",
    type: "text",
    name: "name",
    title: "Stream Name",
    placeholder: "Give this stream a name",
    required: true,
  },
  {
    input: "input",
    type: "text",
    name: "admin_user",
    title: "Stream Admin Username",
    placeholder: "Give this stream a name",
    required: true,
  },
  {
    input: "input",
    type: "password",
    name: "admin_password",
    title: "Stream Admin Password",
    placeholder: "Stream Admin Password",
    required: true,
  },
  {
    input: "input",
    type: "text",
    name: "admin_url",
    title: "Base URL for Icecast Admin",
    placeholder: "Base URL for Icecast Admin",
    required: true,
  },
  {
    input: "input",
    type: "text",
    name: "mount_point",
    title: "Mount Point",
    placeholder: "Please include the forward slash at the start (eg /stream)",
    required: true,
  },
  {
    input: "input",
    type: "text",
    name: "recording_url",
    title: "Recording Stream URL",
    placeholder: "The private URL for your stream",
    required: true,
  },
  {
    input: "select",
    type: "text",
    name: "type",
    title: "Stream Type",
    required: true,
    options: [
      {
        name: "Icecast",
        value: "icecast",
      },
    ],
  },
  {
    input: "input",
    type: "text",
    name: "public_url",
    title: "Public Stream URL",
    placeholder: "The public facing URL for your stream",
    required: true,
  },
];

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  bio: yup.string(),
});

export default function StreamForm({ id = null, stationId = null }) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const userDetails = useAuthState();
  const { station, setStation } = React.useContext(StationContext);
  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stream, setStream] = useState(null);
  const [disabled, setDisabled] = useState(
    stream && stream.explicit === 1 ? 1 : 0
  );
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  useEffect(() => {
    const http = global.services.http;
    const getStation = async () => {
      var req = await http.get(
        `${process.env.REACT_APP_API}/stations/${stationId}`
      );
      if (req.data) {
        setThisStation(req.data.data);
        setStation(req.data.data);
      }
    };

    if (!station) {
      getStation();
    } else {
      // setThisStation()
    }
    if (userDetails && userDetails.role !== "admin") {
      history.push("/");
    }
  }, [stationId]);

  useEffect(() => {
    const http = global.services.http;
    const getStream = async () => {
      var req = await http.get(`${process.env.REACT_APP_API}/streams/${id}`);
      if (req) {
        setStream(req.data.data);
        setDisabled(req.data.data && req.data.data.disabled === 1 ? 1 : 0);
      }
    };
    if (id) {
      getStream();
    }
  }, [id]);

  const streamSubmit = async (payload) => {
    if (loading === true) return;
    let fields = { ...payload, station_id: stationId };
    fields.disabled = disabled;
    console.log(userDetails);

    if (!id) {
      fields.is_counted = 1;
      fields.active = 1;
    }

    // handle submitting the form
    setLoading(true);
    try {
      if (id) {
        var req = await http.put(
          `${process.env.REACT_APP_API}/streams/${id}`,
          fields
        );
      } else {
        var req = await http.post(
          `${process.env.REACT_APP_API}/streams`,
          fields
        );
      }

      if (req.status === 422) {
        if (req.data.data) {
          if (req.data.data.name) {
            setError("name", {
              type: "manual",
              message: req.data.data.name[0],
            });
          }
          if (req.data.data.bio) {
            setError("bio", {
              type: "manual",
              message: req.data.data.bio[0],
            });
          }
          if (req.data.data.introduction) {
            setError("introduction", {
              type: "manual",
              message: req.data.data.introduction[0],
            });
          }
          if (req.data.data.producer) {
            setError("producer", {
              type: "manual",
              message: req.data.data.producer[0],
            });
          }
          if (req.data.data.grid_one_liner) {
            setError("grid_one_liner", {
              type: "manual",
              message: req.data.data.grid_one_liner[0],
            });
          }
        }
        setLoading(false);
        return;
      } else {
        setLoading(false);
        history.push(`/`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(watch("email"));
  const Header = () => (
    <p className={"text-lg font-bold"}>{`Edit Stream: ${
      id ? stream && stream.name : "Add New Stream"
    }`}</p>
  );

  return (
    <div className={"max-w-full  mr-auto  bg-white shadow-lg rounded-xl"}>
      <SectionHeader content={<Header />} />
      <div className="p-4 block lg:flex bg-white gap-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        {!id || stream ? (
          <>
            <form className="w-full " onSubmit={handleSubmit(streamSubmit)}>
              {fieldsList.map((item, i) => {
                if (item.input === "input") {
                  return (
                    <div key={i} className="mb-8">
                      <label
                        htmlFor={item.name}
                        className={`block font-bold text-sm mb-2 ${
                          errors[item.name] ? "text-red-400" : "text-gray-800"
                        }`}
                      >
                        {item.title}
                      </label>
                      <input
                        type={item.type}
                        name={item.name}
                        id={item.name}
                        defaultValue={stream && stream[item.name]}
                        placeholder={item.placeholder}
                        className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                          errors.name ? "text-red-300  " : " "
                        }`}
                        {...register(item.name)}
                      />
                      {errors[item.name] && (
                        <p className="text-red-500 text-sm mt-2">
                          Oops, there was something wrong with this field.
                        </p>
                      )}
                    </div>
                  );
                }
                if (item.input === "select") {
                  return (
                    <div key={i} className="mb-8">
                      <label
                        htmlFor={item.name}
                        className={`block font-bold text-sm mb-2 ${
                          errors[item.name] ? "text-red-400" : "text-gray-800"
                        }`}
                      >
                        {item.title}
                      </label>
                      <select
                        type={item.type}
                        name={item.name}
                        id={item.name}
                        defaultValue={stream && stream[item.name]}
                        placeholder={item.placeholder}
                        className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                          errors.name ? "text-red-300  " : " "
                        }`}
                        {...register(item.name)}
                      >
                        {item.options.map((option, k) => (
                          <option key={`o${i}${k}`} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {errors[item.name] && (
                        <p className="text-red-500 text-sm mt-2">
                          Oops, there was something wrong with this field.
                        </p>
                      )}
                    </div>
                  );
                }
              })}

              <button className="w-full default-button  ">
                {!id ? "Create New Stream" : "Edit Stream"}
                {loading && <Spinner className="w-4 h-4 ml-2" />}
              </button>
              <p className=" text-center mt-4">
                <Link
                  className="ml-auto inline-block bg-gray-200 text-gray-800 rounded shadow py-1 px-3 text-xs"
                  to={`/station/${stationId}`}
                >
                  Cancel
                </Link>
              </p>
            </form>
          </>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
