import React, { useContext } from "react";
import Header from "./Header";
import PageTitle from "./PageTitle";
// import Background from "./Background";
// import Toggle from "./ThemeToggle";
import { ToastContainer } from "react-toastify";
import { RadioContext } from "../RadioContext";
import Sidebar from "./Sidebar";
import FloatingAudioPlayer from "./FloatingAudioPlayer";
import Alerts from "./Alerts";
import Studio from "./Studio";
import NewHeader from "./NewHeader";
import NewSidebar from "./NewSidebar";
const ONESECOND = `https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3`;

const Layout = ({
  isHomePage,
  children,
  title = null,
  route = null,
  classString = "max-w-7xl",
}) => {
  const { track } = useContext(RadioContext);

  const donateClicked = () => {
    window.gtag("event", "donate_clicked", {
      location: "footer",
    });
  };

  return (
    <div className={"w-full"} data-is-root-path={isHomePage}>
      {/* <div className="absolute right-0 top-0 mr-4 mt-4 md:mr-4 md:mt-4">
          <Toggle />
        </div> */}
      <ToastContainer
        autoClose={1000}
        hideProgressBar={true}
        pauseOnHover
        closeOnClick
        newestOnTop={true}
      />
      <div className=" w-full relative">
        <NewHeader route={route} />
        <div className="flex align-stretch gap-4 min-h-[80vh] site-wrapper">
          <NewSidebar route={route} />
          <div className={"w-full"}>
            {title && <PageTitle title={title} />}
            <main
              className={`z-20 relative bg-background w-full  ${
                track && track.url !== ONESECOND ? "pb-20" : ""
              }`}
            >
              {<Alerts />}
              <div className={`page-content p-4 mx-auto ${classString} w-full`}>
                {/* <!-- Replace with your content --> */}
                <div className="py-4  sm:p-2">{children}</div>
                {/* <!-- /End replace --> */}
              </div>
            </main>
          </div>
        </div>
        <footer className="w-full bg-gray-50 text-gray-700 py-12">
          <div className=" mx-auto max-w-5xl w-full">
            <p className="text-center text-sm font-bold">
              Thanks for using myradio.click.
            </p>

            <p className="text-center text-sm">
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=MB8DB5Q5KLB2Q"
                target="_blank"
                className="text-blue-500 font-bold hover:text-blue-700 underline text-lg"
                onClick={() => donateClicked()}
              >
                Donations Welcome - Buy Me a Coffee
              </a>
            </p>
          </div>
        </footer>
        <FloatingAudioPlayer />
      </div>
      {<Studio />}
    </div>
  );
};

export default Layout;
