import React from "react";
import StationForm from "../components/forms/StationForm";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import NewPageHeader from "../components/Global/NewPageHeader";

export default function EditStation() {
  const { id } = useParams();
  return (
    <Layout route="edit" title={`${id ? "Edit Station" : "Add New Station"}`}>
      <NewPageHeader
        title={`${id ? "Edit Station" : "Add New Station"}`}
        links={[]}
      />
      <StationForm id={id} />
    </Layout>
  );
}
