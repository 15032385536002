import React, { useState, useEffect, useCallback } from "react";
import { StationContext } from "../../context/station-context";
import SectionHeader from "../SectionHeader";
import { useForm } from "react-hook-form";

import { useAuthState } from "../../context";
import { useHistory, Link } from "react-router-dom";
import * as yup from "yup";
import Spinner from "../Spinner";

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  bio: yup.string(),
});

export default function WidgetGridForm({
  widget = null,
  station = null,
  onFormComplete,
  theme = null,
  loading,
  setLoading,
}) {
  //   const { id, station_id } = useParams();
  let history = useHistory();
  const userDetails = useAuthState();

  const [thisStation, setThisStation] = React.useState(
    station ? station : null
  );
  const http = global.services.http;
  const [errorMessage] = useState(null);
  const [disabled, setDisabled] = useState(
    widget && widget.explicit === 1 ? 1 : 0
  );
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver,
  });

  const widgetSubmit = async (payload) => {
    if (loading === true) return;
    let fields = { ...payload, station_id: station.id };
    fields.disabled = disabled;
    console.log(userDetails);

    // handle submitting the form
    setLoading(true);
    onFormComplete(fields);
  };

  return (
    <div className={"w-full"}>
      <div className=" block lg:flex bg-white gap-4">
        {errorMessage ? <p>{errorMessage}</p> : null}
        <>
          <form className="w-full " onSubmit={handleSubmit(widgetSubmit)}>
            <div className="mb-8">
              <label
                htmlFor={"name"}
                className={`block font-bold text-sm mb-2 ${
                  errors["name"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Name
              </label>
              <input
                type={"text"}
                name={"name"}
                id={"name"}
                defaultValue={widget && widget.name}
                placeholder={"Widget Name"}
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.name ? "text-red-300  " : " "
                }`}
                {...register("name", { required: true })}
              />
              {errors["name"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>
            <div className="mb-8">
              <label
                htmlFor={"highlight_color"}
                className={`block font-bold text-sm mb-2 ${
                  errors["highlight_color"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Highlight Color
              </label>
              <input
                type={"text"}
                name={"highlight_color"}
                id={"highlight_color"}
                defaultValue={
                  widget && theme && theme.highlight_color
                    ? theme.highlight_color
                    : "#000000"
                }
                placeholder={"Choose a highlight color"}
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.highlight_color ? "text-red-300  " : " "
                }`}
                {...register("highlight_color", { required: true })}
              />
              <p className="text-sm">
                eg. #ffffff. Choose a colour that can handle white text above
                it.
              </p>
              {errors["highlight_color"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>

            <div className="mb-8">
              <label
                htmlFor={"grid_type"}
                className={`block font-bold text-sm mb-2 ${
                  errors["paginate"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Default View (for larger screens)
              </label>
              <select
                name={"grid_type"}
                id={"grid_type"}
                defaultValue={
                  widget && theme && theme.grid_type ? theme.grid_type : "grid"
                }
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.grid_type ? "text-red-300  " : " "
                }`}
                {...register("grid_type")}
              >
                <option value={"grid"}>Grid</option>
                <option value={"list"}>List</option>
              </select>
              {errors["grid_type"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>
            <div className="mb-8">
              <label
                htmlFor={"show_intro"}
                className={`block font-bold text-sm mb-2 ${
                  errors["show_intro"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Show Program Intros? (On smaller screens)
              </label>
              <select
                name={"show_intro"}
                id={"show_intro"}
                defaultValue={
                  widget && theme && theme.show_intro === true
                    ? "true"
                    : "false"
                }
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.show_intro ? "text-red-300  " : " "
                }`}
                {...register("show_intro")}
              >
                <option value={"true"}>Yes</option>
                <option value={"false"}>No</option>
              </select>
              {errors["show_intro"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>
            <div className="mb-8">
              <label
                htmlFor={"show_language"}
                className={`block font-bold text-sm mb-2 ${
                  errors["show_language"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Show Program Language?
              </label>
              <select
                name={"show_language"}
                id={"show_language"}
                defaultValue={
                  widget && theme && theme.show_language === true
                    ? "true"
                    : "false"
                }
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.show_language ? "text-red-300  " : " "
                }`}
                {...register("show_language")}
              >
                <option value={"true"}>Yes</option>
                <option value={"false"}>No</option>
              </select>
              {errors["show_language"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>
            <div className="mb-8">
              <label
                htmlFor={"show_genre"}
                className={`block font-bold text-sm mb-2 ${
                  errors["show_genre"] ? "text-red-400" : "text-gray-800"
                }`}
              >
                Show Program Genres?
              </label>
              <select
                name={"show_genre"}
                id={"show_genre"}
                defaultValue={
                  widget && theme && theme.show_genre === true
                    ? "true"
                    : "false"
                }
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  ${
                  errors.show_genre ? "text-red-300  " : " "
                }`}
                {...register("show_genre")}
              >
                <option value={"true"}>Yes</option>
                <option value={"false"}>No</option>
              </select>
              {errors["show_genre"] && (
                <p className="text-red-500 text-sm mt-2">
                  Oops, there was something wrong with this field.
                </p>
              )}
            </div>

            <button className="w-full default-button  ">
              {!widget ? "Create New Widget" : "Edit Widget"}
              {loading && <Spinner className="w-4 h-4 ml-2" />}
            </button>
            <p className=" text-center mt-4">
              <Link
                className="ml-auto inline-block bg-gray-200 text-gray-800 rounded shadow py-1 px-3 text-xs"
                to={`/station/${station.id}/widgets`}
              >
                Cancel
              </Link>
            </p>
          </form>
        </>
      </div>
    </div>
  );
}
