import React, { useContext } from "react";
import PlaceholderImage from "../images/placeholder.png";
import AudioPlayer from "./AudioPlayer";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import DownloadAudio from "./DownloadAudio";
import { ArrowCircleRightIcon, TrashIcon } from "@heroicons/react/outline";
import { RadioContext } from "../RadioContext";
import { useAuthState } from "../context";
import PlayButton from "./PlayButton";
import { StationContext } from "../context/station-context";
import "moment-timezone";
import { buildEpisodeTrack } from "../config/functions";
import ProgressBarImage from "./ProgressBarImage";
import EpisodeReportTiny from "./EpisodeReportTiny";

function convertUTCToTimezone(
  utcDt,
  utcDtFormat,
  timezone,
  targetFormat = "dddd, MMMM Do YYYY, h:mm a"
) {
  return moment.utc(utcDt, utcDtFormat).tz(timezone).format(targetFormat);
}

export default function EpisodeItem({
  station,
  episode,
  index,
  showPlayer = true,
  onComplete = null,
}) {
  const { updateTime, track } = useContext(RadioContext);
  const thisTrack = buildEpisodeTrack({ episode });
  let history = useHistory();
  const userDetails = useAuthState(); //read user details from context
  const http = global.services.http;

  const deleteEpisode = async () => {
    if (window.confirm("Are you sure you want to delete this item?") !== true) {
      return;
    }
    var req = await http.delete(
      `${process.env.REACT_APP_API}/episodes/${episode.id}`
    );
    if (req.data) {
      if (onComplete) {
        onComplete();
      } else {
        history.push(`/station/${station.id}/episodes`);
      }
    }
  };

  return (
    <div className="w-full md:flex bg-white shadow-xl text-gray-700 mb-4 p-4 gap-4 relative group">
      <div className="image  lg:flex-0 mb-2 md:mb-0 text-center">
        <Link to={`/station/${station.id}/episode/${episode.id}`}>
          {episode.image || episode.program?.image ? (
            <img
              alt={"for our station"}
              src={
                episode.image && episode.image.webp
                  ? episode.image.webp.tile
                  : episode.program.image && episode.program.image.webp
                  ? episode.program.image.webp.tile
                  : null
              }
              style={{
                width: "100px",
                height: "auto",
              }}
            />
          ) : (
            <div>
              <img
                alt={"for our station"}
                src={PlaceholderImage}
                style={{
                  width: "100px",
                  height: "auto",
                }}
              />
            </div>
          )}
        </Link>
      </div>
      <div className="image w-full  flex-grow flex-1">
        <Link to={`/station/${station.id}/episode/${episode.id}`}>
          <h3 className={"font-bold text-xl lg:text-2xl mb-2"}>
            {episode.program.name}
          </h3>
        </Link>
        {episode.title && <p className="font-bold">Title: {episode.title}</p>}
        <p className={"mb-2 text-xs"}>{episode.readable}</p>
        {episode.report && <EpisodeReportTiny report={episode.report} />}
        <div className="flex gap-4 items-center">
          {thisTrack && <PlayButton thisTrack={thisTrack} />}
          {/* <div className="w-full">
            {track &&
            thisTrack &&
            track.url === thisTrack.url &&
            episode.waveform ? (
              <ProgressBarImage
                index={episode.id}
                episode={episode}
                bgcolor={"rgba(0,0,0,0.2"}
              />
            ) : episode && episode.waveform ? (
              <img
                style={{ height: "60px", width: "100%" }}
                src={episode.waveform.url}
              />
            ) : null}
          </div> */}
          <div>
            {/* {moment().unix() > episode.timestamp && (
              <div>
                <Link
                  to={`/station/${station.id}/episode/${episode.id}/reports`}
                >
                  <span className="text-red-500 font-bold ">"NEW!"</span>{" "}
                  Reports (beta){" "}
                  <ArrowCircleRightIcon className="inline w-4 h-4" />
                </Link>
              </div>
            )}*/}
          </div>
        </div>

        {/* {moment().isAfter(startStream) &&
          episode &&
          episode.station.hls_stream &&
          showPlayer === false && (
            <div className="mt-4">
              <DownloadAudio
                url={streamUrl}
                stationCallsign={station && station.hls_stream}
              />
            </div>
          )} */}

        {/* {episode.waveform && <img src={episode.waveform.url} />} */}

        {((station && userDetails && userDetails.id === station.owner_id) ||
          (station && userDetails.role === "admin")) && (
          <button
            className="p-2 bg-red-500 rounded text-white absolute top-0 right-0 hidden group-hover:inline"
            onClick={() => deleteEpisode()}
          >
            <TrashIcon className="h-2 w-2" />
          </button>
        )}
      </div>
    </div>
  );
}
