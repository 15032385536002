import React from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  TrashIcon,
} from "@heroicons/react/outline";
// import axios from "axios";
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthState } from "../../context";
import { loadingColumns, loadingData } from "../../config/constants";
import Table from "../Table";
import ReactPaginate from "react-paginate";

// import { Link } from 'react-router-dom';

export default function InviteList({
  invites,
  pageNo,
  setPageNo,
  pageCount,
  setPageCount,
  no,
  handlePageClick,
  loading,
  setLoading,
  station = null,
  reload,
}) {
  let history = useHistory();
  const inviteDetails = useAuthState(); //read invite details from context
  const http = global.services.http;
  const resend = async (id) => {
    setLoading(true);
    var req = await http.post(
      `${process.env.REACT_APP_API}/invites/resend/${id}`,
      { station_id: station.id }
    );
    if (req.data) {
      alert("Invite has been resent");
      reload();
    }
  };
  const deleteInvite = async (id) => {
    if (
      window.confirm("Are you sure you want to delete this invite?") !== true
    ) {
      return;
    }
    setLoading(true);
    var req = await http.delete(`${process.env.REACT_APP_API}/invites/${id}`);
    if (req.data) {
      alert("Invite has been deleted");
      reload();
    }
  };

  const EditCell = ({ value, column, row }) => {
    return (
      <div className="flex gap-2 justify-end">
        {(inviteDetails.role === "admin" ||
          station.owner_id === inviteDetails.userDetails.id) &&
          row.original.completed !== 1 && (
            <button
              className={
                " border-blue-600 border text-blue-600 hover:bg-blue-600 hover:text-white rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
              }
              onClick={() => resend(row.original.id)}
            >
              Resend
            </button>
          )}
        {(inviteDetails.role === "admin" ||
          station.owner_id === inviteDetails.userDetails.id) && (
          <button
            className={
              " border-red-600 border text-red-600 hover:bg-red-600 hover:text-white rounded shadow py-2 px-4 text-uppercase text-xs flex gap-2 items-center"
            }
            onClick={() => deleteInvite(row.original.id)}
          >
            <TrashIcon className="w-4 h-4" />
          </button>
        )}
      </div>
    );
  };

  const RoleCell = ({ value, column, row }) => {
    return row.original.role ? row.original.role.role : false;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Sent At",
        accessor: "created_at",
      },
      {
        Header: "Valid Until",
        accessor: "valid_until",
      },
      {
        Header: "Accepted",
        accessor: "completed",
      },
      {
        Header: "Completed At",
        accessor: "completed_at",
      },
      {
        Header: "Action",
        Cell: EditCell,
        accessor: "updated_at",
      },
    ],
    []
  );

  return (
    <div>
      <div className="block w-full overflow-x-auto bg-white ">
        <>
          {invites && (
            <Table
              columns={loading ? loadingColumns : columns}
              data={loading ? loadingData : invites}
            />
          )}
          <div className="bg-white px-0 py-3 flex items-center justify-between border-t border-gray-200 ">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<ChevronDoubleRightIcon className="w-5 h-5" />}
              previousLabel={<ChevronDoubleLeftIcon className="w-5 h-5" />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount - 1}
              renderOnZeroPageCount={null}
              containerClassName={"paginator"}
              forcePage={pageNo - 1}
            />
          </div>
        </>
      </div>
    </div>
  );
}
